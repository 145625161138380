import React, { useState } from 'react';
import { Alert, Card, Select, Space } from 'antd';
import { capitalize } from 'lodash';
import styled from 'styled-components';

import getTextCatalog from '../../services/I18nService';
import CdrEmptyState from '../../shared/components/CdrEmptyState';
import gettextCatalog from '../../services/I18nService';
import { CdWebsiteAnalytics } from '../../shared/components/Icons';
import cdApp from '../../config';

import { WebsiteAnalyticsInterface } from './types/widget.type';

const StyledCard = styled(Card)`
  &&&& .ant-card-body {
    padding: 0;
    width: 100%;
    background-color: #f0f2f5;
  }
`;
interface WebsiteAnalyticsProps {
  items: WebsiteAnalyticsInterface[];
}
export const WebsiteAnalytics = ({
  websitesWithAnalytics,
}: {
  websitesWithAnalytics: WebsiteAnalyticsProps;
}) => {
  const [viewAnalytics, setViewAnalytics] = useState(
    websitesWithAnalytics?.items[0]
  );

  return (
    <Space direction="vertical" size={null}>
      <Select
        optionFilterProp="children"
        showSearch
        size="large"
        style={{ width: 300, marginTop: '16px', marginLeft: '24px' }}
        placeholder={
          viewAnalytics
            ? getTextCatalog.getString('Select a website to view analytics')
            : getTextCatalog.getString('No website available')
        }
        onChange={(value) => {
          const newWebsite = websitesWithAnalytics?.items.find(
            (val) => val.siteId === value
          );
          setViewAnalytics(newWebsite);
        }}
        value={viewAnalytics?.siteId}
      >
        {websitesWithAnalytics?.items &&
          websitesWithAnalytics?.items.map((website) => (
            <Select.Option key={website.siteId} value={website.siteId}>
              {capitalize(website.domain)}
            </Select.Option>
          ))}
      </Select>
      <StyledCard
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
        }}
      >
        {viewAnalytics?.sharedLink ? (
          <iframe
            src={`${viewAnalytics?.sharedLink}&embed=true&theme=light&background=transparent&width=manual`}
            width="100%"
            height="2000px"
            style={{ border: '0' }}
          />
        ) : (
          <CdrEmptyState
            title={gettextCatalog.getString(
              'No website available to view analytics'
            )}
            EmptyStateIcon={<CdWebsiteAnalytics />}
          />
        )}
        {viewAnalytics &&
          cdApp.organization?.locale?.language !== 'en-gb' &&
          cdApp.organization?.locale?.language !== 'en' && (
            <Space
              style={{
                margin: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Alert
                style={{
                  margin: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  boxShadow:
                    'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                }}
                description={getTextCatalog.getString(
                  'The analytics tool is made in collaboration with a partner that specializes in creating privacy-focused analytics tools for websites. It is therefore not possible to create a Danish/German language version of this dashboard. We have placed a higher priority on ensuring the best possible privacy and data security solution for our customers.'
                )}
                type="info"
                showIcon
              />
            </Space>
          )}
      </StyledCard>
    </Space>
  );
};
