import { $injector } from 'ngimport';
import type { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import {
  IntentionFee,
  IntentionFeeTypes,
  IntentionFeeRecipient,
} from '../models/fee';
import gettextCatalog from '../../services/I18nService';
import { handleSuccessMessage } from '../../shared/utils';
import { navigate } from '../../services/StateServiceFactory';
import IntentionFeeService, {
  IntentionFeesData,
} from '../services/IntentionFeeService';
import {
  CreateIntentionFeeActionType,
  DeleteIntentionFeeActionType,
  FetchIntentionFeeActionType,
  FetchIntentionFeesActionType,
  UpdateIntentionFeeActionType,
  clearIntentionFee,
  fetchIntentionFeeSuccess,
  fetchIntentionFees,
  fetchIntentionFeesSuccess,
  fetchStolesFeesSuccess,
  fetchIntentionFeeRecipientsSuccess,
  fetchIntentionFeeRecipients,
  DeleteIntentionFeeRecipientActionType,
  CreateIntentionFeeRecipientActionType,
  UpdateIntentionFeeRecipientActionType,
  FetchIntentionFeeRecipientsActionType,
} from '../redux/intention-fees/Actions';

export function* getIntentionFeesSaga(
  action: FetchIntentionFeesActionType
): SagaIterator {
  const { type } = action.payload;
  const data = yield call(IntentionFeeService.getIntentionFees, action.payload);
  if (type === IntentionFeeTypes.INTENTIONEN) {
    yield put(fetchIntentionFeesSuccess(data as IntentionFeesData));
  } else if (type === IntentionFeeTypes.STOLARIEN) {
    yield put(fetchStolesFeesSuccess(data as IntentionFeesData));
  }
}

export function* getIntentionFeeSaga(
  action: FetchIntentionFeeActionType
): SagaIterator {
  const data = yield call(IntentionFeeService.getIntentionFee, action.payload);
  yield put(fetchIntentionFeeSuccess(data as IntentionFee));
}

export function* createIntentionFeeSaga(
  action: CreateIntentionFeeActionType
): SagaIterator {
  const createPayload = action.payload;
  yield call(IntentionFeeService.createIntentionFee, createPayload);
  // Show success message
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully created fee.')
  );
  // Refresh intentions by navigating
  navigate('app.private.settings.fees.overview', { tab: createPayload.type });
}

export function* updateIntentionFeeSaga(
  action: UpdateIntentionFeeActionType
): SagaIterator {
  const { id, updatePayload } = action.payload;
  yield call(IntentionFeeService.updateIntentionFee, id, updatePayload);
  // Clear the selected intention
  yield put(clearIntentionFee());
  // Show success message
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully updated fee.')
  );
  // Refresh intentions by navigating
  navigate('app.private.settings.fees.overview', { tab: updatePayload.type });
}

export function* deleteIntentionFeeSaga(
  action: DeleteIntentionFeeActionType
): SagaIterator {
  const { id, type } = action.payload;
  yield call(IntentionFeeService.deleteIntentionFee, id);
  // Refresh intentions by re-fetching
  yield put(fetchIntentionFees({ type }));
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully deleted fee.')
  );
}

export function* getIntentionFeeRecipientsSaga(
  action: FetchIntentionFeeRecipientsActionType
): SagaIterator {
  const data = yield call(
    IntentionFeeService.getIntentionFeeRecipients,
    action.payload
  );
  yield put(
    fetchIntentionFeeRecipientsSuccess(data as IntentionFeeRecipient[])
  );
}

export function* createIntentionFeeRecipientSaga(
  action: CreateIntentionFeeRecipientActionType
): SagaIterator {
  yield call(IntentionFeeService.createIntentionFeeRecipient, action.payload);
  // Refresh intentions by re-fetching
  yield put(fetchIntentionFeeRecipients());
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully created recipient.')
  );
}

export function* updateIntentionFeeRecipientSaga(
  action: UpdateIntentionFeeRecipientActionType
): SagaIterator {
  const { id, updatePayload } = action.payload;
  yield call(
    IntentionFeeService.updateIntentionFeeRecipient,
    id,
    updatePayload
  );
  // Refresh intentions by re-fetching
  yield put(fetchIntentionFeeRecipients());
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully updated recipient.')
  );
}

export function* deleteIntentionFeeRecipientSaga(
  action: DeleteIntentionFeeRecipientActionType
): SagaIterator {
  yield call(IntentionFeeService.deleteIntentionFeeRecipient, action.payload);
  // Refresh intentions by re-fetching
  yield put(fetchIntentionFeeRecipients());
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully deleted recipient.')
  );
}
