import {
  faGlobeAmericas,
  faInbox,
  faRss,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventApi } from '@fullcalendar/core';
import _ from 'lodash';
import moment from 'moment';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { findDOMNode } from 'react-dom';
import styled, { css } from 'styled-components';

import {
  calculateTextColorBasedOnBGColor,
  COLOR_INDEX,
  LITURGICAL_HOLIDAY_COLOR,
  NATIONAL_HOLIDAY_COLOR,
  PAST_COLOR_INDEX,
  PAST_LITURGICAL_HOLIDAY_COLOR,
  PAST_NATIONAL_HOLIDAY_COLOR,
  COLLECTION_COLOR,
  PAST_COLLECTION_COLOR,
} from '../../../app/ResourceColors';
import { AttendanceStatus, EventType } from '../models/calendar';
import gettextCatalog from '../../services/I18nService';
import { Resource } from '../../shared/models/resource';
import {
  getCommaFormattedNumber,
  LightenDarkenColor,
} from '../../shared/utils';
import { HolyDayTypes } from '../services/HolidayService';
import { useToggleOpenedAbsencePopover } from '../store/absences/absencesSlice';

import CalendarEventPopover from './CalendarEventPopover';
interface EventProps {
  event: EventApi;
  currentView: string;
  showResources?: boolean;
  showIntentions?: boolean;
  showPrepTime?: boolean;
  hasIntentions?: boolean;
  titleHeight?: number;
  onDoubleClick: (event: EventApi) => void;
  isPlaceHolder?: boolean;
  isResizing?: boolean;
  isDragging?: boolean;
  resources?: Resource[];
  renderPopover?: boolean;
  popoverIndex: string;
}

const flexMixin = css<{
  currentView: string;
  showPrepTime: boolean;
  hasCleanupTime: boolean;
  hasPrepTime: boolean;
}>`
  ${({ currentView, showPrepTime, hasCleanupTime, hasPrepTime }) => {
    // Month view
    if (
      currentView !== 'dayGridMonth' &&
      showPrepTime &&
      (hasCleanupTime || hasPrepTime)
    ) {
      return css`
        display: flex;
        flex-direction: column;
      `;
    } else {
      return css`
        overflow: hidden;
      `;
    }
  }}
`;

const heightMixin = css<{
  currentView: string;
  showResources: boolean;
  showIntentions: boolean;
  allDay: boolean;
}>`
  ${({ currentView, allDay, showResources, showIntentions }) => {
    // Month view
    if (currentView === 'dayGridMonth' || allDay) {
      return showResources && showIntentions
        ? css`
            height: 54px;
          `
        : showResources || showIntentions
          ? css`
            height: 36px;
          `
          : css`
            height: 100%;
            display: flex;
            align-items: center;
          `;
    }
    // Week & day view
    if (
      (currentView === 'timeGridWeek' ||
        currentView === 'timeGridDay' ||
        currentView === 'resourceTimeGridDay') &&
      !allDay
    ) {
      return css`
        height: 100%;
      `;
    }
    return '';
  }}
`;

const contentMixin = css<{
  currentView: string;
  duration: number;
  allDay: boolean;
  hasResources: boolean;
  showResources: boolean;
  hasIntentions: boolean;
  showIntentions: boolean;
}>`
  ${({
    currentView,
    duration,
    allDay,
    hasResources,
    showResources,
    hasIntentions,
    showIntentions,
  }) => {
    if (currentView === 'dayGridMonth') {
      return css`
        flex-direction: row;
      `;
    }
    if (currentView === 'timeGridWeek') {
      if (duration < 1) {
        return css`
          flex-direction: row;
        `;
      }
      if (duration >= 1 && duration < 1.5) {
        return hasResources && showResources && hasIntentions && showIntentions
          ? css`
              flex-direction: row;
            `
          : css`
              flex-direction: column;
            `;
      }
      if (duration >= 1.5) {
        return css`
          flex-direction: column;
        `;
      }
    }
    if (
      currentView === 'timeGridDay' ||
      currentView === 'resourceTimeGridDay'
    ) {
      if (duration < 1 || allDay) {
        return css`
          flex-direction: row;
        `;
      }
      if (duration >= 1 && duration < 1.5) {
        return hasResources && showResources && hasIntentions && showIntentions
          ? css`
              flex-direction: row;
            `
          : css`
              flex-direction: column;
            `;
      }
      if (duration >= 1.5) {
        return css`
          flex-direction: column;
        `;
      }
    }
    return '';
  }}
`;

const timeMixin = css<{
  currentView: string;
  duration: number;
  allDay: boolean;
}>`
  ${({ currentView, duration, allDay }) => {
    if (currentView === 'timeGridWeek') {
      if (allDay) {
        return css`
          display: none;
        `;
      }
      if (duration >= 0.5 && duration < 1) {
        return css`
          margin-right: 3px;
        `;
      }
      if (duration >= 1) {
        return css`
          margin-right: 3px;
          display: flex;
          margin-bottom: 2px;
        `;
      }
    }
    if (
      currentView === 'timeGridDay' ||
      currentView === 'resourceTimeGridDay'
    ) {
      if (duration >= 0.5 && duration < 1) return css``;
      if (duration >= 1 && !allDay) {
        return css`
          margin-bottom: 2px;
        `;
      }
    }
    return '';
  }}
`;

const titleMixin = css<{
  currentView: string;
  duration: number;
  hasResources: boolean;
  showResources: boolean;
  hasIntentions: boolean;
  showIntentions: boolean;
  isAbsence: boolean;
  isFeed: boolean;
  isLiturgicalHoliday: boolean;
  isNationalHoliday: boolean;
  allDay: boolean;
}>`
  ${({
    currentView,
    duration,
    hasResources,
    showResources,
    isAbsence,
    isLiturgicalHoliday,
    isNationalHoliday,
    allDay,
    isFeed,
    hasIntentions,
    showIntentions,
  }) => {
    if (isAbsence && currentView === 'dayGridMonth') {
      return allDay
        ? css`
            white-space: normal;
            --max-lines: 2;
          `
        : css`
            white-space: nowrap;
            --max-lines: 1;
          `;
    }
    if (
      (isAbsence && allDay && isFeed && currentView === 'timeGridWeek') ||
      currentView === 'timeGridDay' ||
      currentView === 'resourceTimeGridDay'
    ) {
      return css`
        white-space: normal;
        --max-lines: 2;
      `;
    }
    if (isLiturgicalHoliday || isNationalHoliday) {
      return showResources || showIntentions
        ? css`
            white-space: normal;
            --max-lines: 2;
          `
        : css`
            white-space: nowrap;
            --max-lines: 1;
          `;
    }
    if (currentView === 'dayGridMonth') {
      return css`
        display: flex;
        align-items: center;
      `;
    }
    if (currentView === 'timeGridWeek') {
      if (duration <= 0.5) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              white-space: nowrap;
              overflow: hidden;
              --max-lines: 1;
            `
          : css`
              white-space: nowrap;
              overflow: hidden;
              max-height: ${`${duration * (29 * 2) - 15}px`};
              --max-lines: 1;
            `;
      }
      if (duration > 0.5 && duration < 1) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              white-space: nowrap;
              overflow: hidden;
              --max-lines: 1;
            `
          : css`
              white-space: normal;
              overflow: hidden;
              max-height: ${`${duration * (29 * 2) - 15}px`};
              --max-lines: 1;
            `;
      }
      if (duration >= 1 && duration < 1.5) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 1;
              white-space: nowrap;
            `
          : css`
              --max-lines: 2;
            `;
      }
      if (duration >= 1.5 && duration < 2) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 1;
              white-space: nowrap;
            `
          : css`
              --max-lines: 4;
            `;
      }
      if (duration === 2) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 2;
            `
          : css`
              --max-lines: 6;
            `;
      }
      if (duration > 2) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 3;
            `
          : css`
              --max-lines: 10;
            `;
      }
    }
    if (
      currentView === 'timeGridDay' ||
      currentView === 'resourceTimeGridDay'
    ) {
      if (duration > 0.5 && duration <= 1) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              white-space: nowrap;
              overflow: hidden;
            `
          : css`
              white-space: normal;
              overflow: hidden;
              max-height: ${`${duration * (29 * 2) - 15}px`};
            `;
      }
      if (duration > 1 && duration <= 1.5) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 1;
            `
          : css`
              --max-lines: 4;
            `;
      }
      if (duration > 1.5 && duration <= 2) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 2;
            `
          : css`
              --max-lines: 6;
            `;
      }
      if (duration > 2) {
        return (hasResources && showResources) ||
          (hasIntentions && showIntentions)
          ? css`
              --max-lines: 3;
            `
          : css`
              --max-lines: 8;
            `;
      }
    }
    return '';
  }}
`;

const colorMixin = css<{
  replyStatus: AttendanceStatus;
  backgroundColor: string;
  borderColor: string;
  isAbsence: boolean;
  isFeed: boolean;
  isPast: boolean;
  isLiturgicalHoliday: boolean;
  isNationalHoliday: boolean;
  isPlaceHolder: boolean;
  isExternal: boolean;
  isCollection: boolean;
}>`
  ${({
    replyStatus,
    backgroundColor,
    borderColor,
    isAbsence,
    isFeed,
    isPast,
    isLiturgicalHoliday,
    isNationalHoliday,
    isPlaceHolder,
    isExternal,
    isCollection,
  }) => {
    if (isPlaceHolder) {
      return css`
        background: #a79b8e;
        box-shadow: inset 0px 0px 0px 1px #a79b8e;
        color: #fff;
      `;
    }
    if (isAbsence) {
      return css`
        background: ${backgroundColor};
        box-shadow: inset 0px 0px 0px 1px ${borderColor};
        color: ${isPast ? 'rgba(32, 33, 36, 0.5)' : '#fff'};
      `;
    }
    if (isFeed) {
      return css`
        background: ${backgroundColor};
        box-shadow: inset 0px 0px 0px 1px ${borderColor};
        color: ${isPast ? 'rgba(32, 33, 36, 0.5)' : '#fff'};
      `;
    }
    if (isLiturgicalHoliday) {
      return css`
        background: ${
          isPast ? PAST_LITURGICAL_HOLIDAY_COLOR : LITURGICAL_HOLIDAY_COLOR
        };
        box-shadow: inset 0px 0px 0px 1px
          ${isPast ? PAST_LITURGICAL_HOLIDAY_COLOR : LITURGICAL_HOLIDAY_COLOR};
        color: ${isPast ? 'rgba(32, 33, 36, 0.5)' : '#fff'};
      `;
    }
    if (isNationalHoliday) {
      return css`
        background: ${
          isPast ? PAST_NATIONAL_HOLIDAY_COLOR : NATIONAL_HOLIDAY_COLOR
        };
        box-shadow: inset 0px 0px 0px 1px
          ${isPast ? PAST_NATIONAL_HOLIDAY_COLOR : NATIONAL_HOLIDAY_COLOR};
        color: ${isPast ? 'rgba(32, 33, 36, 0.5)' : '#fff'};
      `;
    }

    if (isCollection) {
      return css`
        background: ${isPast ? PAST_COLLECTION_COLOR : COLLECTION_COLOR};
        box-shadow: inset 0px 0px 0px 1px
          ${isPast ? PAST_COLLECTION_COLOR : COLLECTION_COLOR};
        color: ${isPast ? 'rgba(32, 33, 36, 0.5)' : '#fff'};
      `;
    }

    if (!replyStatus || isExternal || replyStatus === AttendanceStatus.Going) {
      return css`
        background: ${backgroundColor};
        box-shadow: inset 0px 0px 0px 1px ${borderColor};
        color: ${isPast ? 'rgba(32, 33, 36, 0.5)' : '#fff'};
      `;
    }
    if (
      replyStatus === AttendanceStatus.Maybe ||
      replyStatus === AttendanceStatus.NoAnswer
    ) {
      return css`
        background: #fff;
        box-shadow: inset 0px 0px 0px 1px ${borderColor};
        color: ${backgroundColor};
      `;
    }
    if (replyStatus === AttendanceStatus.NotGoing) {
      return css`
        background: #fff;
        box-shadow: inset 0px 0px 0px 1px ${borderColor};
        color: ${backgroundColor};
      `;
    }

    return '';
  }}
`;

interface EventContainerProps {
  event: EventApi;
  currentView: string;
  showResources: boolean;
  showIntentions: boolean;
  hasIntentions: boolean;
  allDay: boolean;
  duration: number;
  hasResources: boolean;
  isLiturgicalHoliday: boolean;
  isNationalHoliday: boolean;
  isPast: boolean;
  isAbsence: boolean;
  replyStatus: AttendanceStatus;
  backgroundColor: string;
  borderColor: string;
  isFeed: boolean;
  isPlaceHolder: boolean;
  cleanupTimeMinutes: number;
  prepTimeMinutes: number;
  eventMinutes: number;
  hasPrepTime: boolean;
  hasCleanupTime: boolean;
  showPrepTime: boolean;
  isExternal: boolean;
  isCollection: boolean;
}

const StyledPrepTime = styled.div<Partial<EventContainerProps>>`
  margin: -3px -3px 0px;
  height: ${({ prepTimeMinutes }) => prepTimeMinutes}px;
  padding: 2px 3px;
  border-radius: 3px 3px 0px 0px !important;
  flex: ${({ prepTimeMinutes }) => prepTimeMinutes};
  background: ${({ backgroundColor }) => `repeating-linear-gradient(
    -55deg,
    ${LightenDarkenColor(backgroundColor, 25)},
    ${LightenDarkenColor(backgroundColor, 25)} 1px,
    ${LightenDarkenColor(backgroundColor, 50)} 1px,
    ${LightenDarkenColor(backgroundColor, 50)} 10px
  )`};

  .fc-prep-title {
    overflow: hidden;
    white-space: nowrap;
    ${({ isPast, backgroundColor }) =>
      !isPast
        ? `color: ${calculateTextColorBasedOnBGColor(backgroundColor)}`
        : null};
  }

  @media screen and (max-height: 699px) {
    padding: 1px;
    margin: -1px -1px 0px;
  }
`;

const StyledCleanupTime = styled.div<Partial<EventContainerProps>>`
  margin: 0px -3px -3px;
  height: ${({ cleanupTimeMinutes }) => cleanupTimeMinutes}px;
  padding: 2px 3px;
  border-radius: 0px 0px 3px 3px !important;
  flex: ${({ cleanupTimeMinutes }) => cleanupTimeMinutes};
  background: ${({ backgroundColor }) => `repeating-linear-gradient(
    -55deg,
    ${LightenDarkenColor(backgroundColor, 25)},
    ${LightenDarkenColor(backgroundColor, 25)} 1px,
    ${LightenDarkenColor(backgroundColor, 50)} 1px,
    ${LightenDarkenColor(backgroundColor, 50)} 10px
  )`};

  .fc-cleanup-title {
    overflow: hidden;
    white-space: nowrap;
    ${({ isPast, backgroundColor }) =>
      !isPast
        ? `color: ${calculateTextColorBasedOnBGColor(backgroundColor)}`
        : null};
  }
  @media screen and (max-height: 699px) {
    padding: 1px;
    margin: -1px -1px 0px;
  }
`;

const isIntentionBilled = (event: EventApi) =>
  _.get(event, 'extendedProps.isIntentionsBilled');

const isStoleBilled = (event: EventApi) =>
  _.get(event, 'extendedProps.isStolesBilled');

const StyledEvent = styled.div<EventContainerProps>`
  ${heightMixin}
  ${colorMixin}
  ${flexMixin}
  border-radius: 3px !important;
  padding: 2px 3px;
  border: 1px solid #fff;
  min-height: 26px;
  @media screen and (max-height: 699px) {
    padding: 1px;
  }
  @media print {
    color: #555 !important;
    box-shadow: inset 0px 0px 0px 1px #555 !important;
    border-left: 3px solid ${({ borderColor }) => borderColor} !important;
    background: #fff !important;
  }
  .fc-content-wrapper {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: ${({ eventMinutes }) => (eventMinutes < 30 ? 30 : eventMinutes)};

    .fc-main-content {
      overflow: hidden;
      display: flex;
      ${contentMixin}
      .time-container {
        ${timeMixin}
        line-height: 1.4rem;
        .fc-time {
          font-weight: 500;
          letter-spacing: 0.1px;
          font-size: 0.95em;
          margin-right: 3px;
          text-decoration: ${({ replyStatus, isExternal }) =>
            replyStatus === AttendanceStatus.NotGoing && !isExternal
              ? 'line-through'
              : 'none'};
          white-space: nowrap;
        }
      }
      i.fa {
        display: contents;
        margin: 0;
      }
      .fc-title {
        ${titleMixin}
        text-overflow: -o-ellipsis-lastline;
        line-height: 1.4rem;
        max-height: calc(1.4rem * var(--max-lines));
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.1px;
        text-decoration: ${({ replyStatus, isExternal }) =>
          replyStatus === AttendanceStatus.NotGoing && !isExternal
            ? 'line-through'
            : 'none'};

        .svg-inline--fa {
          transform: scale(0.9);

          &.fa-calendar-times {
            transform: scale(1);
          }
        }
      }
    }
    .fc-extra-content {
      overflow: hidden;
      display: flex;
      flex: 1;
      flex-direction: column;

      .fc-extra-content__intentions {
        display: flex;
        margin-top: 1px;

        .fc-extra-content__intentions__info {
          border: 1px solid
            ${({ isPast }) => (isPast ? 'rgba(32, 33, 36, 0.5)' : '#fff')};
          border-radius: 3px;
          background-color: ${({ event, isPast }) =>
            isIntentionBilled(event)
              ? 'transparent'
              : isPast
                ? LightenDarkenColor('#f5222d', 50)
                : '#f5222d'};
          padding: 0px 3px;
          font-size: 10px;
          font-family: monospace !important;
          overflow: hidden;
          max-width: ${({ event }) =>
            event.extendedProps.noOfStoles > 0 ? '60%' : 'none'};
          margin-right: 3px;
          white-space: nowrap;
        }

        .fc-extra-content__stoles__info {
          border: 1px solid
            ${({ isPast }) => (isPast ? 'rgba(32, 33, 36, 0.5)' : '#fff')};
          border-radius: 3px;
          background-color: ${({ event, isPast }) =>
            isStoleBilled(event)
              ? 'transparent'
              : isPast
                ? LightenDarkenColor('#f5222d', 50)
                : '#f5222d'};
          padding: 0px 3px;
          font-size: 10px;
          font-family: monospace !important;
          overflow: hidden;
          max-width: ${({ event }) =>
            event.extendedProps.noOfIntentions > 0 ? '40%' : 'none'};
          white-space: nowrap;
        }
      }
    }
  }
`;

const ResourceUl = styled.ul<{
  isHorizontal: boolean;
  count: number;
  currentView: string;
  showIntentions: boolean;
  hasIntentions: boolean;
}>`
  display: flex;
  flex-direction: ${({ isHorizontal }) => (isHorizontal ? 'row' : 'column')};
  margin-bottom: 0px;
  margin-top: 0px;
  padding-left: 0px;
  align-items: flex-start;
  .item {
    display: flex;
    align-items: center;
    max-width: ${(props) =>
      (props.currentView === 'timeGridDay' ||
        props.currentView === 'resourceTimeGridDay') &&
      props.isHorizontal
        ? 'auto'
        : !props.isHorizontal
          ? '100%'
          : props.count === 1
            ? '100%'
            : props.count === 2
              ? '50%'
              : '30%'};
    margin-right: 5px;
    span {
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

const Bullet = styled.div`
  min-width: 10px;
  min-height: 10px;
  display: block;
  box-shadow: inset 0 0 0 1px white;
  border-radius: 2px;
  background: ${(props) => props.color};
  margin-right: 2px;
  @media print {
    background: ${(props) => props.color} !important;
    box-shadow: inset 0 0 0 1px #555 !important;
  }
`;

const IntentionsInfo: FunctionComponent<Partial<EventProps>> = ({ event }) => {
  const noOfIntentions = _.get(event, 'extendedProps.noOfIntentions');
  const noOfStoles = _.get(event, 'extendedProps.noOfStoles');

  return (
    <div className="fc-extra-content__intentions">
      {noOfIntentions > 0 ? (
        <div className="fc-extra-content__intentions__info">
          {gettextCatalog.getPlural(
            noOfIntentions,
            '1 Intention',
            '{{ count }} Intentions',
            {
              count: noOfIntentions,
            }
          )}
        </div>
      ) : null}
      {noOfStoles > 0 ? (
        <div className="fc-extra-content__stoles__info">
          {gettextCatalog.getPlural(
            noOfStoles,
            '1 Stole',
            '{{ count }} Stoles',
            {
              count: noOfStoles,
            }
          )}
        </div>
      ) : null}
    </div>
  );
};

const ExtraInfo: FunctionComponent<Partial<EventProps>> = ({
  event,
  currentView,
  resources,
  titleHeight,
  isResizing,
  showResources,
  showIntentions,
  hasIntentions,
}) => {
  const duration = getDuration(event, isResizing);
  const isResourceHorizontal = isResourcesHorizontal(
    currentView,
    event,
    duration,
    showIntentions,
    hasIntentions
  );
  const isResourceMOreThanThree = resources.length > 3;
  const resourcesToRender = getResourcesToRender(
    currentView,
    event,
    duration,
    resources,
    isResourceMOreThanThree,
    titleHeight,
    isResourceHorizontal,
    showIntentions && hasIntentions
  );

  // Remove a resource if vertical display to make room for intentions
  if (
    hasIntentions &&
    showIntentions &&
    !isResourceHorizontal &&
    resources.length > resourcesToRender.length
  ) {
    resourcesToRender.pop();
  }
  return (
    <div className="fc-extra-content">
      {/* Resources */}
      {showResources &&
      resources &&
      resources.length > 0 &&
      !_.isEmpty(resources) ? (
        <ResourceUl
          isHorizontal={isResourceHorizontal}
          count={resources.length}
          currentView={currentView}
          showIntentions={showIntentions}
          hasIntentions={hasIntentions}
        >
          {resourcesToRender.map((resource) => (
            <li className="item" key={resource.id + 'key'}>
              <Bullet
                title={resource.name}
                color={
                  event.extendedProps.isPast
                    ? PAST_COLOR_INDEX[resource.color]
                    : COLOR_INDEX[resource.color]
                }
              />
              <span title={resource.name}>{resource.name}</span>
            </li>
          ))}
          {resources.length > resourcesToRender.length ? (
            <li className="item">
              <span>+{resources.length - resourcesToRender.length}</span>
              {gettextCatalog.getString('more')}
            </li>
          ) : null}
        </ResourceUl>
      ) : null}
      {/* Intention details */}
      {showIntentions &&
      hasIntentions &&
      !(duration <= 0.5 && currentView !== 'dayGridMonth') ? (
        <IntentionsInfo event={event} />
      ) : null}
    </div>
  );
};

const getDuration = (event: EventApi, isResizing: boolean): number => {
  let start: Date, end: Date;
  if (!event.start || !event.end) {
    return -1;
  } else if (isResizing) {
    const prepTimeMinutes = _.get(event, 'extendedProps.prepTimeMinutes') || 0;
    const cleanupTimeMinutes =
      _.get(event, 'extendedProps.cleanupTimeMinutes') || 0;
    start = moment(event.start).add(prepTimeMinutes, 'minutes').toDate();
    end = moment(event.end).subtract(cleanupTimeMinutes, 'minutes').toDate();
  } else {
    start = event.extendedProps.originalStartDate;
    end = event.extendedProps.originalEndDate;
  }
  return moment.duration(moment(end).diff(start)).asHours();
};

function isResourcesHorizontal(
  currentView: string,
  event: EventApi,
  duration: number,
  showIntentions: boolean,
  hasIntentions: boolean
) {
  if (currentView === 'dayGridMonth') return true;
  if (
    currentView === 'timeGridDay' ||
    currentView === 'timeGridWeek' ||
    currentView === 'resourceTimeGridDay'
  ) {
    if (event.allDay) return true;
    if (duration >= 0.5 && duration <= 1) return true;
    if (duration <= 1.5 && showIntentions && hasIntentions) return true;
    if (duration > 1) return false;
  }
  return true;
}

const getPrepTimeLabel = (type: string, minutes: number): string => {
  if (minutes < 30) return;

  // Show hour
  if (minutes >= 60) {
    const numHours = getCommaFormattedNumber(minutes / 60, 1);
    return type === 'Prep'
      ? gettextCatalog.getPlural(
          parseFloat(numHours.replace(',', '.')),
          '1h. preparation',
          '{{ numHours }}h. preparation',
          {
            numHours,
          }
        )
      : gettextCatalog.getPlural(
          parseFloat(numHours.replace(',', '.')),
          '1h. clean up',
          '{{ numHours }}h. clean up',
          {
            numHours,
          }
        );
  } else {
    // Show minutes
    return type === 'Prep'
      ? gettextCatalog.getString('{{amount}} min. preparation', {
          amount: minutes,
        })
      : gettextCatalog.getString('{{amount}} min. clean up', {
          amount: minutes,
        });
  }
};

function getResourcesToRender(
  currentView: string,
  event: EventApi,
  duration: number,
  resources: Resource[],
  isResourceMoreThanThree: boolean,
  titleHeight: number,
  isResourceHorizontal: boolean,
  mustShowIntention: boolean
) {
  if (currentView === 'dayGridMonth') {
    return resources.slice(0, isResourceMoreThanThree ? 2 : 3);
  }
  if (
    currentView === 'timeGridDay' ||
    currentView === 'timeGridWeek' ||
    currentView === 'resourceTimeGridDay'
  ) {
    if (event.allDay) {
      return resources.slice(0, isResourceMoreThanThree ? 2 : 3);
    }
    if (duration > 0.5 && duration <= 1) {
      return resources.slice(0, isResourceMoreThanThree ? 2 : 3);
    }
    if (isResourceHorizontal) {
      return resources.slice(0, isResourceMoreThanThree ? 2 : 3);
    }
    if (duration > 1) {
      let maxResourcesToShow = (27 * 2 * duration - (titleHeight + 15)) / 15;
      if (mustShowIntention) maxResourcesToShow--;
      return maxResourcesToShow < resources.length
        ? resources.slice(0, maxResourcesToShow)
        : resources;
    }
  }
  return [];
}

const EventItemContent: FunctionComponent<EventProps> = (props) => {
  const {
    event,
    currentView,
    showResources = true,
    onDoubleClick,
    showPrepTime,
    showIntentions,
    isResizing,
    popoverIndex,
  } = props;
  const eventResources = event?.extendedProps?.resources
    ? event.extendedProps.resources
    : {};
  const resources = _.keys(eventResources).map((key) => ({
    id: key,
    ...eventResources[key],
  }));
  const duration = getDuration(event, isResizing);
  const titleRef = useRef();
  const [titleHeight, setTitleHeight] = useState(0);
  const isNationalHoliday =
    event.extendedProps.holidayType === HolyDayTypes.NATIONAL;
  const isLiturgicalHoliday =
    event.extendedProps.holidayType === HolyDayTypes.LITURGICAL;
  const isPast = event.extendedProps.isPast;
  const isAbsence = event?.extendedProps?.type === 'absence';
  const isPlaceHolder = event.title === '' && event.id === '';
  const isFeed = event?.extendedProps?.type === EventType.Feed;
  const isCollection = event?.extendedProps?.type === 'collection';

  // Event section lengths
  const prepTimeMinutes = showPrepTime
    ? _.get(event, 'extendedProps.prepTimeMinutes')
    : 0;
  const cleanupTimeMinutes = showPrepTime
    ? _.get(event, 'extendedProps.cleanupTimeMinutes')
    : 0;
  const eventMinutes = duration * 60;

  // Prep time booleans
  const hasPrepTime = showPrepTime ? prepTimeMinutes > 0 : false;
  const hasCleanupTime = showPrepTime ? cleanupTimeMinutes > 0 : false;
  const shouldShowPrepAndCleanupTime = showPrepTime
    ? currentView === 'timeGridDay' ||
      currentView === 'timeGridWeek' ||
      currentView === 'resourceTimeGridDay'
    : false;

  // Intentions
  const hasIntentions =
    _.get(event, 'extendedProps.noOfIntentions') > 0 ||
    _.get(event, 'extendedProps.noOfStoles') > 0;

  const { customBackgroundColor, customBorderColor } = event.extendedProps;
  const setIsToolTipVisible = useToggleOpenedAbsencePopover();
  const openTooltip = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsToolTipVisible(popoverIndex);
    },
    [setIsToolTipVisible, popoverIndex]
  );

  useEffect(() => {
    // eslint-disable-next-line react/no-find-dom-node
    const titleDom = findDOMNode(titleRef.current);
    const titleDomHeight = $(titleDom).height();
    setTitleHeight(titleDomHeight);
  }, [event.start, event.end, event.title, titleHeight]);

  let clickTimeout = null;
  return (
    <StyledEvent
      event={event}
      currentView={currentView}
      showResources={showResources}
      showIntentions={showIntentions}
      showPrepTime={showPrepTime}
      allDay={event.allDay}
      duration={duration}
      hasResources={resources && resources.length > 0}
      hasIntentions={hasIntentions}
      hasPrepTime={hasPrepTime}
      hasCleanupTime={hasCleanupTime}
      prepTimeMinutes={prepTimeMinutes}
      cleanupTimeMinutes={cleanupTimeMinutes}
      eventMinutes={eventMinutes}
      isNationalHoliday={isNationalHoliday}
      isCollection={isCollection}
      isLiturgicalHoliday={isLiturgicalHoliday}
      isPast={isPast}
      isAbsence={isAbsence}
      isFeed={isFeed}
      replyStatus={event.extendedProps.status as AttendanceStatus}
      backgroundColor={customBackgroundColor}
      borderColor={customBorderColor}
      onClick={(e) => {
        if (clickTimeout !== null) {
          if (event.extendedProps.isMinimizedAbsences) return;
          onDoubleClick(event);
          clearTimeout(clickTimeout);
          clickTimeout = null;
        } else {
          clickTimeout = setTimeout(() => {
            clearTimeout(clickTimeout);
            openTooltip(e);
            clickTimeout = null;
          }, 200);
        }
      }}
      isPlaceHolder={isPlaceHolder}
      isExternal={event.extendedProps.isExternal}
    >
      {hasPrepTime && shouldShowPrepAndCleanupTime ? (
        <StyledPrepTime
          backgroundColor={customBackgroundColor}
          borderColor={customBorderColor}
          prepTimeMinutes={prepTimeMinutes}
        >
          <div className="fc-prep-title u-text-italic">
            {getPrepTimeLabel('Prep', prepTimeMinutes)}
          </div>
        </StyledPrepTime>
      ) : null}
      <div className="fc-content-wrapper">
        <div className="fc-main-content" ref={titleRef}>
          <div className="time-container">
            {' '}
            {!event.allDay &&
            event.extendedProps.holidayType !== HolyDayTypes.LITURGICAL &&
            event.extendedProps.holidayType !== HolyDayTypes.NATIONAL &&
            event.start ? (
              <span className="fc-time">
                {' '}
                {moment(event.start)
                  .add(prepTimeMinutes, 'minutes')
                  .format('HH:mm')}
              </span>
            ) : null}
            {currentView !== 'dayGridMonth' &&
            duration >= 1 &&
            !event.allDay &&
            event.end ? (
              <span className="fc-time">
                {' '}
                -{' '}
                {moment(event.end)
                  .subtract(cleanupTimeMinutes, 'minutes')
                  .format('HH:mm')}{' '}
              </span>
            ) : null}
          </div>
          <span className="fc-title" title={event.title}>
            {event.extendedProps.visibility === 'public' ? (
              <>
                <FontAwesomeIcon icon={faGlobeAmericas} />
                {'\u00A0'}
              </>
            ) : null}
            {event.extendedProps.type === 'absence' ? (
              <>
                <FontAwesomeIcon icon={faCalendarTimes} />
                {'\u00A0'}
              </>
            ) : null}
            {event.extendedProps.type === EventType.Feed ? (
              <>
                <FontAwesomeIcon icon={faRss} />
                {'\u00A0'}
              </>
            ) : null}
            {event.extendedProps.type === 'external' ? (
              <>
                <FontAwesomeIcon icon={faUserClock} />
                {'\u00A0'}
              </>
            ) : null}
            {event.extendedProps.type === 'collection' ? (
              <>
                <FontAwesomeIcon icon={faInbox} />
                {'\u00A0'}
              </>
            ) : null}
            {event.title ? event.title : gettextCatalog.getString('(No title)')}
          </span>
        </div>
        {!(duration <= 0.5 && currentView !== 'dayGridMonth') ? (
          <ExtraInfo
            event={event}
            currentView={currentView}
            resources={resources}
            titleHeight={titleHeight}
            isResizing={isResizing}
            showResources={showResources}
            showIntentions={showIntentions}
            hasIntentions={hasIntentions}
          />
        ) : null}
      </div>
      {hasCleanupTime && shouldShowPrepAndCleanupTime ? (
        <StyledCleanupTime
          backgroundColor={customBackgroundColor}
          borderColor={customBorderColor}
          cleanupTimeMinutes={cleanupTimeMinutes}
        >
          <div className="fc-cleanup-title u-text-italic">
            {getPrepTimeLabel('Clean up', cleanupTimeMinutes)}
          </div>
        </StyledCleanupTime>
      ) : null}
    </StyledEvent>
  );
};

const EventItem: FunctionComponent<EventProps> = (props) => {
  const { event, popoverIndex } = props;
  return (
    <CalendarEventPopover event={event} popoverIndex={popoverIndex}>
      <EventItemContent {...props} />
    </CalendarEventPopover>
  );
};
export default EventItem;
