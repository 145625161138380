import { Space, Col, Row, Alert, Spin } from 'antd';
import React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { CdUsers } from '../../../../shared/components/Icons';
import { messageRecipientsCalculatorQuery } from '../../../store/message';
import gettextCatalog from '../../../../services/I18nService';

import { separateRecipientSelection } from './recipient-selector/recipient-selector.lib';

const LoadingCalculation = () => (
  <Space>
    {gettextCatalog.getString('Finding recipients')}
    <Spin size="small" />
  </Space>
);

interface RecipientCalculatorProps {
  toValue: string[];
  type: 'email' | 'sms';
}

const RecipientCalculator = ({ toValue, type }: RecipientCalculatorProps) => {
  const { segmentIds, personIds } = separateRecipientSelection(toValue);
  const messageRecipientsCalculatorLoadable = useRecoilValueLoadable(
    messageRecipientsCalculatorQuery({ segmentIds, personIds, type })
  );
  const loading = messageRecipientsCalculatorLoadable.state === 'loading';
  const messageRecipientsStats = messageRecipientsCalculatorLoadable.contents;
  if (!messageRecipientsStats) {
    return null;
  }
  const totalActiveRecipients = messageRecipientsStats.activeRecipients;
  return (
    <Alert
      style={{ marginTop: '8px' }}
      icon={
        <Space
          style={{
            padding: '10px 20px 10px 0',
            marginRight: '20px',
            fontWeight: 700,
            borderRight: loading ? 'none' : '2px solid #008db6',
            fontSize: '16px',
            color: '#008db6',
          }}
        >
          <CdUsers style={{ color: '#008db6' }} />
          {loading ? (
            <LoadingCalculation />
          ) : (
            <>
              {gettextCatalog.getString('{{count}} total recipients', {
                count: totalActiveRecipients,
              })}
            </>
          )}
        </Space>
      }
      message={
        !loading && (
          <>
            <Row>
              <Col span={20} style={{ color: '#008db6' }}>
                {gettextCatalog.getString(
                  'Total contacts on selected lists (including individual contacts)'
                )}
              </Col>
              <Col span={4} style={{ color: '#008db6', textAlign: 'center' }}>
                {messageRecipientsStats.totalRecipients}
              </Col>
            </Row>
            <Row>
              <Col span={20} style={{ color: '#f41909' }}>
                {gettextCatalog.getString(
                  'Some contacts cannot receive your message (blocked emails, unsubscribed, etc.)'
                )}
              </Col>
              <Col span={4} style={{ color: '#f41909', textAlign: 'center' }}>
                {messageRecipientsStats.inActiveRecipients}
              </Col>
            </Row>
          </>
        )
      }
      showIcon
      type="info"
    />
  );
};

export default RecipientCalculator;
