import { Button } from 'antd';
import React from 'react';

import useConsentStatement from '../hooks/useConsentStatement';

import gettextCatalog from '@/react/services/I18nService';
import { CdDeleteIcon } from '@/react/shared/components/Icons';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const ConsentDeleteStatementButton = ({ id, defaultConsent }) => {
  const { deleteStatement } = useConsentStatement();
  const confirmDeleteModal = (id: string) =>
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Delete consent'),
      message: gettextCatalog.getString(
        `All registered consent on your contacts will be deleted.`
      ),
      icon: <CdDeleteIcon />,
      okType: 'danger',
      okText: gettextCatalog.getString('Delete'),
      onOk: async () => {
        await deleteStatement(id);
      },
    });

  return (
    <CdTooltip
      title={
        defaultConsent
          ? gettextCatalog.getString(
              'This is the default consent you are not able to delete it.'
            )
          : undefined
      }
      color="red"
    >
      <Button
        onClick={() => confirmDeleteModal(id)}
        type="text"
        disabled={defaultConsent}
        style={{ width: '100%', textAlign: 'start' }}
      >
        <CdDeleteIcon />
        {gettextCatalog.getString('Delete')}
      </Button>
    </CdTooltip>
  );
};

export default ConsentDeleteStatementButton;
