import { useRecoilCallback } from 'recoil';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import getTextCatalog from '@/react/services/I18nService';
import { handleError } from '@/react/services/ErrorHandlingService';

export const useAnswerToEvent = (entityId?: number) => {
  const sendAnswerToEvent = useRecoilCallback(
    () => async (respond: string) => {
      const response = await mainApi.post<any>(
        `/calendar/invitations/${entityId}/attending/${respond}`,
        {}
      );
      if (!response.ok) {
        handleError(response);
      } else {
        NotificationService.notifySuccess(
          getTextCatalog.getString('Event updated.')
        );
      }
    },
    [entityId]
  );

  return {
    sendAnswerToEvent,
  };
};
