import React, { Suspense, useCallback } from 'react';
import { Input, Select, Form, Card, Button, Col, Row } from 'antd';

import getTextCatalog from '@/react/services/I18nService';
import useOrganizations from '@/react/organization/hooks/useOrganizations';
import useBlogs, { useSearch } from '@/react/people/hooks/useBlogs';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { PeopleMessageBlog } from '@/react/people/types/message';
import { BlogSearchQuery } from '@/react/people/store/message';
import gettextCatalog from '@/react/services/I18nService';
import { CdBlog } from '@/react/shared/components/Icons';
import AuthorizationService from '@/react/services/AuthorizationService';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';

const blogColumns = () => [
  {
    title: getTextCatalog.getString('Title'),
    dataIndex: 'title',
    key: 'title',
    sorter: true,
    active: true,
  },
  {
    title: getTextCatalog.getString('Author'),
    dataIndex: 'author',
    key: 'author',
    sorter: true,
    active: true,
  },
  {
    title: getTextCatalog.getString('Category'),
    dataIndex: 'category',
    key: 'category',
    sorter: true,
    active: true,
  },
];

interface BlogTableProps {
  onRowsChange?: (blogs: PeopleMessageBlog[]) => void;
}

const BlogTable = ({ onRowsChange }: BlogTableProps) => {
  const { selectedBlogIds } = useBlogs();
  const isKeySelected = ({ id }: PeopleMessageBlog) =>
    !selectedBlogIds.includes(id);

  return (
    <CdSimpleTable<PeopleMessageBlog>
      columnSelectorKey="BlogSearchForm"
      recoilSource={BlogSearchQuery}
      filters={[isKeySelected]}
      emptyStateProps={{
        title: getTextCatalog.getString('No blogs found'),
        EmptyStateIcon: <CdBlog />,
      }}
      columns={blogColumns()}
      rowSelection={{
        type: 'checkbox',
        onChange: (_id, blogs) => onRowsChange(blogs),
      }}
    />
  );
};

export default function BlogSearchForm({ form }) {
  const {
    debounced: { searchBlogs },
  } = useSearch();
  const { organizations, setSearchId, searchId, searchByName } =
    useOrganizations();
  const handleSearch = useCallback(
    (e) => searchBlogs(e.target.value),
    [searchBlogs]
  );
  const hasCollaborationPackage =
    AuthorizationService.hasPackage('collaboration');

  return (
    <>
      <Card>
        <Form onValuesChange={handleSearch} layout="inline">
          {hasCollaborationPackage && (
            <Row justify="space-between" style={{ flexGrow: 1, width: '100%' }}>
              <Col span={24}>
                <Form.Item
                  label={gettextCatalog.getString('Organization')}
                  style={{ marginRight: '0px' }}
                >
                  <Select<number>
                    showSearch
                    value={searchId}
                    onSearch={searchByName}
                    filterOption
                    optionFilterProp="label"
                    onChange={setSearchId}
                    options={organizations.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row
            justify="space-between"
            style={{ flexGrow: 1, margin: '' }}
            wrap={false}
          >
            <Col flex="auto">
              <Form.Item label={getTextCatalog.getString('Title')}>
                <Input
                  placeholder={getTextCatalog.getString('Blog title')}
                  onChange={handleSearch}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button type="primary" htmlType="submit">
                  {gettextCatalog.getString('Search')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Form form={form} style={{ marginTop: '8px' }}>
        <Suspense fallback={<CdrPageLoader />}>
          <Form.Item
            name="blogs"
            trigger="onRowsChange"
            valuePropName="selectedKeys"
          >
            <BlogTable />
          </Form.Item>
        </Suspense>
      </Form>
    </>
  );
}
