import React from 'react';
import { useRecoilValue } from 'recoil';

import { CountryISO2State } from '../../../user/store/user-session';

import { PostersAndInvitesTableColumns } from './PostersAndInvitesTableColumns';

import {
  EventWithStudioItems,
  getStudioContentByLocale,
  StudioContentKeys,
} from '@/react/calendar/types/studio';
import gettextCatalog from '@/react/services/I18nService';
import CdUnPagedTable from '@/react/shared/components/cd-unpaged-table/CdUnPagedTable';
import { getPosters } from '@/react/calendar/services/studioService';

export const PostersAndInvitesTable = ({
  canCommunicate,
}: {
  canCommunicate: boolean;
}) => {
  const countryISO2 = useRecoilValue(CountryISO2State);

  const showStudioContentByLocale = () => {
    const studioInfoPageUrl = getStudioContentByLocale(
      countryISO2,
      StudioContentKeys.STUDIO_INFO_PAGE
    );
    window.open(studioInfoPageUrl, '_blank', 'noreferrer');
  };

  return (
    <CdUnPagedTable<EventWithStudioItems>
      columns={PostersAndInvitesTableColumns(canCommunicate)}
      dataResult={getPosters}
      emptyStateProps={{
        title: gettextCatalog.getString(
          'Print poster for events with a few clicks'
        ),
        subtitle: gettextCatalog.getString(
          'From now on you can easily create beautiful newsletters, flyers and personal invitations without much effort in ChurchDesk Studio. Reach more people with beautiful designs and a personal touch. With instant print function of event posters!. Your parish likely have several events that you would want to promote on notice boards with beautiful posters and flyers. With ChurchDesk Studio, such flyers and posters can be designed or created directly from the calendar. With one click, you can make beautiful event posters for easy printing or sharing on social media.'
        ),
        onButtonClick: showStudioContentByLocale,
        buttonText: gettextCatalog.getString('Learn more about Studio'),
      }}
    />
  );
};
