import { useMutation, useQuery } from '@tanstack/react-query';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import {
  getContactProfileInformation,
  saveContactProfileInformation,
} from '../store/contactProfileInformation';
import { ConflictingPersonWithRelation } from '../services/people.service';

import { handleSuccessMessage } from '@/react/shared/utils';
import getTextCatalog from '@/react/services/I18nService';
import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';
import { navigate } from '@/react/services/StateServiceFactory';

type ContactProfileInformationKey = [string, number];
export const useContactProfileInformation = (
  contactId: number,
  accessFields?: any
) => {
  const [conflictingPeopleByEmail, setConflictingPeopleByEmail] = useState<
    ConflictingPersonWithRelation[]
  >([]);
  const [conflictingPeopleByPhone, setConflictingPeopleByPhone] = useState<
    ConflictingPersonWithRelation[]
  >([]);
  const [initialValues, setInitialValues] = useState<any>(null);
  const conflictingPeopleByEmailRef = useRef(conflictingPeopleByEmail);
  const conflictingPeopleByPhoneRef = useRef(conflictingPeopleByPhone);
  const initialValuesRef = useRef(initialValues);

  useEffect(() => {
    conflictingPeopleByEmailRef.current = conflictingPeopleByEmail;
  }, [conflictingPeopleByEmail]);

  useEffect(() => {
    conflictingPeopleByPhoneRef.current = conflictingPeopleByPhone;
  }, [conflictingPeopleByPhone]);

  useEffect(() => {
    initialValuesRef.current = initialValues;
  }, [initialValues]);

  const { data, isPending, isRefetching } = useQuery({
    queryKey: [
      'useContactProfileInformation',
      contactId,
    ] as ContactProfileInformationKey,
    queryFn: async () => await getContactProfileInformation(contactId),
  });
  useEffect(() => {
    const processInitialValues = () => {
      const value = { ...data };
      if (value) {
        if (value.phone && accessFields?.phone?.canEdit) {
          const phone = parsePhoneNumber(value.phone);
          value.phone = {
            phone: phone.nationalNumber,
            code: phone.countryCallingCode,
            short: phone.country,
          };
        } else {
          delete value.phone;
        }

        if (value.homePhone && accessFields?.homePhone?.canEdit) {
          const phone = parsePhoneNumber(value.homePhone);
          value.homePhone = {
            phone: phone.nationalNumber,
            code: phone.countryCallingCode,
            short: phone.country,
          };
        } else {
          delete value.homePhone;
        }
        if (value.workPhone && accessFields?.workPhone?.canEdit) {
          const phone = parsePhoneNumber(value.workPhone);
          value.workPhone = {
            phone: phone.nationalNumber,
            code: phone.countryCallingCode,
            short: phone.country,
          };
        } else {
          delete value.workPhone;
        }
        value.birthday = value.birthday ? moment(value.birthday) : undefined;
        return value;
      }
      return null;
    };

    if (data) {
      setInitialValues(processInitialValues());
    }
  }, [data, accessFields]);
  const saveContactProfileInfo = async ({ form }) => {
    const errorValues = await form.validateFields().catch((err) => {
      handleAntFormValidationErrors(err);
    });
    if (!errorValues) return;
    const values = form.getFieldsValue();
    const relations: { relation: string; person: { personId: number } }[] = [];
    // Process conflicts with contacts sharing the selected email address
    if (conflictingPeopleByEmailRef.current.length > 0) {
      const relationsByEmail = conflictingPeopleByEmailRef.current.map(
        (item) => ({
          relation: item.relation,
          person: { personId: item.conflictingPerson.id },
        })
      );
      relations.push(...relationsByEmail);
    }

    // Process conflicts with contacts sharing the selected phone number
    if (conflictingPeopleByPhoneRef.current.length > 0) {
      const relationsByPhone = conflictingPeopleByPhoneRef.current.map(
        (item) => ({
          relation: item.relation,
          person: { personId: item.conflictingPerson.id },
        })
      );
      relations.push(...relationsByPhone);
    }
    const payload = { ...initialValuesRef.current, ...values };
    if (relations.length > 0) {
      payload.relations = relations;
    }
    const preparedData = { ...payload };
    if (payload.phone.phone) {
      preparedData.phone = `+${payload.phone.code}${payload.phone.phone}`;
    } else {
      preparedData.phone = null;
    }
    if (payload.homePhone.phone) {
      preparedData.homePhone = `+${payload.homePhone.code}${payload.homePhone.phone}`;
    } else {
      preparedData.homePhone = null;
    }
    if (payload.workPhone.phone) {
      preparedData.workPhone = `+${payload.workPhone.code}${payload.workPhone.phone}`;
    } else {
      preparedData.workPhone = null;
    }
    if (payload.birthday) {
      preparedData.birthday = payload.birthday.format('YYYY-MM-DD');
    }
    await mutateAsync({ payload: preparedData, contactId });
  };
  const { mutateAsync } = useMutation({
    mutationFn: saveContactProfileInformation,
    onSuccess: () => {
      handleSuccessMessage(
        getTextCatalog.getString('The information was saved successfully.')
      );
      navigate(
        `app.private.people.contacts.view`,
        { id: contactId },
        { reload: true }
      );
    },
  });
  return {
    isPending,
    isRefetching,
    initialValues,
    saveContactProfileInfo,
    setConflictingPeopleByEmail,
    setConflictingPeopleByPhone,
    conflictingPeopleByEmail,
    conflictingPeopleByPhone,
  };
};
