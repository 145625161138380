import React from 'react';
import { Button, Col, Form, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { useCommentList } from '../../hooks/use-comment-list';

import getTextCatalog from '@/react/services/I18nService';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import cdApp from '@/react/config';

interface CommentsEditorProps {
  eventId: number;
}

const CommentsEditor = (props: CommentsEditorProps) => {
  const { addComment, newCommentValue, setNewCommentValue, isCommentPosting } =
    useCommentList({
      eventId: props.eventId,
    });
  const currentUserProfilePic = cdApp.me.picture?.url;
  return (
    <>
      <Row wrap={false} style={{ display: 'flex', flexDirection: 'column' }}>
        <Row>
          <Col flex="none" style={{ marginRight: '8px' }}>
            <CdUserAvatar
              light
              picture={currentUserProfilePic}
              size="default"
            />
          </Col>
          <Col flex="1 1 min-content">
            <Form.Item
              extra={getTextCatalog.getString(
                'Commenting here will notify all the booked users via email.'
              )}
              style={{ marginBottom: '8px' }}
            >
              <TextArea
                rows={4}
                onChange={(e) => setNewCommentValue(e.target.value)}
                value={newCommentValue}
                placeholder={getTextCatalog.getString('Write a comment...')}
                style={{ marginTop: '4px' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Row>

      <Row>
        <Col flex="auto" style={{ marginLeft: '40px' }}>
          <Form.Item>
            <Button
              htmlType="submit"
              loading={isCommentPosting}
              onClick={() => addComment(newCommentValue)}
              type="primary"
              disabled={!newCommentValue}
            >
              {getTextCatalog.getString('Post')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CommentsEditor;
