import { useCallback, useState } from 'react';
import {
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useRecoilCallback,
  useRecoilValue,
} from 'recoil';

import getTextCatalog from '../../../services/I18nService';
import { CommentsObj } from '../../models/calendar';

import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { EventCommentsQuery } from '@/react/calendar/store/events/event-comments.store';
import NotificationService from '@/react/services/NotificationService';
import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const useCommentList = ({ eventId }: { eventId: number }) => {
  const comments = useRecoilValue(EventCommentsQuery({ calendarId: eventId }));
  const [isCommentPosting, setIsCommentPosting] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const [editedComment, setEditedComment] = useState<CommentsObj>(undefined);
  const [editedCommentBody, setEditedCommentBody] = useState<string>(undefined);
  const [newCommentValue, setNewCommentValue] = useState<string>(undefined);

  const refreshComments = useRecoilRefresher(
    EventCommentsQuery({ calendarId: eventId })
  );

  const deleteComment = useRecoilCallback(
    () => async (deleteCommentId) => {
      const response = await mainApi.delete<CommentsObj>(
        `/calendar/${eventId}/comments/${deleteCommentId}`
      );
      if (response.ok) {
        refreshComments();
        NotificationService.notifySuccess(
          getTextCatalog.getString('The comment has been deleted')
        );
        return;
      }
    },
    [eventId, refreshComments]
  );

  const updateComment = useRecoilCallback(
    () => async (body) => {
      const calenderId = eventId;
      const commentId = editedComment?.id;
      const response = await mainApi.put<CommentsObj>(
        `/calendar/${eventId}/comments/${commentId}`,
        {
          calendarId: calenderId,
          body,
        }
      );
      if (response.ok) {
        refreshComments();
        NotificationService.notifySuccess(
          getTextCatalog.getString('Your comment has been updated')
        );
        setIsEditModalOpen(false);
      }
    },
    [eventId, editedComment?.id, refreshComments]
  );

  const addComment = useRecoilCallback(
    () => async (value) => {
      if (!value) return;
      setIsCommentPosting(true);
      const calenderId = eventId;
      const response = await mainApi.post<CommentsObj>(
        `/calendar/${eventId}/comments`,
        { calenderId, body: value }
      );
      if (!response.ok) {
        handleError(response);
        return null;
      }
      refreshComments();
      NotificationService?.notifySuccess(
        getTextCatalog.getString('Your comment has been submitted')
      );
      setNewCommentValue(undefined);
      setIsCommentPosting(false);
      return;
    },
    [eventId, refreshComments]
  );

  const onEditHandler = useCallback((itemProps) => {
    setIsEditModalOpen(true);
    setEditedComment(itemProps);
    setEditedCommentBody(itemProps?.body);
  }, []);

  const onCancelHandler = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);

  const onDeleteOkHandler = useCallback(
    (commentId) => {
      if (!commentId) return;
      deleteComment(commentId);
    },
    [deleteComment]
  );

  const textChangeHandler = useCallback((e) => {
    const newValue = e.target.value;
    setEditedCommentBody(newValue);
  }, []);
  const onDeleteHandler = (commentId) =>
    showConfirmModal({
      title: getTextCatalog.getString('Delete comment?'),
      message: getTextCatalog.getString(
        'Are you sure you want to delete this comment? This action cannot be undone.'
      ),
      okButtonProps: { danger: true },
      okText: getTextCatalog.getString('Delete'),
      onOk: async () => {
        await onDeleteOkHandler(commentId);
      },
    });
  return {
    editedCommentBody,
    isEditModalOpen,
    onEditHandler,
    onCancelHandler,
    onDeleteOkHandler,
    textChangeHandler,
    addComment,
    newCommentValue,
    setNewCommentValue,
    isCommentPosting,
    onDeleteHandler,
    comments,
    updateComment,
  };
};
