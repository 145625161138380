import React, { useState, Fragment, useRef } from 'react';
import { Button, Modal, Form, Input, Select, Typography } from 'antd';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFolder } from '@fortawesome/free-solid-svg-icons';

import { fileArchiveFolderCreate } from '../../../store/actions';
import mountingContainer from '../../mountContainer';

import gettextCatalog from '@/react/services/I18nService';

const { Text } = Typography;

const CreateFolder: React.FC<{
  folderId: number;
  groupId: number | null;
  folderName: string;
  onFinish: any;
}> = ({ folderId, groupId, folderName, onFinish }) => {
  const [visible, setVisibleState] = useState(false);
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.filepicker?.lookups.groups);
  const formRef = useRef(null);
  const showModal = () => setVisibleState(true);
  const hideModal = () => setVisibleState(false);
  const [form] = Form.useForm();
  return (
    <Fragment>
      <Button
        onClick={showModal}
        icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />}
        type="default"
      >
        {gettextCatalog.getString('Create folder')}
      </Button>
      <Modal
        open={visible}
        onCancel={hideModal}
        title={<span>{gettextCatalog.getString('Create folder')}</span>}
        okText={gettextCatalog.getString('Save')}
        centered
        cancelText={gettextCatalog.getString('Cancel')}
        zIndex={999988}
        getContainer={mountingContainer}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              dispatch(
                fileArchiveFolderCreate({
                  ...values,
                  ...{ parentFolderId: folderId === -1 ? undefined : folderId },
                })
              );
              form.resetFields();
              onFinish();
              hideModal();
            })
            .catch();
        }}
      >
        <Form
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 12,
          }}
          layout="horizontal"
          ref={formRef}
          form={form}
          name="form_create_folder"
          initialValues={{ groupId }}
        >
          <Form.Item
            name="folderId"
            label={gettextCatalog.getString('Folder')}
            extra={
              folderId === -1
                ? gettextCatalog.getString(
                    'To place an image in a folder, please navigate to the folder before clicking upload.'
                  )
                : undefined
            }
          >
            <Text disabled>
              <FontAwesomeIcon icon={faFolder} style={{ marginRight: 5 }} />{' '}
              {folderName}
            </Text>
          </Form.Item>
          <Form.Item
            name="title"
            label={gettextCatalog.getString('Title')}
            rules={[
              {
                required: true,
                message: gettextCatalog.getString('Title field is required.'),
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>

          <Form.Item
            name="groupId"
            label={gettextCatalog.getString('Share with group')}
            rules={[
              {
                required: true,
                message: gettextCatalog.getString('Please select a Group'),
              },
            ]}
          >
            <Select
              placeholder={gettextCatalog.getString('Select group')}
              getPopupContainer={mountingContainer}
              disabled={!isNil(groupId)}
            >
              {groups.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default CreateFolder;
