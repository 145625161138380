import { react2angular } from 'react18-react2angular';
import angular from 'angular';

import AssignUserRolesInline from '../../../../../react/user/screens/AssignUserRolesInline';

// Example: <cdr-assign-user-roles-inline userId="userId"/>
angular
  .module('cdApp.settings')
  .component(
    'cdrAssignUserRolesInline',
    react2angular(AssignUserRolesInline, ['userId'], [])
  );
