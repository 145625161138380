import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Skeleton, Typography } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux-saga-modal';

import gettextCatalog from '../../services/I18nService';
import MODAL_TYPES from '../sagas/ModalTypes';
import { toggleAllMyCalendars } from '../store/filters/Actions';
import { CdCalendarFilterCollapse } from '../containers/calendar-filter/CdCalendarFilterCollapse';
import {
  selectIsAllMyCalendarSelected,
  selectIsUserFiltersLoaded,
} from '../store/filters/Selectors';
import {
  getCalendarSubscriptions,
  selectIsInResourceView,
} from '../store/main-view/Selectors';
import FeatureToggleService from '../../services/FeatureToggleService';

import CalendarSubscriptions from './CalendarSubscriptions';
import HolidaysFilter from './HolidaysFilter';
import PersonalCalendarFilter from './PersonalCalendarFilter';
import { FilterSectionHeader } from './FilterSectionHeader';
import CollectionFilter from './CollectionFilter';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const CalendarSubscriptionButton = () => {
  const dispatch = useDispatch();
  return (
    <Button
      shape="circle"
      type="text"
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        dispatch(showModal(MODAL_TYPES.CALENDAR_SUBSCRIPTION_MODAL));
      }}
    >
      <CdTooltip title={gettextCatalog.getString('Add external calendar')}>
        <Typography.Text>
          <FontAwesomeIcon icon={faPlus} />
        </Typography.Text>
      </CdTooltip>
    </Button>
  );
};

const useToggleMyCalendar = () => {
  const dispatch = useDispatch();
  const isAllMyCalendarSelected = useSelector(selectIsAllMyCalendarSelected);
  const subscriptions = useSelector(getCalendarSubscriptions, _.isEqual);
  return useCallback(() => {
    dispatch(
      toggleAllMyCalendars({ isActive: isAllMyCalendarSelected, subscriptions })
    );
  }, [isAllMyCalendarSelected, dispatch, subscriptions]);
};

const MyCalenders = () => {
  const isUserFiltersLoaded = useSelector(selectIsUserFiltersLoaded);
  const isInResourceView = useSelector(selectIsInResourceView);

  const toggleMyCalendar = useToggleMyCalendar();
  const isAllMyCalendarsSelected = useSelector(selectIsAllMyCalendarSelected);
  const [collectionActive, setCollectionActive] = useState<boolean>(false);

  useEffect(() => {
    FeatureToggleService.hasFeature('collection').then(setCollectionActive);
  }, []);
  return (
    <CdCalendarFilterCollapse
      defaultActiveKey="MY_CALENDARS"
      $transparentBackground
      items={[
        {
          key: 'MY_CALENDARS',
          label: isUserFiltersLoaded ? (
            <FilterSectionHeader
              isAbsence={false}
              isDisabled={isInResourceView}
              name={gettextCatalog.getString('My calendars')}
              isAllSelected={isInResourceView || isAllMyCalendarsSelected}
              onClick={toggleMyCalendar}
              addonAfter={!isInResourceView && <CalendarSubscriptionButton />}
            />
          ) : (
            <div style={{ padding: '0 16px' }}>
              <Skeleton
                title={{ width: '80%' }}
                paragraph={false}
                loading
                active
              />
            </div>
          ),
          children: isUserFiltersLoaded ? (
            <div>
              <PersonalCalendarFilter />
              <div>
                <HolidaysFilter isDisabled={isInResourceView} />
                {collectionActive && (
                  <CollectionFilter isDisabled={isInResourceView} />
                )}
                <CalendarSubscriptions isDisabled={isInResourceView} />
              </div>
            </div>
          ) : (
            <div style={{ padding: '0 16px' }}>
              <Skeleton
                title={false}
                paragraph={{ rows: 4, width: '100%' }}
                loading
                active
              />
            </div>
          ),
        },
      ]}
    />
  );
};

export default MyCalenders;
