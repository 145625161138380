import React from 'react';
import { Button, Space } from 'antd';
import styled from 'styled-components';
import NiceModal from '@ebay/nice-modal-react';
import moment from 'moment';

import { OrderType } from '../../shared/models/table';
import gettextCatalog from '../../services/I18nService';
import { CdExclamationTriangleIcon } from '../../shared/components/Icons';
import { ColumnType } from '../../shared/components/cd-table';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const StyledSpace = styled(Space)`
  &&&& {
    font-size: 12px;
  }
`;

export const safeguardingTableColumns = ({ canEdit }: { canEdit: boolean }) => {
  const safeguardingTableColumn: ColumnType<any>[] = [
    {
      key: 'fullName',
      title: gettextCatalog.getString('Name'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) => (
        <Space direction="vertical" size={1}>
          {data.fullName}
          {data.email}
        </Space>
      ),
    },
    {
      key: 'safeguardRequirement',
      title: gettextCatalog.getString('Requirement'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      dataIndex: 'safeguardRequirement',
      render: (data) => {
        switch (data) {
          case 'basic':
            return gettextCatalog.getString('Basic');
          case 'extended':
            return gettextCatalog.getString('Extended');
          case 'not required':
            return gettextCatalog.getString('Not required');
        }
      },
    },
    {
      key: 'course',
      title: gettextCatalog.getString('Course'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) => (
        <Space direction="vertical" style={{ width: '100%' }} size={1}>
          {data?.course?.courseTitle}
          {data?.course && (
            <Space direction="vertical" size={1}>
              {gettextCatalog.getString('Reviewed by')}
              <StyledSpace direction="horizontal">
                {data?.course?.reviewedBy}
                {gettextCatalog.getString('on')}
                {moment(data?.course?.reviewDate).format('L')}
              </StyledSpace>
              {data?.course?.refreshRequired ? (
                <StyledSpace direction="horizontal">
                  {gettextCatalog.getString('Expires on')}
                  {moment(data?.course?.refreshDate).format('L')}
                </StyledSpace>
              ) : null}
            </Space>
          )}
          {data.validation?.courses?.basic?.missing && (
            <Space
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {gettextCatalog.getString('Basic course missing')}
              <CdExclamationTriangleIcon />
            </Space>
          )}
          {data.validation?.courses?.extended?.missing && (
            <Space
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {gettextCatalog.getString('Extended course missing')}
              <CdExclamationTriangleIcon />
            </Space>
          )}
        </Space>
      ),
    },
    {
      key: 'documents',
      title: gettextCatalog.getString('Documents'),
      active: true,
      render: (data) => (
        <Space direction="vertical" style={{ width: '100%' }} size={1}>
          {data.validation?.documents?.codeOfConduct?.missing && (
            <Space
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {gettextCatalog.getString('Code of conduct')}
              <CdExclamationTriangleIcon />
            </Space>
          )}
          {data.validation?.documents?.selfDisclosure?.missing && (
            <Space
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {gettextCatalog.getString('Self-disclosure statement')}
              <CdExclamationTriangleIcon />
            </Space>
          )}
        </Space>
      ),
    },
    {
      key: 'certificateOfConduct',
      title: gettextCatalog.getString('Certificate of conduct'),
      active: true,
      render: (data) => (
        <Space direction="vertical" size={1} style={{ width: '100%' }}>
          {data?.validation?.certificateOfConduct?.missing && (
            <Space
              size={1}
              direction="horizontal"
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {gettextCatalog.getString('Missing!')}
              <CdExclamationTriangleIcon />
            </Space>
          )}
          {data?.certificateOfConduct && (
            <Space direction="vertical" size={1}>
              {gettextCatalog.getString('Reviewed')}
              <StyledSpace direction="horizontal">
                {data?.certificateOfConduct?.reviewedBy}
                {gettextCatalog.getString('on')}
                {moment(data?.certificateOfConduct?.reviewDate).format('L')}
              </StyledSpace>
              {data?.certificateOfConduct?.refreshRequired ? (
                <StyledSpace direction="horizontal">
                  {gettextCatalog.getString('Expires on')}
                  {moment(data?.certificateOfConduct?.refreshDate).format('L')}
                </StyledSpace>
              ) : null}
            </Space>
          )}
        </Space>
      ),
    },
    {
      key: 'safeguardingEdit',
      title: '',
      dataIndex: 'userId',
      active: true,
      align: 'center',
      render: (userId) => (
        <Space direction="horizontal">
          <CdTooltip
            title={
              canEdit
                ? undefined
                : gettextCatalog.getString('You do not have edit permission.')
            }
          >
            <Button
              type="primary"
              disabled={!canEdit}
              onClick={() =>
                NiceModal.show('SafeguardingDrawer', {
                  isEdit: canEdit,
                  currentUserId: userId,
                })
              }
            >
              {gettextCatalog.getString('Edit')}
            </Button>
          </CdTooltip>
          {!canEdit && (
            <Button
              type="primary"
              onClick={() =>
                NiceModal.show('SafeguardingDrawer', {
                  isEdit: canEdit,
                  currentUserId: userId,
                })
              }
            >
              {gettextCatalog.getString('View')}
            </Button>
          )}
        </Space>
      ),
    },
  ];
  return safeguardingTableColumn;
};
