import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Button,
  Form,
  Layout,
  Checkbox,
  Row,
  Col,
  Input,
  List,
  Divider,
  Typography,
  Empty,
  Skeleton,
  Tabs,
  Switch,
  Space,
  Alert,
  Dropdown,
  Menu,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTimes,
  faCheckCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { clone, map, isEmpty, set, get, isUndefined } from 'lodash';
import DownOutlined from '@ant-design/icons/DownOutlined';

import { CreateRoleButton } from '../../components/CreateRoleModal';
import gettextCatalog from '../../../services/I18nService';
import {
  RoleInterface,
  RoleSectionPermissionsInterface,
} from '../../types/organization-role.types';
import { selectIsModulesLoading } from '../../../shared/loading/redux/Selectors';
import { RolesActions, roleSelectors } from '../../store/roleSlice';
import {
  CdCopyIcon,
  CdDeleteIcon,
  CdDisableRole,
  CdEditIcon,
  CdEnableRole,
} from '../../../shared/components/Icons';

import { VisibilityRadioOption } from './VisibilityDropdown';
import { SideMenu } from './SideMenu';
import { FetchRolesOnInit, useUpdateRole } from './hooks';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
const { TextArea } = Input;
const { Content } = Layout;
const { Text, Paragraph, Title } = Typography;
const { TabPane } = Tabs;

const CheckboxStyle = { marginTop: 10 };

const ManageRoles: FunctionComponent<{
  currentRoleId: string;
  currentRoleContext: string;
  forceReloadRoles: boolean;
}> = ({ currentRoleId, currentRoleContext, forceReloadRoles }) => {
  const dispatch = useDispatch();
  const [isEditMode, setEditMode] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [forceUpdate, setForceUpdate] = useState(1);
  const [roleDescription, setRoleDescription] = useState('');
  const UpdateRole = useUpdateRole();
  const [form] = Form.useForm();
  const onChangeInputRoleName = useCallback(
    ({ target: { value } }) => setRoleName(value),
    [setRoleName]
  );
  const onChangeInputDescription = useCallback(
    ({ target: { value } }) => setRoleDescription(value),
    [setRoleDescription]
  );
  FetchRolesOnInit(currentRoleId, currentRoleContext, forceReloadRoles);

  const roleLoading = useSelector(
    selectIsModulesLoading([RolesActions.fetchRoles.type])
  );
  const role = useSelector(roleSelectors.selectById(currentRoleId));
  const isRoleEnabled = role && role.isEnabled;
  useEffect(() => {
    if (!role) return;
    setRoleName(role.name);
    setRoleDescription(role.description);
    form.setFieldsValue({ permissions: role.permissions });
  }, [role, form]);

  const onChangeAccessSwitchUpdated = useCallback(() => {
    // Force re-render of the tab Enabled/Disable text.
    setForceUpdate(forceUpdate + 1);
  }, [setForceUpdate, forceUpdate]);
  const saveRole = () => {
    form.validateFields().then((values) => {
      UpdateRole({
        id: currentRoleId,
        name: roleName,
        description: roleDescription,
        context: currentRoleContext,
        permissions: values.permissions,
        isEnabled: role.isEnabled,
      });
      setEditMode(!isEditMode);
    });
  };

  const fieldsChange = (changedFields) => {
    // Auto enable permission switches if one of the child permissions are enabled.
    if (!changedFields[0]) return;
    if (get(changedFields[0].name.slice(4, 5), '[0]') === 'switch') return;
    if (!changedFields[0].value) return;
    const section = changedFields[0].name.slice(0, 3);
    const permissionPath = section.concat(['access', 'switch', 'granted']);
    if (isUndefined(form.getFieldValue(permissionPath))) return;
    form.setFieldsValue(set({}, permissionPath, true));
  };

  return (
    <Form form={form} onFieldsChange={fieldsChange}>
      <div>
        <PageHeader
          title={gettextCatalog.getString('Roles and access')}
          style={{ padding: '0px 0px 16px' }}
          extra={<CreateRoleButton />}
        />
        <div className="panel panel-default">
          <div className="panel-body" style={{ padding: 0 }}>
            <Layout>
              <SideMenu currentRoleId={currentRoleId} />
              <Content style={{ background: '#fff', minHeight: 500 }}>
                {!currentRoleId ? (
                  <EmptyPlaceholder />
                ) : roleLoading || !role ? (
                  <Row>
                    <Col style={{ padding: ' 24px 24px', width: '100%' }}>
                      <Skeleton active />
                    </Col>
                  </Row>
                ) : (
                  <div>
                    <PageHeader
                      ghost={false}
                      title={
                        isEditMode ? (
                          <Input
                            required
                            size="large"
                            value={roleName}
                            placeholder={gettextCatalog.getString(
                              'Enter role name...'
                            )}
                            onChange={onChangeInputRoleName}
                            maxLength={255}
                          />
                        ) : (
                          <Title level={2}>{roleName}</Title>
                        )
                      }
                      extra={actionButtons(
                        role,
                        isEditMode,
                        saveRole,
                        setEditMode,
                        dispatch
                      )}
                    >
                      {isRoleEnabled ? null : (
                        <Alert
                          message={gettextCatalog.getString(
                            'The role is disabled and cannot be assigned to users. Enable it from the actions menu if you would like to make changes to it.'
                          )}
                          type="warning"
                          showIcon
                          style={{ marginBottom: '8px' }}
                          closable={false}
                        />
                      )}
                      {isEditMode ? (
                        <div>
                          <TextArea
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            value={roleDescription}
                            placeholder={gettextCatalog.getString(
                              'Enter description...'
                            )}
                            onChange={onChangeInputDescription}
                            maxLength={255}
                          />
                          <Text>
                            {gettextCatalog.getString('Max. 255 characters.')}
                          </Text>
                        </div>
                      ) : (
                        <Paragraph
                          ellipsis={{
                            symbol: gettextCatalog.getString('Expand'),
                            expandable: true,
                          }}
                          style={{ whiteSpace: 'pre-wrap' }}
                        >
                          {isEmpty(roleDescription)
                            ? gettextCatalog.getString('Enter description...')
                            : roleDescription}
                        </Paragraph>
                      )}
                      <Divider />
                      <Row>
                        <Col style={{ padding: '0 0 24px', width: '100%' }}>
                          <Title level={3}>
                            {gettextCatalog.getString('Access and Permissions')}
                          </Title>
                          <Paragraph type="secondary">
                            {gettextCatalog.getString(
                              'Grant access to the ChurchDesk apps to everyone with this role and manage what they can do within each of the apps.'
                            )}
                          </Paragraph>

                          <Tabs type="card">
                            {map(role.permissions, (module, moduleKey) => (
                              <TabPane
                                tab={
                                  <TabPaneHead
                                    module={module}
                                    moduleKey={moduleKey}
                                  />
                                }
                                key={`m${moduleKey}`}
                              >
                                {map(module.sections, (section, sectionKey) => (
                                  <span key={`s${sectionKey}`}>
                                    <SwitchComponent
                                      section={section}
                                      moduleKey={moduleKey}
                                      sectionKey={sectionKey}
                                      isEditMode={isEditMode}
                                      onChangeAccessSwitchUpdated={
                                        onChangeAccessSwitchUpdated
                                      }
                                    />
                                    <PermissionSection
                                      section={section}
                                      moduleKey={moduleKey}
                                      sectionKey={sectionKey}
                                      isEditMode={isEditMode}
                                    />
                                  </span>
                                ))}
                              </TabPane>
                            ))}
                          </Tabs>
                        </Col>
                      </Row>
                    </PageHeader>
                  </div>
                )}
              </Content>
            </Layout>
          </div>
        </div>
      </div>
    </Form>
  );
};

const TabPaneHead = ({
  module,
  moduleKey,
}: {
  module: any;
  moduleKey: string;
}) => (
  <span>
    {module.sections.access.switch ? (
      <Form.Item
        noStyle
        name={[
          'permissions',
          moduleKey,
          'sections',
          module.statusSectionId,
          'switch',
          'granted',
        ]}
        valuePropName="value"
      >
        <TapPaneHeadIcon />
      </Form.Item>
    ) : null}
    <Title level={4} style={{ display: 'inline-block', margin: 0 }}>
      {module.name}
    </Title>
    {/* Icon with Tooltip to show user legacy roles excl. admin as this will never be possible to associate to a church */}
    {module.isLegacy && module.sections.access.switch ? (
      <CdTooltip
        title={gettextCatalog.getString(
          `For the moment {{name}} can not be associated with a church. Therefore if any user is granted a role with permissions in {{name}}, the user will have these permissions in all churches, instead of limited to selected churches. The ability to limit {{name}} to a church will be available later in the year (2020).`,
          { name: module.name }
        )}
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
          color="#c6c8c9"
          style={{ marginLeft: 10 }}
        />
      </CdTooltip>
    ) : null}
  </span>
);

const TapPaneHeadIcon: FunctionComponent<{ value?: boolean }> = ({ value }) => {
  if (value) {
    return (
      <FontAwesomeIcon
        size="lg"
        color="#7ab800"
        icon={faCheckCircle}
        style={{ marginRight: 10 }}
      />
    );
  } else {
    return (
      <FontAwesomeIcon
        size="lg"
        color="#c6c8c9"
        icon={faCircle}
        style={{ marginRight: 10 }}
      />
    );
  }
};

const moduleDescriptions = (moduleDescription, description) => {
  if (!isEmpty(moduleDescription)) {
    return (
      <>
        {moduleDescription.text}
        <ul className="u-ml-30">
          {map(moduleDescription.listOfAccess, (accessDescription, key) => (
            <li key={key}>{accessDescription}</li>
          ))}
        </ul>
        {moduleDescription.description}
      </>
    );
  }
  return description;
};

const SwitchComponent = ({
  section,
  moduleKey,
  sectionKey,
  isEditMode,
  onChangeAccessSwitchUpdated,
}: {
  section: any;
  moduleKey: string;
  sectionKey: string;
  isEditMode: boolean;
  onChangeAccessSwitchUpdated: () => void;
}) =>
  section.switch ? (
    <List
      key={`s${sectionKey}`}
      itemLayout="horizontal"
      style={{
        padding: '0 10px 10px',
        backgroundColor: '#fafafa',
        border: '1px solid #f0f0f0',
        borderRadius: 4,
      }}
    >
      {/* Switch. Example: Access to People. */}
      <List.Item key={`s${sectionKey}-switch`}>
        <List.Item.Meta
          avatar={
            <CdTooltip
              title={
                !isEditMode &&
                gettextCatalog.getString(
                  'Click edit role to change permissions'
                )
              }
            >
              <Form.Item
                valuePropName="checked"
                name={[
                  'permissions',
                  moduleKey,
                  'sections',
                  sectionKey,
                  'switch',
                  'granted',
                ]}
              >
                <Switch
                  checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                  unCheckedChildren={<FontAwesomeIcon icon={faTimes} />}
                  onChange={onChangeAccessSwitchUpdated}
                  disabled={!isEditMode || section.switch.editable === false}
                  style={{ marginTop: 10 }}
                />
              </Form.Item>
            </CdTooltip>
          }
          title={<Text strong>{section.switch.text}</Text>}
          description={moduleDescriptions(
            section.switch.moduleDescription,
            section.switch.description
          )}
        />
      </List.Item>
    </List>
  ) : null;

const EmptyPlaceholder: FunctionComponent = () => (
  <Empty
    description={
      <>
        <Text strong>
          {gettextCatalog.getString(
            'Create roles to manage user permissions and access'
          )}
        </Text>
        <br />
        <Text type="secondary">
          {gettextCatalog.getString(
            'Assign roles to users matching their responsibilities in the churches they belong to.'
          )}
        </Text>
      </>
    }
    style={{ marginTop: 40 }}
  />
);

const actionButtons = (
  role: RoleInterface,
  isEditMode,
  saveRole,
  setEditMode,
  dispatch
) => {
  if (!role) return null;
  const isRoleLocked = !!role.usesStatic;
  const currentRoleName = role.name;
  const isRoleEnabled = role.isEnabled;
  const currentRoleId = role.id;
  const currentRoleContext = role.context;
  const menu = (
    <Menu>
      <Menu.Item
        key="copy"
        icon={<CdCopyIcon />}
        disabled={!role.canCopy || false}
        onClick={showCopyConfirm}
      >
        {gettextCatalog.getString('Copy role')}
      </Menu.Item>
      {!isRoleEnabled ? (
        <Menu.Item
          key="disable-role"
          icon={<CdEnableRole />}
          onClick={showEnableConfirm}
        >
          {gettextCatalog.getString('Enable role')}
        </Menu.Item>
      ) : null}
      {isRoleEnabled ? (
        <Menu.Item
          key="enable-role"
          icon={<CdDisableRole />}
          disabled={role.context === 'organization'}
          onClick={showDisableConfirm}
        >
          {gettextCatalog.getString('Disable role')}
        </Menu.Item>
      ) : null}
      <Menu.Divider key="divider-1" />
      <Menu.Item
        className="manage-roles_extra-actions_delete"
        key="delete-menu-item"
        onClick={showDeleteConfirm}
        icon={<CdDeleteIcon />}
        disabled={isRoleLocked || false}
      >
        <CdTooltip
          key="delete-tooltip"
          title={
            isRoleLocked &&
            gettextCatalog.getString('System role cannot be deleted')
          }
        >
          {gettextCatalog.getString('Delete')}
        </CdTooltip>
      </Menu.Item>
    </Menu>
  );

  function showCopyConfirm() {
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Copy role?'),
      icon: <CdCopyIcon />,
      message: (
        <Paragraph>
          {gettextCatalog.getString('Do you want to copy the role:')}{' '}
          <Text strong>{currentRoleName}</Text>.
        </Paragraph>
      ),
      okText: `${gettextCatalog.getString('Yes')}`,
      onOk() {
        dispatch(RolesActions.copyRole(role.id));
      },
    });
  }

  function showDisableConfirm() {
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Disable role?'),
      message: (
        <Paragraph>
          {gettextCatalog.getString('Do you want to disable the role:')}{' '}
          <Text strong>{currentRoleName}</Text>.
        </Paragraph>
      ),
      okText: `${gettextCatalog.getString('Yes')}`,
      onOk() {
        dispatch(RolesActions.disableRole(role));
      },
    });
  }

  function showDeleteConfirm() {
    showConfirmModalDeprecated({
      title: `${gettextCatalog.getString('Delete role')}`,
      message: (
        <Paragraph>
          {gettextCatalog.getString('Do you want to delete the role:')}{' '}
          <Text strong>{currentRoleName}</Text>.
        </Paragraph>
      ),
      okText: `${gettextCatalog.getString('Yes')}`,
      okType: 'primary',
      okButtonProps: {
        danger: true,
      },
      onOk() {
        if (isRoleLocked) return;
        dispatch(
          RolesActions.removeRole({
            id: currentRoleId,
            context: currentRoleContext,
          })
        );
      },
    });
  }

  function showEnableConfirm() {
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Enable role?'),
      icon: <CdEnableRole />,
      message: (
        <Paragraph>
          {gettextCatalog.getString('Do you want to enable the role:')}{' '}
          <Text strong>{currentRoleName}</Text>.
        </Paragraph>
      ),
      okText: `${gettextCatalog.getString('Yes')}`,
      onOk() {
        dispatch(RolesActions.enableRole(role));
      },
    });
  }

  return [
    !isEditMode && isRoleEnabled && (
      <CdTooltip
        key="edit-tooltip"
        title={
          isRoleLocked &&
          gettextCatalog.getString('System role cannot be edited')
        }
      >
        <Button
          key="edit"
          value="edit"
          type="default"
          disabled={isRoleLocked}
          onClick={() => setEditMode(!isEditMode)}
        >
          <Space>
            <CdEditIcon />
            {gettextCatalog.getString('Edit role')}
          </Space>
        </Button>
      </CdTooltip>
    ),
    isEditMode && (
      <Button
        key="save"
        type="primary"
        value="saved"
        onClick={() => saveRole()}
      >
        {gettextCatalog.getString('Save role')}
      </Button>
    ),
    isEditMode && (
      <Button
        key="cancel"
        type="default"
        value="cancel"
        onClick={() => setEditMode(!isEditMode)}
      >
        {gettextCatalog.getString('Cancel')}
      </Button>
    ),
    <Dropdown
      className="manage-roles_extra-actions"
      key="extra-actions"
      overlay={menu}
    >
      <Button>
        {gettextCatalog.getString('Actions')} <DownOutlined />
      </Button>
    </Dropdown>,
  ];
};

const PermissionSection = ({
  section,
  moduleKey,
  sectionKey,
  isEditMode,
}: {
  section: any;
  moduleKey: string;
  sectionKey: string;
  isEditMode: boolean;
}) => (
  <>
    {moduleKey === 'form' ? (
      <>
        <Title level={3}>{gettextCatalog.getString('Form responses')}</Title>
        <Text>
          {gettextCatalog.getString(
            'Choose the level of response detail the user can see for all forms they can access.'
          )}
        </Text>
        <CdTooltip
          title={
            !isEditMode &&
            gettextCatalog.getString('Click edit role to change permissions')
          }
        >
          <Form.Item
            name={[
              'permissions',
              moduleKey,
              'sections',
              sectionKey,
              'permissions',
              'view',
              'visibilityGranted',
            ]}
            valuePropName="value"
          >
            <VisibilityRadioOption
              isEditMode={isEditMode}
              config={section.permissions.view.visibilityConfig}
            />
          </Form.Item>
        </CdTooltip>
      </>
    ) : null}
    {isEmpty(section.permissions) ? null : (
      <>
        <Title level={3}>{section.name}</Title>
        <Text>{section.description}</Text>
        <List
          itemLayout="horizontal"
          dataSource={map(section.permissions, (p, key) => {
            const newP = clone(p);
            newP.permissionKey = key;
            return newP;
          })}
          renderItem={(peoplePermission: RoleSectionPermissionsInterface) => {
            const permissionPath = [
              'permissions',
              moduleKey,
              'sections',
              sectionKey,
              'permissions',
              peoplePermission.permissionKey,
              'granted',
            ];
            return (
              <List.Item key={peoplePermission.permissionKey} actions={[]}>
                <List.Item.Meta
                  avatar={
                    <CdTooltip
                      title={
                        !isEditMode &&
                        gettextCatalog.getString(
                          'Click edit role to change permissions'
                        )
                      }
                    >
                      <Form.Item name={permissionPath} valuePropName="checked">
                        <Checkbox
                          style={CheckboxStyle}
                          disabled={!isEditMode}
                        ></Checkbox>
                      </Form.Item>
                    </CdTooltip>
                  }
                  title={peoplePermission.name}
                  description={
                    <>
                      {peoplePermission.description}
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          get(prevValues, permissionPath) !==
                          get(currentValues, permissionPath)
                        }
                      >
                        {({ getFieldValue }) =>
                          peoplePermission.visibilityConfig &&
                          moduleKey !== 'form' && (
                            <Form.Item
                              name={[
                                'permissions',
                                moduleKey,
                                'sections',
                                sectionKey,
                                'permissions',
                                peoplePermission.permissionKey,
                                'visibilityGranted',
                              ]}
                              valuePropName="value"
                            >
                              <VisibilityRadioOption
                                isEditMode={
                                  isEditMode && getFieldValue(permissionPath)
                                }
                                config={
                                  section.permissions.view.visibilityConfig
                                }
                              />
                            </Form.Item>
                          )
                        }
                      </Form.Item>
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      </>
    )}
  </>
);

const OrganizationRoot: FunctionComponent<{
  currentRoleId: string;
  currentRoleContext: string;
  forceReloadRoles: boolean;
}> = ({ currentRoleId, currentRoleContext, forceReloadRoles }) => (
  <ManageRoles
    currentRoleId={currentRoleId}
    currentRoleContext={currentRoleContext}
    forceReloadRoles={forceReloadRoles}
  />
);
export default OrganizationRoot;
