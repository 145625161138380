import _ from 'lodash';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUser } from '../../config/store/Selector';
import { AppState } from '../../redux';
import gettextCatalog from '../../services/I18nService';
import { ToggleMeFilter } from '../store/filters/Actions';
import { Me } from '../../shared/models/authentication';

import { FilterListItem } from './FilterItem';
import { showCalendarSubscribeModal } from './CalendarSubscribeModal';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const getClassName = (isSelected: boolean, color: number): string =>
  `bullet color-${color || 0} ${isSelected ? 'bullet-full' : 'bullet-empty'}`;

const PersonalCalendarFilter: FunctionComponent = () => {
  const dispatch = useDispatch();
  const onToggle = () => dispatch(ToggleMeFilter());
  const me: Me = useSelector(getCurrentUser, _.isEqual);
  const isSelected: boolean = useSelector(
    (state: AppState) => state.calendar.filters.showMyCalendar
  );
  return (
    <FilterListItem
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div className="bullet-wrapper">
        <div
          className="bullet-label"
          onClick={() => {
            event.stopPropagation();
            event.preventDefault();
            onToggle();
          }}
        >
          <span className={getClassName(isSelected, 888)} />
          <div className="label">
            <span className="name">{me.fullName ? me.fullName : me.email}</span>
          </div>
        </div>

        <span className="filter-actions">
          <CdTooltip title={gettextCatalog.getString('Export to iCal')}>
            <FontAwesomeIcon
              icon={faExternalLinkAlt}
              onClick={() =>
                showCalendarSubscribeModal({ feedType: 'user', feedId: me.id })
              }
              style={{ marginRight: 5, cursor: 'pointer' }}
            />
          </CdTooltip>
        </span>
      </div>
    </FilterListItem>
  );
};

export default PersonalCalendarFilter;
