'use strict';

import _ from 'lodash';

import { GenderTypes } from '@/react/people/types/people';

/**
 * A class that describes a static/standard field
 *
 * @constructor
 * @param {Object} person - A person to get values from
 * @param {Object} options - An object of properties of the static field
 * @param {string} options.key - The key used to reference the field from the person object
 * @param {string} options.label - The label that will be shown in the view
 * @param {boolean} options.available - Whether the field will be shown or not
 * @param {Object[]} options.options - An array of options for the dropdown field
 * @param {function} getView - Returns the actual value from the person object
 * @param {function} getViewValue - Returns the value that will be shown in the view
 */
angular.module('cdApp.people').factory('StaticField', function () {
  'ngInject';

  return class StaticField {
    constructor(person, icons, options) {
      this.person = person;

      // Default options
      _.extend(this, {
        available: true,
        icon: _.get(icons, options.key),
      });

      _.extend(this, options);
    }

    getValue() {
      return _.get(this.person, this.key);
    }

    getViewValue() {
      return this.getValue();
    }
  };
});

class PeopleFieldsService {
  constructor(_, moment, cdApp, gettextCatalog, $filter, StaticField) {
    'ngInject';

    this._ = _;
    this.moment = moment;
    this.cdApp = cdApp;
    this.gettextCatalog = gettextCatalog;
    this.$filter = $filter;
    this.StaticField = StaticField;
    this.genderIcons = {
      [GenderTypes.OTHER]: 'fa-genderless',
      [GenderTypes.FEMALE]: 'fa-venus',
      [GenderTypes.MALE]: 'fa-mars',
    };
  }

  getStaticFieldsSections(staticFieldsOptions, person) {
    const { _, moment, cdApp, gettextCatalog, $filter, StaticField } = this;
    const organizationCountry = _.get(this.cdApp, 'organization.countryIso2');

    const icons = this.getStaticFieldsIcons(person);

    const contactFields = [
      new StaticField(person, icons, {
        key: 'prefix',
        label: gettextCatalog.getString('Prefix'),
        type: 'textfield',
      }),

      new StaticField(person, icons, {
        key: 'firstName',
        label: gettextCatalog.getString('First name'),
        type: 'textfield',
      }),

      new StaticField(person, icons, {
        key: 'lastName',
        label: gettextCatalog.getString('Surname'),
        type: 'textfield',
      }),

      new StaticField(person, icons, {
        key: 'email',
        label: gettextCatalog.getString('E-mail'),
        type: 'textfield',
        getViewValue() {
          return $filter('linky')(this.getValue());
        },
      }),

      new StaticField(person, icons, {
        key: 'phone',
        label: gettextCatalog.getString('Mobile phone'),
        type: 'phone',
        getViewValue() {
          return $filter('formatPhoneNumber')(this.getValue());
        },
      }),

      new StaticField(person, icons, {
        key: 'homePhone',
        label: gettextCatalog.getString('Home phone'),
        type: 'phone',
        getViewValue() {
          return $filter('formatPhoneNumber')(this.getValue());
        },
      }),

      new StaticField(person, icons, {
        key: 'workPhone',
        label: gettextCatalog.getString('Work phone'),
        type: 'phone',
        getViewValue() {
          return $filter('formatPhoneNumber')(this.getValue());
        },
      }),
    ];

    return [
      /**
       * Contact
       */
      {
        title: gettextCatalog.getString('Contact'),
        fields: contactFields,
      },

      /**
       * Address
       */
      {
        title: gettextCatalog.getString('Address'),
        fields: [
          new StaticField(person, icons, {
            key: 'street',
            label: gettextCatalog.getString('Street address'),
            type: 'textfield',
          }),

          new StaticField(person, icons, {
            key: 'city',
            label: gettextCatalog.getString('City'),
            type: 'textfield',
          }),

          new StaticField(person, icons, {
            key: 'zipcode',
            label: gettextCatalog.getString('Post code'),
            type: 'textfield',
          }),

          new StaticField(person, icons, {
            key: 'country',
            label: gettextCatalog.getString('Country'),
            type: 'select',
            options: _(cdApp.data.countries)
              .values()
              .sortBy('nameTranslated')
              .map((country) => ({
                value: country.iso2,
                label: country.nameTranslated,
              }))
              .value(),
            getViewValue() {
              return _.result(
                _.find(this.options, { value: _.toLower(this.getValue()) }),
                'label'
              );
            },
          }),
        ],
      },

      /**
       * Other details
       */
      {
        title: gettextCatalog.getString('Other details'),
        fields: [
          new StaticField(person, icons, {
            key: 'birthday',
            label: gettextCatalog.getString('Birthday'),
            type: 'date',
            getViewValue() {
              return moment(this.getValue()).format('L');
            },
          }),

          new StaticField(person, icons, {
            key: 'gender',
            label: gettextCatalog.getString('Gender'),
            type: 'radio',
            options: {
              male: gettextCatalog.getString('Male'),
              female: gettextCatalog.getString('Female'),
              other: gettextCatalog.getString('Other'),
            },

            getViewValue() {
              switch (this.getValue()) {
                case 'male':
                  return gettextCatalog.getString('Male');
                case 'female':
                  return gettextCatalog.getString('Female');
                case 'other':
                  return gettextCatalog.getString('Other');
              }
            },
          }),

          new StaticField(person, icons, {
            key: 'occupation',
            label: gettextCatalog.getString('Occupation'),
            type: 'textfield',
          }),

          new StaticField(person, icons, {
            key: 'note',
            label: gettextCatalog.getString('Internal note'),
            type: 'textarea',
          }),

          new StaticField(person, icons, {
            key: 'civilStatus',
            label: gettextCatalog.getString('Civil status'),
            type: 'select',
            options: _.transform(
              _.get(staticFieldsOptions, 'civilStatus'),
              (result, label, value) => {
                result.push({ value, label });
                return result;
              },
              []
            ),

            getViewValue() {
              return _.result(
                _.find(this.options, { value: this.getValue() }),
                'label'
              );
            },
          }),
        ],
      },

      /**
       * Health
       */
      {
        title: gettextCatalog.getString('Health'),
        fields: [
          new StaticField(person, icons, {
            key: 'nationalIdentificationNumber',
            label: gettextCatalog.getString('National identification number'),
            type: organizationCountry === 'dk' ? 'cprNumber' : 'textfield',
            available: organizationCountry === 'dk', // For now, only Danish organizations can use this field
            getViewValue() {
              const value = this.getValue();
              if (!value) return null;
              return organizationCountry === 'dk'
                ? [value.slice(0, 6), '-', value.slice(6)].join('')
                : value;
            },
          }),

          new StaticField(person, icons, {
            key: 'allergy',
            label: gettextCatalog.getString('Allergies and intolerances'),
            type: 'multiselect',
            options: _.get(staticFieldsOptions, 'allergy'),
            getValue() {
              const value = _.get(person, this.key);
              return value && value.length ? value : null;
            },
            getViewValue() {
              const value = this.getValue();
              if (!value) return null;
              return _(value)
                .map((value) => this.options[value])
                .join(', ');
            },
          }),

          new StaticField(person, icons, {
            key: 'dietary',
            label: gettextCatalog.getString('Diet'),
            type: 'select',
            options: _.transform(
              _.get(staticFieldsOptions, 'dietary'),
              (result, label, value) => {
                result.push({ value, label });
                return result;
              },
              []
            ),

            getViewValue() {
              return _.result(
                _.find(this.options, { value: this.getValue() }),
                'label'
              );
            },
          }),

          new StaticField(person, icons, {
            key: 'medicalNote',
            label: gettextCatalog.getString('Medical note'),
            type: 'textarea',
          }),
        ],
      },
    ];
  }

  /**
   * Gets an object that has all static fields keys mapped to their icons. Matches the response from the `people/people/:id` endpoint
   */
  getStaticFieldsIcons(person) {
    return {
      id: 'fa-hashtag',
      // Basic
      prefix: 'fa-user',
      firstName: 'fa-user',
      lastName: 'fa-user',
      fullName: 'fa-user',
      // Contact
      email: 'fa-at',
      phone: 'fa-mobile-alt',
      mobilePhone: 'fa-mobile-alt',
      homePhone: 'fa-phone-rotary',
      workPhone: 'fa-phone-office',
      // Address
      street: 'fa-sign',
      city: 'fa-city',
      zipcode: 'fa-map-marker-alt',
      country: 'fa-flag',
      // Other details
      birthday: 'fa-birthday-cake',
      gender: this.genderIcons[_.get(person, 'gender')],
      occupation: 'fa-suitcase',
      note: 'fa-sticky-note',
      civilStatus: 'fa-heart',
      nationalIdentificationNumber: 'fa-id-badge',
      // Health
      allergy: 'fa-allergies',
      dietary: 'fa-utensils',
      medicalNote: 'fa-comment-medical',
      // Settings
      tags: 'fa-tag',
      registered: 'fa-calendar-plus',
    };
  }
}
PeopleFieldsService.$inject = [
  '_',
  'moment',
  'cdApp',
  'gettextCatalog',
  '$filter',
  'StaticField',
];

angular
  .module('cdApp.people')
  .service('peopleFieldsService', PeopleFieldsService);
