import React, { useEffect } from 'react';
import { Modal } from 'antd';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import { Form, Input } from 'antd';
import styled from 'styled-components';

import useNewsletterList, { SetUpType } from '../hooks/useNewsletterList';

import gettextCatalog from '@/react/services/I18nService';

const Container = styled.div`
  margin: 0 32px 48px;
`;
interface RenameNewsletterOrFilterProps {
  listId: number;
  listName: string;
  type: string;
  setup: SetUpType;
}

export const RenameNewsletterOrFilterModal = NiceModal.create(
  ({
    listId,
    listName,
    type,
    setup,
  }: {
    listId: number;
    listName: string;
    type: string;
    setup: SetUpType;
  }) => (
    <RenameNewsletterOrFilter
      listId={listId}
      listName={listName}
      type={type}
      setup={setup}
    />
  )
);

export const RenameNewsletterOrFilter = ({
  listId,
  listName,
  type,
  setup,
}: RenameNewsletterOrFilterProps) => {
  const modal = useModal('RenameNewsletterOrFilter');
  const [form] = Form.useForm();

  const { editListName } = useNewsletterList();
  useEffect(() => {
    form.setFieldsValue({
      name: listName,
    });
    // eslint-disable-next-line
  }, []);

  const onFinish = async () => {
    await editListName(form.getFieldValue('name'), setup, listId, type);
    modal.remove();
  };
  let title: string;
  if (type === 'filter') {
    title = gettextCatalog.getString('Rename filter');
  } else if (type === 'newsletter') {
    title = gettextCatalog.getString('Rename newsletter list');
  } else {
    title = gettextCatalog.getString('Rename participant list');
  }

  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        maskClosable: false,
        title,
        bodyStyle: { padding: 0 },
        onCancel: modal.remove,
        okText: gettextCatalog.getString('Save'),
        onOk: onFinish,
      }}
    >
      <Form
        style={{ marginTop: '16px' }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        form={form}
        onFinish={onFinish}
      >
        <Container>
          <h4>{gettextCatalog.getString(`Name`)}</h4>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: gettextCatalog.getString('Please input a name!'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Container>
      </Form>
    </Modal>
  );
};
