import React from 'react';

import gettextCatalog from '../../services/I18nService';
import { CdPage } from '../../shared/components/cd-page/CdPage';
import { Anniversary } from '../anniversary/components/Anniversary';

export default function AnniversaryPage() {
  return (
    <CdPage
      pageHeaderProps={{
        title: gettextCatalog.getString('Anniversaries'),
        subTitle: gettextCatalog.getString(
          'View all upcoming anniversaries for next 14 days'
        ),
        backIcon: false,
      }}
    >
      <Anniversary />
    </CdPage>
  );
}
