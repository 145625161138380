import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import getTextCatalog from '@/react/services/I18nService';
import { handleError } from '@/react/services/ErrorHandlingService';
import { GetNotifications } from '@/react/calendar/store/events/eventBookedusersNotifications';

export const useNotification = (entityId?: number) => {
  const notificationRefresher = useRecoilRefresher(
    GetNotifications({ entityId, entityType: 'calendar' })
  );

  const sendNotification = useRecoilCallback(
    () => async (entityId: number, userId: number) => {
      const response = await mainApi.post<any>(`/notifications`, {
        entityId,
        entityType: 'calendar',
        userId,
      });
      if (!response.ok) {
        handleError(response);
      } else {
        NotificationService.notifySuccess(
          getTextCatalog.getString('User notified!')
        );
        notificationRefresher();
      }
    },
    [notificationRefresher]
  );

  return {
    sendNotification,
  };
};
