import { Checkbox, DatePicker, Form, Space } from 'antd';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import React from 'react';

import getTextCatalog from '@/react/services/I18nService';
import { CdTimePicker } from '@/react/shared/components/cd-time-picker/CdTimePicker';
import { CdRepeatPicker } from '@/react/calendar/event-details/components/CdRepeatPicker';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const DatePickerMoment =
  DatePicker.generatePicker<Moment>(momentGenerateConfig);

export interface DateInputValue {
  startDate: Moment;
  endDate: Moment;
  allDay: boolean;
  rrule: any;
  hideEndTime: boolean;
}
export const DateInput = (props: {
  value?: DateInputValue;
  onChange?: (value: DateInputValue) => void;
  eventId: number;
  previousRRule?: string;
  disabled?: boolean;
}) => {
  const prepAndCleanUp = Form.useWatch('resourcesLocation')?.prepAndCleanUp;
  const datePickerFormat = getTextCatalog.getLongDateFormat('ll');

  const onChange = (newValue, path: string) => {
    if (path === 'startTime') {
      const newStartDate = moment(
        `${props.value.startDate.format('YYYY-MM-DD')} ${newValue}`,
        'YYYY-MM-DD HH:mm'
      ) as Moment;
      const difference = newStartDate.diff(props.value.startDate, 'minutes');
      return props.onChange({
        ...props.value,
        startDate: moment(
          `${props.value.startDate.format('YYYY-MM-DD')} ${newValue}`,
          'YYYY-MM-DD HH:mm'
        ) as Moment,
        endDate: props.value.endDate.add(difference, 'minutes'),
      });
    }

    if (path === 'endTime') {
      return props.onChange({
        ...props.value,
        endDate: moment(
          `${props.value.endDate.format('YYYY-MM-DD')} ${newValue}`,
          'YYYY-MM-DD HH:mm'
        ) as Moment,
      });
    }
    if (path === 'startDate') {
      const newStartDate = newValue;
      const difference = props.value.endDate.diff(
        props.value.startDate,
        'minutes'
      );
      return props.onChange({
        ...props.value,
        startDate: newValue,
        endDate: newStartDate.clone().add(difference, 'minutes'),
      });
    }
    if (path === 'allDay' && !newValue) {
      return props.onChange({
        ...props.value,
        allDay: newValue,
        startDate: moment()
          .set({
            date: props.value.startDate.date(),
            month: props.value.startDate.month(),
            year: props.value.startDate.year(),
          })
          .add(1, 'hour')
          .startOf('hour'),
        endDate: moment()
          .set({
            date: props.value.endDate.date(),
            month: props.value.endDate.month(),
            year: props.value.endDate.year(),
          })
          .add(2, 'hour')
          .startOf('hour'),
      });
    }

    return props.onChange({
      ...props.value,
      [path]: newValue,
    });
  };
  const startDate = props.value?.startDate;
  const endDate = props.value?.endDate;
  const startTime = props.value?.startDate?.format('HH:mm');
  const endTime = props.value?.endDate?.format('HH:mm');
  const isEndTimeInvalid = props.value?.startDate.isAfter(props.value.endDate);

  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <DatePickerMoment
          aria-label="Date picker for startDate"
          suffixIcon={null}
          placeholder="Select date"
          format={datePickerFormat}
          allowClear={false}
          value={startDate}
          showToday={false}
          onChange={(value) => onChange(value, 'startDate')}
          getPopupContainer={(trigger) => trigger.parentElement}
          disabled={props.disabled}
        />
        {!props.value?.allDay && (
          <CdTimePicker
            id="startTime"
            value={startTime}
            onChange={(value) => onChange(value, 'startTime')}
            disabled={props.disabled}
          />
        )}

        {getTextCatalog.getString('to', undefined, 'Date interval')}
        {!props.value?.allDay && (
          <CdTimePicker
            id="endTime"
            value={endTime}
            onChange={(value) => onChange(value, 'endTime')}
            isInvalid={isEndTimeInvalid}
            diffStartDate={startDate}
            diffEndDate={endDate}
            disabled={props.disabled}
          />
        )}
        <DatePickerMoment
          aria-label="Date picker for endDate"
          suffixIcon={null}
          placeholder="Select date"
          format={datePickerFormat}
          allowClear={false}
          value={endDate}
          showToday={false}
          onChange={(value) => onChange(value, 'endDate')}
          status={isEndTimeInvalid ? 'error' : undefined}
          getPopupContainer={(trigger) => trigger.parentElement}
          disabled={props.disabled}
        />
      </Space>
      <Space direction="horizontal">
        <CdTooltip
          title={getTextCatalog.getString(
            'An event cannot be all day if it uses preparation or clean up time.'
          )}
        >
          <Checkbox
            checked={props.value?.allDay}
            onChange={(e) => onChange(e.target.checked, 'allDay')}
            disabled={props.disabled || prepAndCleanUp}
          >
            {getTextCatalog.getString('All day')}
          </Checkbox>
        </CdTooltip>
        <Checkbox
          checked={props.value?.hideEndTime}
          onChange={(e) => onChange(e.target.checked, 'hideEndTime')}
          disabled={props.disabled}
        >
          {getTextCatalog.getString('Hide end time')}
        </Checkbox>
        <CdRepeatPicker
          startDate={startDate}
          rrule={props.value?.rrule}
          onChange={(value) => onChange(value, 'rrule')}
          eventId={props.eventId}
          previousRRule={props.previousRRule}
          disabled={props.disabled}
        />
      </Space>
    </Space>
  );
};
