import moment from 'moment';
import { RRule } from 'rrule';

import { AddEvent } from '@/react/calendar/store/main-view/Actions';
import { fullCalendarActions } from '@/react/calendar/store/events/fullCalendarEventsSlice';
import { calendarSettingsSelectors } from '@/react/calendar/store/calendar-settings/calendarSettingsSlice';
import { PaymentMethodTypes } from '@/react/intention/models/intention';
import { getRRuleText } from '@/react/calendar/services/RRuleService';
import {
  fetchEventStole,
  refreshEventStole,
} from '@/react/intention/redux/stoles/Actions';
import { unEscape } from '@/react/shared/utils';
import {
  angularDebounceValidator,
  checkIfAliasExist,
} from '@/react/calendar/event-details/services/checkIfAliasExist';
import { getStateUrl } from '@/react/services/StateServiceFactory';

/* eslint-disable @typescript-eslint/no-use-before-define */
(function () {
  'use strict';
  function CreateContentController(
    _,
    $ngRedux,
    $q,
    $scope,
    $uibModal,
    $uibModalInstance,
    $filter,
    $timeout,
    Groups,
    Calendar,
    Taxonomies,
    Resources,
    Blogs,
    SognDk,
    Files,
    toastr,
    gettextCatalog,
    hotkeys,
    Tasks,
    Authorization,
    Users,
    Me,
    resolvedOptions,
    resolvedEntity,
    dateFormatsLookup,
    calendarPollerService,
    stepsFactory,
    uiSelectAllowNewMarker,
    cdApp,
    appUtils
  ) {
    // =============================================================================
    // General stuff.
    // =============================================================================

    // Stop the calendar poller running in the background
    calendarPollerService.stop();

    $scope.forms = {
      event: {
        name: 'event',
        templateUrl:
          '@/app/shared/components/create-content/partials/event/event-form.html',
      },

      absence: {
        name: 'absence',
        templateUrl:
          '@/app/shared/components/create-content/partials/absence/absence-form.html',
      },

      blog: {
        name: 'blog',
        templateUrl:
          '@/app/shared/components/create-content/partials/blog/blog-form.html',
      },
    };

    $scope.hasIntentionAccess = Authorization.hasPackage('intentions');

    $scope.form = $scope.forms[resolvedOptions.type];
    $scope.editData = resolvedEntity;
    $scope.calendarId = _.get($scope.editData, 'id');
    $scope.isEvent = resolvedOptions.type === 'event';
    $scope.isBlog = resolvedOptions.type === 'blog';
    $scope.isAbsence = resolvedOptions.type === 'absence';
    $scope.isEdit =
      (_.get(resolvedEntity, 'id') && resolvedOptions.operation === 'edit') ||
      resolvedOptions.operation === 'copy' ||
      false;
    $scope.isCopy =
      (_.get(resolvedEntity, 'id') && resolvedOptions.operation === 'copy') ||
      false;
    $scope.onCreate = _.get(resolvedOptions, 'options.onCreate', () => {});
    $scope.contentCreation = {};
    $scope.conflicts = {};
    $scope.permissions = [];
    $scope.rrule = null;
    $scope.noConflicts = false;
    $scope.shareEventWithEveryone = true;
    $scope.gettext = gettextCatalog;
    $scope.blog = {
      allowSummary: false,
      schedulePost: false,
      publishSummary: gettextCatalog.getString(
        'To be published <b>immediately</b> in the <b>group</b> you choose.'
      ),
    };

    $scope.cropKey = _.get(resolvedEntity, 'id')
      ? resolvedOptions.type + '-' + resolvedEntity.id
      : guid();
    $scope.selectedGroupId = null;
    $scope.uiSelectAllowNewMarker = uiSelectAllowNewMarker;
    $scope.conflictingUsers = []; // Stores conflict information. Used by child components
    $scope.updateParentOnStoleChange = updateParentOnStoleChange;
    $scope.showChurchSelector = cdApp.showChurchSelector;
    // If there is no data, we start from scratch.
    $scope.data = {
      cropKey: $scope.cropKey,
    };
    $scope.errorLink = '';
    // Image picker in Redactor should be hidden if the Intranet package isn't enabled
    $scope.descriptionRedactorOptions = {
      plugins: [
        'alignment',
        'table',
        'source',
        'fullscreen',
        'bufferbuttons',
        'linkit',
        'fontsize',
        'fontcolor',
        'imagePicker',
      ],

      formatting: ['p', 'blockquote', 'h3', 'h4', 'h5', 'h6'],
    };

    // If data is passed to the modal, we add it to the form to get started.
    if (resolvedEntity) {
      if (resolvedEntity.internalNote) {
        resolvedEntity.internalNote = unEscape(resolvedEntity.internalNote);
      }
      $scope.data = _.extend($scope.data, _.cloneDeep(resolvedEntity));
      calculateDiff($scope.data);
      if (resolvedEntity.locationObj || resolvedEntity.location) {
        $scope.data.location =
          resolvedEntity.locationObj || resolvedEntity.location;
      }
      if (resolvedEntity.locationName) {
        $scope.data.locationName = resolvedEntity.locationName;
      }
    }

    // Prep and Clean up time data
    if ($scope.isEvent) {
      $scope.data.hasPrepTime =
        $scope.data.preparationStartDate &&
        moment($scope.data.startDate).diff(
          $scope.data.preparationStartDate,
          'seconds'
        ) !== 0;
      $scope.data.hasCleanupTime =
        $scope.data.cleanupEndDate &&
        moment($scope.data.endDate).diff(
          $scope.data.cleanupEndDate,
          'seconds'
        ) !== 0;
      $scope.data.prepTimeMinutes = $scope.data.hasPrepTime
        ? moment($scope.data.startDate).diff(
            $scope.data.preparationStartDate,
            'minutes'
          )
        : null;
      $scope.data.cleanupTimeMinutes = $scope.data.hasCleanupTime
        ? moment($scope.data.cleanupEndDate).diff(
            $scope.data.endDate,
            'minutes'
          )
        : null;

      if ($scope.data.prepTimeMinutes === 0) $scope.data.prepTimeMinutes = null;
      if ($scope.data.cleanupTimeMinutes === 0) {
        $scope.data.cleanupTimeMinutes = null;
      }

      $scope.data.prepTimeSelectOptions = [
        5, 10, 15, 20, 30, 45, 60, 90, 120, 180,
      ];

      $scope.prepTimeEnabled =
        $scope.data.hasPrepTime || $scope.data.hasCleanupTime;
    }

    $scope.updatePrepTimeEnabled = () => {
      $scope.prepTimeEnabled = !$scope.prepTimeEnabled;
    };
    const debouncedValidateAlias = angularDebounceValidator(
      async (alias, scope) => {
        if (!alias) return null;
        try {
          const response = await checkIfAliasExist({
            alias: alias,
            entityId: scope.calendarId,
            entityType: 'blog',
          });
          if (response) {
            if (response.type === 'blog') {
              scope.errorLink = response.url;
            } else if (response.type === 'event') {
              scope.errorLink = getStateUrl('app.private.calendar.event', {
                id: response.id,
              });
            }
            scope.contentCreation.blog.alias.$setValidity('customError', false);
          } else {
            scope.errorLink = '';
            scope.contentCreation.blog.alias.$setValidity('customError', true);
          }
          // Ensure the view is updated
          scope.$applyAsync();
        } catch (error) {
          scope.contentCreation.blog.alias.$setValidity('customError', false);
          scope.$applyAsync();
        }
      },
      500
    );
    $scope.checkIfAliasExists = async (alias) =>
      new Promise(async (resolve) => {
        debouncedValidateAlias(alias, $scope);
        resolve(); // Resolve immediately, as debouncedValidateAlias will handle validation
      });

    // Obtain the church IDs
    $scope.data.churchIds = _.map($scope.data.churches, 'id');

    if (
      _.get($scope.data, 'facebook.publish') &&
      !_.get($scope.data, 'facebook.publishedOn')
    ) {
      _.set($scope.data, 'facebook.isScheduled', true);
      _.set(
        $scope.data,
        'facebook.timeOfDay',
        moment($scope.data.facebook.timeOfDay)
      );
    }

    $scope.onEventVisibilityChanged = () => {
      if ($scope.data.visibility !== 'public') {
        _.unset($scope.data, 'facebook');
      }
    };

    $scope.onPublishToFacebookChanged = () => {
      const shouldPublish = _.get($scope.data, 'facebook.publish');
      $scope.data.facebook = shouldPublish
        ? { publish: true, isScheduled: true }
        : { publish: false };
    };

    /**
     * Whether the user has permission to share public events on Facebook
     */
    $scope.canShareOnFacebook = () =>
      Authorization.hasPermission('canSetVisibilityToPublic');

    /**
     * An alert div should be shown in case the event is not public, but the user can change it and then share it
     */
    $scope.shouldShowFacebookAlert = () =>
      $scope.isFacebookAuthenticated &&
      (($scope.isEvent && $scope.data.visibility !== 'public') ||
        ($scope.isBlog && $scope.data.visibility !== 'web')) &&
      !_.get($scope.data, 'facebook.publishedOn') &&
      $scope.canShareOnFacebook();

    /**
     * Whether the Facebook sharing section should be disabled
     */
    $scope.shouldDisableFacebookSection = () =>
      !$scope.canShareOnFacebook() || $scope.shouldShowFacebookAlert();

    /**
     * Whether the organization is linked to a Facebook account
     */
    $scope.setIsFacebookAuthenticated = () => {
      $scope.isFacebookAuthenticated = !_.isEmpty(
        _.get(cdApp, 'organization.facebook')
      );
    };
    $scope.setIsFacebookAuthenticated();

    // Datepicker options
    $scope.datepickerOptions = {
      endDate: {
        minDate: moment($scope.data.startDate).toDate(),
      },

      schedulePublish: {
        minDate: moment().toDate(),
      },

      scheduleUnpublish: {
        minDate: moment().toDate(),
      },

      ngModelOptions: {
        updateOn: 'blur',
      },
    };

    // Scroll to a specific element
    if (resolvedOptions.options.scrollTo) {
      switch (resolvedOptions.options.scrollTo) {
        case 'sogndk': {
          $scope.data.sogndk.export = true;
          updateScrollPosition();
          break;
        }

        default:
          break;
      }
    }

    // =============================================================================
    // Editing.
    // =============================================================================

    (function () {
      if (!$scope.isEdit) return;

      // Taxonomies
      if ($scope.data.taxonomies) {
        $scope.data.mainCategory = _.parseInt(
          _.findKey($scope.data.taxonomies, { isMaster: true })
        );

        $scope.data.taxonomies = _($scope.data.taxonomies)
          .omit($scope.data.mainCategory)
          .keys()
          .map(_.parseInt)
          .value();
        $scope.showAdditionalCategories = _.size($scope.data.taxonomies) > 0;
      }

      // Resources
      if ($scope.data.resources) {
        if (_.isArray(resolvedEntity.resources)) {
          $scope.data.resources = _.map(resolvedEntity.resources, _.parseInt);
        } else if (_.isObject(resolvedEntity.resources)) {
          $scope.data.resources = _(resolvedEntity.resources)
            .keys()
            .map(_.parseInt)
            .value();
        }
      }

      // Users
      if ($scope.data.users) {
        // Save the user information such as names etc. so we can show users names even though the user has been
        // removed.
        $scope.data.usersData = _.cloneDeep($scope.data.users);
        if (_.isArray(resolvedEntity.users)) {
          $scope.data.users = _.map(resolvedEntity.users, _.parseInt);
        } else if (_.isObject(resolvedEntity.users)) {
          let mappedUsers = _(resolvedEntity.users)
            .keys()
            .map(_.parseInt)
            .value();
          $scope.data.users = $scope.isAbsence
            ? _.first(mappedUsers)
            : mappedUsers;
        }

        if ($scope.isEvent) {
          checkUsersAvailability();
        }
      }

      // Rrule
      if ($scope.data.rrule) {
        // We extract the correct parsable rule.
        let re = /RRULE:([^&]*)/;
        let parsableRrule = $scope.data.rrule.match(re);

        // Build the recurrence rule so we can parse it to human readable text.
        $scope.rrule = new RRule(RRule.parseString(parsableRrule[1]));

        // We extract excluded and included dates, if any.
        const excludedPart = $scope.data.rrule.match(/EXDATE:([^&]*)/);
        const includedPart = $scope.data.rrule.match(/RDATE:([^&]*)/);

        $scope.excludedDates = !_.isNull(excludedPart)
          ? excludedPart[1].split(',')
          : [];
        $scope.includedDates = !_.isNull(includedPart)
          ? includedPart[1].split(',')
          : [];
      }

      // Image
      if ($scope.data.imageId) {
        Files.get({ id: $scope.data.imageId }, function (response) {
          let originalUrl = $scope.data.image;
          $scope.data.image = {
            file: response,
            url: originalUrl,
          };
        });
      }
    })();

    // =============================================================================
    // Copying
    // =============================================================================
    (function () {
      if ($scope.isCopy) {
        copyForm();
      }
    })();

    // =============================================================================
    // General stuff.
    // =============================================================================

    // Fetch all groups current user is part of.
    $scope.groups = Groups.query(() => {
      const myGroups = _.filter($scope.groups, (group) =>
        _.includes(group.members, Me.id)
      );

      /**
       * When creating events with "internal-group" visibility or absences, if no group was selected in the popup window
       * and the user is member of only one group, select that unique group automatically.
       */
      if (
        !$scope.isEdit &&
        _.isEmpty($scope.data.groupIds) &&
        _.size(myGroups) === 1
      ) {
        if ($scope.isEvent && $scope.data.visibility === 'internal-group') {
          $scope.data.groupIds = [myGroups[0].id];
        } else if (!$scope.isEvent) {
          $scope.data.groupId = myGroups[0].id;
        }
      }
    });

    // Fetch taxonomies
    Taxonomies.query((taxonomies) => {
      $scope.eventCategories = _.filter(taxonomies, { type: 'event' });
      $scope.absenceCategories = _.filter(taxonomies, { type: 'absence' });
      $scope.blogCategories = _.filter(taxonomies, { type: 'blog' });
    });

    // Watch for image changes.
    $scope.$watch('data.image', function (newValue, oldValue) {
      if (
        (!newValue && !oldValue) ||
        _.some([newValue, oldValue], _.isString)
      ) {
        return;
      }
      setDirty();
    });

    $scope.summaryMaxLength = 200;

    // =============================================================================
    // Repeat functionality.
    // =============================================================================

    (function () {
      if ($scope.isBlog) return;

      $scope.openRepeat = openRepeat;
      $scope.editRecurring = editRecurring;
      $scope.repeatCtrl = {
        value: null,
      };

      /**
       * Genrate a list of predefined repeat options
       *  - Daily
       *  - Weekly
       *  - Monthly
       *  - Yearly
       *  - Current selected custom option (if exists)
       *  - Custom (When selected, the custom repeat modal is open)
       */
      $scope.generateRRuleOptions = () => {
        const startDate = moment($scope.data.startDate).startOf('day');

        // Get week day based on event's start date
        const weekdays = moment.weekdays(); // ['Sunday', 'Monday', 'Tuesday', ...]
        const weekdayIndex = startDate.day();
        const weekday = weekdays[weekdayIndex];

        // Weekday based on the start date of the event
        const rruleWeekdays = [
          RRule.SU,
          RRule.MO,
          RRule.TU,
          RRule.WE,
          RRule.TH,
          RRule.FR,
          RRule.SA,
        ];

        // Get day of month based on event's start date

        const startOfMonth = moment(startDate).startOf('month');
        const daysInMonth = startOfMonth.daysInMonth();

        /**
         * Get which weekday is it. 1st, 2nd, 3rd, 4th, 5th
         */
        let ordinal = 1;
        let weekdaysCount = 0;
        _.forEach(_.range(daysInMonth), (dayInMonthIndex) => {
          const weekDay = moment(startOfMonth).add(dayInMonthIndex, 'day');
          if (weekDay.weekday() === startDate.weekday()) {
            weekdaysCount += 1;
            if (weekDay.isBefore(startDate)) {
              ordinal += 1;
            }
          }
        });

        let ordinalString = _.get(
          [
            gettextCatalog.getString('1st'),
            gettextCatalog.getString('2nd'),
            gettextCatalog.getString('3rd'),
            gettextCatalog.getString('4th'),
            gettextCatalog.getString('5th'),
          ],

          ordinal - 1
        );

        // Find out if it's the last weekday of the month (last Friday, etc..)
        if (ordinal === weekdaysCount) {
          ordinal = -1;
          // eslint-disable-next-line spaced-comment
          /// Monthly on the "last" Friday
          ordinalString = gettextCatalog.getString(
            'last',
            null,
            'weekday ordinal'
          );
        }

        const repeatRules = {
          daily: new RRule({
            freq: RRule.DAILY,
            interval: 1,
            count: 30,
          }),

          weekly: new RRule({
            freq: RRule.WEEKLY,
            interval: 1,
            byweekday: [rruleWeekdays[weekdayIndex]],
            count: 30,
          }),

          monthly: new RRule({
            freq: RRule.MONTHLY,
            interval: 1,
            byweekday: [rruleWeekdays[weekdayIndex].nth(ordinal)],
            count: 30,
          }),

          yearly: new RRule({
            freq: RRule.YEARLY,
            interval: 1,
            count: 30,
          }),
        };

        /**
         * Predefined options for repeating the event
         */
        $scope.repeatDropdownOptions = [
          {
            type: 'never',
            rrule: null,
            label: gettextCatalog.getString("Doesn't repeat"),
          },

          {
            type: 'daily',
            rrule: repeatRules.daily,
            label: gettextCatalog.getString('Daily'),
          },

          {
            type: 'weekly',
            rrule: repeatRules.weekly,
            label: gettextCatalog.getString('Weekly on {{weekday}}', {
              weekday,
            }),

            weekday,
          },

          {
            type: 'monthly',
            rrule: repeatRules.monthly,
            label: gettextCatalog.getString(
              'Monthly on the {{ordinal}} {{weekday}}',
              {
                ordinal: ordinalString,
                weekday,
              }
            ),

            weekday,
          },

          {
            type: 'yearly',
            rrule: repeatRules.yearly,
            label: gettextCatalog.getString('Yearly on {{date}}', {
              date: moment($scope.data.startDate).format('LL'),
            }),
          },
        ];

        /**
         * Add the currently selected custom rule as an option in the dropdown
         */
        if ($scope.rrule) {
          const hasExcludedOrIncludedDates = !_.isEmpty(
            _.compact(_.concat($scope.excludedDates, $scope.includedDates))
          );

          const isPredefinedRule = _.find(
            _.values(repeatRules),
            (repeatRule) => repeatRule.toString() === $scope.rrule.toString()
          );

          if (hasExcludedOrIncludedDates || !isPredefinedRule) {
            $scope.repeatDropdownOptions.push({
              rrule: $scope.rrule,
              label: gettextCatalog.getString('Loading'),
              type: 'selectedCustom',
            });
            getRRuleText({
              startDate: $scope.data.startDate,
              rrule: $scope.rrule.toString(),
            }).then((text) => {
              const selectedCustom = _.find($scope.repeatDropdownOptions, {
                type: 'selectedCustom',
              });
              selectedCustom.label = _.capitalize(text);
            });
          }
        }

        $scope.repeatDropdownOptions.push({
          type: 'custom',
          rrule: null,
          label: gettextCatalog.getString('Custom...'),
        });
      };
      $scope.generateRRuleOptions();

      /**
       * When the start date of the event changes, the repeat dropdown options need to be regenerated
       */
      $scope.$watch('data.startDate', () => {
        $scope.generateRRuleOptions();
      });

      $scope.getSelectedRRuleOption = () => {
        const doesntRepeat = _.find($scope.repeatDropdownOptions, {
          type: 'never',
        });

        const customOption = _.find($scope.repeatDropdownOptions, {
          type: 'custom',
        });

        const selectedCustom = _.find($scope.repeatDropdownOptions, {
          type: 'selectedCustom',
        });

        if (!$scope.rrule) {
          return doesntRepeat;
        }
        const hasExcludedOrIncludedDates = !_.isEmpty(
          _.compact(_.concat($scope.excludedDates, $scope.includedDates))
        );

        if (hasExcludedOrIncludedDates) {
          return selectedCustom;
        }

        return (
          _.find(
            $scope.repeatDropdownOptions,
            (option) =>
              option.rrule &&
              option.rrule.toString() === $scope.rrule.toString()
          ) || customOption
        );
      };

      $scope.setSelectedRRuleOption = () => {
        const selectedRRule = $scope.getSelectedRRuleOption();
        $scope.repeatCtrl.value = _.get(selectedRRule, 'type');
      };
      $scope.setSelectedRRuleOption();

      $scope.selectRrule = (rrule, exdate = '', rdate = '') => {
        $scope.rrule = rrule;

        // We extract excluded and included dates, if any.
        const excludedPart = exdate.match(/EXDATE:([^&]*)/);
        const includedPart = rdate.match(/RDATE:([^&]*)/);

        $scope.excludedDates = !_.isNull(excludedPart)
          ? excludedPart[1].split(',')
          : [];
        $scope.includedDates = !_.isNull(includedPart)
          ? includedPart[1].split(',')
          : [];

        // Set the form to dirty.
        if (rrule) {
          setDirty();
        }

        $scope.generateRRuleOptions();
        $scope.setSelectedRRuleOption();
      };

      $scope.onRepeatDropdownOptionChanged = (data) => {
        const selectedOption = _.find($scope.repeatDropdownOptions, {
          type: $scope.repeatCtrl.value,
        });

        switch ($scope.repeatCtrl.value) {
          case 'never':
          case 'daily':
          case 'weekly':
          case 'monthly':
          case 'yearly':
            $scope.selectRrule(_.get(selectedOption, 'rrule'));
            break;
          case 'custom':
            $scope.openRepeat(data, 'check');
            break;
          default:
            break;
        }
      };
      $scope.editCustomRepeatRule = () =>
        $scope.openRepeat($scope.data, 'check');

      /**
       * Whether to show the repeat count string
       */
      $scope.shouldShowRepeatCount = () => {
        const selectedRRule = $scope.getSelectedRRuleOption();
        return _.includes(
          ['daily', 'weekly', 'monthly', 'yearly'],
          _.get(selectedRRule, 'type')
        );
      };

      /**
       * Returns a string of many times the event repeats
       */
      $scope.getRepeatCountString = () => {
        if (!$scope.shouldShowRepeatCount()) return null;

        const selectedRRule = $scope.getSelectedRRuleOption();
        const count = _.get(selectedRRule, 'rrule.origOptions.count');
        const until = _.get(selectedRRule, 'rrule.origOptions.until');
        if (count) {
          return gettextCatalog.getString('Repeats {{count}} times.', {
            count,
          });
        }
        if (until) {
          return gettextCatalog.getString('Repeats until {{date}}.', {
            date: moment(until).format('LL'),
          });
        }
      };

      /**
       * Returns a string summarizing the excluded and included dates.
       * @example `Excluded dates: 5, included dates: 04/10/2018`
       */
      $scope.getExcludedAndIncludedString = () => {
        const hasExcluded = !_.isEmpty(_.compact($scope.excludedDates));
        const hasIncluded = !_.isEmpty(_.compact($scope.includedDates));

        const truncateDates = (dates) =>
          _.size(dates) > 1
            ? _.size(dates)
            : moment(_.first(dates)).format('L');
        const excluded = truncateDates($scope.excludedDates);
        const included = truncateDates($scope.includedDates);

        if (!hasExcluded && !hasIncluded) {
          return null;
        }
        if (hasExcluded && !hasIncluded) {
          return gettextCatalog.getString('Excluded dates: {{ excluded }}', {
            excluded,
          });
        }
        if (!hasExcluded && hasIncluded) {
          return gettextCatalog.getString('Included dates: {{ included }}', {
            included,
          });
        }
        if (hasExcluded && hasIncluded) {
          return gettextCatalog.getString(
            'Excluded dates: {{ excluded }}, included dates: {{ included }}',
            {
              excluded,
              included,
            }
          );
        }
      };

      /**
       * Open the date repeat modal.
       */
      function openRepeat(data) {
        return $uibModal.open({
          windowClass: 'repeat-modal modal-ui-select',
          backdrop: 'static',
          keyboard: false,
          component: 'repeatRuleModalComponent',
          size: 'lg',
          resolve: {
            rrule: () => $scope.rrule,
            startDate: () => data.startDate,
            excludedDates: () => $scope.excludedDates,
            includedDates: () => $scope.includedDates,
            selectRrule: () => $scope.selectRrule,
            setSelectedRRuleOption: () => $scope.setSelectedRRuleOption,
            eventId: () => $scope.data?.id,
          },
        });
      }

      /**
       * Toggle a confirmation window when editing a recurring entity.
       */
      function editRecurring(data) {
        return $uibModal.open({
          component: 'cdEditRecurringModal',
          resolve: {
            type() {
              return $scope.data.type;
            },
            timeChanged() {
              return (
                !moment(data.endDate).isSame(resolvedEntity.endDate) ||
                !moment(data.startDate).isSame(resolvedEntity.startDate) ||
                generateRRuleString() !== resolvedEntity.rrule
              );
            },
            shiftsChanged() {
              const formatShifts = (shifts) =>
                angular.toJson(
                  _.map(shifts, (shift) =>
                    _.pick(shift, ['note', 'task', 'users'])
                  )
                );

              return !angular.equals(
                formatShifts(data.shifts),
                formatShifts(resolvedEntity.shifts)
              );
            },
          },
        }).result;
      }
    })();

    // =============================================================================
    // Event related functionality.
    // =============================================================================

    (function () {
      if (!$scope.isEvent) return;

      // Event visibility options.
      $scope.eventVisibilityOptions = [
        {
          title: '<b>' + gettextCatalog.getString('Public') + '</b>',
          value: 'public',
          description: gettextCatalog.getString(
            'Allow this event on your website and include it in messages sent from People. Details are shared internally with all users.'
          ),

          icon: 'fa-globe',
          isAllowed: function () {
            return $scope.isEdit
              ? canEditField('visibility', 'public')
              : Authorization.hasPermission('canSetVisibilityToPublic');
          },
        },

        {
          title: gettextCatalog.getString('<b>All users</b>'),
          value: 'internal-all',
          description: Authorization.hasPermission('canEditSensitiveInfo')
            ? gettextCatalog.getString(
                'Anyone in your church with a ChurchDesk login can view the details of this event. Sensitive information remains hidden.'
              )
            : gettextCatalog.getString(
                'Anyone in your church with a ChurchDesk login can view the details of this event.'
              ),

          icon: 'fa-church',
          isAllowed: function () {
            return $scope.isEdit
              ? canEditField('visibility', 'internal-all')
              : Authorization.hasPermission('canSetVisibilityToInternalAll');
          },
        },

        {
          title: gettextCatalog.getString('<b>Groups</b>'),
          value: 'internal-group',
          description: gettextCatalog.getString(
            'Anyone in these specific groups can view the details of this event.'
          ),

          icon: 'fa-users',
          isAllowed: function () {
            return $scope.isEdit
              ? canEditField('visibility', 'internal-group')
              : Authorization.hasPermission('canSetVisibilityToInternalGroup');
          },
        },

        {
          title: '<b>' + gettextCatalog.getString('Private') + '</b>',
          value: 'private',
          description: gettextCatalog.getString(
            'Only you can view this event. You can change visibility later.'
          ),
          icon: 'fa-lock-alt',
          isAllowed: function () {
            return $scope.isEdit ? canEditField('visibility', 'private') : true;
          },
        },
      ];

      // Set the default event visibility.
      if (!$scope.data.visibility) {
        setDefaultEventVisibility();
      }

      $scope.placeholderSognDKPastor = () =>
        !$scope.sognDk?.disablePastorField
          ? gettextCatalog.getString('Choose a pastor.')
          : gettextCatalog.getString('Not available');

      // Set the default event hideEndTime
      if (_.isNil($scope.data.hideEndTime)) {
        $scope.data.hideEndTime = true;
      }

      // Fetch resources.
      $scope.resources = Resources.query();

      // Fetch sogn.dk data, only for danish sites.
      if (_.get(cdApp, 'organization.countryIso2').toLowerCase() === 'dk') {
        $scope.sognDk = {
          disablePastorField: false,
        };

        // Initialize the sogndk object.
        if ($scope.data.sogndk) {
          $scope.data.sogndk.export = Boolean($scope.data.sogndk.export);
        } else {
          $scope.data.sogndk = {
            export: false,
          };
        }

        // Categories.
        SognDk.query(
          {
            type: 'categories',
          },

          function (response) {
            _.filter(response, function (item) {
              if (
                _.includes(
                  [
                    'Bisættelse',
                    'Begravelse',
                    'Vielse',
                    'Velsignelse',
                    'Dåb',
                    'Anden kirkelig handling',
                  ],

                  item.sognDkCategoryName
                )
              ) {
                item.sognDkCategoryName =
                  item.sognDkCategoryName + ' (ikke offentlig)';
              }
            });

            $scope.sognDk.sognDkCategories = response;
            const currentCategory = _.find($scope.sognDk.sognDkCategories, {
              sognDkCategoryId: $scope.data.sogndk.categoryId,
            });

            if (
              currentCategory &&
              currentCategory.sognDkCategoryType === 'arrangement'
            ) {
              $scope.sognDk.disablePastorField = true;
            }
          }
        );

        // Parish.
        SognDk.query(function (response) {
          $scope.sognDk.sognDkParish = response;
          let parish = _.first(response);
          $scope.data.sogndk.sognId =
            $scope.data.sogndk.sognId || (parish && parish.id);
        });

        // Churches.
        SognDk.query(
          {
            type: 'churches',
          },

          function (response) {
            $scope.data.sogndk.churchId =
              $scope.data.sogndk.churchId ||
              (response.length === 1 ? response[0].sognDkChurchId : null);
            $scope.sognDk.sognDkChurches = response;
            $scope.sognDk.sognDkChurches.push({
              sognDkChurchId: -1,
              sogneName: gettextCatalog.getString('Location'),
            });

            $scope.sognDk.updateDefaultSognDkChurch();
          }
        );

        // Priests.
        SognDk.query(
          {
            type: 'priests',
          },

          function (response) {
            $scope.sognDk.sognDkPastors = response;
            $scope.sognDk.sognDkPastors.push({
              sognDkPastorId: -1,
              sogneName: gettextCatalog.getString('Contributor'),
            });

            $scope.sognDk.updateDefaultSognDkPastor();
          }
        );

        /**
         * Whether to export the event to sogn.dk or not
         *
         * @param {Boolean} value
         */
        $scope.sognDk.toggleSognDk = (value) => {
          $scope.data.sogndk.export = value;
          if (value) updateScrollPosition();
        };

        /**
         * Add the actual location to the default sogn.dk church field
         */
        $scope.sognDk.updateDefaultSognDkChurch = () => {
          if (_.get(cdApp, 'organization.countryIso2') !== 'dk') return;
          const field = _.find($scope.sognDk.sognDkChurches, {
            sognDkChurchId: -1,
          });

          if (!field) return;

          field.sognDkChurchName =
            $scope.data.locationName && !_.isEmpty($scope.data.locationName)
              ? `${$scope.data.locationName} (${gettextCatalog.getString(
                  'from the field "Location name"'
                )})`
              : gettextCatalog.getString(
                  'Add location in the field "Location name"'
                );
        };

        /**
         * Add the actual contributor to the default sogn.dk pastor field
         */
        $scope.sognDk.updateDefaultSognDkPastor = () => {
          if (
            $scope.sognDk.disablePastorField ||
            _.get(cdApp, 'organization.countryIso2') !== 'dk'
          ) {
            return;
          }

          const contributor = $scope.data.contributor;
          const field = _.find($scope.sognDk.sognDkPastors, {
            sognDkPastorId: -1,
          });

          if (!field) return;

          field.sognDkPastorName = _.isEmpty(contributor)
            ? gettextCatalog.getString(
                'Add the name for the priest in the field "Contributor"'
              )
            : gettextCatalog.getString(
                '{{ contributorName }} from the field "Contributor"',
                {
                  contributorName: contributor,
                }
              );

          $scope.data.sogndk.pastorId =
            $scope.data.sogndk.pastorId ||
            (!$scope.sognDk.disablePastorField && -1);
        };

        /**
         * Called when a sogn.dk category is selected
         */
        $scope.sognDk.onSognDkCategorySelected = (category) => {
          if (category.sognDkCategoryType === 'arrangement') {
            $scope.sognDk.disablePastorField = true;
            $scope.data.sogndk.pastorId = null;
          } else {
            $scope.sognDk.disablePastorField = false;
            $scope.sognDk.updateDefaultSognDkPastor();
          }
        };
      }

      /**
       * Shows the double booking information modal. Invoked by <cd-form-event-users-component>
       *
       * @param {*} conflicts the conflict data.
       */
      $scope.showDoubleBookingModal = (conflicts) => {
        doubleBooking($scope.data, 'check', conflicts);
      };

      /**
       * Triggered by <cd-form-event-users-component> when the selected booked users change.
       *
       * @param {number[]} userIds
       * @param {boolean} wasUserRemoved
       */
      $scope.userListUpdated = (userIds, wasUserRemoved) => {
        $scope.data.users = userIds;
        if (wasUserRemoved) return $q.resolve();
        return $q((resolve) => checkUsersAvailability(resolve));
      };

      /**
       * Triggered by <cd-form-event-planning-component> when the selected calendar_tasks change.
       *
       * @param {*} userIds
       */
      $scope.calendarTasksListUpdated = (calendarTasks) => {
        $scope.data.calendar_tasks = calendarTasks;
        checkUsersAvailability();
      };
    })();

    // =============================================================================
    // Absence related functionality.
    // =============================================================================

    (function () {
      if (!$scope.isAbsence) return;

      // If user is not allowed to book others for absence, we select him by default.
      if (
        !$scope.isEdit &&
        !Authorization.hasPermission('canCreateAbsenceAndBook')
      ) {
        $scope.data.users = _.get(cdApp, 'me.id');
      }

      Users.query().$promise.then((users) => {
        $scope.users = users;
      });

      $scope.onAbsenceUserCreated = (user) => {
        $scope.users = $scope.users.concat([user]);
        $scope.data.users = user.id;
      };

      $scope.onAbsenceUserSelected = (user) => {
        if (!_.isEmpty(user)) {
          $scope.data.users = user.id;
        }
      };
    })();

    // =============================================================================
    // Blog related functionality.
    // =============================================================================

    (function () {
      if (!$scope.isBlog) return;
      $scope.updateBlogSummary = updateBlogSummary;
      $scope.removeBlogSchedule = removeBlogSchedule;

      // Blog comment options.
      $scope.commentOptions = [
        {
          name: gettextCatalog.getString('Open'),
          value: 'open',
          description: gettextCatalog.getString('Users can post comments.'),
        },

        {
          name: gettextCatalog.getString('Closed'),
          value: 'closed',
          description: gettextCatalog.getString('Users cannot post comments.'),
        },

        {
          name: gettextCatalog.getString('Hidden'),
          value: 'hidden',
          description: gettextCatalog.getString(
            'Users can see comments, but cannot post.'
          ),
        },
      ];

      // Blog visibility options.
      $scope.visibilityOptions = [
        {
          name: gettextCatalog.getString('Draft'),
          value: 'draft',
          description: gettextCatalog.getString(
            'Saves the content as a draft that can only be seen by the author. The author is {{authorName}}.',
            {
              authorName:
                $scope.data.authorName ||
                $scope.data.authorEmail ||
                gettextCatalog.getString('Unknown'),
            }
          ),

          available: true,
        },

        {
          name: gettextCatalog.getString('Group'),
          value: 'group',
          description: gettextCatalog.getString(
            'Publishes the content only in the group you choose.'
          ),

          available: true,
        },

        {
          name: gettextCatalog.getString('Website'),
          value: 'web',
          description: gettextCatalog.getString(
            'Allows the content to be visible on your website.'
          ),

          available: true,
        },
      ];

      // Set the default visibility
      if (!$scope.isEdit) {
        $scope.data.visibility = $scope.visibilityOptions[1].value;
      } else {
        // If we edit a blog, we check if we have summary or schedule dates.
        $scope.blog.allowSummary = $scope.data.summary ? true : false;

        if ($scope.data.schedulePublish || $scope.data.scheduleUnpublish) {
          $scope.blog.schedulePost = true;
        }
      }

      /**
       * Build the summary according to selected options.
       */
      function updateBlogSummary() {
        if ($scope.blog.schedulePost) {
          // The blog is to be scheduled.
          switch ($scope.data.visibility) {
            case 'group':
              if (!$scope.data.scheduleUnpublish) {
                $scope.blog.publishSummary = gettextCatalog.getString(
                  'To be published on <b>{{publishDate}}</b> in the <b>group</b> you choose.',
                  {
                    publishDate:
                      ($scope.data.schedulePublish &&
                        moment($scope.data.schedulePublish).format('LLL')) ||
                      moment().format('LLL'),
                  }
                );
              } else {
                $scope.blog.publishSummary = gettextCatalog.getString(
                  'To be published on <b>{{publishDate}}</b> in the <b>group</b> you choose, until <b>{{unpublishDate}}</b>.',
                  {
                    publishDate:
                      ($scope.data.schedulePublish &&
                        moment($scope.data.schedulePublish).format('LLL')) ||
                      moment().format('LLL'),
                    unpublishDate: moment($scope.data.scheduleUnpublish).format(
                      'LLL'
                    ),
                  }
                );
              }
              break;
            case 'web':
              if (!$scope.data.scheduleUnpublish) {
                $scope.blog.publishSummary = $scope.data?.facebook?.publish
                  ? gettextCatalog.getString(
                      'To be published on <b>{{publishDate}}</b> on your <b>website</b> & <b>facebook</b>.',
                      {
                        publishDate:
                          ($scope.data.schedulePublish &&
                            moment($scope.data.schedulePublish).format(
                              'LLL'
                            )) ||
                          moment().format('LLL'),
                      }
                    )
                  : gettextCatalog.getString(
                      'To be published on <b>{{publishDate}}</b> on your <b>website</b>.',
                      {
                        publishDate:
                          ($scope.data.schedulePublish &&
                            moment($scope.data.schedulePublish).format(
                              'LLL'
                            )) ||
                          moment().format('LLL'),
                      }
                    );
              } else {
                $scope.blog.publishSummary = gettextCatalog.getString(
                  'To be published on <b>{{publishDate}}</b> on your <b>website</b>, until <b>{{unpublishDate}}</b>.',
                  {
                    publishDate:
                      ($scope.data.schedulePublish &&
                        moment($scope.data.schedulePublish).format('LLL')) ||
                      moment().format('LLL'),
                    unpublishDate: moment($scope.data.scheduleUnpublish).format(
                      'LLL'
                    ),
                  }
                );
              }
              break;
          }
        } else {
          // The blog is not to be scheduled.
          switch ($scope.data.visibility) {
            case 'draft':
              $scope.blog.publishSummary = gettextCatalog.getString(
                'To be saved <b>immediately</b> as a <b>draft</b>.'
              );

              break;
            case 'group':
              $scope.blog.publishSummary = gettextCatalog.getString(
                'To be published <b>immediately</b> in the <b>group</b> you choose.'
              );

              break;
            case 'web':
              $scope.blog.publishSummary = gettextCatalog.getString(
                'To be published <b>immediately</b> on your <b>website</b>.'
              );

              break;
          }
        }

        $scope.datepickerOptions.scheduleUnpublish.minDate = moment(
          $scope.data.schedulePublish
        ).toDate();
      }

      /**
       * Reset the blog publish schedule dates.
       */
      function removeBlogSchedule(type) {
        if (type === 'publish') {
          $scope.data.schedulePublish = null;
        } else {
          $scope.data.scheduleUnpublish = null;
        }
        angular.element('#' + type).val('');

        updateBlogSummary();
      }

      // Update the blog summary when editing a blog.
      if ($scope.isEdit) {
        updateBlogSummary();
      }
    })();

    // =============================================================================
    // Utils.
    // =============================================================================

    /**
     * Check if one ore more fields can be edited.
     */
    function canEdit(fields, value, fn) {
      fields = _.isArray(fields) ? fields : [fields];
      if (!fields.length) return false;

      return fn(fields, function (field) {
        let baseCondition = true;

        if ($scope.isEdit) {
          // First we verify that the field can be edited by checking the `canEdit` property
          baseCondition = _.get(
            resolvedEntity,
            ['fields', field, 'canEdit'],
            false
          );

          // Sometimes the field can be edited, but only some values are allowed, so we check that
          if (baseCondition && !_.isUndefined(value)) {
            baseCondition = _.includes(
              _.get(resolvedEntity, ['fields', field, 'allowedValues']),
              value
            );
          }
        }

        // Some fields require additional access and permission checks
        switch (field) {
          case 'secureInformation':
            return (
              baseCondition &&
              Authorization.hasPermission('canEditSensitiveInfo')
            );

          case 'users':
            return (
              baseCondition &&
              Authorization.hasPermission('canCreateEventAndBook')
            );

          case 'resources':
            return (
              baseCondition &&
              Authorization.hasPermission('canCreateEventAndBook')
            );

          case 'sogndk':
            return (
              baseCondition && Authorization.hasPermission('canExportToSognDk')
            );

          default:
            return baseCondition;
        }
      });
    }

    function updateParentOnStoleChange(stoleForm) {
      if (stoleForm) {
        const payload = stoleForm.getFieldsValue();
        let stole;
        // Creating stole
        stole = {
          stoleText: payload.stoleText,
          church: { id: payload.churchId },
          fee: { id: payload.feeId },
          resource: payload.resourceId ? { id: payload.resourceId } : null,
          paid: payload.paid || false,
          paymentMethod: payload.paymentMethod || PaymentMethodTypes.PAIDBYCASH,
          comment: payload.comment || null,
        };

        $scope.data.stole = stole;
        $scope.isStoleValid = validateStole($scope.data.stole);
      } else {
        $scope.data.stole = null;
      }

      $scope.isStoleInactive = _.isNil(stoleForm);

      if (!$scope.$$phase) {
        $scope.$apply();
      }
    }

    /**
     * Check if all fields can be edited.
     *
     * @param fields - The field(s) to be checked
     * @param value - The value to be checked if allowed
     */
    function canEditField(fields, value) {
      return _.partial(canEdit, fields, value)(_.every);
    }

    /**
     * Check if some fields can be edited.
     *
     * @param fields - The field(s) to be checked
     * @param value - The value to be checked if allowed
     */
    function canEditAnyField(fields, value) {
      return _.partial(canEdit, fields, value)(_.some);
    }

    /**
     * Assert if the form requirements have been met.
     */
    function isFormFulfilled() {
      const { title, mainCategory, visibility, groupIds, sogndk } = $scope.data;
      const { isStoleInactive, isStoleValid, hasIntentionAccess } = $scope;

      let isFormValid = _.get($scope, [
        'contentCreation',
        resolvedOptions.type,
        '$valid',
      ]);

      if (resolvedOptions.type === 'event') {
        isFormValid =
          isFormValid &&
          !_.isUndefined(title) &&
          !_.isUndefined(mainCategory) &&
          (visibility === 'internal-group' ? !_.isEmpty(groupIds) : true) &&
          (sogndk
            ? sogndk.export
              ? !_.isUndefined(sogndk.categoryId)
              : true
            : true) &&
          (hasIntentionAccess ? (isStoleInactive ? true : isStoleValid) : true);
      }

      return isFormValid || $scope.busy;
    }

    function validateStole(stole) {
      const hasChurchId = !_.isUndefined(_.get(stole, 'church.id'));
      const hasFeeId = !_.isUndefined(_.get(stole, 'fee.id'));
      const hasStoleText =
        !_.isUndefined(_.get(stole, 'stoleText')) &&
        !_.isEmpty(_.get(stole, 'stoleText'));

      return hasChurchId && hasFeeId && hasStoleText;
    }

    /**
     * Generate a unique id.
     */
    function guid() {
      // @see http://stackoverflow.com/a/2117523/599991
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          let r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    }

    /**
     * Remove chosen image.
     */
    function removeImage() {
      $scope.data.image = null;
      $scope.data.imageId = null;
    }

    /**
     * Check availability or users and resources.
     */
    function checkAvailability(data, onSuccess, onError) {
      // Calculate prep time dates for conflict checking dynamically as form is being filled out
      const prepTimeMinutes = _.get(data, 'prepTimeMinutes');
      const cleanupTimeMinutes = _.get(data, 'cleanupTimeMinutes');
      const startDate = _.get(data, 'startDate');
      const endDate = _.get(data, 'endDate');
      let users;
      if (data.type === 'absence') {
        users = [data.users];
      } else {
        users = data.users;
      }
      const preparationStartDate =
        prepTimeMinutes > 0
          ? moment(startDate).subtract(prepTimeMinutes, 'minutes').toDate()
          : startDate;
      const cleanupEndDate =
        cleanupTimeMinutes > 0
          ? moment(endDate).add(cleanupTimeMinutes, 'minutes').toDate()
          : endDate;
      const userIds = Array.isArray(users)
        ? data.users
        : _.map(_.keys(users), _.parseInt);
      const resourceIds = Array.isArray(data.resources)
        ? data.resources
        : _.map(_.keys(data.resources), _.parseInt);

      const payload = {
        startDate: !data.allDay
          ? startDate
          : moment(startDate).startOf('day').toDate(),
        endDate: !data.allDay ? endDate : moment(endDate).endOf('day').toDate(),
        preparationStartDate: !data.allDay ? preparationStartDate : undefined,
        cleanupEndDate: !data.allDay ? cleanupEndDate : undefined,
        users: userIds,
        shifts: data.shifts,
        resources: resourceIds,
        eventId: data.id,
      };

      if ($scope.rrule) {
        payload.rrule = generateRRuleString();
        payload.timezone = moment.tz.guess();
      }

      if (
        Authorization.hasPermission('canAccessPlanning') &&
        _.size(data.shifts)
      ) {
        payload.users = _.union(
          payload.users,
          _(data.shifts).map('users').flatten().map('id').compact().value()
        );
      }

      // Call the API for checking conflicts.
      Calendar.checkAvailability(
        null,
        payload,
        function () {
          $scope.conflictingUsers = [];
          return (
            onSuccess ||
            function () {
              // No conflicts found.
              $scope.noConflicts = true;
              toastr.success(
                gettextCatalog.getString(
                  'All resources/users you selected are available on the chosen dates.'
                )
              );
            }
          ).apply(this, arguments);
        },
        function (error) {
          return (
            onError ||
            function () {
              // If the server sends back a conflict.
              if (error.status === 409) {
                $scope.conflicts = error.data;
                $scope.doubleBooking(data, 'check', error.data);
                toastr.info(
                  gettextCatalog.getString(
                    'We found one or more conflicts with your bookings.'
                  )
                );
              } else {
                appUtils.showError(
                  _.get(error, 'data.message'),
                  toastr,
                  gettextCatalog
                );
              }
            }
          ).apply(this, arguments);
        }
      );
    }

    /**
     * Check availability of booked users.
     *
     * @param {Function} [callback] Callback function to be called, in case there is need
     *  to do some additional processing once the user availability has been checked
     */
    function checkUsersAvailability(callback = _.noop) {
      checkAvailability($scope.data, callback, ({ status, data }) => {
        // Skip if not a conflict error or no users/workplan users conflict
        if (status !== 409 || (!data.users && !data.workplan)) {
          return callback();
        }

        // Sets the data for the <cd-form-event-users-component>.
        $scope.conflictingUsers = data;

        return callback();
      });
    }

    /**
     * Reset the form data.
     */
    function resetForm() {
      $scope.cropKey = guid();
      $scope.data = {
        cropKey: $scope.cropKey,
      };

      $scope.rrule = null;

      if ($scope.isBlog) {
        $scope.data.publishDate = moment().toDate();
      } else {
        $scope.data.startDate = roundDate(moment().toDate());
        $scope.data.endDate = roundDate(moment().add(1, 'hours').toDate());
      }

      if (!$scope.isAbsence) {
        setDefaultEventVisibility();
      }

      // Reset churches and resources in the child component
      $scope.childRef?.resetSelectedResourceAndChurches();
    }

    /**
     * Duplicate the form data.
     */
    function copyForm() {
      setDirty();
      $scope.data.title +=
        ' (' + gettextCatalog.getString('copy', null, 'Noun') + ')';
      $scope.data.alias = null;
      $scope.data.authorName = cdApp.me?.fullName;
      $scope.data.authorId = cdApp.me?.id;
      delete $scope.data.allowDoubleBooking;

      if ($scope.data.id) {
        delete $scope.data.id;
        delete $scope.data.createdAt;
        delete $scope.data.updatedAt;
        delete $scope.data.parentEntity;

        if ($scope.isEvent) {
          setCopiedEventVisibility();
          delete $scope.data.fields;

          if (!Authorization.hasPermission('canBook')) {
            delete $scope.data.users;
            delete $scope.data.resources;
            delete $scope.data.shifts;
          }
          if (!Authorization.hasPermission('canEditSensitiveInfo')) {
            delete $scope.data.secureInformation;
          }
          if (!Authorization.hasPermission('canExportToSognDk')) {
            delete $scope.data.sogndk;
          }

          checkUsersAvailability();
        }
      }

      // Reset Facebook scheduling options
      if ($scope.data.facebook) {
        $scope.data.facebook = _.pick($scope.data.facebook, [
          'publish',
          'isScheduled',
          'schedulingOptions',
          'message',
        ]);
      }

      // Persist image cropping when copying events or blogs.
      let sourceCropKey = $scope.data.cropKey;
      $scope.cropKey = $scope.data.cropKey = guid();

      if (!_.isEmpty(_.get($scope, 'data.image.file.bounds'))) {
        let sourceCropSettings = $scope.data.image.file.bounds[sourceCropKey];

        Files.get({ id: $scope.data.image.file.id }, function (response) {
          let payload = response;
          payload.bounds[$scope.data.cropKey] = sourceCropSettings;

          new Files(payload).$save();
        });
      }

      calculateDiff($scope.data);
    }

    /**
     * Filter taxonomies when selecting from more than one field, like in
     * the event form where we have main and others, to avoid duplicates.
     */
    function filterTaxonomies(criteria) {
      return function (item) {
        return item.isPlaceholder || _.isArray(criteria)
          ? !_.includes(criteria, item.id)
          : criteria !== item.id;
      };
    }

    /**
     * Filter groups based on permissions
     */
    function filterGroups(group) {
      if ($scope.isEvent) {
        return Authorization.hasPermission([
          'canSetVisibilityToInternalGroup',
          '!canSetVisibilityToInternalAll',
        ])
          ? _.includes(group.members, Me.id)
          : true;
      } else if ($scope.isAbsence) {
        // Only include groups with absence enabled.
        return !group.absenceDisabled && _.includes(group.members, Me.id);
      }

      // Return all groups for blogs that the user is a part of
      return _.includes(group.members, Me.id);
    }

    /**
     * Filter out other users if current user is not allowed to book others than himself.
     */
    function filterUsers(item) {
      if (Authorization.hasPermission('canCreateAbsenceAndBook')) {
        // return !_.isEmpty(_.intersection(item.groups, cdApp.organization.myGroups));
        return item;
      } else {
        return item.id === Me.id ? item : false;
      }
    }

    /**
     * Check if a group choice is allowed to be shown in the dropdown based on access.
     */
    function isGroupChoiceAllowed(groupId) {
      return _.some($filter('filter')($scope.groups, filterGroups), {
        id: groupId,
      });
    }

    /**
     * Update scroll position inside the modal.
     */
    function updateScrollPosition() {
      $timeout(() =>
        angular.element('.create-content-modal .panel-body').animate(
          {
            scrollTop: angular
              .element('.create-content-modal .create-content-form')
              .height(),
          },

          'slow'
        )
      );
    }

    /**
     * Update dates according to the all day value.
     */
    function updateAllDay(start, end) {
      let currentHour = moment().hour();
      let currentMinutes = moment().minutes();

      if (moment(start).isSame(moment(start).startOf('day'))) {
        $scope.data.startDate = roundDate(
          moment(start).hour(currentHour).minutes(currentMinutes).toDate()
        );

        $scope.data.endDate = roundDate(
          moment(end)
            .hour(currentHour + 1)
            .minutes(currentMinutes)
            .toDate()
        );

        calculateDiff($scope.data);
        if (resolvedEntity) {
          _.extend(resolvedEntity, {
            startDate: $scope.data.startDate,
            endDate: $scope.data.endDate,
          });
        }
      }
    }

    /**
     * Util function to round up a specific date.
     */
    function roundDate(date) {
      let minutes = moment(date).minutes();
      let hour = moment(date).hour();

      // If the source date is before the half hour mark, we round it up
      // to half hour, otherwise if it is after, we round it up sharp.
      if (minutes <= 30) {
        return moment(date).hour(hour).minutes(30).seconds(0).toDate();
      } else {
        return moment(date)
          .hour(hour + 1)
          .minutes(0)
          .seconds(0)
          .toDate();
      }
    }

    /**
     * Build the RRule string from the rule, excluded and included dates
     */
    function generateRRuleString() {
      const { rrule, excludedDates, includedDates } = $scope;

      const exdate = _.isEmpty(excludedDates)
        ? ''
        : `&EXDATE:${excludedDates.join(',')}`;
      const rdate = _.isEmpty(includedDates)
        ? ''
        : `&RDATE:${includedDates.join(',')}`;
      return rrule + exdate + rdate;
    }

    /**
     * Set a default visibility for an event based on access.
     */
    function setDefaultEventVisibility() {
      if (Authorization.hasPermission('canSetVisibilityToInternalAll')) {
        $scope.data.visibility = 'internal-all';
      } else if (
        !Authorization.hasPermission('canSetVisibilityToInternalAll') &&
        Authorization.hasPermission('canSetVisibilityToInternalGroup')
      ) {
        $scope.data.visibility = 'internal-group';
      } else {
        $scope.data.visibility = 'private';
      }
    }

    /**
     * Set visibility for a copied event based on access.
     */
    function setCopiedEventVisibility() {
      switch ($scope.data.visibility) {
        case 'public':
          if (!Authorization.hasPermission('canSetVisibilityToPublic')) {
            setDefaultEventVisibility();
          }
          break;
        case 'internal-all':
          if (!Authorization.hasPermission('canSetVisibilityToInternalAll')) {
            setDefaultEventVisibility();
          }
          break;
        case 'internal-group':
          if (!Authorization.hasPermission('canSetVisibilityToInternalGroup')) {
            setDefaultEventVisibility();
            delete $scope.data.groupIds;
          }
          break;
      }
    }

    /**
     * Check if visibility changes can be undone.
     */
    function canUndoEventVisibilityChanges() {
      return (
        $scope.isEdit &&
        (!_.isEqual($scope.data.visibility, resolvedEntity.visibility) ||
          !_.isEqual($scope.data.groupIds, resolvedEntity.groupIds))
      );
    }

    /**
     * Undo changes to the visibility of an existing event.
     */
    function undoEventVisibilityChanges() {
      if (!$scope.isEvent || !$scope.isEdit || !canUndoEventVisibilityChanges) {
        return;
      }

      $scope.data.visibility = resolvedEntity.visibility;
      $scope.data.groupIds = resolvedEntity.groupIds;
    }

    /**
     * Set the current form state to dirty if pristine.
     */
    function setDirty() {
      let form = $scope.contentCreation[resolvedOptions.type];

      if (form && !form.$dirty) {
        form.$setDirty();
      }
    }

    /**
     * Check if notifications can be sent when saving an event.
     */
    function canSendNotifications() {
      let hasPermission = Authorization.hasPermission('canNotify');

      switch ($scope.form.name) {
        case 'event':
          return (
            hasPermission &&
            (!_.isEmpty($scope.data.users) || !_.isEmpty($scope.data.shifts))
          );

        case 'absence':
          return (
            hasPermission && !$scope.isEdit && !_.isNull($scope.data.users)
          );

        default:
          return false;
      }
    }

    /**
     * Create a new group
     */
    const createGroup = () => {
      $uibModal
        .open({
          component: 'cdCreateGroupModal',
        })
        .result.then((newGroup) => {
          $scope.groups = $scope.groups || [];
          $scope.groups.push(newGroup);

          if ($scope.isEvent) {
            $scope.data.groupIds = $scope.data.groupIds || [];
            $scope.data.groupIds.push(newGroup.id);
          } else {
            $scope.data.groupId = newGroup.id;
          }
        });
    };

    /**
     * Crop an image
     *
     * @param {Object} image The image to be cropped
     */
    const cropImage = (image) => {
      let context = _.isEmpty($scope.data.id)
        ? $scope.data.cropKey
        : `${resolvedOptions.type}-${$scope.data.id}`;
      $uibModal
        .open({
          component: 'cdImageCropModal',
          resolve: {
            cropKey: () => $scope.data.cropKey,
            contextId: () => $scope.data.id,
            contextType: () => resolvedOptions.type,
            file: () => image.file,
            bounds: [
              'Files',
              function (Files) {
                return Files.getCropInformation({ id: image.file.id, context })
                  .$promise;
              },
            ],
          },
        })
        .result.then(({ croppedImage }) => {
          $scope.data.image.url = croppedImage;
        });
    };

    /**
     * Called when a taxonomy item is selected
     *
     * If the item is a placeholder, a new taxonomy is created.
     * This is an ugly hack to get around the broken ui-select tagging feature.
     *
     * @see https://github.com/angular-ui/ui-select/issues/1409
     */
    const onTaxonomySelect = (item, type, isOtherCategory = false) => {
      if (item.id || !item.name || !type) return;

      const Constructor = type === 'resource' ? Resources : Taxonomies;
      const newTaxonomy = new Constructor({
        type: type,
        name: item.name,
        color: 0,
      });

      newTaxonomy.$save(({ id }) => {
        // Reset the placeholder item name
        item.name = '';
        const savedTaxonomy = _.extend({ id }, newTaxonomy);

        switch (type) {
          case 'event': {
            $scope.eventCategories.push(savedTaxonomy);

            if (isOtherCategory) {
              $scope.data.taxonomies = _.compact($scope.data.taxonomies) || [];
              $scope.data.taxonomies.push(id);
            } else {
              $scope.data.mainCategory = id;
            }

            break;
          }
          case 'absence':
            $scope.absenceCategories.push(savedTaxonomy);
            $scope.data.mainCategory = id;
            break;
          case 'blog':
            $scope.blogCategories.push(savedTaxonomy);
            $scope.data.categoryId = id;
            break;
          case 'resource':
            $scope.resources.push(savedTaxonomy);
            $scope.data.resources = _.compact($scope.data.resources) || [];
            $scope.data.resources.push(id);
            break;
        }
      });
    };

    const onPrepTimeSelect = () => {
      checkUsersAvailability();
    };

    /**
     * Update file list when files are uploaded
     */
    const onUpdateFiles = (fileList) => {
      $scope.data.files = fileList;
      // Mark the current state of the form as changed or "dirty"
      setDirty();
    };

    const onChurchSelect = (selectedChurchIds) => {
      $scope.data.churchIds = selectedChurchIds;
      // Mark the current state of the form as changed or "dirty"
      setDirty();
    };

    const onResourceSelect = (selectedResourceIds) => {
      $scope.data.resources = selectedResourceIds;
      // Mark the current state of the form as changed or "dirty"
      setDirty();
    };

    const onAddressSelect = (selectedAddress) => {
      $scope.data.location = selectedAddress;
      // Mark the current state of the form as changed or "dirty"
      setDirty();
    };

    const onLocationNameSelected = (selectedLocationName) => {
      $scope.data.locationName = selectedLocationName;
      if ($scope.sognDk) $scope.sognDk.updateDefaultSognDkChurch();
      setDirty();
    };

    const onRegisterChildReference = (child) => {
      $scope.childRef = child;
    };

    $scope.utils = {
      removeImage,
      checkAvailability,
      resetForm,
      filterTaxonomies,
      filterGroups,
      filterUsers,
      isGroupChoiceAllowed,
      updateScrollPosition,
      updateAllDay,
      canEditField,
      canEditAnyField,
      isFormFulfilled,
      canUndoEventVisibilityChanges,
      undoEventVisibilityChanges,
      canSendNotifications,
      cropImage,
      createGroup,
      onUpdateFiles,
      onTaxonomySelect,
      onChurchSelect,
      onPrepTimeSelect,
      onResourceSelect,
      onAddressSelect,
      onLocationNameSelected,
      onRegisterChildReference,
    };

    // =============================================================================
    // Datepicker and timepicker handling.
    // =============================================================================

    $scope.today = moment().toDate();
    $scope.oneWeekFromNow = moment().add(1, 'weeks').toDate();
    $scope.datepickerFormat = dateFormatsLookup.getFormat();
    $scope.timePickerOptions = {
      step: 15,
      timeFormat: 'H:i',
      appendTo: 'body',
      asMoment: true,
    };

    $scope.calculateDiff = calculateDiff;
    $scope.updateDate = updateDate;

    /**
     * Get the difference between dates when end date is changed.
     */
    function calculateDiff(data) {
      let start = moment(data.startDate);
      let end = moment(data.endDate);

      $scope.hoursDiff = end.diff(start, 'minutes');
      checkUsersAvailability();
    }

    /**
     * Update the date according to the difference.
     */
    function updateDate(data) {
      if ($scope.hoursDiff && $scope.hoursDiff > 1) {
        data.endDate = moment(data.startDate)
          .add($scope.hoursDiff, 'minutes')
          .toDate();
      } else {
        data.endDate = moment(data.startDate).add(1, 'hours').toDate();
      }

      $scope.datepickerOptions.endDate.minDate = moment(
        data.startDate
      ).toDate();
      checkUsersAvailability();
    }

    // Handle the dates if coming back from backend and default.
    if (resolvedEntity) {
      if ($scope.isBlog) {
        $scope.data.publishDate = moment($scope.data.publishDate).toDate();
        if ($scope.data.schedulePublish) {
          $scope.data.schedulePublish = moment(
            $scope.data.schedulePublish
          ).toDate();
        }
        if ($scope.data.scheduleUnpublish) {
          $scope.data.scheduleUnpublish = moment(
            $scope.data.scheduleUnpublish
          ).toDate();
        }
      } else {
        // Parse the date strings as date objects.
        $scope.data.startDate = moment($scope.data.startDate).toDate();
        $scope.data.endDate = moment($scope.data.endDate).toDate();
      }
    } else {
      // We set the dates to current date by default.
      if ($scope.isBlog) {
        $scope.data.publishDate = moment().toDate();
      } else {
        $scope.data.startDate = roundDate(moment().toDate());
        $scope.data.endDate = roundDate(moment().add(1, 'hours').toDate());
      }
    }

    // Set event year
    $scope.data.eventYear = moment($scope.data.startDate).year();

    // =============================================================================
    // Modals and confirmation windows.
    // =============================================================================

    $scope.confirmClose = confirmClose;
    $scope.doubleBooking = doubleBooking;

    /**
     * Toggle a confirmation window open closing the modal.
     */
    function confirmClose() {
      $uibModal
        .open({
          templateUrl:
            '@/app/shared/components/create-content/modals/confirm-cancel.html',
          scope: $scope,
          windowClass: 'create-content-confirm-cancel',
          controller: [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              'ngInject';

              $scope.discard = function () {
                $uibModalInstance.close();
              };

              $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
              };
            },
          ],
        })
        .result.then(function () {
          $uibModalInstance.dismiss('cancel');
        });
    }

    /**
     * Toggle a modal showing that a double booking has occurred.
     */
    function doubleBooking(data, action, conflicts) {
      $uibModal
        .open({
          component: 'cdDoubleBookingModal',
          resolve: {
            conflicts: () => conflicts,
            allowConflicts: () => () => {
              data.allowDoubleBooking = true;
              return new Promise((resolve) =>
                saveCalendar(data, function (savedEvent) {
                  $scope.busy = false;
                  !$scope.isEdit || $scope.isCopy
                    ? $scope.addEvent(savedEvent)
                    : $scope.reloadCurrentView();
                  $scope.onCreate(savedEvent);
                  checkResources(savedEvent);
                  resolve(savedEvent);
                })
              );
            },
          },
        })
        .result.then(function (savedEvent) {
          onSaveSuccess(savedEvent, action);
        });
    }

    // =============================================================================
    // Handle form submission and send data to the API.
    // =============================================================================

    /**
     * Save the calendar object.
     *
     * @param {Object} payload - The event or absence to be saved.
     * @param {Function} success
     * @param {Function} error
     */
    function saveCalendar(payload, success, error) {
      let out = angular.copy(payload);

      out.type = resolvedOptions.type;
      out.allowDoubleBooking = payload.allowDoubleBooking || false;

      if (!$scope.rrule) {
        out.rrule = null;
      } else {
        out.rrule = generateRRuleString();
      }

      if (payload.allDay) {
        out.startDate = moment(payload.startDate).startOf('day').toDate();
        out.endDate = moment(payload.endDate).endOf('day').toDate();
      }

      if ($scope.prepTimeEnabled) {
        out.preparationStartDate =
          payload.prepTimeMinutes > 0
            ? moment(payload.startDate)
                .subtract(payload.prepTimeMinutes, 'minutes')
                .toDate()
            : out.startDate;
        out.cleanupEndDate =
          payload.cleanupTimeMinutes > 0
            ? moment(payload.endDate)
                .add(payload.cleanupTimeMinutes, 'minutes')
                .toDate()
            : out.endDate;
      } else {
        out.preparationStartDate = out.startDate;
        out.cleanupEndDate = out.endDate;
      }

      if (_.isObject(payload.image)) {
        out.imageId = _.get(payload, 'image.file.id');
      }

      // Sharing to Facebook
      if (!_.get(payload, 'facebook.publish')) {
        payload.facebook = { publish: false };
      }

      // Manage the event location.
      if (!angular.isString(payload.location) && !payload.locationObj) {
        // The location was NOT set previously for the event and the specified location is an object
        out.locationObj = payload.location;
        out.location = null;
      } else if (angular.isString(payload.location) && payload.locationObj) {
        // Check if location string is not the same as locationObj string
        if (payload.location === payload.locationObj.string) {
          // That means we did not modify the location
          out.location = null;
        } else {
          // That means we insert custom location free text
          out.locationObj = null;
        }
      } else if (angular.isObject(payload.location) && payload.locationObj) {
        // That means the new location object (either a resource's location or a Google location) is selected
        const resourceId =
          _.get(payload, 'location.location_map.resourceSourceId') ||
          _.get(payload, 'location.custom_data.resourceId');
        out.locationObj = appUtils.buildGooglePlacesObject(
          payload.location,
          resourceId
        );

        out.location = null;
      } else if (!payload.location) {
        // The location was removed by the user
        out.locationObj = null;
        out.location = null;
      }

      // Add timezone
      out.timezone = moment.tz.guess();

      // Remove churches from private events.
      if (out.type !== 'event' || out.visibility === 'private') {
        out.churches = undefined;
        out.churchIds = undefined;
      }

      new Calendar(out).$save(
        function (savedEvent) {
          return (
            success ||
            function () {
              onSaveSuccess(out);
            }
          ).apply(this, [savedEvent]);
        },
        function (response) {
          return (
            error ||
            function () {
              appUtils.showError(
                _.get(response, 'data.message'),
                toastr,
                gettextCatalog
              );
            }
          ).apply(this, arguments);
        }
      );
    }

    /**
     * Success callback.
     *
     * @param {Object} data - The object that was saved.
     * @param {String} action - The action that was performed (`copy`, `reset`, `send`).
     */
    function onSaveSuccess(data, action) {
      $scope.busy = false;
      $scope.save = _.once(save);

      // Fetch the event stole if it has been updated by the event updating
      if ($scope.calendarId && $scope.hasIntentionAccess) {
        $scope.refreshEventStole($scope.calendarId);
      }

      if (!data.id && !action) {
        if ($scope.isEvent) {
          toastr.success(
            gettextCatalog.getString('A new event has been created.')
          );
        } else if ($scope.isAbsence) {
          toastr.success(
            gettextCatalog.getString('A new absence has been created.')
          );
        } else {
          toastr.success(
            gettextCatalog.getString('A new blog has been created.')
          );
        }

        $uibModalInstance.close('reloadState');
      } else if (data.id && action !== 'copy') {
        if ($scope.isEvent) {
          toastr.success(
            gettextCatalog.getString('The event has been updated.')
          );
        } else if ($scope.isAbsence) {
          toastr.success(
            gettextCatalog.getString('The absence has been updated.')
          );
        } else {
          toastr.success(
            gettextCatalog.getString('The blog has been updated.')
          );
        }

        $uibModalInstance.close('reloadState');
      } else if (action === 'reset') {
        resetForm();

        if ($scope.isEvent) {
          toastr.success(
            gettextCatalog.getString(
              'A new event has been created and the form has been cleared.'
            )
          );
        } else if ($scope.isAbsence) {
          toastr.success(
            gettextCatalog.getString(
              'A new absence has been created and the form has been cleared.'
            )
          );
        } else {
          toastr.success(
            gettextCatalog.getString(
              'A new blog has been created and the form has been cleared.'
            )
          );
        }
      } else if (action === 'send') {
        if ($scope.isEvent) {
          toastr.success(
            gettextCatalog.getString(
              'A new event has been created and the users you booked have been notified.'
            )
          );
        } else if ($scope.isAbsence) {
          toastr.success(
            gettextCatalog.getString(
              'A new absence has been created and the user has been notified.'
            )
          );
        }

        $uibModalInstance.close('reloadState');
      } else if (action === 'copy') {
        copyForm();

        if ($scope.data.id) {
          if ($scope.isEvent) {
            toastr.success(
              gettextCatalog.getString(
                'The event has been updated and the form has been copied.'
              )
            );
          } else if ($scope.isAbsence) {
            toastr.success(
              gettextCatalog.getString(
                'The absence has been updated and the form has been copied.'
              )
            );
          } else {
            toastr.success(
              gettextCatalog.getString(
                'The blog has been updated and the form has been copied.'
              )
            );
          }
        } else {
          if ($scope.isEvent) {
            toastr.success(
              gettextCatalog.getString(
                'A new event has been created and the form has been copied.'
              )
            );
          } else if ($scope.isAbsence) {
            toastr.success(
              gettextCatalog.getString(
                'A new absence has been created and the form has been copied.'
              )
            );
          } else {
            toastr.success(
              gettextCatalog.getString(
                'A new blog has been created and the form has been copied.'
              )
            );
          }
        }
      }
    }

    function checkResources(event) {
      // Retrieve all the resources
      Resources.query()
        .$promise.then((resources) => {
          // Check if a location but no resources were specified
          if (_.isEmpty(event.resources) && event.locationObj) {
            // Retrieve organization resources WITHOUT a location
            const organizationResourcesWithoutLocation = _.filter(
              resources,
              (resource) => !_.get(resource, 'location')
            );

            showResourceLocationModal(
              'ORGANIZATION_RESOURCES',
              event,
              organizationResourcesWithoutLocation
            );
          } else {
            // Retrieve the calendar resources that do not have a location
            const eventResourceIds = _.map(
              _.keys(event.resources),
              _.ary(parseInt, 1)
            );

            const calendarResourcesWithoutLocation = [];
            _.each(eventResourceIds, (eventResourceId) => {
              // Find the standalone resource or the resource's parent (church) WITHOUT a location
              const standaloneResourceOrChurch = _.find(
                resources,
                (resource) => {
                  const childResourceIds = _.map(
                    _.get(resource, 'resources'),
                    'id'
                  );

                  return (
                    _.get(resource, 'id') === eventResourceId ||
                    _.includes(childResourceIds, eventResourceId)
                  );
                }
              );

              // If NO resource was found or the found resource DOES have a location, skip it
              if (
                !standaloneResourceOrChurch ||
                _.get(standaloneResourceOrChurch, 'location')
              ) {
                return false;
              }
              // Add the standalone resource or church to the list of event resources WITHOUT location, if it wasn't added before
              const isResourceAdded = _.find(calendarResourcesWithoutLocation, {
                id: _.get(standaloneResourceOrChurch, 'id'),
              });

              if (!isResourceAdded) {
                calendarResourcesWithoutLocation.push(
                  standaloneResourceOrChurch
                );
              }
            });
            showResourceLocationModal(
              'EVENT_RESOURCES',
              event,
              calendarResourcesWithoutLocation
            );
          }
        })
        .catch((error) => {
          appUtils.showError(
            _.get(error, 'data.message'),
            toastr,
            gettextCatalog
          );
        });
    }

    function showResourceLocationModal(mode, event, resources) {
      // Show, address suggestions
      if (!_.isEmpty(resources) && $scope.showChurchSelector) {
        $uibModal.open({
          component: 'cdResourceLocationModal',
          windowClass: 'modal-ui-select',
          resolve: {
            mode: () => mode,
            resources: () => resources,
            location: () => _.get(event, 'locationObj'),
          },
        });
      }
    }

    /**
     * Error callback.
     *
     * @param {Object} data - The object that was saved.
     * @param {String} action - The action that was performed (`copy`, `reset`, `send`).
     * @param {Object} error - The error object.
     */
    function onSaveError(data, action, error) {
      $scope.busy = false;
      $scope.save = _.once(save);

      if (error && error.status === 409) {
        $scope.conflicts = error.data;
        $scope.doubleBooking(data, action, error.data);
        toastr.warning(
          gettextCatalog.getString(
            'One or more conflicts prevented this from being saved.'
          )
        );
      } else {
        appUtils.showError(
          _.get(error, 'data.message'),
          toastr,
          gettextCatalog
        );
      }
    }

    /**
     * Submit the form.
     */
    $scope.save = _.once(save);

    function save(context, data, action) {
      $scope.busy = true;
      data.sendNotifications = action && action === 'send';

      if ($scope.isBlog) {
        if (!$scope.blog.schedulePost) {
          data.schedulePublish = data.scheduleUnpublish = null;
        }

        if (_.isObject(data.image)) {
          data.imageId = _.get(data, 'image.file.id');
        }

        if (data.facebook?.publish) {
          // Schedule facebook post if blog is scheduled
          if (data.schedulePublish) {
            data.facebook.isScheduled = true;
          } else {
            data.facebook.isScheduled = false;
            data.facebook.schedulingOptions = null;
          }
        }

        new Blogs(data).$save(
          function () {
            onSaveSuccess(data, action);
          },
          function (error) {
            onSaveError(data, action, error);
          }
        );
      } else {
        let triggerSave = function () {
          $scope.busy = true;

          saveCalendar(
            data,
            function (savedEvent) {
              !$scope.isEdit || $scope.isCopy
                ? $scope.addEvent(savedEvent)
                : $scope.reloadCurrentView();
              $scope.onCreate(savedEvent);
              // Invoke the post-save/post-update functions
              onSaveSuccess(data, action);
              checkResources(savedEvent);
            },
            function (error) {
              onSaveError(data, action, error);
            }
          );
        };

        if (
          data.id &&
          data.rrule &&
          resolvedEntity.rrule &&
          $scope.contentCreation[resolvedOptions.type].$dirty
        ) {
          $scope.busy = false;

          // Alert user if they are editing a recurring entity.
          $scope
            .editRecurring(data)
            .then(function (updateWith) {
              _.extend(data, updateWith);
              triggerSave();
            })
            .catch(function () {
              $scope.save = _.once(save);
            });
        } else {
          triggerSave();
        }
      }
    }

    /**
     * Dismiss the form.
     */
    $scope.cancel = function () {
      // If the user has interacted with the form, we show a warning before closing.
      if (
        $scope.contentCreation[resolvedOptions.type] &&
        $scope.contentCreation[resolvedOptions.type].$dirty
      ) {
        confirmClose();
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };

    // =============================================================================
    // Event planning and booking.
    // =============================================================================

    (function () {
      if (!Authorization.hasPermission('canAccessPlanning')) return;

      // Encapsulate planning-specific functionality
      $scope.planning = {
        tasks: Tasks.getAll(),
      };
    })();

    // =============================================================================
    // Create content form steps.
    // =============================================================================

    /**
     * Define the list of steps to be shown in the UI based on the form name
     */
    $scope.stepsObj = new stepsFactory([
      {
        key: 'basic',
        title: gettextCatalog.getString('Add the basics'),
        description: gettextCatalog.getString(
          'Add the essential details of your event'
        ),

        isEnabled: true,
        isShown: true,
      },

      {
        key: 'public',
        title: gettextCatalog.getString('Public information'),
        description: gettextCatalog.getString(
          'Add public information your website, facebook and newsletters'
        ),

        isEnabled: true,
        isShown: true,
      },

      {
        key: 'planning',
        title: gettextCatalog.getString('Add to rotas'),
        description: gettextCatalog.getString(
          'Add event to rotas and assign volunteers and staff'
        ),

        isEnabled: true,
        isShown:
          Authorization.hasPackage('planning') &&
          Authorization.hasPermission('canAccessPlanning'),
      },
    ]);

    // =============================================================================
    // Create content window specific shortcuts.
    // =============================================================================

    // Close window by pressing ESC
    hotkeys.bindTo($scope).add({
      combo: 'esc',
      allowIn: ['INPUT', 'TEXTAREA'],
      callback: function () {
        if (angular.element('.modal').length > 1) return;
        $scope.cancel();
      },
    });

    // Initialize React binding
    const mapStateToScope = (state) => ({
      isNewCalendar: calendarSettingsSelectors.isNewCalendar(state),
      selectedChurchId: _.get(state, 'config.organizationData.churchId'),
    });

    const mapDispatchToScope = (dispatch) => ({
      addEvent: (event) => dispatch(AddEvent(event)),
      reloadCurrentView: () =>
        dispatch(fullCalendarActions.reloadCurrentView()),
      fetchEventStole: (calendarId) => dispatch(fetchEventStole(calendarId)),
      refreshEventStole: (calendarId) =>
        dispatch(refreshEventStole(calendarId)),
    });

    const unsubscribe = $ngRedux.connect(
      mapStateToScope,
      mapDispatchToScope
    )($scope);

    $scope.$on('$destroy', unsubscribe);
  }
  CreateContentController.$inject = [
    '_',
    '$ngRedux',
    '$q',
    '$scope',
    '$uibModal',
    '$uibModalInstance',
    '$filter',
    '$timeout',
    'Groups',
    'Calendar',
    'Taxonomies',
    'Resources',
    'Blogs',
    'SognDk',
    'Files',
    'toastr',
    'gettextCatalog',
    'hotkeys',
    'Tasks',
    'Authorization',
    'Users',
    'Me',
    'resolvedOptions',
    'resolvedEntity',
    'dateFormatsLookup',
    'calendarPollerService',
    'stepsFactory',
    'uiSelectAllowNewMarker',
    'cdApp',
    'appUtils',
  ];

  angular
    .module('cdApp.shared')
    .controller('CreateContentController', CreateContentController)
    .directive('cdAdjustPanelHeight', [
      '$window',
      '$timeout',
      function ($window, $timeout) {
        return {
          restrict: 'A',
          link: function (scope, elem) {
            let resizeHandler = _.debounce(function () {
              $timeout(function () {
                let headerH =
                  angular
                    .element(
                      '.create-content-modal .modal-header.modal-top-header'
                    )
                    .outerHeight() || 0;
                let stepsH =
                  angular
                    .element(
                      '.create-content-modal .modal-header.modal-steps-header'
                    )
                    .outerHeight() || 0;
                let footerH =
                  angular
                    .element('.create-content-modal .modal-footer')
                    .outerHeight() || 0;
                let padding = 40;
                let windowH = $window.innerHeight;

                elem.css(
                  'height',
                  windowH - _.sum([headerH, stepsH, footerH, padding, 4]) + 'px'
                );
              });
            }, 200);

            resizeHandler();

            angular.element($window).bind('resize', resizeHandler);
            scope.$on('$destroy', function () {
              angular.element($window).unbind('resize', resizeHandler);
            });
          },
        };
      },
    ]);
})();
