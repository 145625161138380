import { fileUploadTypes } from '../types/SafeguardingTypes';
import gettextCatalog from '../../services/I18nService';

export const getEmptyStateText = (fileUploadType) => {
  switch (fileUploadType) {
    case fileUploadTypes.CERTIFICATE_OF_CONDUCT:
      return gettextCatalog.getString('No certificate of conduct');
    case fileUploadTypes.DOCUMENT:
      return gettextCatalog.getString('No documents');
    case fileUploadTypes.COURSE:
      return gettextCatalog.getString('No courses');
  }
};
export const getButtonText = (fileUploadType) => {
  switch (fileUploadType) {
    case fileUploadTypes.CERTIFICATE_OF_CONDUCT:
      return gettextCatalog.getString('Register certificate of conduct');
    case fileUploadTypes.DOCUMENT:
      return gettextCatalog.getString('Add documents');
    case fileUploadTypes.COURSE:
      return gettextCatalog.getString('Add courses');
  }
};
export const getFileUploadModalTitle = (fileUploadType) => {
  switch (fileUploadType) {
    case fileUploadTypes.CERTIFICATE_OF_CONDUCT:
      return gettextCatalog.getString('Register certificate of conduct');
    case fileUploadTypes.COURSE:
      return gettextCatalog.getString('Add courses');
    case fileUploadTypes.DOCUMENT:
      return gettextCatalog.getString('Add documents');
    default:
      break;
  }
};
