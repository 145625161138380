import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row, Card } from 'antd';
import styled from 'styled-components';

import gettextCatalog from '../../../services/I18nService';
import { CdForm } from '../../../shared/components/Icons';
import CdrEmptyState from '../../../shared/components/CdrEmptyState';

const InformationDiv = styled.div`
  margin-bottom: 16px;
`;
const FormPreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f2f5;
  padding: 32px;
  width: 100%;
`;

const Frame = ({ height, setHeight, formLink }) => {
  const listener = useCallback(
    (e) => {
      if (
        e.data.source === 'cdFormEmbed' &&
        e.data.action === 'FORM_RESIZE_HEIGHT'
      ) {
        setHeight(e.data.height);
      }
    },
    [setHeight]
  );
  useEffect(() => {
    window.addEventListener('message', listener, false);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [listener]);

  return (
    <iframe
      src={`${formLink}/embed`}
      width="600px"
      height={`${height}px`}
      style={{ border: '0' }}
    />
  );
};

const SignUpPreview = ({ formLink }) => {
  const [height, setHeight] = useState(700);
  return (
    <Row>
      <Col span={24}>
        <InformationDiv>
          {gettextCatalog.getString(
            'This form is added as a sign-up option for the event. It will be displayed in the event widget and the event page.'
          )}
        </InformationDiv>
      </Col>
      <Col span={24}>
        {formLink ? (
          <FormPreviewWrapper>
            <Frame height={height} setHeight={setHeight} formLink={formLink} />
          </FormPreviewWrapper>
        ) : (
          <FormPreviewWrapper>
            <Card
              style={{
                width: '600px',
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CdrEmptyState
                title={gettextCatalog.getString('No forms found')}
                subtitle={gettextCatalog.getString(
                  'Please add a form and save to see the preview.'
                )}
                EmptyStateIcon={<CdForm />}
              />
            </Card>
          </FormPreviewWrapper>
        )}
      </Col>
    </Row>
  );
};

export default SignUpPreview;
