'use strict';

function checkAccessToPeople($state, Authorization) {
  'ngInject';

  if (!Authorization.hasPackage('people')) {
    return $state.target('app.private.people.default', {
      errorCode: 'PACKAGE_NOT_ENABLED',
    });
  }
  if (!Authorization.hasPermission('canAccessPeople')) {
    return $state.target('app.private.people.default', {
      errorCode: 'PEOPLE_ACCESS',
    });
  }
}
checkAccessToPeople.$inject = ['$state', 'Authorization'];

angular.module('cdApp').config([
  '$stateProvider',
  ($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('app.private.people', {
        abstract: true,
        url: '/people',
        template: `
          <cd-app-nav class="app-nav"></cd-app-nav>
          <div class="app-view" ui-view></div>
        `,
      })
      .state('app.private.people.default', {
        url: '',
        component: 'cdPeople',
        params: {
          errorCode: null,
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('People');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],

          errorCode: ['$stateParams', ($stateParams) => $stateParams.errorCode],
        },

        onEnter: [
          '$state',
          'errorCode',
          function ($state, errorCode) {
            if (!errorCode) {
              return $state.target('app.private.people.contacts.list');
            }
          },
        ],
      })

      /**
       * Contacts
       */
      .state('app.private.people.contacts', {
        abstract: true,
        url: '/contacts',
        template: '<div class="app-view" ui-view></div>',
        onEnter: checkAccessToPeople,
      })
      .state('app.private.people.contacts.list', {
        url: '?{list:int}&{filter:string}&{search:string}&{editing:string}&{createList:bool}',
        component: 'cdPeopleListState',
        params: {
          list: {
            value: -1,
            dynamic: true,
            squash: true,
          },

          search: {
            value: '',
            dynamic: true,
            squash: true,
          },

          filter: {
            value: '',
            dynamic: true,
            squash: true,
          },

          editing: {
            value: '',
            dynamic: true,
            squash: true,
          },

          createList: {
            value: false,
            squash: true,
            dynamic: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Contacts');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],

          lists: [
            'PeopleSegments',
            function (PeopleSegments) {
              return PeopleSegments.query().$promise;
            },
          ],

          filterGroups: [
            'People',
            function (People) {
              return People.getFilters().$promise;
            },
          ],
        },
      })
      .state('app.private.people.contacts.view', {
        url: '/{id:int}?step',
        component: 'cdPeopleDetailState',
        params: {
          step: {
            value: 'basic',
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            '$filter',
            'routingHelpers',
            'person',
            function ($filter, routingHelpers, person) {
              const base = 'Contact Detail';
              return {
                base,
                rendered: routingHelpers.buildTitle($filter('getName')(person)),
              };
            },
          ],

          person: [
            'People',
            '$stateParams',
            function (People, $stateParams) {
              return People.get({ id: $stateParams.id }).$promise;
            },
          ],

          customFields: [
            'PeopleCustomFields',
            '$stateParams',
            function (PeopleCustomFields, $stateParams) {
              return PeopleCustomFields.get({ personId: $stateParams.id })
                .$promise;
            },
          ],

          customFieldsData: [
            'PeopleCustomFieldsData',
            '$stateParams',
            function (PeopleCustomFieldsData, $stateParams) {
              return PeopleCustomFieldsData.get({ id: $stateParams.id })
                .$promise;
            },
          ],

          staticFields: [
            'People',
            function (People) {
              return People.getStaticFields().$promise;
            },
          ],

          staticFieldsOptions: [
            'People',
            function (People) {
              return People.getStaticFieldsOptions().$promise;
            },
          ],
        },
      })
      .state('app.private.people.contacts.view.edit', {
        url: '/edit?context',
        params: {
          context: {
            value: 'basic',
            squash: true,
          },
        },

        // Manual annotation for proper dependency injection
        onEnter: [
          '$uibModal',
          '$state',
          '$stateParams',
          'person',
          'customFields',
          'customFieldsData',
          'staticFields',
          'staticFieldsOptions',
          function (
            $uibModal,
            $state,
            $stateParams,
            person,
            customFields,
            customFieldsData,
            staticFields,
            staticFieldsOptions
          ) {
            $uibModal
              .open({
                component: 'cdEditPeopleModal',
                resolve: {
                  context: () => $stateParams.context,
                  person: () => angular.copy(person),
                  customFields() {
                    if ($stateParams.context === 'custom') {
                      return angular.copy(customFields);
                    }
                  },
                  customFieldsData() {
                    if ($stateParams.context === 'custom') {
                      return angular.copy(customFieldsData);
                    }
                  },
                  staticFields: () => angular.copy(staticFields),
                  staticFieldsOptions: () => angular.copy(staticFieldsOptions),
                },

                size: 'lg',
                windowClass:
                  $stateParams.context === 'family'
                    ? 'modal-ui-select'
                    : 'modal-scrollable',
                backdrop: 'static',
              })
              .result.then(() => $state.go('^', {}, { reload: true }))
              .catch(() => $state.go('^'));
          },
        ],
      })
      .state('app.private.people.contacts.import', {
        url: '/import',
        component: 'cdPeopleImportState',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Import Contacts');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.people.contacts.importHistory', {
        url: '/import/history',
        component: 'cdPeopleImportHistoryState',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Import History');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.people.contacts.importHistory.details', {
        url: '/:id',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Import Details');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: [
          '$uibModal',
          '$state',
          '$stateParams',
          function ($uibModal, $state, $stateParams) {
            'ngInject';
            $uibModal
              .open({
                component: 'cdPeopleImportDetailsState',
                resolve: {
                  id: () => $stateParams.id,
                },

                size: 'lg',
              })
              .result.finally(() => $state.go('^'));
          },
        ],
      })
      .state('app.private.people.contacts.export', {
        url: '/export/:id',
        component: 'cdPeopleExportDownloadState',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Export');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })

      /**
       * Messages
       */ .state('app.private.people.messages', {
        abstract: true,
        url: '/messages',
        template: '<div class="app-view" ui-view></div>',
        onEnter: checkAccessToPeople,
      })
      .state('app.private.people.messages.list', {
        url: '?{tab:string}&{page:int}',
        component: 'cdPeopleMessageListState',
        params: {
          tab: {
            value: 'sent',
            dynamic: true,
            squash: true,
          },

          page: {
            value: 1,
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            function (gettextCatalog, routingHelpers) {
              const base = 'People Messages';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Messages')
                ),
              };
            },
          ],
        },
      })
      .state('app.private.people.messages.editorV2', {
        url: '/mail/:type',
        abstract: true,
        template: '<div class="app-view" ui-view></div>',
        onEnter: checkAccessToPeople,
        params: {
          type: { value: 'basic', dynamic: true },
        },
      })
      .state('app.private.people.messages.editorV2.view', {
        url: '/{messageId:int}?{filter}&{contactIds}',
        params: {
          messageId: {
            value: null,
            dynamic: true,
          },
          to: [],
          contactIds: {
            value: [],
            type: 'int',
            array: true,
            squash: true,
            dynamic: true,
          },
          filter: {
            value: 'all',
            type: 'string',
          },
        },

        component: 'cdrRootMailEditor',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Message');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.people.messages.editorV2.statistics', {
        url: '/{messageId:int}/statistics?{filter:string}',
        params: {
          messageId: {
            value: null,
          },

          to: [],
          type: 'basic',
          filter: {
            value: 'all',
          },
        },

        component: 'cdrRootMailEditor',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Message');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.people.messages.create', {
        url: '/new/:type',
        component: 'cdPeopleMessageEditorState',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            function (gettextCatalog, routingHelpers) {
              const base = 'Create People Message';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Create Message')
                ),
              };
            },
          ],

          message: [
            '$stateParams',
            'PeopleMessages',
            function ($stateParams, PeopleMessages) {
              if (!$stateParams.messageObject) {
                return PeopleMessages.empty($stateParams.type);
              }

              const incomingMessage = angular.copy($stateParams.messageObject);

              if (incomingMessage.type === 'email') {
                incomingMessage.content = incomingMessage.content.replace(
                  /\n/g,
                  '<br />'
                );
              }

              return incomingMessage;
            },
          ],
        },

        params: {
          messageObject: null,
        },
      })
      .state('app.private.people.messages.view', {
        url: '/{id:int}',
        component: 'cdPeopleMessageEditorState',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            function (gettextCatalog, routingHelpers) {
              const base = 'People Message Detail';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Messages')
                ),
              };
            },
          ],

          message: [
            '$stateParams',
            'PeopleMessages',
            function ($stateParams, PeopleMessages) {
              return PeopleMessages.get({ id: $stateParams.id }).$promise;
            },
          ],

          messageForms: [
            'message',
            'Forms',
            '_',
            function (message, Forms, _) {
              if (_.isEmpty(message.forms)) return [];
              return Forms.getPublic(
                {},
                { formIds: _.map(message.forms, 'id') }
              ).$promise;
            },
          ],
        },

        params: {
          isSentMessage: false,
        },
      })
      .state('app.private.people.messages.statistics', {
        url: '/{id:int}/statistics?filter',
        component: 'cdPeopleMessageStatisticsState',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            function (gettextCatalog, routingHelpers) {
              const base = 'People Message Statistics';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Message Statistics')
                ),
              };
            },
          ],

          message: [
            '$stateParams',
            'PeopleMessages',
            function ($stateParams, PeopleMessages) {
              return PeopleMessages.get({ id: $stateParams.id }).$promise;
            },
          ],
        },
      })

      /**
       * Others
       */ .state('app.private.people.tagList', {
        url: '/tags',
        component: 'cdPeopleTagListState',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            function (gettextCatalog, routingHelpers) {
              const base = 'People Tags';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Tags')
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToPeople,
      })
      .state('app.private.people.fields', {
        url: '/fields?step',
        component: 'cdPeopleCustomFieldsState',
        params: {
          sourceContactId: null,
          step: {
            value: 'settings',
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'Me',
            'gettextCatalog',
            'routingHelpers',
            function (Me, gettextCatalog, routingHelpers) {
              const base = 'People Custom Fields';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Custom fields')
                ),
              };
            },
          ],

          staticFields: [
            'People',
            function (People) {
              return People.getStaticFields().$promise;
            },
          ],

          customFields: [
            'PeopleCustomFields',
            function (PeopleCustomFields) {
              return PeopleCustomFields.get().$promise;
            },
          ],
        },

        onEnter: [
          '$state',
          'Authorization',
          function ($state, Authorization) {
            'ngInject';
            return (
              Authorization.hasPermission('canAdministerUsers') ||
              $state.target('app.private.people.default', {
                errorCode: 'CUSTOM_FIELDS_ACCESS',
              })
            );
          },
        ],
      })
      .state('app.private.people.customFields', {
        url: '/custom-fields',
        redirectTo: 'app.private.people.fields',
      })
      .state('app.private.people.consentTypes2', {
        url: '/consent-types2',
        component: 'cdrConsentTypeList',
        resolve: {
          $title: [
            'Me',
            'gettextCatalog',
            'routingHelpers',
            function (Me, gettextCatalog, routingHelpers) {
              const base = 'People Consents';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Consents')
                ),
              };
            },
          ],
        },

        onEnter: [
          '$state',
          'Authorization',
          function ($state, Authorization) {
            'ngInject';
            return (
              Authorization.hasPermission('canAdministerUsers') ||
              $state.target('app.private.people.default', {
                errorCode: 'PEOPLE_ACCESS',
              })
            );
          },
        ],
      })
      .state('app.private.people.anniversary', {
        url: '/anniversary',
        component: 'cdrAnniversary',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Upcoming anniversaries');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
        onEnter: checkAccessToPeople,
      })
      .state('app.private.people.pastoralNotes', {
        url: '/pastoral-notes',
        component: 'cdPastoralNotesListState',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            function (gettextCatalog, routingHelpers) {
              const base = 'Pastoral Notes';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Pastoral Notes')
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToPeople,
      })
      .state('app.public.people-newsletter', {
        url: '/public/newsletter/:uuid',
        component: 'cdPublicNewsletter',
        params: {
          messageId: {
            value: null,
            dynamic: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Newsletter');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base),
                  false
                ),
              };
            },
          ],

          isPublic() {
            return true;
          },
        },
      })
      .state('app.private.people.lists2', {
        url: '/lists2?{activeTab}',
        component: 'cdrNewsletterList',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            function (gettextCatalog, routingHelpers) {
              const base = 'People Lists';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Lists')
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToPeople,
      })
      .state('app.public.people-unsubscribe', {
        url: '/manage/subscription?token&organizationId',
        component: 'cdrManageSubscription',
        params: {
          token: { type: 'string', value: null, dynamic: true },
          organizationId: { type: 'int', value: null, dynamic: true },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Manage subscription');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base),
                  false
                ),
              };
            },
          ],

          isPublic() {
            return true;
          },
        },
      })
      .state('app.private.people.people-announcement', {
        url: '/announcement?{continue:string}',
        component: 'cdrNewsletterSignUpAnnouncementPage',
        params: {
          continue: { value: null, dynamic: false },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Newsletter sign up announcement');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base),
                  false
                ),
              };
            },
          ],
        },
      })
      .state('app.private.people.people-setup-newsletter', {
        url: '/setup-newsletter?{continue:string}',
        component: 'cdrNewsletterSetupPage',
        params: {
          continue: { value: null, dynamic: false },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Newsletter sign up announcement');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base),
                  false
                ),
              };
            },
          ],
        },
      })
      .state('app.public.errorLinkExpiration', {
        url: '/error-link-expiration',
        component: 'cdrErrorLinkExpiration',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            function (gettextCatalog, routingHelpers) {
              const base = 'Error';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base),
                  false
                ),
              };
            },
          ],
          isPublic() {
            return true;
          },
        },
      });
  },
]);
