import React, { useCallback, useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Moment } from 'moment';

import { useRotaAndIntentionState } from '../../hooks/use-rota-and-intention-state';

import getTextCatalog from '@/react/services/I18nService';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import {
  AssignedIntentionsAtom,
  RotaTableState,
} from '@/react/calendar/store/events/eventRotaDuties';
import IntentionsListView from '@/react/calendar/event-details/components/intentions/IntentionsListView';

export type AssignIntentionModalProps = {
  eventId: number;
  eventStartDate: Moment;
  eventTitle: string;
  churchIds: number[];
  categoryIds: number[];
};

export const openAssignIntentionModal =
  createCdModal<AssignIntentionModalProps>(
    ({
      setModalProps,
      eventStartDate,
      eventId,
      eventTitle,
      churchIds,
      categoryIds,
    }) => {
      const rotaTableState = useRecoilValue(RotaTableState);
      const { updateRotaTableStateByAssigningTheIntention } =
        useRotaAndIntentionState();
      const [assignedIntentions, setAssignedIntentions] = useRecoilState(
        AssignedIntentionsAtom
      );
      // should define an accumulator that concat all the arrays together
      const [extraData, setExtraData] = useState(
        rotaTableState.shiftsAndIntentions
          .map((item) => item.intentions)
          .reduce((accumulator, currentValue) =>
            accumulator.concat(currentValue)
          )
      );

      const rotasInfoNeededForIntentions =
        rotaTableState?.shiftsAndIntentions[0]?.shifts?.map((shift) => ({
          taskId: shift.taskId,
          noOfAssigned: shift.users?.length,
        }));
      const onOk = useCallback(() => {
        updateRotaTableStateByAssigningTheIntention(
          eventId,
          assignedIntentions
        );
      }, [
        assignedIntentions,
        eventId,
        updateRotaTableStateByAssigningTheIntention,
      ]);

      useEffect(() => {
        setModalProps({
          modalType: ModalType.MODAL,
          title: getTextCatalog.getString('Intentions'),
          width: '90vw',
          maxBodyHeight: 76,
          onOk,
        });
      }, [onOk, setModalProps]);
      useEffect(() => {
        const items = rotaTableState.shiftsAndIntentions.find(
          (e) => e.calendarId === eventId
        ).intentions;
        setAssignedIntentions({ items, total: items.length });
      }, [eventId, rotaTableState.shiftsAndIntentions, setAssignedIntentions]);

      return (
        <IntentionsListView
          eventStartDate={eventStartDate}
          eventTitle={eventTitle}
          churchIds={churchIds}
          categoryIds={categoryIds}
          rotasInfoNeededForIntentions={rotasInfoNeededForIntentions}
          eventId={eventId}
          extraData={extraData}
          setExtraData={setExtraData}
        />
      );
    }
  );
