/* eslint-disable react-hooks/exhaustive-deps */
import * as _ from 'lodash';
import styled from 'styled-components';
import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Input,
  Form,
  Popconfirm,
  Button,
  InputNumber,
  Checkbox,
  Select,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { IntentionFeeAllocation } from '../../models/fee';
import gettextCatalog from '../../../services/I18nService';
import { StyledTable } from '../../../shared/antd/StyledAntdComponents';
import { getIntentionFeeRecipients } from '../../redux/intention-fees/Selectors';
import { fetchIntentionFeeRecipients } from '../../redux/intention-fees/Actions';
import {
  getCommaFormattedNumber,
  getDecimalSeparator,
  getOrganizationCurrencySymbol,
} from '../../../shared/utils';

// Antd
const { Option } = Select;

// @ts-ignore: Copied from a ANT-Design example
const EditableContext = React.createContext<any>();

const StyledDiv = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    min-height: 27px;
    padding: 4px 11px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  }
`;

interface EditableRowProps {
  index: number;
}

interface IntentionFeeAllocationRow extends IntentionFeeAllocation {
  key: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  inputType: 'number' | 'text' | 'boolean';
  editable: boolean;
  required: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: IntentionFeeAllocationRow;
  handleSave?: (record: IntentionFeeAllocationRow) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  inputType,
  editable,
  required,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing && inputRef) {
      // @ts-ignore: Copied from a ANT-Design example
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    let inputNode = <Input ref={inputRef} onPressEnter={save} onBlur={save} />;
    if (inputType === 'number') {
      inputNode = (
        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
      );
    }
    if (inputType === 'boolean') {
      inputNode = <Checkbox ref={inputRef} onChange={save} />;
    }

    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required,
            message: gettextCatalog.getString(
              "The field '{{field}}' is required.",
              {
                field: title,
              }
            ),
          },
        ]}
        valuePropName={inputType === 'boolean' ? 'checked' : 'value'}
      >
        {inputNode}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {inputType === 'boolean' ? (
          record.shouldForwardShare ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            <FontAwesomeIcon icon={faTimes} />
          )
        ) : (
          children
        )}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

// class EditableTable extends React.Component<{}, EditableTableState> {
const EditableTable = (props: {
  value?: IntentionFeeAllocation[];
  onChange?: (feeAllocations: IntentionFeeAllocation[]) => void;
}) => {
  const dispatch = useDispatch();
  const { onChange, value } = props;
  const intentionFeeRecipients = useSelector(getIntentionFeeRecipients);

  const [dataSource, setDataSource] = useState({
    items: [],
    count: 0,
  });

  // Initial load
  useEffect(() => {
    dispatch(fetchIntentionFeeRecipients());
  }, []);

  useEffect(() => {
    let key = -1;
    const items: IntentionFeeAllocationRow[] = _.map(value, (feeAllocation) => {
      key += 1;
      return _.extend(
        {},
        _.pick(feeAllocation, ['recipientId', 'amount', 'shouldForwardShare']),
        {
          key,
        }
      ) as IntentionFeeAllocationRow;
    });
    setDataSource({
      items,
      count: _.size(value),
    });
  }, [value]);

  // Handlers
  const handleDelete = (key) => {
    let items = [...dataSource.items];
    items = _.filter(items, (item) => item.key !== key);
    setDataSource({
      items,
      count: _.size(items),
    });
    // Update parent component's data
    onChange(items);
  };

  const handleAdd = () => {
    const items = [...dataSource.items];
    const { count } = dataSource;
    const newData = {
      key: count,
      recipientId: null,
      amount: 5,
      shouldForwardShare: true,
    };
    items.push(newData);
    setDataSource({
      items,
      count: _.size(items),
    });
  };

  const handleRecipientChange = (
    recipientId: string,
    currentFeeAllocation: IntentionFeeAllocationRow
  ) => {
    const data: any = _.cloneDeep(dataSource);
    const updatedItems = _.forEach(data.items, (feeAllocation) => {
      // Find feeAllocation that recipient is being changed for
      if (feeAllocation.key === currentFeeAllocation.key) {
        feeAllocation.recipientId = recipientId;
      }
    });
    setDataSource({
      items: updatedItems,
      count: data.count,
    });
    // Update parent component's data
    onChange(updatedItems);
  };

  const handleAmountChange = (
    feeAmount: number,
    currentFeeAllocation: IntentionFeeAllocationRow
  ) => {
    const data: any = _.cloneDeep(dataSource);
    const updatedItems = _.forEach(data.items, (feeAllocation) => {
      // Find feeAllocation that recipient is being changed for
      if (feeAllocation.key === currentFeeAllocation.key) {
        feeAllocation.amount = feeAmount;
      }
    });
    setDataSource({
      items: updatedItems,
      count: data.count,
    });
    // Update parent component's data
    onChange(updatedItems);
  };

  const handleSave = (row) => {
    const items = [...dataSource.items];
    const index = items.findIndex((item) => row.key === item.key);
    const item = items[index];
    items.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource({
      items,
      count: _.size(items),
    });
    // Update parent component's data
    onChange(items);
  };

  // Component initialization
  const columnDefinitions: any[] = [
    {
      title: gettextCatalog.getString('Recipient'),
      required: true,
      dataIndex: 'recipient',
      width: '30%',
      render: function columnRender(value, currentFeeAllocation) {
        return (
          <Select
            placeholder={gettextCatalog.getString('Recipient...')}
            onChange={(recipientId: string) =>
              handleRecipientChange(recipientId, currentFeeAllocation)
            }
            defaultValue={_.get(currentFeeAllocation, 'recipientId')}
            allowClear
          >
            {intentionFeeRecipients.map((recipient) => (
              <Option key={recipient.id} value={recipient.id}>
                {_.get(recipient, 'name')}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: gettextCatalog.getString('Amount'),
      required: true,
      dataIndex: 'amount',
      width: '10%',
      render: function columnRender(feeAmount, feeAllocation) {
        return (
          <InputNumber
            decimalSeparator={getDecimalSeparator()}
            defaultValue={feeAmount}
            onChange={(newFeeAmount: number) =>
              handleAmountChange(newFeeAmount, feeAllocation)
            }
            precision={2}
          />
        );
      },
    },
    {
      title: gettextCatalog.getString('Should forward share'),
      inputType: 'boolean',
      editable: true,
      required: true,
      dataIndex: 'shouldForwardShare',
      width: '30%',
    },
  ];

  columnDefinitions.push({
    title: null,
    dataIndex: 'operation',
    width: '5%',
    render: (text, record: IntentionFeeAllocationRow) => {
      const items: IntentionFeeAllocationRow[] = _.get(dataSource, 'items');
      if (_.size(items) < 1) return null;
      const title = gettextCatalog.getString(
        "Are you sure you want to delete the allocation for '{{allocation}}'?",
        {
          allocation: _.get(
            _.find(intentionFeeRecipients, ['id', record.recipientId]),
            'name'
          ),
        }
      );
      return (
        <Popconfirm title={title} onConfirm={() => handleDelete(record.key)}>
          <a>{gettextCatalog.getString('Delete')}</a>
        </Popconfirm>
      );
    },
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = columnDefinitions.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        title: col.title,
        inputType: col.inputType,
        editable: col.editable,
        required: col.required,
        dataIndex: col.dataIndex,
        record,
        handleSave,
      }),
    };
  });
  return (
    <StyledDiv>
      <StyledTable
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource.items}
        columns={columns}
        summary={(pageData: IntentionFeeAllocation[]) => {
          let totalAmount = 0;
          pageData.forEach(({ amount }) => {
            if (!amount) amount = 0;
            totalAmount += parseFloat(amount.toString());
          });
          return (
            <>
              <tr>
                <th colSpan={2}>
                  {gettextCatalog.getString('Fee total amount')}
                </th>
                <td>
                  <label style={{ marginLeft: 12 }}>
                    {getOrganizationCurrencySymbol() +
                      getCommaFormattedNumber(totalAmount)}
                  </label>
                </td>
                <td colSpan={2}>
                  <Button
                    type="link"
                    onClick={handleAdd}
                    style={{ marginBottom: 10, paddingLeft: 0 }}
                  >
                    + {gettextCatalog.getString('Add fee allocation')}
                  </Button>
                </td>
              </tr>
            </>
          );
        }}
      />
    </StyledDiv>
  );
};

export default EditableTable;
