import { RecoilValue } from 'recoil';
import React from 'react';

import CdTable, { CdTableProps } from '../cd-table';

import {
  UnPagedTableApiSearchParams,
  UnPagedTableApiSearchResult,
  CdUnPagedTableData,
} from './types';
import { UnPagedTablePagination } from './UnPagedTablePagination';
import { useCdUnPagedTable } from './useCdUnPagedTable';

interface CdUnPagedTableProps<T>
  extends Omit<
    CdTableProps<T>,
    'isLoading' | 'data' | 'onTableChange' | 'headingText'
  > {
  dataResult: (
    param: UnPagedTableApiSearchParams
  ) => RecoilValue<UnPagedTableApiSearchResult<T>>;
  headingText?: (data: CdUnPagedTableData<T>) => string;
  extraParams?: any;
}

const DEFAULT_PAGE_SIZE = 30;

export default function CdUnPagedTable<T extends Record<string, any> = any>({
  dataResult,
  headingText,
  extraParams,
  ...props
}: CdUnPagedTableProps<T>) {
  const {
    isFetching,
    data,
    handleTableChange,
    pageNumber,
    updatePageNumber,
    heading,
  } = useCdUnPagedTable<T>({
    dataResult,
    extraParams: extraParams,
    pageSize: DEFAULT_PAGE_SIZE,
    headingText,
  });

  return (
    <CdTable<T>
      {...props}
      isLoading={isFetching}
      // total is a mandatory prop for CdTable and it is not required for CdUnPagedTable
      data={{ items: data?.items, total: data?.items?.length }}
      onTableChange={handleTableChange}
      page={[pageNumber, updatePageNumber]}
      headingText={heading}
      pageSize={DEFAULT_PAGE_SIZE}
      OverridePagination={UnPagedTablePagination}
    />
  );
}
