import NiceModal, { antdDrawerV5 } from '@ebay/nice-modal-react';
import { Alert, Button, Drawer, Layout, Space, Tag } from 'antd';
import React from 'react';

import getTextCatalog from '../../services/I18nService';
import {
  ISelectableCardItem,
  SelectableCards,
} from '../../shared/components/SelectableCards';
import CdrEmptyState from '../../shared/components/CdrEmptyState';
import gettextCatalog from '../../services/I18nService';
import { CdrSpinner } from '../../shared/components/cd-spinner/CdrSpinner';

import { useCreateWebsite } from './hooks/use-create-website';

import AuthorizationService from '@/react/services/AuthorizationService';
import cdApp from '@/react/config';
import { CdNewWebsite } from '@/react/shared/components/Icons';
export interface TemplateCard {
  name: 'string';
  templateId: number;
  templateView: string;
}

export const CreateWebsiteDrawer = NiceModal.create(
  ({ previewOnly }: { previewOnly: boolean }) => {
    const canAccessHomepage =
      AuthorizationService.hasPermission('canAccessHomepage');
    const {
      modal,
      setSelectedTemplateId,
      createHandler,
      selectedTemplateId,
      onClose,
      isCreationInProgress,
      getWebsiteTemplates,
    } = useCreateWebsite();
    const hasHomePagePackage = AuthorizationService.hasPackage('homepage');
    const hasStudio = AuthorizationService.hasPackage('studio');
    const canCreateWebsite = hasHomePagePackage && !previewOnly;
    const organizationDioceseId = cdApp.organization.dioceseId;

    return (
      <Drawer
        {...antdDrawerV5(modal)}
        title={
          !previewOnly
            ? getTextCatalog.getString('Create new website')
            : getTextCatalog.getString('Website templates')
        }
        width="70%"
        bodyStyle={{ padding: '0px' }}
        destroyOnClose
        onClose={onClose}
        footer={
          <Space style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={onClose}>
              {getTextCatalog.getString('Cancel')}
            </Button>
            {canCreateWebsite ? (
              <Button
                loading={isCreationInProgress}
                disabled={
                  !selectedTemplateId ||
                  isCreationInProgress ||
                  !canAccessHomepage
                }
                type="primary"
                onClick={createHandler}
              >
                {getTextCatalog.getString('Create')}
              </Button>
            ) : undefined}
          </Space>
        }
      >
        <Layout
          style={{ padding: '24px 24px 8px', overflow: 'auto', height: '100%' }}
        >
          {isCreationInProgress && (
            <CdrSpinner
              tipLabel={gettextCatalog.getString(
                'Creating your website. This may take up to 2 minutes.'
              )}
              width={70}
            />
          )}
          <SelectableCards
            showBorderedCover
            loading={false}
            dataSource={getWebsiteTemplates().map((template) => {
              const isSelectable = canCreateWebsite
                ? !template.requiresStudio || hasStudio
                : false;
              return {
                ...template,
                isSelectable,
                screenshotUrl: `https://assets.churchdesk.com/websiteTemplates/v2/${
                  template.title
                }-${template.language.toUpperCase()}.png?class=large`,
                title:
                  organizationDioceseId === template.dioceseId ? (
                    <Space>
                      {template.title}
                      <Tag
                        color="#27ae60"
                        icon={<CdNewWebsite />}
                        onClick={() =>
                          isSelectable && setSelectedTemplateId(template.id)
                        }
                      >
                        {gettextCatalog.getString(
                          'Recommended by {{ title }}',
                          { title: template.title }
                        )}
                      </Tag>
                    </Space>
                  ) : (
                    template.title
                  ),
                description: (
                  <Space direction="vertical">
                    <Button
                      type="link"
                      href={`https://${template.subdomain}.churchdesk.site/`}
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      {gettextCatalog.getString('See a live preview')}
                    </Button>
                    {template.requiresStudio && !hasStudio && (
                      <Alert
                        message={gettextCatalog.getString(
                          'You must have the Studio package in your plan to use this template'
                        )}
                        type="warning"
                      />
                    )}
                  </Space>
                ),
              } as ISelectableCardItem;
            })}
            onSelect={setSelectedTemplateId}
            value={selectedTemplateId}
            emptyText={
              <CdrEmptyState
                title={gettextCatalog.getString('No templates found')}
              />
            }
          />
        </Layout>
      </Drawer>
    );
  }
);
