import React from 'react';
import { useRecoilValue } from 'recoil';
import { Row, Alert } from 'antd';
import { isEmpty } from 'lodash';
import { Moment } from 'moment';

import AssignedIntentions from './AssignedIntentions';
import UnassignedIntentions from './UnassignedIntentions';

import {
  IntentionFeesQuery,
  MaxNumberOfPrimaryIntentionsQuery,
} from '@/react/calendar/event-details/store/intentions';
import getTextCatalog from '@/react/services/I18nService';

const IntentionsListView = ({
  eventStartDate,
  eventTitle,
  churchIds,

  categoryIds,
  rotasInfoNeededForIntentions,
  eventId,
  extraData,
  setExtraData,
}: {
  eventStartDate: Moment;
  eventTitle: string;
  churchIds: number[];
  categoryIds: number[];
  rotasInfoNeededForIntentions: { taskId: number; noOfAssigned: number }[];
  eventId: number;
  extraData: any;
  setExtraData: (value) => void;
}) => {
  // get the intentionfees type
  const intentionFees = useRecoilValue(IntentionFeesQuery);

  const maxNumberOfPrimaryIntentions = useRecoilValue(
    MaxNumberOfPrimaryIntentionsQuery({
      categoryIds,
      rotas: rotasInfoNeededForIntentions,
      calendarId: eventId,
    })
  );
  return (
    <Row>
      <Row>
        {isEmpty(intentionFees) && (
          <Alert
            message={getTextCatalog.getString(
              'No intentions can be created for this event because its categories do not match any of the categories associated to the active intention types.'
            )}
            type="info"
            showIcon
          />
        )}
      </Row>
      <Row style={{ width: '100%' }}>
        <AssignedIntentions
          eventTitle={eventTitle}
          eventStartDate={eventStartDate}
          maxNumberOfPrimaryIntentions={maxNumberOfPrimaryIntentions}
          setExtraData={setExtraData}
        />
      </Row>
      <Row style={{ width: '100%' }}>
        <UnassignedIntentions
          eventTitle={eventTitle}
          eventStartDate={eventStartDate}
          churchIds={churchIds}
          maxNumberOfPrimaryIntentions={maxNumberOfPrimaryIntentions}
          extraData={extraData}
          setExtraData={setExtraData}
          categoryIds={categoryIds}
        />
      </Row>
    </Row>
  );
};

export default IntentionsListView;
