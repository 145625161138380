import { Tag } from 'antd';
import React from 'react';

import gettextCatalog from '@/react/services/I18nService';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const getStaticsLabel = (type: string) => {
  switch (type) {
    case 'delivered':
      return gettextCatalog.getString('Delivered');
    case 'sending':
      return gettextCatalog.getString('Sending');
    case 'MissingEmailError':
      return gettextCatalog.getString('Missing email address');
    case 'UnsubscribedAllSMSError':
      return gettextCatalog.getString('Unsubscribed from all SMS');
    case 'EmailStatusError':
      return gettextCatalog.getString('Email is blocked');
    case 'EmailBlockedError':
      return gettextCatalog.getString('Email is blocked');
    case 'MissingPhoneError':
      return gettextCatalog.getString('Missing phone number');
    case 'UnsubscribedAllEmailError':
      return gettextCatalog.getString('Unsubscribed from all email');
    case 'UnsubscribedFromSegmentError':
      return gettextCatalog.getString('Unsubscribed from this list');
    case 'DefaultConsentNotConfirmed':
      return gettextCatalog.getString('Default consent is not confirmed');
    case 'DuplicateMessageError':
      return gettextCatalog.getString('Duplicate message');
    case 'bounced':
      return gettextCatalog.getString('Bounced');
    case 'spam':
      return gettextCatalog.getString('Spam');
    case 'failed':
      return gettextCatalog.getString('Failed');
  }
};

export const emailSent = (type: string) => {
  switch (type) {
    case 'MissingEmailError':
    case 'UnsubscribedAllSMSError':
    case 'EmailStatusError':
    case 'EmailBlockedError':
    case 'MissingPhoneError':
    case 'UnsubscribedAllEmailError':
    case 'UnsubscribedFromSegmentError':
    case 'DuplicateMessageError':
    case 'DefaultConsentNotConfirmed':
      return false;
    default:
      return true;
  }
};

export const StatisticsStatus = ({
  type,
  onClick,
}: {
  type: string;
  onClick?: () => void;
}) => {
  switch (type) {
    case 'delivered':
      return <Tag color="success">{getStaticsLabel(type)}</Tag>;
    case 'sending':
    case 'MissingEmailError':
    case 'MissingPhoneError':
    case 'undelivered':
      return <Tag>{getStaticsLabel(type)}</Tag>;
    case 'UnsubscribedAllSMSError':
    case 'UnsubscribedAllEmailError':
    case 'UnsubscribedFromSegmentError':
    case 'DefaultConsentNotConfirmed':
      return <Tag color="warning">{getStaticsLabel(type)}</Tag>;
    case 'EmailStatusError':
    case 'EmailBlockedError':
      return <Tag color="error">{getStaticsLabel(type)}</Tag>;
    case 'DuplicateMessageError':
      return (
        <Tag style={{ cursor: 'pointer' }} onClick={onClick} color="processing">
          {getStaticsLabel(type)}
        </Tag>
      );
    case 'bounced':
      return (
        <CdTooltip
          title={gettextCatalog.getString(
            'This message could not be delivered to the recipient. Please ensure the email is correct and still in use.'
          )}
        >
          <Tag color="error">{gettextCatalog.getString('Bounced')}</Tag>
        </CdTooltip>
      );
    case 'spam':
      // warning
      return (
        <CdTooltip
          title={gettextCatalog.getString(
            'This message has been marked as spam.'
          )}
        >
          <Tag color="warning">{gettextCatalog.getString('Spam')}</Tag>
        </CdTooltip>
      );
    case 'failed':
      return <Tag color="error">{gettextCatalog.getString('Failed')}</Tag>;

    default:
      if (!type) {
        return <Tag>{getStaticsLabel('sending')}</Tag>;
      } else {
        return (
          <Tag>
            {gettextCatalog.getString('N/A')} ({type})
          </Tag>
        );
      }
  }
};
