import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
  AnniversaryListQuery,
  AnniversaryTypeListQuery,
} from '../store/anniversary';

export default function useAnniversary() {
  const [anniversaryType, setAnniversaryType] = useState<string>('birthday');
  const anniversaryListQuery = AnniversaryListQuery({ type: anniversaryType });

  const handleAnniversaryTypeChange = useCallback((type) => {
    setAnniversaryType(type);
  }, []);
  const anniversaryTypes = useRecoilValue(AnniversaryTypeListQuery);

  return {
    anniversaryType,
    anniversaryListQuery,
    handleAnniversaryTypeChange,
    anniversaryTypes,
    setAnniversaryType,
  };
}
