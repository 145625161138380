import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import gettextCatalog from '../../services/I18nService';
import {
  AbsencesActions,
  AbsenceSelectors,
} from '../store/absences/absencesSlice';

import { FilterListItem } from './FilterItem';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

interface DisplayFilterItem {
  name: string;
  groupId: number;
  isDisabled: boolean;
}

export const getClassName = (isSelected: boolean, color: number): string =>
  `bullet color-${color || 0} ${isSelected ? 'bullet-full' : 'bullet-empty'}`;

const AbsenceItem = (props: DisplayFilterItem) => {
  const { name, groupId, isDisabled } = props;
  const dispatch = useDispatch();
  const isSelected = useSelector(
    AbsenceSelectors.isGroupAbsenceActive(groupId)
  );
  const onAbsenceClick = useCallback(
    () => !isDisabled && dispatch(AbsencesActions.toggleGroupAbsence(groupId)),
    [isDisabled, groupId, dispatch]
  );
  const content = (
    <FilterListItem
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      isDisabled={isDisabled}
    >
      <div className="bullet-wrapper">
        <div
          className="bullet-label"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onAbsenceClick();
          }}
        >
          <span className={getClassName(isSelected, 4)} />
          <div className="label">
            <span className="name">{name}</span>
          </div>
        </div>
      </div>
    </FilterListItem>
  );
  if (isDisabled) {
    return (
      <CdTooltip
        title={gettextCatalog.getString(
          'This calendar cannot be enabled for this view'
        )}
      >
        {content}
      </CdTooltip>
    );
  }
  return content;
};

export default AbsenceItem;
