'use strict';

const uuidRegex =
  '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}';

const checkAccessToContributions = (
  $state,
  Authorization,
  StripeService,
  cdApp
) => {
  'ngInject';

  if (!StripeService.isCountrySupported(cdApp.organization.countryIso2)) {
    return $state.target('app.private.contributions.default', {
      errorCode: 'COUNTRY_NOT_SUPPORTED',
    });
  }
  if (!Authorization.hasPackage('contributions')) {
    return $state.target('app.private.contributions.default', {
      errorCode: 'PACKAGE_NOT_ENABLED',
    });
  }
  if (!Authorization.hasPermission('canAccessContributions')) {
    return $state.target('app.private.contributions.default', {
      errorCode: 'ACCESS_DENIED',
    });
  }
};
checkAccessToContributions.$inject = [
  '$state',
  'Authorization',
  'StripeService',
  'cdApp',
];

angular.module('cdApp').config([
  '$stateProvider',
  ($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('app.private.contributions', {
        abstract: true,
        url: '/contributions',
        template: `
          <cd-app-nav class="app-nav"></cd-app-nav>
          <div class="app-view" ui-view></div>
        `,
        lazyLoad: ($transition$) => {
          const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');

          return import(
            /* webpackChunkName: "contributions.module" */ './contributions.module.js'
          )
            .then((mod) => $ocLazyLoad.load(mod.default))
            .catch((err) => console.error(err));
        },
      })
      .state('app.private.contributions.default', {
        url: '',
        component: 'cdContributions',
        params: {
          errorCode: null,
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Contributions');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],

          errorCode: ['$stateParams', ($stateParams) => $stateParams.errorCode],
        },

        onEnter: [
          '$state',
          'errorCode',
          function ($state, errorCode) {
            if (!errorCode) {
              return $state.target('app.private.contributions.projects.list');
            }
          },
        ],
      })

      /**
       * Projects
       */ .state('app.private.contributions.projects', {
        abstract: true,
        url: '/projects',
        template: '<div class="app-view" ui-view> </div>',
        onEnter: checkAccessToContributions,
      })
      .state('app.private.contributions.projects.list', {
        url: '',
        component: 'cdContributionsProjectListState',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Projects');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToContributions,
      })
      .state('app.private.contributions.projects.view', {
        url: `/{id:${uuidRegex}}`,
        component: 'cdProjectDetails',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            function (gettextCatalog, routingHelpers) {
              const base = 'Project Detail';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Projects')
                ),
              };
            },
          ],

          projectId: [
            '$stateParams',
            function ($stateParams) {
              return $stateParams.id;
            },
          ],
        },
      })
      .state('app.private.contributions.projects.create', {
        url: '/new?type&step',
        component: 'cdContributionsProjectFormState',
        params: {
          type: {
            value: 'donation',
          },

          step: {
            value: 'simple',
            dynamic: true,
          },
        },

        resolve: {
          peopleLists: [
            'PeopleSegments',
            function (PeopleSegments) {
              return PeopleSegments.getPublicNewsletterLists().$promise;
            },
          ],

          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Create Project');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],

          project: [
            '$stateParams',
            'Projects',
            function ($stateParams, Projects) {
              return Projects.init($stateParams.type || 'payment');
            },
          ],
        },
      })
      .state('app.private.contributions.projects.edit', {
        url: `/{id:${uuidRegex}}/edit?step`,
        component: 'cdContributionsProjectFormState',
        params: {
          step: {
            value: 'simple',
            dynamic: true,
          },
        },
        resolve: {
          peopleLists: [
            'PeopleSegments',
            function (PeopleSegments) {
              return PeopleSegments.getPublicNewsletterLists().$promise;
            },
          ],
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Edit Project');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
          project: [
            '$stateParams',
            'Projects',
            function ($stateParams, Projects) {
              return Projects.get({ id: $stateParams.id }).$promise;
            },
          ],
        },
      })

      /**
       * Others
       */ .state('app.private.contributions.contributionList', {
        url: '/all',
        component: 'cdContributionPayments',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Contributions');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToContributions,
      })
      .state('app.private.contributions.transferList', {
        url: '/transfers',
        component: 'transfersPage',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Transfers');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToContributions,
      })
      .state('app.private.contributions.widget', {
        url: '/widget',
        component: 'cdrWidgetDesign',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Widget');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
        onEnter: checkAccessToContributions,
      })
      .state('app.private.contributions.terminals', {
        url: '/terminals',
        component: 'terminalsWidget',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Terminals');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
        onEnter: checkAccessToContributions,
      })
      .state('app.private.contributions.terminalDetails', {
        url: '/terminals/:id',
        component: 'terminalDetails',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Terminals');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
          terminalId: [
            '$stateParams',
            function ($stateParams) {
              return $stateParams.id;
            },
          ],
        },
        onEnter: checkAccessToContributions,
      });
  },
]);
