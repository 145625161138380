import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';

import { Consent } from '../types/consent.type';
import { ConsentTypeQuery } from '../store/consentState';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import gettextCatalog from '@/react/services/I18nService';

const useConsentStatement = () => {
  const refreshAllConsentType = useRecoilRefresher(ConsentTypeQuery);
  const createStatement = useRecoilCallback(
    () => async (body: object) => {
      const response = await mainApi.post<Consent>(
        `/people/consent-types`,
        body
      );
      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      } else {
        refreshAllConsentType();
        NotificationService.notifySuccess(
          gettextCatalog.getString('Statement was created!')
        );
      }
    },
    [refreshAllConsentType]
  );

  const editStatement = useRecoilCallback(
    () => async (id: string, body: object) => {
      const response = await mainApi.put<Consent>(
        `/people/consent-types/${id}`,
        body
      );
      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      } else {
        NotificationService.notifySuccess(
          gettextCatalog.getString('Statement updated!')
        );
      }
      refreshAllConsentType();
    },
    [refreshAllConsentType]
  );

  const deleteStatement = useRecoilCallback(
    () => async (id: string) => {
      const response = await mainApi.delete<Consent>(
        `/people/consent-types/${id}`
      );
      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      } else {
        NotificationService.notifySuccess(
          gettextCatalog.getString('Statement deleted!')
        );
        refreshAllConsentType();
      }
    },
    [refreshAllConsentType]
  );

  return {
    createStatement,
    editStatement,
    deleteStatement,
    refreshAllConsentType,
  };
};

export default useConsentStatement;
