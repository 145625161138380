require('./accordion/accordion.component');
require('./add-group-member/add-group-member.component');
require('./edit-billing-customer/edit-billing-customer.component');
require('./advanced-filter');
require('./advanced-pagination/advanced-pagination.component');
require('./announcements');
require('./app-menu/app-menu.component');
require('./app-nav/app-nav.component');
require('./billing-information/billing-information.component');
require('./cd-comments/cd-comments.component');
require('./cd-comments-form/cd-comments-form.component');
require('./cd-dynamic-progressbar/cd-dynamic-progressbar.component');
require('./cd-statistic/cd-statistic.component');
require('./contribution-status/contribution-status.component');
require('./contribution-feed/contribution-feed.component');
require('./connect-to-facebook/connect-to-facebook.component');
require('./connect-to-facebook/modals/select-facebook-page.component');
require('./column-manager');
require('./create-content');
require('./create-event-task/create-event-task.component');
require('./create-folder/create-folder.component');
require('./create-group/create-group.component');
require('./create-group-message/create-group-message.component');
require('./date-box/date-box.component');
require('./date-interval-filter/date-interval-filter.component');
require('./document-viewer/document-viewer.component');
require('./dropdown-filter/dropdown-filter.component');
require('./edit-recurring-modal/edit-recurring-modal.component');
require('./empty-state/empty-state.component');
require('./error-state/error-state.component');
require('./feature-toggle/feature-toggle.component');
require('./file-uploader/file-uploader.component');
require('./file-uploader-modal/file-uploader-modal.component');
require('./flex-alert/flex-alert.component');
require('./font-picker/font-picker.component');
require('./forgot-password/forgot-password.component');
require('./image-crop/image-crop.component');
require('./image-crop-modal/image-crop-modal.component');
require('./image-drop-area/image-drop-area.component');
require('./image-picker');
require('./input-helper/input-helper');
require('./input-label/input-label.component');
require('./input-search-pages/input-search-pages.component');
require('./invitation-response/invitation-response.component');
require('./invitation-response-button/invitation-response-button.component');
require('./landing-page/landing-page.component');
require('./legal-document-modal/legal-document-modal.component');
require('./legal-documents/legal-documents.component');
require('./link-it/plugin');
require('./address-select/address-select.component');
require('./loader/loader.component');
require('./login-form/login-form.component');
require('./login-page/login-page.component');
require('./login-prompt/login-prompt.component');
require('./page-header/page-header.component');
require('./page-statistics/page-statistics.component');
require('./password-requirements/password-requirements.component');
require('./people-custom-fields-data/people-custom-fields-data.component');
require('./register-page/register-page.component');
require('./repeat');
require('./resource-address-select/resource-address-select.component');
require('./rotas-table');
require('./select-folder/select-folder.component');
require('./select-or-create-user/select-or-create-user.component');
require('./share-on-facebook/share-on-facebook.component');
require('./share-on-facebook-modal/share-on-facebook-modal.component');
require('./shared-context-root/shared-context-root.component');
require('./shifts-list/shifts-list.component');
require('./shifts-view-note/shifts-view-note.controller');
require('./simple-modal/simple-modal.component');
require('./steps/steps.component');
require('./task-assign-user/task-assign-users.component');
require('./text-avatar/text-avatar.component');
require('./text-truncater/text-truncater.component');
require('./update-organization-email/update-organization-email.component');
require('./update-person-tags-modal/update-person-tags-modal.component');
require('./update-user-phone/update-user-phone.component');
require('./user-organizations/user-organizations.component');
require('./widget-manager');
require('./resource-select/resource-select.component');
require('./new-multi-parish-modal/new-multi-parish-modal.component');
require('./upload-files/cdr-upload-files');
require('./legal-popup/legal-popup.component');
require('./cdr-sales-button');
require('./cdr-masquerade-tag');
require('./cd-unsafe-html.component');
