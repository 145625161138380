import { atom, selector, selectorFamily } from 'recoil';

import { ApiSearchResult, mainApi } from '../../api';
import { handleError } from '../../services/ErrorHandlingService';
import {
  fileUploadTypes,
  SafeguardingDetailsInterface,
  SafeguardingListInterface,
  SafeguardingListQueryInterface,
} from '../types/SafeguardingTypes';
import { UnPagedTableApiSearchResult } from '../../shared/components/cd-unpaged-table/types';

export const SafeGuardingQuery = selector<SafeguardingListQueryInterface>({
  key: 'SafeGuardingQuery',
  get: async () => {
    // this state is ised to get the safeguarding access.
    const response = await mainApi.get<SafeguardingListQueryInterface>(
      `/safeguard/v2/users`,
      { limit: 10, search: '', filter: 'all' }
    );
    if (!response.ok) {
      handleError(response);
      return;
    }
    return response.data;
  },
});
export interface SafeguardingSearchParams extends Record<string, any> {
  limit: number;
  cursor: string;
}
/*
 * SafeguardingListCache is used to refresh the list of safeguarding users
 * It will refresh the cache.
 */
export const SafeguardingListCache = atom<number>({
  key: 'SafeguardingListCache',
  default: 0,
});

export const SafeguardingSearchParamAtom = atom({
  key: 'SafeguardingSearchParamAtom',
  default: '',
});

export const SafeGuardingListQuery = selectorFamily<
  UnPagedTableApiSearchResult<SafeguardingListInterface>,
  SafeguardingSearchParams
>({
  key: 'SafeGuardingListQuery',
  get:
    ({ extraParams, cursor, limit }: SafeguardingSearchParams) =>
    async ({ get }) => {
      const search = get(SafeguardingSearchParamAtom);
      const { filter } = extraParams;
      get(SafeguardingListCache);
      const response = await mainApi.get<SafeguardingListQueryInterface>(
        `/safeguard/v2/users`,
        { cursor, limit, search, filter: filter }
      );
      if (!response.ok) {
        handleError(response);
        return {
          items: [],
          cursor: undefined,
        };
      }
      return {
        items: response?.data?.items.map((user) => ({
          ...user,
          id: user.userId,
        })),
        cursor: response?.data?.cursor,
      };
    },
});

export const SafeGuardingDetailsQuery = selectorFamily<
  SafeguardingDetailsInterface,
  number
>({
  key: 'SafeGuardingDetailsQuery',
  get: (userId: number) => async () => {
    const response = await mainApi.get<SafeguardingDetailsInterface>(
      '/safeguard/users/' + userId
    );
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});

export const TableDetailsSelector = selectorFamily<
  ApiSearchResult<any>,
  { fileType: string; userId: number }
>({
  key: 'TableDetailsSelector',
  get:
    ({ userId, fileType }) =>
    async ({ get }) => {
      const userDetails = get(SafeGuardingDetailsQuery(userId));
      if (userDetails === null) return null;
      switch (fileType) {
        case fileUploadTypes.CERTIFICATE_OF_CONDUCT:
          return {
            items: userDetails?.certificatesOfConduct,
            total: userDetails?.certificatesOfConduct?.length,
          };

        case fileUploadTypes.COURSE:
          return {
            items: userDetails?.courses,
            total: userDetails?.courses?.length,
          };

        case fileUploadTypes.DOCUMENT:
          return {
            items: userDetails?.documents,
            total: userDetails?.documents?.length,
          };
        default:
          return { items: [], total: 0 };
      }
    },
});
