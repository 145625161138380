'use strict';

import { loadStripeDynamically } from '@/app/contributions/shared/load-stripe-dynamically';
class ContributionsMvrFormController {
  constructor($state, cdApp, $window) {
    'ngInject';
    this.$state = $state;
    this.cdApp = cdApp;
    this.$window = $window;
  }

  $onInit() {
    const { cdApp } = this;
    this.isMasquerading = cdApp.me.masquerading;

    loadStripeDynamically(this.$window);
  }

  /**
   * Save the minimum verification requirements
   */
  onMvrSubmitted() {
    this.$state.go('app.private.settings.mvrForm', {}, { reload: true });
  }

  /**
   * Get a link to the stripe account.
   */
  getStripeLink() {
    const { stripeAccount } = this;
    if (this.cdApp.config.environment === 'production') {
      return `https://dashboard.stripe.com/connect/accounts/${stripeAccount.id}`;
    } else {
      return `https://dashboard.stripe.com/test/connect/accounts/${stripeAccount.id}`;
    }
  }
}
ContributionsMvrFormController.$inject = ['$state', 'cdApp', '$window'];

angular.module('cdApp.settings').component('cdContributionsMvrState', {
  templateUrl: '@/app/settings/mvr-form/mvr-form.component.html',
  controller: ContributionsMvrFormController,
  bindings: {
    accountData: '<',
    stripeAccount: '<',
  },
});
