import React, { Suspense } from 'react';
import { Form, Select, Skeleton } from 'antd';
import { capitalize, orderBy } from 'lodash';

import useSognDK from './useSognDK';

import getTextCatalog from '@/react/services/I18nService';

const CdSognDkForm = ({ disabled }: { disabled: boolean }) => {
  const { sognDK } = useSognDK();
  const contributor = Form.useWatch('contributor');
  const locationName = Form.useWatch(['resourcesLocation', 'locationName']);

  return (
    <>
      <Form.Item
        name={['sogndk', 'categoryId']}
        label={getTextCatalog.getString('Sogn.dk category')}
      >
        <Select
          placeholder={getTextCatalog.getString('Choose a sogn.dk category')}
          allowClear
          disabled={disabled}
          options={sognDK.categoryTypes.map((type) => ({
            label: capitalize(type.name),
            options: orderBy(
              type.categories.map((category) => {
                let sognDkCategoryName = category.sognDkCategoryName;
                if (
                  [
                    'Bisættelse',
                    'Begravelse',
                    'Vielse',
                    'Velsignelse',
                    'Dåb',
                    'Anden kirkelig handling',
                  ].includes(category.sognDkCategoryName)
                ) {
                  sognDkCategoryName =
                    category.sognDkCategoryName + ' (ikke offentlig)';
                }
                return {
                  value: category.sognDkCategoryId,
                  label: sognDkCategoryName,
                };
              }),
              ['label']
            ),
          }))}
        />
      </Form.Item>
      <Form.Item
        name={['sogndk', 'sognId']}
        label={getTextCatalog.getString('Parish')}
      >
        <Select
          placeholder={getTextCatalog.getString('Choose a parish')}
          allowClear
          options={sognDK.parishes.map((parish) => ({
            label: parish.name,
            value: parish.id,
          }))}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        name={['sogndk', 'churchId']}
        label={getTextCatalog.getString('Church')}
      >
        <Select
          placeholder={getTextCatalog.getString('Choose a church')}
          allowClear
          disabled={disabled}
          options={[
            ...sognDK.parishes.map((parish) => ({
              label: parish.name,
              options: parish.churchs.map((church) => ({
                value: church.sognDkChurchId,
                label: church.sognDkChurchName,
              })),
            })),
            {
              label: getTextCatalog.getString('Location').toUpperCase(),
              options: [
                {
                  value: -1,
                  label: locationName
                    ? getTextCatalog.getString(
                        '{{ locationName }} from the field "Location name"',
                        {
                          locationName,
                        }
                      )
                    : getTextCatalog.getString(
                        'Add location in the field "Location name"'
                      ),
                },
              ],
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        name={['sogndk', 'pastorId']}
        label={getTextCatalog.getString('Pastor')}
      >
        <Select
          placeholder={getTextCatalog.getString('Choose a priest')}
          allowClear
          disabled={disabled}
          options={[
            ...sognDK.parishes.map((parish) => ({
              label: parish.name,
              options: parish.priests.map((church) => ({
                value: church.sognDkPastorId,
                label: church.sognDkPastorName,
              })),
            })),
            {
              label: getTextCatalog.getString('Contributor').toUpperCase(),
              options: [
                {
                  value: -1,
                  label:
                    contributor?.trim().length > 0
                      ? getTextCatalog.getString(
                          '{{ contributor }} from the field "Contributor"',
                          {
                            contributor,
                          }
                        )
                      : getTextCatalog.getString(
                          'Add the name for the priest in the field "Contributor"'
                        ),
                },
              ],
            },
          ]}
        />
      </Form.Item>
    </>
  );
};

export const CdSognDkFormWrapper = (props: { disabled: boolean }) => (
  <Suspense
    fallback={
      <>
        <Form.Item label={getTextCatalog.getString('Sogn.dk category')}>
          <Skeleton.Input active block />
        </Form.Item>
        <Form.Item label={getTextCatalog.getString('Parish')}>
          <Skeleton.Input active block />
        </Form.Item>
        <Form.Item label={getTextCatalog.getString('Church')}>
          <Skeleton.Input active block />
        </Form.Item>
        <Form.Item label={getTextCatalog.getString('Pastor')}>
          <Skeleton.Input active block />
        </Form.Item>
      </>
    }
  >
    <CdSognDkForm disabled={props.disabled} />
  </Suspense>
);
