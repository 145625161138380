'use strict';

class TextTruncater {
  constructor(_, gettextCatalog, $uibModal) {
    'ngInject';

    this._ = _;
    this.gettextCatalog = gettextCatalog;
    this.$uibModal = $uibModal;
  }

  $onInit() {
    const defaultText = this.gettextCatalog.getString('Read more');

    this.text = this._.trim(this.text);
    this.length = this.length || 60;
    this.readMoreText = this.readMoreText || defaultText;
    this.modalTitle = this.modalTitle || defaultText;
  }

  isTruncatable() {
    return this.text.length > this.length;
  }

  readMore() {
    if (this.inline) {
      this.length = this._.size(this.text);
    } else {
      this.$uibModal.open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.modalTitle,
          body: () => this.text,
          options: {
            hideConfirmButton: true,
            closeButtonText: this.gettextCatalog.getString('Close'),
          },
        },
      });
    }
  }
}
TextTruncater.$inject = ['_', 'gettextCatalog', '$uibModal'];
angular.module('cdApp.shared').component('cdTextTruncater', {
  templateUrl:
    '@/app/shared/components/text-truncater/text-truncater.component.html',
  bindings: {
    text: '<',
    length: '<',
    modalTitle: '<',
    readMoreText: '<',
    inline: '<',
  },

  controller: TextTruncater,
});
