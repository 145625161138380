import moment from 'moment';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Dropdown, Space } from 'antd';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import { Column } from '../../../shared/models/table';
import gettextCatalog from '../../../services/I18nService';
import { IntentionFeeRecipient } from '../../models/fee';
import { deleteIntentionFeeRecipient } from '../../redux/intention-fees/Actions';

import { openIntentionRecipientModal } from './IntentionRecipient.modal';

import {
  CdAngleDown,
  CdDeleteIcon,
  CdEditIcon,
} from '@/react/shared/components/Icons';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

export const IntentionRecipientColumns = (): Partial<Column>[] => {
  const dispatch = useDispatch();

  const deleteFeeRecipient = (intentionFeeRecipient: IntentionFeeRecipient) => {
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Delete Recipient'),
      message: gettextCatalog.getString(
        'Do you want to delete the recipient {{name}}?',
        {
          name: intentionFeeRecipient.name,
        }
      ),
      onOk() {
        dispatch(deleteIntentionFeeRecipient(intentionFeeRecipient.id));
      },
    });
  };

  const menuItems = (intentionFeeRecipient) => [
    {
      key: 'edit',
      label: gettextCatalog.getString('Edit'),
      onClick: () => openIntentionRecipientModal(intentionFeeRecipient),
      icon: <CdEditIcon />,
    },
    {
      key: 'delete',
      label: gettextCatalog.getString('Delete'),
      onClick: () => deleteFeeRecipient(intentionFeeRecipient),
      icon: <CdDeleteIcon />,
    },
  ];

  return [
    {
      title: gettextCatalog.getString('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: gettextCatalog.getString('Weiterleitungsempfänger'),
      dataIndex: 'isForwardingRecipient',
      key: 'isForwardingRecipient',
      width: 250,
      align: 'center',
      render: function columnRender(isForwardingRecipient: boolean) {
        return (
          <div>
            {isForwardingRecipient ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} />
            )}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Created at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: function columnRender(createdAt: Date) {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {moment(createdAt).format('ddd, ll')}
          </div>
        );
      },
    },
    {
      title: null,
      dataIndex: null,
      key: 'actionsMenu',
      fixed: 'right',
      render: function columnRender(
        intentionFeeRecipient: IntentionFeeRecipient
      ) {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Dropdown
              menu={{ items: menuItems(intentionFeeRecipient) }}
              trigger={['click']}
            >
              <Button size="small">
                <Space>
                  {gettextCatalog.getString('Actions')}
                  <CdAngleDown />
                </Space>
              </Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];
};
