import React from 'react';

import { getString } from '@/react/services/I18nService';

interface CdUnsafeHTMLProps {
  html: string;
}

export const CdUnsafeHTML: React.FC<CdUnsafeHTMLProps> = (props: {
  html: string;
}) => {
  if (!props.html) return null;
  // Create a placeholder for iframes
  const warningMessage = getString(
    'This ifame is disabled for security reasons. It works just fine on your website.'
  );
  const iframePlaceholder =
    '<div style="background: repeating-linear-gradient(45deg, #f0f2f5, #f0f2f5 10px, #ffffff 10px, #ffffff 20px); border: solid 1px #d4d4d5; padding: 16px; text-align: center;">' +
    warningMessage +
    '</div>';

  // Replace iframes with the placeholder
  const sanitizedHtml = props.html.replace(
    /<iframe[^>]*>([\s\S]*?)<\/iframe>/gi,
    iframePlaceholder
  );

  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
