import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileWord } from '@fortawesome/free-regular-svg-icons';
import { Typography } from 'antd';
import styled from 'styled-components';

import ElementWrapper from './ElementWrapper';

import gettextCatalog from '@/react/services/I18nService';

const { Paragraph, Text } = Typography;

const StyledParagraph = styled(Paragraph)`
  white-space: pre-wrap;
  max-height: 25vh;
  max-width: 250px;
  overflow-y: scroll;
  border: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 0 !important;

  .ant-typography-expand {
    float: right;
  }
  .ant-typography-collapse {
    display: flex;
    justify-content: flex-end;
    max-width: 250px;
  }
`;

const Caption: React.FC<{ caption: string }> = ({ caption }) => {
  const [typoParagraphSetting, setTypoParagraphSettingState] = useState({
    expand: false,
    counter: 0,
  });
  useEffect(() => {
    setTypoParagraphSettingState({
      expand: false,
      counter: !typoParagraphSetting.expand
        ? typoParagraphSetting.counter + 0
        : typoParagraphSetting.counter + 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caption]);
  const onExpand = () => {
    setTypoParagraphSettingState({
      expand: true,
      counter: !typoParagraphSetting.expand
        ? typoParagraphSetting.counter + 0
        : typoParagraphSetting.counter + 1,
    });
  };
  const onCollapse = () => {
    setTypoParagraphSettingState({
      expand: false,
      counter: !typoParagraphSetting.expand
        ? typoParagraphSetting.counter + 0
        : typoParagraphSetting.counter + 1,
    });
  };

  const renderParagraph = () => (
    <div key={typoParagraphSetting.counter}>
      <StyledParagraph
        key={typoParagraphSetting.counter}
        ellipsis={{ rows: 3, expandable: true, onExpand: onExpand }}
        style={{ maxHeight: '30vh', overflowY: 'scroll' }}
      >
        {caption}
      </StyledParagraph>
    </div>
  );

  return (
    <React.Fragment>
      {caption ? (
        <ElementWrapper>
          <FontAwesomeIcon icon={faFileWord} />
          <Text copyable={{ text: caption }} strong>
            {gettextCatalog.getString('Copy text')}
          </Text>
          {renderParagraph()}
          {typoParagraphSetting.expand && (
            <a className="ant-typography-collapse" onClick={onCollapse}>
              {gettextCatalog.getString('Hide')}
            </a>
          )}
        </ElementWrapper>
      ) : null}
    </React.Fragment>
  );
};

export default Caption;
