import { Form, Input, Radio, Space } from 'antd';
import React from 'react';

import {
  Content,
  ContentTypeSubtitle,
  Header,
} from '../privacy-policy/PrivacyPolicy';
import gettextCatalog from '../../../services/I18nService';
import Redactor from '../../../people/message-editor/shared/containers/Redactor';
import { ImprintType, InitialValues } from '../../hooks/useImprint';

export const Imprint = ({
  imprintForm,
  initialValues,
}: {
  imprintForm: any;
  initialValues: InitialValues;
}) => (
  <Content>
    <Form
      form={imprintForm}
      initialValues={initialValues}
      layout="vertical"
      style={{
        maxWidth: '800px',
        width: '100%',
      }}
    >
      <Form.Item
        name="contentType"
        label={gettextCatalog.getString('How will you create this imprint?')}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value="text">
              <Header>
                {gettextCatalog.getString(`Provide imprint statement as text`)}
              </Header>
              <ContentTypeSubtitle>
                {gettextCatalog.getString(
                  `You can provide the imprint statement here`
                )}
              </ContentTypeSubtitle>
            </Radio>
            <Radio value="link">
              <Header>
                {gettextCatalog.getString('Provide imprint statement as link')}
              </Header>
              <ContentTypeSubtitle>
                {gettextCatalog.getString(
                  '..or you can provide a link to your current imprint statement'
                )}
              </ContentTypeSubtitle>
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) =>
          getFieldValue('contentType') === ImprintType.TEXT ? (
            <Form.Item
              name="imprintText"
              label={gettextCatalog.getString('Full imprint statement')}
              style={{
                width: '100%',
              }}
              rules={[
                {
                  required: true,
                  validator(_, value: any) {
                    const textWithoutHtml = (value || '')
                      .replace(/(<([^>]+)>)/gi, '')
                      .replace(/\n/g, '')
                      .trim();
                    if (textWithoutHtml.length < 100) {
                      return Promise.reject(
                        new Error(
                          gettextCatalog.getString(
                            'Please provide a imprint statement with at least 100 characters'
                          )
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Redactor
                imagePluginEnabled={false}
                messagePlaceHolderEnabled={false}
              />
            </Form.Item>
          ) : (
            <Form.Item
              style={{ width: '100%' }}
              name="imprintLink"
              label={gettextCatalog.getString(
                'Alternatively you can provide link to your imprint statement page'
              )}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString(
                    'Please provide a link to your imprint statement'
                  ),
                },
                {
                  type: 'url',
                  message: gettextCatalog.getString(
                    'Please provide a valid url'
                  ),
                },
              ]}
            >
              <Input placeholder="https://...." />
            </Form.Item>
          )
        }
      </Form.Item>
    </Form>
  </Content>
);
