import moment from 'moment';

import { ColumnType } from '../../../shared/components/cd-table';
import { AnniversaryInterface } from '../../../calendar/types/anniversaryTypes';
import gettextCatalog from '../../../services/I18nService';
import { OrderType } from '../../../shared/models/table';

export const anniversaryColumns: ColumnType<AnniversaryInterface>[] = [
  {
    dataIndex: 'fullName',
    title: gettextCatalog.getString('Name'),
    defaultSortOrder: 'ascend',
    orderType: OrderType.STRING,
  },
  {
    dataIndex: ['anniversary', 'label'],
    title: gettextCatalog.getString('Event type'),
    defaultSortOrder: 'ascend',
    orderType: OrderType.STRING,
  },
  {
    dataIndex: ['anniversary', 'yearSince'],
    title: gettextCatalog.getString('Year since'),
    active: true,
  },
  {
    dataIndex: ['anniversary', 'date'],
    title: gettextCatalog.getString('Date'),
    active: true,
    render: (date: string) => moment(date).format('LL'),
  },
];
