// React, Ant
import { useEffect } from 'react';
import { ButtonProps, Space, Typography } from 'antd';
import React from 'react';

import { createCdModal, ModalType } from '../cd-modal-new/CdModal2';
import { CdWarningIcon } from '../Icons';

const Text = Typography.Text;

export type CdConfirmModalProps = {
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  icon?: JSX.Element;
  onOk: () => void;
  onCancel?: () => void;
  okText?: string | React.ReactNode;
  cancelText?: string;
  okButtonProps?: ButtonProps;
};

export const showConfirmModal = createCdModal<CdConfirmModalProps>(
  ({
    setModalProps,
    title,
    message,
    icon,
    onOk,
    onCancel,
    okText,
    okButtonProps,
    cancelText,
  }) => {
    useEffect(() => {
      if (setModalProps) {
        setModalProps({
          modalType: ModalType.POPUP,
          title,
          width: 500,
          onOk,
          onCancel,
          okText,
          okButtonProps,
          cancelText,
        });
      }
    }, [
      cancelText,
      okButtonProps,
      okText,
      onCancel,
      onOk,
      setModalProps,
      title,
    ]);

    const iconProps = {
      style: {
        fontSize: '32px',
      },
      type: 'warning' as 'warning' | 'error' | 'info' | 'success' | undefined,
      ...(icon?.props ? icon.props : {}),
    };

    return (
      <Space size="middle" align="start">
        {icon ? (
          React.cloneElement(icon, iconProps)
        ) : (
          <CdWarningIcon {...iconProps} />
        )}
        <Text>{message}</Text>
      </Space>
    );
  }
);

interface CdConfirmModalPropsDeprecated extends Partial<CdConfirmModalProps> {
  okType?: 'primary' | 'danger' | 'default';
  // TODO
  getContainer?: HTMLElement | (() => HTMLElement) | undefined;
}

export const showConfirmModalDeprecated = (
  props: CdConfirmModalPropsDeprecated
) => {
  if (props.okType === 'danger') {
    props.okButtonProps = { ...props.okButtonProps, danger: true };
  } else if (props.okType === 'primary') {
    props.okButtonProps = { ...props.okButtonProps, type: 'primary' };
  }

  // @ts-ignore
  showConfirmModal({ ...props });
};
