/* eslint-disable react/display-name */
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Avatar,
  Table,
  Space,
  Button,
  Dropdown,
  Menu,
  Col,
  Typography,
  Checkbox,
  Row,
  TreeSelect,
  Tag,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { showModal } from 'redux-saga-modal';
import {
  faUser,
  faUserLock,
  faCheck,
  faBan,
  faAngleDown,
  faUserPlus,
  faRedo,
  faEnvelope,
  faUserTimes,
  faUserEdit,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  concat,
  filter,
  find,
  get,
  intersection,
  isEmpty,
  isNumber,
  lowerCase,
  map,
  reduce,
  size,
  sortBy,
  truncate,
} from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { DataNode } from 'antd/lib/tree';

import { FetchUsers } from '../redux/actions';
import { getUsers, selectIsUsersLoading } from '../redux/selectors';
import AssignUserRolesModal from '../components/assign-user-roles/AssignUserRolesModal';
import MODAL_TYPES from '../redux/modal-types';
import { navigate } from '../../services/StateServiceFactory';
import gettextCatalog from '../../services/I18nService';
import {
  StyledSearch,
  TableToolbar,
} from '../../shared/antd/StyledAntdComponents';
import { User } from '../types/User.types';
import { useFetchRoles } from '../../organization/screens/manage-roles/hooks';
import AuthorizationService from '../../services/AuthorizationService';
import { roleSelectors } from '../../organization/store/roleSlice';
import { showUserTimeRegistrationModal } from '../components/UserTimeRegistrationModal';
import { showCreateUserModal } from '../components/CreateUserModal';
import { showTimeRegistrationLandingModal } from '../components/TimeRegistrationLandingModal';
import {
  removeUserFromCurrentOrganization,
  updateUserStatus,
} from '../services/user.service';

import { UserTimeRegistrationEnabled } from '@/react/shared/components/Icons';
import { useOrganization } from '@/react/organization/hooks/useOrganization';
import { sortBoolean } from '@/react/shared/sorter';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import NotificationService from '@/react/services/NotificationService';
import { showConfirmInputModal } from '@/react/shared/components/cd-confirm-input-modal/CdConfirmInputModal';
import cdApp from '@/react/config';

const { Link, Text } = Typography;

interface FilterStateInterface {
  active: boolean;
  blocked: boolean;
  search: string;
  roles: number[] | string[];
}

const defaultFilterState = {
  active: true,
  blocked: false,
  search: '',
  roles: [],
};

const UserListTable = ({ sendMessageModal, resendInvite }) => {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const isUsersLoading = useSelector(selectIsUsersLoading);
  const [filterState, setFilterState] =
    useState<FilterStateInterface>(defaultFilterState);
  const [tableData, setTableData] = useState([]);
  const hasUserAdminRole =
    AuthorizationService.hasPermission('canAdministerUsers');
  const launchModal = useCallback(
    (userId, userName) => {
      dispatch(showModal(MODAL_TYPES.USER_ROLES, { userId, userName }));
    },
    [dispatch]
  );
  const showChurchSelector = get(window, 'cdApp.showChurchSelector');
  useEffect(() => {
    dispatch(FetchUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { organizationSettings } = useOrganization();

  const toggleStatusModal = useCallback(
    (user) =>
      showConfirmModal({
        title:
          user.status === 0
            ? gettextCatalog.getString('Unblock user?')
            : gettextCatalog.getString('Block user?'),
        message: (
          <Space direction="vertical">
            {user.status === 0
              ? gettextCatalog.getString(
                  'Are you sure you want to unblock {{ user }}?',
                  {
                    user: user.name || user.email,
                  }
                )
              : gettextCatalog.getString(
                  'Are you sure you want to block {{ user }} from the system? They will no longer be able to:',
                  {
                    user: user.name || user.email,
                  }
                )}
            {user.status !== 0 && (
              <ul style={{ margin: 0 }}>
                <li>
                  {gettextCatalog.getString(
                    'Log in',
                    undefined,
                    "User blocked won't be able to"
                  )}
                </li>
                <li>
                  {gettextCatalog.getString(
                    'Access protected content',
                    undefined,
                    "User blocked won't be able to"
                  )}
                </li>
              </ul>
            )}
            {user.status === 0
              ? gettextCatalog.getString(
                  'When {{ user }} was blocked, all permissions and group memberships were removed. Everything will need to be manually added again.',
                  {
                    user: user.name || user.email,
                  }
                )
              : gettextCatalog.getString(
                  'The user will lose all permissions and will be removed from all the groups. Everything will need to be manually added again if the user is unblocked.',
                  undefined,
                  'Shown when a user is about to get blocked'
                )}
          </Space>
        ),
        onOk: () =>
          updateUserStatus(user.id, cdApp.organization.id, !user.status ? 1 : 0)
            .then(() => {
              NotificationService.notifySuccess(
                gettextCatalog.getString('The user status has been changed.')
              );
              dispatch(FetchUsers());
            })
            .catch(() =>
              NotificationService.notifyError(
                gettextCatalog.getString(
                  'The user status could not be changed.'
                )
              )
            ),
        okText:
          user.status === 0
            ? gettextCatalog.getString('Unblock')
            : gettextCatalog.getString('Block'),
        okButtonProps: {
          danger: user.status !== 0,
        },
      }),
    [dispatch]
  );

  const filterData = (newFilter: FilterStateInterface) => {
    const currentFilter = newFilter;
    if (isEmpty(users)) return;
    const filteredData = filter(
      users,
      (user) =>
        ((currentFilter.active && user.status === 1) ||
          (currentFilter.blocked && user.status === 0)) &&
        (lowerCase(user.email).indexOf(lowerCase(currentFilter.search)) > -1 ||
          lowerCase(user.name).indexOf(lowerCase(currentFilter.search)) > -1) &&
        (isEmpty(currentFilter.roles) ||
          !isEmpty(
            intersection<number | string>(user.roles, currentFilter.roles)
          ))
    );
    setTableData(filteredData);
    setFilterState(newFilter);
  };

  useEffect(() => {
    if (isEmpty(users)) return;
    filterData(filterState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const columns: ColumnsType<User> = useMemo(
    () => [
      {
        title: gettextCatalog.getString('Name'),
        dataIndex: 'name',
        key: 'name',
        visible: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
        render: (text, record) => (
          <Space>
            <Avatar
              icon={<FontAwesomeIcon icon={faUser} />}
              src={record.picture}
            ></Avatar>
            <Space size={0} direction="vertical">
              <a
                onClick={() =>
                  navigate('app.private.settings.users.detail', {
                    id: record.id,
                  })
                }
              >
                {truncate(text || record.email, { length: 30 })}
              </a>
              <div style={{ fontSize: '12px' }}>
                <Link
                  style={{ color: 'black' }}
                  href={`mailto:${record.email}`}
                >
                  {truncate(record.email, { length: 35 })}
                </Link>
              </div>
            </Space>
          </Space>
        ),
      },
      {
        title: gettextCatalog.getString('Status'),
        dataIndex: 'lastAccess',
        key: 'lastAccess',
        width: 200,
        visible: true,
        sorter: (a, b) =>
          moment(a.lastAccess).unix() - moment(b.lastAccess).unix(),
        render: (text, record) => {
          const statusLabel = (
            <Space>
              {record.status === 1 ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faBan} />
              )}
              {record.status === 1
                ? gettextCatalog.getString('Active')
                : gettextCatalog.getString('Blocked')}
            </Space>
          );
          return (
            <>
              {hasUserAdminRole ? (
                <a onClick={() => toggleStatusModal(record)}>{statusLabel}</a>
              ) : (
                statusLabel
              )}
              {record.status === 1 ? (
                <small style={{ display: 'block' }}>
                  {text ? (
                    moment(text).fromNow()
                  ) : record.editUser ? (
                    <>
                      {gettextCatalog.getString('Never logged in')}
                      <Button
                        size="small"
                        style={{ marginTop: 5 }}
                        onClick={() => resendInvite(record)}
                      >
                        <FontAwesomeIcon
                          icon={faRedo}
                          size="sm"
                          style={{ marginRight: 5 }}
                        />
                        <small>
                          {gettextCatalog.getString('Invite again')}
                        </small>
                      </Button>
                    </>
                  ) : (
                    gettextCatalog.getString('Never logged in')
                  )}
                </small>
              ) : null}
            </>
          );
        },
      },
      {
        title: gettextCatalog.getString('Roles'),
        key: 'permissions',
        visible: true,
        render: (text, record) => (
          <>
            {record.status === 1 ? (
              size(record.churchRoles) > 0 ||
              size(record.organizationRoles) > 0 ? (
                <Row wrap={false}>
                  <Col flex="auto">
                    <Space wrap size="small">
                      {map(
                        record.organizationRoles.concat(
                          sortBy(record.churchRoles, 'name')
                        ),
                        (role) => (
                          <Tag
                            key={`tag-${role.id}`}
                            style={{ marginRight: 0 }}
                          >
                            {role.name}
                          </Tag>
                        )
                      )}
                    </Space>
                  </Col>
                </Row>
              ) : (
                <Row wrap={false}>
                  <Col flex="auto">
                    {record.roles.length === 1
                      ? gettextCatalog.getString('1 permission')
                      : record.roles.length +
                        ' ' +
                        gettextCatalog.getString('permissions')}
                  </Col>
                </Row>
              )
            ) : (
              <Text>{gettextCatalog.getString('None')}</Text>
            )}
          </>
        ),
      },
      {
        title: gettextCatalog.getString('Parishes'),
        key: 'churches',
        visible: showChurchSelector,
        render: (text, record) => (
          <>
            <Row wrap={false}>
              <Col flex="auto">
                <Space wrap size="small">
                  {map(sortBy(record.churches, 'name'), (church) => (
                    <Tag key={church.id} style={{ marginRight: 0 }}>
                      {church.name}
                    </Tag>
                  ))}
                  {isEmpty(record.churches) ? (
                    <Text>{gettextCatalog.getString('None')}</Text>
                  ) : null}
                </Space>
              </Col>
            </Row>
          </>
        ),
      },
      {
        title: gettextCatalog.getString('Time registration'),
        key: 'timeRegistrationEnabled',
        dataIndex: 'timeRegistrationEnabled',
        align: 'center',
        visible: true,
        sorter: (a, b) =>
          sortBoolean(a.timeRegistrationEnabled, b.timeRegistrationEnabled),
        render: (data) => {
          if (data) {
            return <UserTimeRegistrationEnabled size="lg" />;
          }
        },
      },
      {
        title: '',
        key: 'action',
        visible: true,
        width: '100px',
        align: 'right',
        render: (text, record) => (
          <Dropdown
            menu={{
              items: [
                {
                  key: 0,
                  label: (
                    <Space>
                      <FontAwesomeIcon icon={faUserEdit} />
                      {gettextCatalog.getString('Edit')}
                    </Space>
                  ),
                  onClick: () =>
                    navigate('app.private.settings.users.edit', {
                      id: record.id,
                    }),
                  disabled: !record.editUser,
                },
                {
                  key: 1,
                  label: (
                    <Space>
                      <FontAwesomeIcon icon={faUserLock} />
                      {gettextCatalog.getString('Edit permissions')}
                    </Space>
                  ),
                  onClick: () => launchModal(record.id, record.name),
                  disabled: !record.editUser,
                },
                {
                  key: 3,
                  label: (
                    <Space>
                      <FontAwesomeIcon icon={faEnvelope} />
                      {gettextCatalog.getString('Send E-Mail')}
                    </Space>
                  ),
                  onClick: () => sendMessageModal(record),
                },
                !record.lastAccess &&
                  record.editUser &&
                  record.status === 1 && {
                    key: 4,
                    label: (
                      <Space>
                        <FontAwesomeIcon icon={faRedo} />
                        {gettextCatalog.getString('Invite again')}
                      </Space>
                    ),
                    onClick: () => resendInvite(record),
                  },
                hasUserAdminRole && {
                  key: 5,
                  label: (
                    <Space>
                      <FontAwesomeIcon icon={faUserClock} />
                      {gettextCatalog.getString('Manage time registration')}
                    </Space>
                  ),
                  onClick:
                    organizationSettings.timeRegistrationCap === 0
                      ? () => showTimeRegistrationLandingModal()
                      : () =>
                          showUserTimeRegistrationModal({
                            currentUserId: record.id,
                            userName: record.name,
                            timeRegistrationEnabled:
                              record.timeRegistrationEnabled,
                            initialTimeBalance: record.initialTimeBalance,
                            userEmail: record.email,
                          }),
                },
                { type: 'divider' },
                {
                  key: 2,
                  label: (
                    <Space>
                      {record.status === 0 ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        <FontAwesomeIcon icon={faBan} />
                      )}
                      {record.status === 0
                        ? gettextCatalog.getString('Unblock')
                        : gettextCatalog.getString('Block')}
                    </Space>
                  ),
                  onClick: () => toggleStatusModal(record),
                  disabled: !record.editUser,
                },
                record.status === 0 && {
                  key: 6,
                  label: (
                    <Space>
                      <FontAwesomeIcon icon={faUserTimes} />
                      {gettextCatalog.getString('Remove user')}
                    </Space>
                  ),
                  onClick: () =>
                    showConfirmInputModal({
                      title: gettextCatalog.getString('Remove user?'),
                      confirmType: 'text',
                      confirmLabel: gettextCatalog.getString(
                        "Enter the user's name ({{ name}})",
                        {
                          name: record.name || record.email,
                        }
                      ),
                      confirmWord: record.name || record.email,
                      okText: gettextCatalog.getString('Remove'),
                      message: (
                        <Space direction="vertical">
                          {gettextCatalog.getString(
                            'Are you sure you want to remove {{ name }}? This action cannot be undone.',
                            {
                              name: record.name || record.email,
                            }
                          )}
                          {gettextCatalog.getString(
                            "The following user's data will be deleted. Remember to export the data before removing the user."
                          )}
                          <ul style={{ margin: 0 }}>
                            <li>
                              {gettextCatalog.getString(
                                'Absence reports',
                                null,
                                'Will be deleted when user is removed'
                              )}
                            </li>
                            {AuthorizationService.hasPackage(
                              'safeguarding'
                            ) && (
                              <li>
                                {gettextCatalog.getString(
                                  'Safeguarding reports',
                                  null,
                                  'Will be deleted when user is removed'
                                )}
                              </li>
                            )}
                            {organizationSettings.timeRegistrationCap > 0 && (
                              <li>
                                {gettextCatalog.getString(
                                  'Time registration reports',
                                  null,
                                  'Mentioned in a list of data that will be deleted when the user is removed.'
                                )}
                              </li>
                            )}
                            <li>
                              {gettextCatalog.getString(
                                'Event bookings and rotas',
                                null,
                                'Mentioned in a list of data that will be deleted when the user is removed.'
                              )}
                            </li>
                          </ul>
                        </Space>
                      ),
                      okButtonProps: {
                        danger: true,
                      },
                      onOk: () =>
                        removeUserFromCurrentOrganization(record.id)
                          .then(() => {
                            NotificationService.notifySuccess(
                              gettextCatalog.getString(
                                'The user has been removed.'
                              )
                            );
                            dispatch(FetchUsers());
                          })
                          .catch(() =>
                            NotificationService.notifyError(
                              gettextCatalog.getString(
                                'The user could not be removed.'
                              )
                            )
                          ),
                    }),
                  disabled: !record.editUser,
                },
              ],
            }}
            key={`user-actions-${record.id}`}
            placement="bottomRight"
            trigger={['click']}
            overlayStyle={{ minWidth: 200 }}
          >
            <Button>
              <Space>
                {gettextCatalog.getString('Actions')}
                <FontAwesomeIcon icon={faAngleDown} />
              </Space>
            </Button>
          </Dropdown>
        ),
      },
    ],
    [
      showChurchSelector,
      hasUserAdminRole,
      toggleStatusModal,
      resendInvite,
      organizationSettings.timeRegistrationCap,
      launchModal,
      sendMessageModal,
      dispatch,
    ]
  );

  return (
    <>
      <AssignUserRolesModal />
      <TableToolbar className="table-toolbar">
        <Col xs={24} md={5}>
          <Space>
            <Button
              disabled={!hasUserAdminRole}
              type="primary"
              onClick={() => showCreateUserModal({})}
            >
              <Space>
                <FontAwesomeIcon icon={faUserPlus} />
                {gettextCatalog.getString('New user')}
              </Space>
            </Button>
          </Space>
        </Col>
        <Col xs={24} md={19} className="table-toolbar__right-aligned-actions">
          <Space size="small" className="u-flex-wrap">
            <RoleFilterComponent
              filterState={filterState}
              filterData={filterData}
              hasMultiChurchNewRoles={true}
            />
            <FilterByStatusComponent
              filterState={filterState}
              filterData={filterData}
            />
            <StyledSearch
              style={{ display: 'inlineFlex' }}
              placeholder={gettextCatalog.getString('Search...')}
              allowClear
              enterButton
              onSearch={(value) =>
                filterData({ ...filterState, search: value })
              }
            />
          </Space>
        </Col>
      </TableToolbar>
      <Table
        columns={filter(columns, { visible: true }) as ColumnsType<User>}
        dataSource={tableData}
        bordered
        pagination={{ defaultPageSize: 20 }}
        rowKey="id"
        loading={isUsersLoading}
        rowClassName={(record) =>
          record.status === 0 ? 'ant-alert-error' : ''
        }
      />
    </>
  );
};

const RoleFilterComponent = ({
  filterState,
  filterData,
  hasMultiChurchNewRoles,
}: {
  filterState: FilterStateInterface;
  filterData: (newFilter: FilterStateInterface) => void;
  hasMultiChurchNewRoles: boolean;
}) => {
  const fetchRoles = useFetchRoles();
  const [treeData, setTreeData] = useState([]);
  const churchRoles = useSelector(roleSelectors.selectChurchRoles);
  const organizationRoles = useSelector(roleSelectors.selectOrganizationRoles);
  const onRoleFilterChange = (selectedKeys) => {
    const selectedRoleFilters = reduce(
      selectedKeys,
      (accumulator, selectedKey) => {
        if (!isNaN(selectedKey) && isNumber(parseInt(selectedKey))) {
          accumulator.roles.push(parseInt(selectedKey));
        } else if (selectedKey.length > 10) {
          accumulator.roles.push(selectedKey);
        }
        return accumulator;
      },
      { roles: [] }
    );
    filterData({ ...filterState, roles: selectedRoleFilters.roles });
  };

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newTreeData = getRoleFilterTreeData(hasMultiChurchNewRoles);
    const newRoles = concat(churchRoles, organizationRoles);
    if (!isEmpty(newRoles) && find(newTreeData, { key: 'allRoles' })) {
      find(newTreeData, { key: 'allRoles' }).children = map(
        newRoles,
        (role) => ({
          title: role.name,
          key: role.id,
          value: role.id,
        })
      );
    }
    setTreeData(newTreeData);
  }, [churchRoles, organizationRoles, hasMultiChurchNewRoles]);

  return (
    <Space>
      <Text>{gettextCatalog.getString('Filter by:')}</Text>
      <TreeSelect
        treeData={treeData}
        treeCheckable
        onChange={onRoleFilterChange}
        allowClear
        showSearch={false}
        style={{ minWidth: 200, textAlign: 'left' }}
        dropdownStyle={{ whiteSpace: 'nowrap', minWidth: 350 }}
        placeholder={
          hasMultiChurchNewRoles
            ? gettextCatalog.getString('Choose roles...')
            : gettextCatalog.getString('Choose permissions...')
        }
        maxTagCount={0}
        maxTagPlaceholder={(selectedItems) => {
          const count = selectedItems.length;
          if (count > 1) {
            return `${count} ` + hasMultiChurchNewRoles
              ? gettextCatalog.getString('roles')
              : gettextCatalog.getString('permissions');
          } else {
            return `${count} ` + hasMultiChurchNewRoles
              ? gettextCatalog.getString('role')
              : gettextCatalog.getString('permission');
          }
        }}
      />
    </Space>
  );
};

const FilterByStatusComponent = ({
  filterState,
  filterData,
}: {
  filterState: FilterStateInterface;
  filterData: (newFilter: FilterStateInterface) => void;
}) => {
  const checkBoxChangeActive = (e) => {
    filterData({ ...filterState, ['active']: e.target.checked });
  };
  const checkBoxChangeBlocked = (e) => {
    filterData({ ...filterState, ['blocked']: e.target.checked });
  };

  const menu = (
    <Menu>
      <Menu.Item key="active">
        <Checkbox
          onChange={checkBoxChangeActive}
          checked={filterState['active']}
          style={{ width: '100%', fontWeight: 400 }}
        >
          {gettextCatalog.getString('Active')}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="blocked">
        <Checkbox
          onChange={checkBoxChangeBlocked}
          checked={filterState['blocked']}
          style={{ width: '100%', fontWeight: 400 }}
        >
          {gettextCatalog.getString('Blocked')}
        </Checkbox>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger={['click']}
      overlayStyle={{ minWidth: 200 }}
    >
      <Button>
        <Space>
          {gettextCatalog.getString('Status')}
          <FontAwesomeIcon icon={faAngleDown} />
        </Space>
      </Button>
    </Dropdown>
  );
};

const getRoleFilterTreeData = (hasMultiChurchNewRoles: boolean): DataNode[] => {
  const legacyRoles = [
    {
      title: gettextCatalog.getString('Organisation administrator'),
      key: '8',
      value: '8',
    },
    {
      title: gettextCatalog.getString('Calendar'),
      key: '0-2',
      value: '0-2',
      children: [
        {
          title: gettextCatalog.getString('Calendar access'),
          key: '15',
          value: '15',
        },
        {
          title: gettextCatalog.getString(
            'Calendar share events with all users'
          ),
          key: '16',
          value: '16',
        },
        {
          title: gettextCatalog.getString('Can edit all events'),
          key: '17',
          value: '17',
        },
        {
          title: gettextCatalog.getString('Can edit group events'),
          key: '4',
          value: '4',
        },
        {
          title: gettextCatalog.getString('Can view absences'),
          key: '11',
          value: '11',
        },
        {
          title: gettextCatalog.getString('Can manage absences'),
          key: '10',
          value: '10',
        },
        {
          title: gettextCatalog.getString('Can access sensitive information'),
          key: '9',
          value: '9',
        },
      ],
    },
    {
      title: gettextCatalog.getString('People'),
      key: '0-3',
      value: '0-3',
      children: [
        {
          title: gettextCatalog.getString('People access'),
          key: '2',
          value: '2',
        },
        {
          title: gettextCatalog.getString('Can access sensitive information'),
          key: '20',
          value: '20',
        },
      ],
    },
    {
      title: gettextCatalog.getString('Forms'),
      key: '0-4',
      value: '0-4',
      children: [
        {
          title: gettextCatalog.getString('Forms access'),
          key: '18',
          value: '18',
        },
        {
          title: gettextCatalog.getString('Can access sensitive information'),
          key: '19',
          value: '19',
        },
      ],
    },
    {
      title: gettextCatalog.getString('Payments and Giving access'),
      key: '14',
      value: '14',
    },
    {
      title: gettextCatalog.getString('Website'),
      key: '3',
      value: '3',
    },
  ];
  const treeData = [];
  if (hasMultiChurchNewRoles) {
    treeData.push({
      title: gettextCatalog.getString('All roles'),
      key: 'allRoles',
      value: 'allRoles',
      children: [],
    });
    treeData.push({
      title: gettextCatalog.getString('Legacy roles'),
      key: 'legacyRoles',
      value: 'legacyRoles',
      children: legacyRoles,
    });
  } else {
    return legacyRoles;
  }
  return treeData;
};

export const UserList = ({ sendMessageModal, resendInvite }) => (
  <Suspense>
    <UserListTable
      sendMessageModal={sendMessageModal}
      resendInvite={resendInvite}
    />
  </Suspense>
);
