import { Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import createReducer from '../../../redux/utils';
import { Intention } from '../../models/intention';
import { Foundation, FoundationTypes } from '../../models/foundation';

import {
  CLEAR_STIFTUNG,
  FETCH_STIFTUNGEN_SUCCESS,
  FetchStiftungenSuccess,
  FETCH_STIFTUNG_SUCCESS,
  FetchStiftungSuccess,
  CLEAR_LEGAT,
  FETCH_LEGATE_SUCCESS,
  FetchLegateSuccess,
  FETCH_LEGAT_SUCCESS,
  FetchLegatSuccess,
  FetchIntentionsForFoundationSuccessType,
  FETCH_INTENTIONS_FOR_FOUNDATION_SUCCESS,
} from './Actions';

export interface FoundationState {
  stiftungen: Foundation[];
  stiftungenCount: number;
  stiftung: Partial<Foundation>;
  legate: Foundation[];
  legateCount: number;
  legat: Partial<Foundation>;
  legatIntentions: Intention[];
  legatIntentionsCount: number;
  stiftungIntentions: Intention[];
  stiftungIntentionsCount: number;
}

export type ImmutableFoundationState =
  immutable.ImmutableObject<FoundationState>;

const initialState: ImmutableFoundationState = immutable.from<FoundationState>({
  stiftungen: [],
  stiftungenCount: 0,
  stiftung: null,
  legate: [],
  legateCount: 0,
  legat: null,
  legatIntentions: [],
  legatIntentionsCount: 0,
  stiftungIntentions: [],
  stiftungIntentionsCount: 0,
});

const clearStiftung: Reducer<ImmutableFoundationState> = (
  state = initialState
) =>
  state.merge({
    stiftung: null, // Clear selected Stiftung (edit/view)
  });

const fetchStiftungenSuccess: Reducer<
  ImmutableFoundationState,
  FetchStiftungenSuccess
> = (state = initialState, { payload }) =>
  state.merge({
    stiftungen: payload.items || [],
    stiftungenCount: payload.count || 0,
  });

const fetchStiftungSuccess: Reducer<
  ImmutableFoundationState,
  FetchStiftungSuccess
> = (state = initialState, { payload }) =>
  state.merge({
    stiftung: payload || null,
  });

const clearLegat: Reducer<ImmutableFoundationState> = (state = initialState) =>
  state.merge({
    legat: null, // Clear selected Legat (edit/view)
  });

const fetchLegateSuccess: Reducer<
  ImmutableFoundationState,
  FetchLegateSuccess
> = (state = initialState, { payload }) =>
  state.merge({
    legate: payload.items || [],
    legateCount: payload.count || 0,
  });

const fetchLegatSuccess: Reducer<ImmutableFoundationState, FetchLegatSuccess> =
  (state = initialState, { payload }) =>
    state.merge({
      legat: payload || null,
    });

const fetchIntentionsForFoundationSuccess: Reducer<
  ImmutableFoundationState,
  FetchIntentionsForFoundationSuccessType
> = (state = initialState, { payload }) =>
  state.merge({
    legatIntentions:
      payload.type === FoundationTypes.LEGATE ? payload.data.items : [],
    legatIntentionsCount:
      payload.type === FoundationTypes.LEGATE ? payload.data.count : 0,
    stiftungIntentions:
      payload.type === FoundationTypes.STIFTUNGEN ? payload.data.items : [],
    stiftungIntentionsCount:
      payload.type === FoundationTypes.STIFTUNGEN ? payload.data.count : 0,
  });

// Handlers

const handlers = {
  [CLEAR_LEGAT]: clearLegat,
  [FETCH_LEGATE_SUCCESS]: fetchLegateSuccess,
  [FETCH_LEGAT_SUCCESS]: fetchLegatSuccess,
  [CLEAR_STIFTUNG]: clearStiftung,
  [FETCH_STIFTUNGEN_SUCCESS]: fetchStiftungenSuccess,
  [FETCH_STIFTUNG_SUCCESS]: fetchStiftungSuccess,
  [FETCH_INTENTIONS_FOR_FOUNDATION_SUCCESS]:
    fetchIntentionsForFoundationSuccess,
};

export default createReducer<ImmutableFoundationState>(initialState, handlers);
