import { Button, CollapseProps, Skeleton } from 'antd';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getCurrentOrganization } from '../../config/store/Selector';
import gettextCatalog from '../../services/I18nService';
import { Category } from '../../shared/models/category';
import { Resource } from '../../shared/models/resource';
import { useToggleSelectAllCategories } from '../hooks';
import { toggleAllParishes } from '../store/filters/Actions';
import {
  getAllSelectedCategories,
  getCategories,
  getSelectedChurch,
  selectIsAllParishesChurchesSelected,
  selectIsUserFiltersLoaded,
} from '../store/filters/Selectors';
import { selectIsInResourceView } from '../store/main-view/Selectors';
import { CdCalendarFilterCollapse } from '../containers/calendar-filter/CdCalendarFilterCollapse';

import ChurchCalendarItem from './ChurchCalendarItem';
import FilterList from './FilterList';
import { FilterSectionHeader } from './FilterSectionHeader';
import { showCalendarSubscribeModal } from './CalendarSubscribeModal';

import { CdExportToIcal } from '@/react/shared/components/Icons';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const ParishSectionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.7em 0 0.7em 45px;
  border-top: 1px solid #ddd;

  .parish-section-header-label {  
    color: #333;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.6;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

interface ChurchFiltersProps {
  isDisabled: boolean;
  churchId: number;
  churchName: string | React.ReactNode;
  categories: Category[];
  showIcalExportButton: boolean;
}

const ParishHeader = ({
  isDisabled,
  churchId,
  churchName,
  showIcalExportButton,
}: Pick<
  ChurchFiltersProps,
  'isDisabled' | 'churchId' | 'churchName' | 'showIcalExportButton'
>) => {
  const isChurchSelected = useSelector(getAllSelectedCategories(churchId));
  const toggleSelectAllCategories = useToggleSelectAllCategories();
  const onChurchClick = useCallback(() => {
    !isDisabled && toggleSelectAllCategories(churchId);
  }, [isDisabled, churchId, toggleSelectAllCategories]);

  return (
    <FilterSectionHeader
      name={churchName}
      isAllSelected={isChurchSelected}
      onClick={onChurchClick}
      isDisabled={isDisabled}
      addonAfterShowOnHover={true}
      addonAfter={
        showIcalExportButton && (
          <CdTooltip title={gettextCatalog.getString('Export to iCal')}>
            <Button
              shape="circle"
              type="text"
              size="small"
              onClick={(e) => {
                showCalendarSubscribeModal({
                  feedId: churchId,
                  feedType: 'parish',
                });
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <CdExportToIcal />
            </Button>
          </CdTooltip>
        )
      }
    />
  );
};

const HeaderSkeleton = styled(Skeleton.Input)`
  &&& {
    width: 120px;
    .ant-skeleton-input {
      height: 22px;
    }
  }
`;

const parishCalendarCollapseItem = ({
  isDisabled,
  churchId,
  churchName,
  categories,
  showIcalExportButton,
}: ChurchFiltersProps) => {
  const item: CollapseProps['items']['1'] = {
    key: churchId,
    label: (
      <ParishHeader
        {...{
          isDisabled,
          churchId,
          churchName,
          showIcalExportButton,
        }}
      />
    ),
    children: (
      <FilterList>
        {categories.map((category: Category) => (
          <ChurchCalendarItem
            categoryId={category.id}
            churchId={churchId}
            name={category.name}
            color={category.color}
            isDisabled={isDisabled}
            key={`category-${category.id}`}
          />
        ))}
      </FilterList>
    ),
  };

  return item;
};

export const ParishCalendars = () => {
  const dispatch = useDispatch();
  const organization = useSelector(getCurrentOrganization, _.isEqual);

  const categories: Category[] = useSelector(getCategories, _.isEqual);
  const isAllParishesChurchesSelected = useSelector(
    selectIsAllParishesChurchesSelected
  );
  const isUserFiltersLoaded = useSelector(selectIsUserFiltersLoaded);

  const isInResourceView = useSelector(selectIsInResourceView);

  // Retrieve and sort churches
  const churchesResources: Resource[] = useSelector(
    getSelectedChurch,
    _.isEqual
  );
  const churches = useMemo(
    () => _.sortBy(churchesResources, (church) => church.name),
    [churchesResources]
  );
  const toggleAllParishesCallBack = useCallback(() => {
    dispatch(
      toggleAllParishes({
        isActive: isAllParishesChurchesSelected,
        categories,
        churches: churchesResources,
      })
    );
  }, [categories, dispatch, isAllParishesChurchesSelected, churchesResources]);

  let churchCollapsibleItems;
  if (isUserFiltersLoaded) {
    churchCollapsibleItems = churches.map((church) =>
      parishCalendarCollapseItem({
        churchName: churches.length > 1 ? church.name : organization.name,
        churchId: church.id,
        categories: categories,
        isDisabled: isInResourceView,
        showIcalExportButton: churches.length > 1,
      })
    );
  } else {
    churchCollapsibleItems = [
      {
        key: 1,
        label: (
          <ParishHeader
            isDisabled={true}
            churchId={1}
            churchName={<HeaderSkeleton active={true} />}
            showIcalExportButton={false}
          />
        ),
      },
    ];
  }

  if (churches && churches.length > 1) {
    return (
      <>
        <ParishSectionHeader>
          {isUserFiltersLoaded ? (
            <>
              <div className="parish-section-header-label">
                {churches.length > 0
                  ? gettextCatalog.getString('Parish calendars')
                  : gettextCatalog.getString('Parish calendar')}
              </div>
              {isAllParishesChurchesSelected ? (
                <a
                  className="text-sm u-text-muted"
                  style={{ paddingRight: 10, flex: 1, textAlign: 'end' }}
                  onClick={toggleAllParishesCallBack}
                >
                  {gettextCatalog.getString('Clear all')}
                </a>
              ) : (
                <a
                  className="text-sm u-text-muted"
                  style={{ paddingRight: 10, flex: 1, textAlign: 'end' }}
                  onClick={toggleAllParishesCallBack}
                >
                  {gettextCatalog.getString('Select all')}
                </a>
              )}
            </>
          ) : (
            <>
              <HeaderSkeleton active={true} />
            </>
          )}
        </ParishSectionHeader>
        <CdCalendarFilterCollapse items={churchCollapsibleItems} />
      </>
    );
  }

  return <CdCalendarFilterCollapse items={churchCollapsibleItems} />;
};
