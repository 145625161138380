import {
  faBuilding,
  faSlidersH,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import adaptivePlugin from '@fullcalendar/adaptive';
import {
  CalendarApi,
  DateSelectArg,
  EventApi,
  PluginDef,
} from '@fullcalendar/core';
import daLocal from '@fullcalendar/core/locales/da';
import deLocal from '@fullcalendar/core/locales/de';
import gbLocal from '@fullcalendar/core/locales/en-gb';
import svLocal from '@fullcalendar/core/locales/sv';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactions from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Avatar, Empty } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import NotificationService from '../../services/NotificationService';
import { calculateTextColorBasedOnBGColor } from '../../../app/ResourceColors';
import {
  getCurrentLanguage,
  getLiturgicalHolidays,
  getNationalHolidays,
} from '../../config/store/Selector';
import { AppState } from '../../redux';
import gettextCatalog from '../../services/I18nService';
import { navigate } from '../../services/StateServiceFactory';
import CalendarControlService from '../services/CalendarViewService';
import EventPopupService, {
  PreloadedEventData,
} from '../services/EventPopupService';
import { HolyDayTypes } from '../services/HolidayService';
import { calendarSettingsSelectors } from '../store/calendar-settings/calendarSettingsSlice';
import {
  fullCalendarActions,
  fullCalendarSelectors,
  ViewTypes,
} from '../store/events/fullCalendarEventsSlice';
import { FilterType } from '../store/filters';
import { getSelectedFilters } from '../store/filters/Selectors';
import { TimePeriod } from '../store/main-view';
import {
  ChangeTimePeriodAction,
  GoToDateAction,
  UpdateEventAction,
} from '../store/main-view/Actions';
import { getCalendarView } from '../store/main-view/Selectors';
import { fetchAllCollections } from '../../intention/redux/collection/Actions';
import { getCollectionsToRender } from '../../intention/redux/collection/Selectors';
import { EventType } from '../models/calendar';

import BookingConflictModal from './BookingConflictModal';
import CalendarSpinner from './CalendarSpinner';
import EventItem from './EventItem';
import EventUpdateModal from './UpdateEventConfirmationModal';

const StyledCalendarContainer = styled.div<{
  noTitle: string;
  isCalendarSwitchVisible: boolean;
  isInPrintMode: boolean;
}>`
  flex: 1;
  background-color: #fff;
  margin: 10px 10px 0;
  height: calc(100% - ${(p) => (p.isCalendarSwitchVisible ? '112px' : '50px')});
  ${({ isInPrintMode }) => isInPrintMode && 'width: 800px;'};

  @media screen and (max-width: 1140px) {
    height: calc(
      100% - ${(p) => (p.isCalendarSwitchVisible ? '142px' : '80px')}
    );
  }
  .fc-icon-x:after {
    content: none;
  }
  .fc .fc-scrollgrid-section table {
    height: 100%;
  }
  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }

  /* Day View */
  .fc-timeGridDay-view {
    td.fc-day-today {
      background-color: #fff;
    }

    .fc-col-header-cell {
      padding: 10px;
      text-align: left;
      font-size: 16px;
      text-transform: uppercase;

      &.fc-day-past {
        opacity: 0.6;
      }

      &.fc-day-today {
        background-color: rgb(0, 140, 182);
        a {
          color: #fff;
          pointer-events: none;
        }
      }

      a {
        color: #333;
      }
    }

    .fc-timegrid-axis-frame a {
      color: #333;

      &:hover {
        padding: 2px 6px;
        border-radius: 50px;
        background-color: #eee;
        text-decoration: none;
      }
    }
    .fc-event.fc-time-grid-event {
      &.fc-short .fc-content {
        display: flex;
        align-items: center;

        .fc-time {
          margin-right: 2px;

          &:after {
            content: none;
          }
        }

        .fc-title {
          display: flex;
          align-items: center;
        }
      }

      .fc-title {
        overflow: initial !important;
      }
    }
  }

  /* Resource grid view */
  .fc-resourceTimeGridDay-view {
    .fc-col-header-cell {
      .fc-scrollgrid-sync-inner {
        width: 95%;
        margin: 0 auto;
        overflow: hidden;
        overflow-wrap: normal;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          margin-right: 5px;
        }

        .fc-col-header-cell-cushion {
          width: auto;
          padding: 0;
          overflow: hidden;
          white-space: normal;
          text-align: right;
          --max-lines: 3;
          text-overflow: -o-ellipsis-lastline;
          max-height: calc(1.4rem * var(--max-lines));
        }
      }
    }
  }

  /* Week View */
  .fc-timeGridWeek-view {
    .fc-col-header-cell {
      padding: 0;
      font-size: 14px;

      &.fc-day-today {
        background: none;

        a {
          background-color: rgb(0, 140, 182);
          color: #fff;

          &:hover {
            background-color: rgb(0, 101, 131);
            color: #fff;
          }
        }
      }
      &.fc-day-past {
        a {
          color: #333;
          opacity: 0.6;
        }
      }
      a {
        color: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        text-transform: uppercase;
        font-size: 12px;

        &:hover {
          background-color: #eee;
          color: #333;
          text-decoration: none;
        }
      }
    }
    .fc-timegrid-axis-frame a {
      color: #333;
    }
    td.fc-day-today {
      background-color: rgb(245, 243, 243);
    }
    .fc-event.fc-time-grid-event {
      &.fc-short .fc-content {
        .fc-time {
          &:after {
            content: none;
          }
        }

        .fc-title {
          display: flex;
          align-items: center;
        }
      }

      .fc-title {
        overflow: initial !important;
      }
    }
  }

  /* Month View */
  .fc-dayGridMonth-view {
    th.fc-col-header-cell {
      text-transform: uppercase;

      a {
        color: #333;
        pointer-events: none;
        padding: 0;
      }
    }

    .fc-daygrid-week-number {
      font-size: 12px;
      padding: 7px 4px;
      background: none;

      &:hover {
        color: #333;
        text-decoration: none;
      }
    }
    td.fc-day.fc-day-today {
      background: none;
    }
    td.fc-daygrid-day {
      padding: 0 0 2px 0;

      .fc-daygrid-day-top {
        justify-content: center;
        padding: 3px 0;
      }

      .fc-daygrid-day-number {
        display: inline-block;
        width: 26px;
        padding: 4px;
        font-size: 12px;
        font-weight: 700;
        color: #333;
        text-align: center;

        &:hover {
          display: inline-block;
          width: 26px;
          padding: 4px;
          background-color: #eee;
          border-radius: 50px;
          color: #333;
          text-decoration: none;
        }
      }

      &.fc-day-today .fc-daygrid-day-number {
        display: inline-block;
        width: 26px;
        padding: 4px;
        background-color: rgb(0, 140, 182);
        color: #fff;
        border-radius: 50px;

        &:hover {
          background-color: rgb(0, 101, 131);
        }
      }
      .fc-daygrid-dot-event {
        padding: 0;
      }

      /* Position placeholder when dragging across days or creating an event */
      .fc-highlight {
        top: 30px;
      }
    }
  }
  /* All views */

  .fc-view {
    .fc-timegrid-axis-cushion {
      text-transform: capitalize;
      font-size: 80%;
    }
    .fc-timegrid-slot.fc-timegrid-slot-lane {
      height: 2em;
    }
    .fc-daygrid-body-natural .fc-daygrid-day-events {
      margin-top: 0;
      margin-bottom: 1px;
    }
    .fc-timegrid-divider.fc-cell-shaded {
      display: none;
    }
    .fc-now-indicator.fc-now-indicator-line {
      border: 1px solid #e74c3c;
    }

    .fc-daygrid-event-harness {
      cursor: not-allowed;
    }

    /* Event styles that can not be moved to the event component as they we can not target the <a> from there */
    a.fc-event {
      background: none !important;
      box-shadow: none !important;
      border: none !important;
      border-radius: 4px !important;

      &:not(.fc-event-draggable) {
        pointer-events: none;
      }

      .fc-event-main {
        padding: 0;
      }

      .fc-daygrid-event {
        margin-top: 0;
      }

      &.fc-timegrid-event-harness-inset {
        border: 1px solid #fff !important;
        border-radius: 4px !important;
      }

      &:hover,
      &[data-hasqtip] {
        transform: translateY(-2px);
        box-shadow: 0 5px 8px -5px #00000080; /* Fallback color */
        box-shadow: 0 5px 8px -5px rgba(0, 0, 0, 0.5);
      }
    }
    @media print {
      .fc-timegrid-body .fc-timegrid-slots {
        tbody,
        tr td.fc-timegrid-slot.fc-timegrid-slot-lane {
          background: none !important;
        }
      }
      .fc-timegrid-now-indicator-container {
        display: none;
      }
      .fc-scroller.fc-scroller-liquid-absolute {
        position: relative !important;
      }
    }

    /* Style placeholder when dragging event across days or creating an event */

    /* All day events in week/day view */
    .fc-timegrid-bg-harness .fc-highlight {
      display: none;
    }
    .fc-timegrid-bg-harness,
    .fc-daygrid-bg-harness {
      .fc-highlight {
        background: var(--fc-highlight-color, rgb(167, 155, 142));
        max-height: 25px;
        margin: 3px;
        border-radius: 4px;
        z-index: 999999999999999 !important;
        opacity: initial;

        &:before {
          color: #fff;
          padding-left: 5px;
          font-size: 80%;
        }
        &[data-hasqtip] {
          &:before {
            content: '${(p) => p.noTitle}';
          }
        }
      }
    }
    /* Events in month view */
    &.fc-dayGridMonth-view {
      .fc-daygrid-bg-harness {
        &[data-hasqtip] {
          .fc-highlight {
            display: flex;
            align-items: center;

            &:before {
              color: #fff;
              padding-left: 5px;
              font-size: 80%;
              content: '${(p) => p.noTitle}';
            }
          }
        }
      }
    }
  }
`;

const onSelect = (args: DateSelectArg): void => {
  const { start, end, jsEvent, allDay } = args;
  if (args.resource) {
    const entityType: FilterType = _.get(
      args.resource,
      '_resource.extendedProps.type'
    );
    const entityId: string = _.get(
      args.resource,
      '_resource.extendedProps.originalId'
    );
    const data: PreloadedEventData = {};
    switch (entityType) {
      case FilterType.resources:
        data.resources = [parseInt(entityId)];
        break;
      case FilterType.users:
        data.users = [parseInt(entityId)];
        break;
      default:
        break;
    }
    EventPopupService.createEvent(
      start,
      end,
      jsEvent.target,
      allDay,
      data,
      () => {}
    );
  } else {
    EventPopupService.createEvent(
      start,
      end,
      jsEvent.target,
      allDay,
      null,
      () => {}
    );
  }
};

const OnEventChanged = (
  oldEvent: EventApi,
  newEvent: EventApi,
  revert: () => void,
  dispatch: Dispatch
): void => {
  dispatch(UpdateEventAction({ newEvent, oldEvent, revert }));
};

const onEventDoubleClick = (event) => {
  const eventType =
    event.extendedProps.type === 'external'
      ? 'externalEvent'
      : event.extendedProps.type;

  // Don't redirect on external/feed events. Popover is the only view.
  if (eventType === 'externalEvent' || eventType === 'feed') return;

  navigate(`app.private.calendar.${eventType}`, { id: event.id });
};

let a = 0;
const getUniqueId = () => {
  const b = a++;
  return `event_${b}`;
};

const isEventAHoliday = (event: EventApi): boolean =>
  _.get(event, 'extendedProps.type') === HolyDayTypes.LITURGICAL ||
  _.get(event, 'extendedProps.type') === HolyDayTypes.NATIONAL;

const Calendar = () => {
  const currentView = useSelector(getCalendarView);
  const dispatch = useDispatch();
  const events = useSelector(fullCalendarSelectors.getEvents, _.isEqual);
  const nationalHolidays = useSelector(getNationalHolidays);
  const showNationalHolidays = useSelector(
    (state: AppState) => state.calendar.view.showNationalHolidays
  );
  const collections = useSelector(getCollectionsToRender);
  const showCollections = useSelector(
    (state: AppState) => state.calendar.view.showCollections
  );
  const liturgicalHolidays = useSelector(getLiturgicalHolidays);
  const showLiturgicalHolidays = useSelector(
    (state: AppState) => state.calendar.view.showLiturgicalHolidays
  );
  const language = useSelector(getCurrentLanguage);
  const showResources = useSelector(
    (state: AppState): boolean => state.calendar.view.showResources
  );
  const showPrepTime = useSelector(
    (state: AppState): boolean => state.calendar.view.showPrepTime
  );
  const showIntentions = useSelector(
    (state: AppState): boolean => state.calendar.view.showIntentions
  );
  const selectedDate = useSelector((state: AppState) =>
    state.calendar.view.selectedDate
      ? state.calendar.view.selectedDate
      : new Date()
  );
  const calendarRef = useRef(null);
  const selectedFilters = useSelector(getSelectedFilters, _.isEqual);

  const navLinkDayClick = useCallback(
    (weekStart) => {
      dispatch(GoToDateAction(weekStart));
      dispatch(ChangeTimePeriodAction(TimePeriod.Day));
    },
    [dispatch]
  );

  const navLinkWeekClick = useCallback(
    (weekStart) => {
      dispatch(GoToDateAction(weekStart));
      dispatch(ChangeTimePeriodAction(TimePeriod.Week));
    },
    [dispatch]
  );

  const onEventResize = useCallback(
    (args): void => {
      OnEventChanged(args.oldEvent, args.event, args.revert, dispatch);
    },
    [dispatch]
  );

  const onEventDrop = useCallback(
    (args): void => {
      const { oldEvent, revert } = args;
      if (oldEvent.extendedProps.type === EventType.Feed) {
        NotificationService.notifyError(
          gettextCatalog.getString(
            'You cannot move events from external calendars'
          )
        );
        revert();
        return;
      }
      if (oldEvent.extendedProps.isMinimizedAbsences) {
        NotificationService.notifyError(
          gettextCatalog.getString('You cannot move grouped absences')
        );
        revert();
        return;
      }
      if (oldEvent.extendedProps.type === 'external') {
        NotificationService.notifyError(
          gettextCatalog.getString(
            'You cannot move events from other ChurchDesk calendars'
          )
        );
        revert();
        return;
      }
      if (
        oldEvent.extendedProps.visibility === 'private' &&
        !oldEvent.extendedProps.isAuthorizedByCurrentUser
      ) {
        NotificationService.notifyError(
          gettextCatalog.getString('You cannot move private event')
        );
        revert();
        return;
      }
      OnEventChanged(args.oldEvent, args.event, args.revert, dispatch);
    },
    [dispatch]
  );

  const eventOrder = useCallback(
    (firstEvent: EventApi, secondEvent: EventApi) => {
      if (isEventAHoliday(firstEvent) && !isEventAHoliday(secondEvent)) {
        return -1;
      }
      if (!isEventAHoliday(firstEvent) && isEventAHoliday(secondEvent)) {
        return 1;
      }
      if (
        _.get(firstEvent, 'extendedProps.isMinimizedAbsences') &&
        !_.get(secondEvent, 'extendedProps.isMinimizedAbsences')
      ) {
        return -1;
      }
      if (
        _.get(secondEvent, 'extendedProps.isMinimizedAbsences') &&
        !_.get(firstEvent, 'extendedProps.isMinimizedAbsences')
      ) {
        return 1;
      }

      if (moment(firstEvent.start).isBefore(secondEvent.start)) {
        return -1;
      }

      if (moment(secondEvent.start).isBefore(firstEvent.start)) {
        return 1;
      }

      if (
        moment(firstEvent.end).diff(moment(firstEvent.start), 'hours') >
        moment(secondEvent.end).diff(moment(secondEvent.start), 'hours')
      ) {
        return -1;
      }
      if (
        moment(firstEvent.end).diff(moment(firstEvent.start), 'hours') <=
        moment(secondEvent.end).diff(moment(secondEvent.start), 'hours')
      ) {
        return 1;
      }
    },
    []
  );

  const eventContent = useCallback(
    (eventInfo) => {
      const event = eventInfo.event;
      const popoverIndex = getUniqueId();
      return (
        <EventItem
          key={`EventItem_${event.id}`}
          event={event}
          popoverIndex={popoverIndex}
          currentView={currentView}
          showResources={showResources}
          showPrepTime={showPrepTime}
          showIntentions={showIntentions}
          isResizing={eventInfo.isResizing}
          isDragging={eventInfo.isDragging}
          onDoubleClick={onEventDoubleClick}
        />
      );
    },
    [showResources, currentView, showPrepTime, showIntentions]
  );

  // On initialize or selectedDate changed or condition of selectedFilters.length === 0
  // or currentView === 'resourceTimeGridDay' or  has changed,
  // load the calendar filters, and get calendarApi from full-calendar component ref
  const changeDetection =
    selectedFilters.length === 0 && currentView === 'resourceTimeGridDay';
  useEffect(() => {
    if (selectedFilters.length === 0 && currentView === 'resourceTimeGridDay') {
      return;
    }
    const calendarApi: CalendarApi = calendarRef.current.getApi();
    if (!CalendarControlService.Api) {
      CalendarControlService.Api = calendarApi;
      CalendarControlService.updateSize();
    }
    dispatch(fullCalendarActions.reloadCurrentView());
    return () => {
      CalendarControlService.Api = null;
    };
  }, [
    selectedDate,
    changeDetection,
    currentView,
    dispatch,
    selectedFilters.length,
  ]);

  // Load collecte.
  useEffect(() => {
    if (selectedFilters.length === 0 && currentView === 'resourceTimeGridDay') {
      return;
    }
    const calendarApi: CalendarApi = calendarRef.current.getApi();
    if (!CalendarControlService.Api) {
      CalendarControlService.Api = calendarApi;
    }
    // Load collection in the current time window
    // We are adding timeout here, since it is dispatching the action a bit early before the fullcalendar updates its date range
    // This happens when we are switching the different calendar views (day-week-month) with shortcuts.
    const timer = setTimeout(() => {
      Promise.all([
        CalendarControlService.getStartDate(),
        CalendarControlService.getEndDate(),
      ]).then(([startDate, endDate]) => {
        dispatch(
          fetchAllCollections({
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
          })
        );
      });
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [selectedDate, currentView, dispatch, selectedFilters.length]);

  // Set isCalendarSwitchAllowed based on the flagr setting
  // isCalendarSwitchAllowed that controls the value subtracted from the full calendar height
  const isCalendarSwitchVisible = useSelector(
    calendarSettingsSelectors.getIsCalendarSwitchAllowed
  );
  const eventSources = useMemo(() => {
    const resources = [
      {
        events: events,
      },
    ];

    if (showLiturgicalHolidays) {
      resources.push({ events: liturgicalHolidays });
    }

    if (showNationalHolidays) {
      if (
        showLiturgicalHolidays &&
        liturgicalHolidays &&
        currentView !== ViewTypes.RESOURCES
      ) {
        const filteredNationalHolidays = _.filter(
          nationalHolidays,
          (nationalHoliday) =>
            !_.some(
              liturgicalHolidays,
              (liturgicalHoliday: EventApi) =>
                moment(liturgicalHoliday.start).format('YYYY-MM-DD') ===
                moment(nationalHoliday.start).format('YYYY-MM-DD')
            )
        );
        resources.push({ events: filteredNationalHolidays });
      } else {
        resources.push({ events: nationalHolidays });
      }
    }

    if (showCollections) {
      resources.push({ events: collections });
    }
    return resources;
  }, [
    events,
    showLiturgicalHolidays,
    liturgicalHolidays,
    nationalHolidays,
    showNationalHolidays,
    currentView,
    showCollections,
    collections,
  ]);

  const showEmpty =
    selectedFilters.length === 0 && currentView === ViewTypes.RESOURCES;

  const [isInPrintMode, setIsInPrintMode] = useState(false);

  const onAfterprint = useCallback(() => {
    setIsInPrintMode(false);
  }, [setIsInPrintMode]);

  const onBeforePrint = useCallback(() => {
    setIsInPrintMode(true);
  }, [setIsInPrintMode]);

  useEffect(() => {
    window.addEventListener('afterprint', onAfterprint);
    window.addEventListener('beforeprint', onBeforePrint);
    return () => {
      window.removeEventListener('afterprint', onAfterprint);
      window.removeEventListener('beforeprint', onBeforePrint);
    };
  }, [onAfterprint, onBeforePrint]);

  if (showEmpty) {
    return (
      <Empty
        style={{
          backgroundColor: '#ffffff',
          border: '1px solid #ddd',
          height: '80vh',
          marginTop: 30,
        }}
        imageStyle={{ marginTop: 40 }}
        image={<FontAwesomeIcon size="3x" icon={faSlidersH} />}
        description={
          <span>
            {gettextCatalog.getString(
              'To view users & rooms side-by-side, please choose at least one of the calendar to the left.'
            )}
          </span>
        }
      />
    );
  }

  return (
    <StyledCalendarContainer
      isCalendarSwitchVisible={isCalendarSwitchVisible}
      id="calendar-container"
      noTitle={gettextCatalog.getString('(No title)')}
      isInPrintMode={isInPrintMode}
      // Add stopPropagation to prevent the Full Calendar popover (https://fullcalendar.io/docs/event-popover) from closing our
      // CalendarEventPopover when the user clicks inside the popover. https://github.com/fullcalendar/fullcalendar/issues/6993
      onMouseDown={(evt) => {
        // Since we removed propagation, the angular qtip event-popup doesn't close when we click inside the calendar.
        // We therefore have to destroy the qtip when it would've been closed. (https://app.shortcut.com/churchdesk/story/27016/view-pop-over-opens-behind-the-create-pop-over)
        const qtip = $('.qtip');
        const highlight = $('.fc-highlight');
        if (qtip.length > 0) {
          if (highlight.parent().attr('data-hasqtip')) {
            // Manually remove the brown highlight
            highlight.remove();
          }
          (qtip as any).qtip('destroy', true);
        }
        evt.stopPropagation();
        // evt.preventDefault(); will prevent text selection in the event popover
      }}
    >
      <CalendarSpinner>
        <FullCalendar
          ref={calendarRef}
          schedulerLicenseKey="0006420099-fcs-1673447847"
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactions,
            resourceTimeGridPlugin,
            adaptivePlugin as unknown as PluginDef,
          ]}
          views={{
            resourceTimeGridDay: {
              titleFormat: {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                weekday: 'long',
              },
            },
          }}
          rerenderDelay={500}
          headerToolbar={false}
          eventSources={eventSources}
          initialView={currentView}
          resources={selectedFilters}
          resourceOrder="sortOrder"
          resourceLabelContent={(args) => {
            const { resource } = args;
            const isUser = resource.extendedProps.type === FilterType.users;
            return (
              <>
                {isUser ? (
                  _.isEmpty(resource.extendedProps.image) ? (
                    <Avatar
                      size={20}
                      style={{ marginBottom: 2 }}
                      icon={<FontAwesomeIcon icon={faUser} />}
                    />
                  ) : (
                    <Avatar
                      size={20}
                      style={{ marginBottom: 2 }}
                      src={resource.extendedProps.image}
                    />
                  )
                ) : (
                  <FontAwesomeIcon
                    style={{ marginTop: 1, marginBottom: 2 }}
                    size="lg"
                    icon={faBuilding}
                  />
                )}
                <div>
                  <span style={{ whiteSpace: 'nowrap' }}>{resource.title}</span>
                </div>
              </>
            );
          }}
          resourceLabelDidMount={(resourceLabel) => {
            const resourceColor = resourceLabel.resource.extendedProps.color;
            if (!_.isNil(resourceLabel.resource.extendedProps.color)) {
              resourceLabel.el.style.backgroundColor = resourceColor;
              resourceLabel.el.style.color =
                calculateTextColorBasedOnBGColor(resourceColor);
            }
          }}
          editable
          eventResourceEditable={false} // Prevent dragging between resources
          selectable
          selectMirror
          unselectAuto={false}
          height="100%"
          dayMaxEvents={9}
          weekends
          weekNumbers
          weekText={gettextCatalog.getString('W')}
          weekNumberFormat={{ week: 'short' }}
          eventOverlap
          navLinks
          nowIndicator
          firstDay={1}
          locales={[daLocal, deLocal, gbLocal, svLocal]}
          locale={language}
          timeZone="local"
          scrollTime="08:00:00"
          fixedWeekCount={false}
          eventContent={eventContent}
          select={onSelect}
          eventResize={onEventResize}
          eventDrop={onEventDrop}
          navLinkDayClick={navLinkDayClick}
          navLinkWeekClick={navLinkWeekClick}
          eventOrder={eventOrder}
        />
      </CalendarSpinner>
      <EventUpdateModal />
      <BookingConflictModal />
    </StyledCalendarContainer>
  );
};
export default Calendar;
