import { useRecoilCallback } from 'recoil';
import NiceModal from '@ebay/nice-modal-react';
import { omit } from 'lodash';
import moment from 'moment';

import NotificationService from '@/react/services/NotificationService';
import gettextCatalog from '@/react/services/I18nService';
import IntentionReportService from '@/react/intention/services/IntentionReportService';
import {
  OrderOfServiceReportPayload,
  OrderOfServiceSearchParams,
} from '@/react/calendar/types/orderOfServiceTypes';
import ErrorHandlingService from '@/react/services/ErrorHandlingService';
import cdApp from '@/react/config';

export const useTemplates = () => {
  const openTemplateSelector = (
    orderOfServiceSearchParams: OrderOfServiceSearchParams
  ) =>
    NiceModal.show(
      'OrderOfServiceTemplateSelector',
      orderOfServiceSearchParams
    );

  const generateOrderOfServiceReport = useRecoilCallback(
    () =>
      async (payload: OrderOfServiceReportPayload, closeModal: () => void) => {
        try {
          const reportPayload = omit(payload, [
            'disabled',
            'startDate',
            'endDate',
          ]);
          const downloadToken =
            await IntentionReportService.fetchIntentionOrderOfServiceReportDownloadToken();

          const params = $.param({
            downloadToken,
            startDate: moment(payload.startDate).format('YYYY-MM-DD'),
            endDate: moment(payload.endDate)
              .add(1, 'days')
              .format('YYYY-MM-DD'),
            ...reportPayload,
            type: 'event',
          });
          window.location.href = `${cdApp.config.api.main}/calendar/order-of-service/report?${params}`;
          NotificationService.notifySuccess(
            gettextCatalog.getString('Report created')
          );
          closeModal();
        } catch (e) {
          ErrorHandlingService.handleError(e);
        }
      },
    []
  );

  return { openTemplateSelector, generateOrderOfServiceReport };
};
