import React, { FunctionComponent, Suspense } from 'react';
import { Button } from 'antd';
import { useRecoilValue } from 'recoil';

import { useSafeguardingReport } from '../hooks/useSafeguardingReport';

import { SafeguardingList } from './SafeguardingList';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import AuthorizationService from '@/react/services/AuthorizationService';
import { CdFallbackPage } from '@/react/shared/components/CdFallbackPage';
import { BeaconChatService } from '@/react/services/beacon-chat.service';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import gettextCatalog from '@/react/services/I18nService';
import CdrEmptyState from '@/react/shared/components/CdrEmptyState';
import {
  CdExclamationTriangleIcon,
  CdSafeguardDocumentTracking,
  CdSafeguardCertificates,
} from '@/react/shared/components/Icons';
import { UserHasPermissionAnywhereQuery } from '@/react/user/store/permissions';
import { showCreateUserModal } from '@/react/user/components/CreateUserModal';

/**
 * This component will render the Safeguarding page for all the organization having de language
 * @returns safeguarding page
 */

const Safeguarding = ({ filter }: { filter: string }) => {
  const title = gettextCatalog.getString('Safeguarding');
  const subTitle = gettextCatalog.getString(
    'Keep track of your required documents and certificates'
  );
  const { getDownloadTokenAndDownloadFile } = useSafeguardingReport();

  const safeguardingPackageEnabled =
    AuthorizationService.hasPackage('safeguarding');

  const canAccessSafeguarding = useRecoilValue(
    UserHasPermissionAnywhereQuery('safeguarding.access')
  );

  const safeguardingMessages = [
    {
      key: 'safeguardingMessage1',
      icon: (
        <CdSafeguardDocumentTracking size="2x" style={{ color: '#008FB4' }} />
      ),
      messageTitle: gettextCatalog.getString(
        'Keep track of your required documents and certificates.'
      ),
      messageSubTitle: gettextCatalog.getString(
        'Stay easily on top of all your safeguarding measures'
      ),
    },
    {
      key: 'safeguardingMessage2',
      icon: <CdSafeguardCertificates size="2x" style={{ color: '#008FB4' }} />,
      messageTitle: gettextCatalog.getString(
        'Easy to maintain the documents and certificates of church members'
      ),
      messageSubTitle: gettextCatalog.getString(
        'No tension of maintaining and losing the hard copies of documents a nd certificates of church members. It is easy to update.'
      ),
    },
  ];

  const renderSafeguardingList = () => {
    if (safeguardingPackageEnabled) {
      if (canAccessSafeguarding) {
        return <SafeguardingList filter={filter} />;
      } else {
        return (
          <CdrEmptyState
            title={gettextCatalog.getString(
              'You do not have access to Safeguarding.'
            )}
            subtitle={gettextCatalog.getString(
              'Ask your organisation administrator to grant access.'
            )}
            EmptyStateIcon={<CdExclamationTriangleIcon />}
          />
        );
      }
    } else {
      return (
        <CdFallbackPage
          title={gettextCatalog.getString(
            'Safeguarding is not included in your plan'
          )}
          subtitle={gettextCatalog.getString(
            'Maintain your personal safeguarding tool.'
          )}
          messages={safeguardingMessages}
          buttonText={gettextCatalog.getString('Write us directly')}
          buttonType="primary"
          onButtonClick={() => {
            BeaconChatService.showNewMessage();
          }}
          customerCareDetails={{
            callUsText: gettextCatalog.getString(
              'Get ChurchDesk Safeguarding by calling us'
            ),
          }}
        />
      );
    }
  };

  return (
    <CdPage
      pageHeaderProps={{
        title,
        subTitle,
        extra: [
          <Button
            key="safeguardingNewUserModal"
            type="primary"
            disabled={!canAccessSafeguarding || !safeguardingPackageEnabled}
            onClick={() => showCreateUserModal({})}
          >
            {gettextCatalog.getString('Add new user')}
          </Button>,
          <Button
            key="safeguardingExport"
            type="default"
            disabled={!canAccessSafeguarding || !safeguardingPackageEnabled}
            onClick={getDownloadTokenAndDownloadFile}
          >
            {gettextCatalog.getString('Export')}
          </Button>,
        ],
      }}
    >
      {renderSafeguardingList()}
    </CdPage>
  );
};
interface SafeguardingWrapperProps {
  $stateParams: {
    filter: string;
  };
}

export const SafeguardingWrapper: FunctionComponent<SafeguardingWrapperProps> =
  ({ $stateParams }) => (
    <Suspense fallback={<CdrPageLoader />}>
      <Safeguarding filter={$stateParams?.filter} />
    </Suspense>
  );
