import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
} from 'recoil';
import { useEffect, useState } from 'react';

import { formsApi } from '@/react/api';
import { EventRegistrationsQuery } from '@/react/calendar/store/events/event';
import NotificationService from '@/react/services/NotificationService';
import getTextCatalog from '@/react/services/I18nService';
import { handleError } from '@/react/services/ErrorHandlingService';
import {
  ApiEventRegistrationResult,
  EventForm,
} from '@/react/calendar/types/event';
import { getStateUrl } from '@/react/services/StateServiceFactory';

export const useSignUp = (calendarId?: number, form?: EventForm) => {
  const [initialForm] = useState(form);
  const attendees: ApiEventRegistrationResult = useRecoilValue(
    EventRegistrationsQuery({ calendarId })
  );
  const formHasBeenChanged = initialForm !== form;

  const addAttendee = () => {
    window.open(form.link, '_blank');
  };

  const editAttendee = (submissionId: string) => {
    const url = getStateUrl('app.private.forms.editResponse', {
      formId: form.id,
      responseId: submissionId,
    });

    window.open(url, '_blank');
  };

  const refreshAttendees = useRecoilRefresher_UNSTABLE(
    EventRegistrationsQuery({ calendarId })
  );

  const resendTickets = useRecoilCallback(
    () => async (submissionId: string) => {
      const response = await formsApi.post<any>(`/forms/tickets/resend`, {
        formId: form.id,
        submissionId,
      });
      if (!response.ok) {
        handleError(response);
        throw response;
      } else {
        NotificationService.notifySuccess(
          getTextCatalog.getString('Tickets are being resent.')
        );
        return response.data;
      }
    },
    [form]
  );

  const deleteAttendee = useRecoilCallback(
    () => async (submissionId: string) => {
      const response = await formsApi.delete<any>(
        `/forms/${form.id}/submissions/${submissionId}`
      );
      if (!response.ok) {
        handleError(response);
      } else {
        NotificationService.notifySuccess(
          getTextCatalog.getString('Participant deleted!')
        );
        refreshAttendees();
      }
    },
    [refreshAttendees, form]
  );

  useEffect(() => {
    refreshAttendees();
  }, [form, refreshAttendees]);

  return {
    attendees: attendees.items,
    hasTickets: attendees.hasTickets,
    hasMoreThanOnePeopleSection: attendees.hasMoreThanOnePeopleSection,
    formHasBeenChanged,
    addAttendee,
    editAttendee,
    refreshAttendees,
    resendTickets,
    deleteAttendee,
  };
};
