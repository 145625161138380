import {
  Checkbox,
  Button,
  Divider,
  Popover,
  Space,
  CheckboxOptionType,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import React from 'react';
import styled from 'styled-components';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import gettextCatalog from '../../services/I18nService';

const Item = styled.li`
  padding: 0;
`;

const WideCheckbox = styled(Checkbox)`
  &&&& {
    width: 100%;
  }
`;

export interface ColumnSelectTableColumn<RecordType>
  extends ColumnType<RecordType> {
  active: boolean;
}

const Container = styled.div`
  overflow-y: auto;
  max-height: 300px;
`;

export interface ColumnSelectProps {
  value: CheckboxValueType[];
  items: CheckboxOptionType[];
  onChange: (value: CheckboxValueType[]) => void;
  buttonLabel: string;
  buttonIcon: React.ReactNode;
}

export default function ColumnSelect({
  value,
  items,
  onChange,
  buttonLabel,
  buttonIcon,
}: ColumnSelectProps) {
  const onCheckAllChange = (e) => {
    if (!e.target.checked) return onChange([]);
    onChange(items.map((item) => item.value));
  };
  const indeterminate = !!value.length && value.length < items.length;
  const checkAll = value.length === items.length;
  const Menu = (
    <Container>
      <Checkbox
        key="checkAll"
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        {gettextCatalog.getString('Columns')}
      </Checkbox>
      <Divider key="divider" style={{ margin: '8px 0' }} />
      <Checkbox.Group key="columnOptions" value={value} onChange={onChange}>
        <Space direction="vertical" size={0}>
          {items.map((item: any) => (
            <Item key={item.value}>
              <WideCheckbox value={item.value}>{item.label}</WideCheckbox>
            </Item>
          ))}
        </Space>
      </Checkbox.Group>
    </Container>
  );
  return (
    <Popover
      trigger={['click']}
      content={Menu}
      arrow={false}
      placement="bottomRight"
    >
      <Button icon={buttonIcon}>{buttonLabel}</Button>
    </Popover>
  );
}
