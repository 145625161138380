import React, { useCallback, useEffect } from 'react';
import { Col, Row, Segmented, Space } from 'antd';
import ReactCrop from 'react-image-crop';
import styled from 'styled-components';
import { useRecoilValueLoadable } from 'recoil';
import 'react-image-crop/dist/ReactCrop.css';

import AuthorizationService from '@/react/services/AuthorizationService';
import gettextCatalog from '@/react/services/I18nService';
import { useCropInformation } from '@/react/files/hooks/useCropInformation';
import { GetFile } from '@/react/files/store/files';
import { CdAlertInfo, EventIcons } from '@/react/shared/components/Icons';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export type CropImageModalTypes = {
  fileId: number;
  entityId: number | string;
  entityType: 'event' | 'people-message' | 'contribution';
  onlyCropType?: string;
};

const CdSegmented = styled(Segmented)`
  &&&& {
    label {
      margin-bottom: 0;
    }
  }
`;

export const showCropImageModal = createCdModal<CropImageModalTypes>(
  ({ setModalProps, fileId, entityId, entityType, onlyCropType }) => {
    const fileLoadable = useRecoilValueLoadable(GetFile(fileId));

    const {
      imgRef,
      refresh,
      saveImageCrop,
      cropType,
      setCropType,
      crop,
      setCrop,
    } = useCropInformation({
      entityId,
      entityType,
      fileId,
      onlyCropType,
    });

    const onOk = useCallback(async () => {
      await saveImageCrop();
    }, [saveImageCrop]);

    const close = useCallback(async () => {
      await refresh();
    }, [refresh]);

    useEffect(() => {
      setModalProps({
        modalType: ModalType.MODAL,
        title: gettextCatalog.getString('Crop image'),
        width: 500,
        onOk,
        onCancel: close,
        noPadding: true,
      });
    }, [onOk, setModalProps, close]);

    return (
      <Row gutter={[8, 8]}>
        {!onlyCropType && (
          <Col
            span={24}
            style={{
              textAlign: 'center',
              marginTop: '8px',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            <CdSegmented
              defaultValue={cropType}
              onChange={(newCurrentType) =>
                setCropType(newCurrentType.toString())
              }
              block
              options={[
                {
                  label: (
                    <div>
                      <EventIcons.Image />
                      <div>{gettextCatalog.getString('Landscape')}</div>
                    </div>
                  ),
                  value: '16-9',
                },
                {
                  label: (
                    <div>
                      <EventIcons.Summary />
                      <div>
                        <Space>
                          {gettextCatalog.getString('Slideshow Widget')}
                          <CdTooltip
                            title={gettextCatalog.getString(
                              'Crop images for the website slideshow widget.'
                            )}
                          >
                            <span>
                              <CdAlertInfo />
                            </span>
                          </CdTooltip>
                        </Space>
                      </div>
                    </div>
                  ),
                  value: 'slideshow-widgets',
                },

                AuthorizationService.hasPermission('canAccessChurchScreen') && {
                  label: (
                    <div>
                      <EventIcons.ChurchScreen />
                      <div>{gettextCatalog.getString('Churchscreen')}</div>
                    </div>
                  ),
                  value: 'churchscreen',
                },
              ].filter(Boolean)}
            />
          </Col>
        )}
        <Col
          span={24}
          style={{
            textAlign: 'center',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          <ReactCrop
            src={fileLoadable.contents?.fileInfo?.full.url}
            onImageLoaded={(img) => {
              imgRef.current = img;
            }}
            crop={crop}
            ruleOfThirds={true}
            onChange={(crop) => setCrop(crop)}
          />
        </Col>
      </Row>
    );
  }
);
