import { Col, Row, Select, Space, Typography } from 'antd';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { COLOR_INDEX } from '@/app/ResourceColors';
import { CdResourceColor, EventIcons } from '@/react/shared/components/Icons';
import getTextCatalog from '@/react/services/I18nService';
import { getAddressFormat } from '@/react/calendar/event-details/services/address.service';

const PlaceDetails = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SelectedResourcesLocation = ({
  resources,
  selectedResourceIds,
  handleSelectedResourcesLocation,
}) => {
  const getSelectedResources = useCallback(() => {
    const updateSelectedResources = resources.filter((r) =>
      selectedResourceIds.includes(r.id)
    );
    let resourcesWithParish = [];
    // add resources with parish if there is.
    for (let i = 0; i < resources.length; i++) {
      if (resources[i].resources?.length > 0) {
        resourcesWithParish = resourcesWithParish.concat(
          resources[i].resources
            .filter((r) => selectedResourceIds.includes(r.id))
            .map((r) => ({ ...r, parishName: resources[i].name }))
        );
      }
    }
    return updateSelectedResources.concat(resourcesWithParish);
  }, [resources, selectedResourceIds]);
  const selectedResources = getSelectedResources();

  const handleChange = (resourceId) => {
    for (let i = 0; i < resources.length; i++) {
      if (resources[i].resources?.length > 0) {
        const resource = resources[i].resources.find(
          (r) => r.id === resourceId
        );
        if (resource) {
          handleSelectedResourcesLocation(resource);
          break;
        }
      } else {
        if (resources[i].id === resourceId) {
          handleSelectedResourcesLocation(resources[i]);
          break;
        }
      }
    }
  };

  const options = selectedResources?.map((resource) => {
    const { address, address2, zipcode, city, state } =
      resource?.location || {};
    const locationName = resource.locationName;

    return {
      label: (
        <Row style={{ width: '100%' }}>
          <Col flex="28px">
            <EventIcons.Location />
          </Col>
          <Col flex="1 1 0">
            <PlaceDetails>
              <div>
                <Space>
                  {resource.parishName && <span>{resource.parishName},</span>}
                  <Space size={4} align="start">
                    <CdResourceColor
                      size="xs"
                      style={{
                        color: COLOR_INDEX[resource.color],
                      }}
                    />
                    {resource.name}
                  </Space>
                </Space>
              </div>
              <div>
                <Typography.Text strong>{locationName}</Typography.Text>
              </div>
              <div>
                {getAddressFormat({ address, address2, zipcode, city, state })}
              </div>
            </PlaceDetails>
          </Col>
        </Row>
      ),
      value: resource.id,
    };
  });
  return (
    <Select
      options={options}
      onChange={handleChange}
      placeholder={getTextCatalog.getString('Choose location...')}
    />
  );
};
