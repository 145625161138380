import React, { Suspense } from 'react';
import { Button } from 'antd';

import { CdPage } from '../../../shared/components/cd-page/CdPage';
import gettextCatalog from '../../../services/I18nService';
import { CdEyeIcon, CdSave } from '../../../shared/components/Icons';
import { useImprint } from '../../hooks/useImprint';
import CdrPageLoader from '../../../shared/components/CdrPageLoader';

import { Imprint } from './Imprint';

const ImprintPage = () => {
  const { onSave, viewImprint, canViewImprint, imprintForm, initialValues } =
    useImprint();

  return (
    <CdPage
      pageHeaderProps={{
        title: gettextCatalog.getString('Imprint'),
        extra: [
          <Button
            key="viewImprint"
            icon={<CdEyeIcon />}
            disabled={canViewImprint}
            onClick={viewImprint}
          >
            {gettextCatalog.getString('View')}
          </Button>,
          <Button
            key="saveImprint"
            type="primary"
            icon={<CdSave />}
            onClick={onSave}
          >
            {gettextCatalog.getString('Save')}
          </Button>,
        ],
      }}
    >
      <Imprint imprintForm={imprintForm} initialValues={initialValues} />
    </CdPage>
  );
};

export default function ImprintWrapper() {
  return (
    <Suspense fallback={<CdrPageLoader />}>
      <ImprintPage />
    </Suspense>
  );
}
