import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Breadcrumb, Input, Row, Col, Layout, Select, Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

// Actions
import {
  loadFileArchiveBreadCrumb,
  loadFileArchiveFiles,
  onFileArchiveFileSelected,
  onFileArchiveFolderSelected,
  closeModal,
  onFileArchivePageChange,
  onFileArchivePageSizeChange,
  fileArchiveUpdateFile,
  fileArchiveDeleteFile,
  fileArchiveSearch,
  fileArchiveOrderingChange,
} from '../../store/actions';
import {
  FileArchive,
  FileArchiveBreadCrumb,
  Ordering,
  SearchType,
} from '../../store/actions/filearchive';
import Upload from '../file-upload';
import FileList from '../../components/file-list';
import { Settings } from '../../services/me';
import { FilePermissions, useHasPermission } from '../file-upload/hooks';
import mountingContainer from '../mountContainer';

import {
  makeSelectFiles,
  makeSelectSelectedFile,
  makeSelectBreadcrumb,
  makeSelectCallback,
  makeSelectSelectedFolderId,
  makeSelectCurrentPage,
  makeSelectPageSize,
  makeSelectTotalCount,
  makeSelectTags,
  makeSelectReloadOnFileUpdateOrDelete,
  makeSelectFilesLoading,
  makeSelectSearch,
  makeSelectOrdering,
  makeSelectIsUsedFromEditor,
} from './selectors';
import SideBar from './side-bar';
import EditModal from './edit';
import CreateFolder from './create-folder';

import cdApp from '@/react/config';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import gettextCatalog from '@/react/services/I18nService';

const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

interface OwnProps {
  isUsedFromEditor: boolean;
  callback: any;
}

const StyledBreadcrumb = styled(Breadcrumb)`
  > span {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;

    &:last-child {
      color: rgba(0, 0, 0, 0.65);
      cursor: not-allowed;
    }
  }
`;

interface Props {
  items: FileArchive[];
  breadcrumb: FileArchiveBreadCrumb[];
  selectedFile: FileArchive | undefined;
  selectedFolderId: number | undefined;
  count: number;
  pageSize: number;
  currentPage: number;
  tags: string[];
  reloadOnFileUpdateOrDelete: string;
  filesLoading: boolean;
  search: SearchType;
  ordering: Ordering;
  isUsedFromEditor: boolean;
  loadFileArchiveFiles: any;
  loadFileArchiveBreadCrumb: any;
  onFileArchiveFileSelected: any;
  onFileArchiveFolderSelected: any;
  callback: any;
  onFileArchivePageChange: any;
  onFileArchivePageSizeChange: any;
  closeModal: any;
  fileArchiveUpdateFile: any;
  fileArchiveDeleteFile: any;
  setSearchState: (search: SearchType) => void;
  onOrderChange: any;
}

const mapDispatchToProps = (
  dispatch: React.Dispatch<{ type: string; payload?: any }>
) => ({
  loadFileArchiveFiles: (payload) => dispatch(loadFileArchiveFiles(payload)),
  loadFileArchiveBreadCrumb: (folderId) =>
    dispatch(loadFileArchiveBreadCrumb(folderId)),
  onFileArchiveFileSelected: (fileId) =>
    dispatch(onFileArchiveFileSelected(fileId)),
  onFileArchiveFolderSelected: (folderId) =>
    dispatch(onFileArchiveFolderSelected(folderId)),
  onFileArchivePageChange: (page) => dispatch(onFileArchivePageChange(page)),
  onFileArchivePageSizeChange: (pageSize) =>
    dispatch(onFileArchivePageSizeChange(pageSize)),
  closeModal: () => dispatch(closeModal()),
  fileArchiveUpdateFile: (fileId, file) =>
    dispatch(fileArchiveUpdateFile(fileId, file)),
  fileArchiveDeleteFile: (fileId) => dispatch(fileArchiveDeleteFile(fileId)),
  setSearchState: (search) => dispatch(fileArchiveSearch(search)),
  onOrderChange: (payload) => dispatch(fileArchiveOrderingChange(payload)),
});

const mapStateToProps = createStructuredSelector({
  items: makeSelectFiles,
  selectedFile: makeSelectSelectedFile,
  breadcrumb: makeSelectBreadcrumb(),
  callback: makeSelectCallback(),
  selectedFolderId: makeSelectSelectedFolderId(),
  count: makeSelectTotalCount(),
  currentPage: makeSelectCurrentPage(),
  pageSize: makeSelectPageSize(),
  tags: makeSelectTags(),
  reloadOnFileUpdateOrDelete: makeSelectReloadOnFileUpdateOrDelete(),
  filesLoading: makeSelectFilesLoading(),
  search: makeSelectSearch,
  ordering: makeSelectOrdering,
  isUsedFromEditor: makeSelectIsUsedFromEditor(),
});

const FileArchives: React.FC<Props> = ({
  items,
  loadFileArchiveFiles,
  loadFileArchiveBreadCrumb,
  onFileArchiveFileSelected,
  onFileArchiveFolderSelected,
  breadcrumb,
  selectedFile,
  selectedFolderId,
  tags,
  count,
  currentPage,
  pageSize,
  reloadOnFileUpdateOrDelete,
  filesLoading,
  search,
  ordering,
  isUsedFromEditor,
  callback,
  onFileArchivePageChange,
  onFileArchivePageSizeChange,
  closeModal,
  fileArchiveUpdateFile,
  fileArchiveDeleteFile,
  setSearchState,
  onOrderChange,
}) => {
  const [editModalState, setEditModalState] = useState(false);
  const creditSettings: Settings = useSelector(
    (state) => state.filepicker?.lookups.settings
  );
  const canChangeVisibility = useHasPermission()(
    FilePermissions.changeVisibility
  );
  const [orderBy, setOrderByState] = useState('alphabeticalAsc');
  useEffect(() => {
    loadFileArchiveBreadCrumb(selectedFolderId);
    loadFileArchiveFiles({
      limit: pageSize,
      offset: ((currentPage || 1) - 1) * pageSize,
      folderId: selectedFolderId || 'root',
      imagesOnly: true,
      search,
      ...ordering,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFolderId,
    currentPage,
    pageSize,
    reloadOnFileUpdateOrDelete,
    search,
    ordering,
  ]);

  const onFileSave = (id, values) => {
    setEditModalState(false);
    fileArchiveUpdateFile(id, values);
  };

  const onImportFile = (file, imageFormat) => {
    callback(file, imageFormat);
    closeModal();
  };

  const showDeleteConfirm = (item) => {
    showConfirmModal({
      title: gettextCatalog.getString(
        'Are you sure you want to delete this file {{title}}?',
        {
          title: item.title,
        }
      ),
      message: gettextCatalog.getString('This action cannot be undone.'),
      okText: gettextCatalog.getString('Delete'),
      okButtonProps: { danger: true },
      cancelText: gettextCatalog.getString('Cancel'),
      onOk() {
        fileArchiveDeleteFile(item.id);
      },
    });
  };

  const onSearch = (value: string) => {
    let searchIn = search.searchIn;
    if (!value) {
      searchIn = 'searchInFolder';
    }
    setSearchState({ ...search, value, searchIn });
  };

  const onBreadcrumbNavigation = (e, folderId?: number) => {
    e.preventDefault();
    onFileArchiveFolderSelected(folderId);
  };

  const orderSetting = {
    alphabeticalAsc: {
      orderBy: 'title',
      orderDirection: 'ASC',
    },
    alphabeticalDesc: {
      orderBy: 'title',
      orderDirection: 'DESC',
    },
    newestFirst: {
      orderBy: 'createdAt',
      orderDirection: 'DESC',
    },
    oldestFirst: {
      orderBy: 'createdAt',
      orderDirection: 'ASC',
    },
  };

  const handleOrderChange = (value: string) => {
    onOrderChange(orderSetting[value]);
    setOrderByState(value);
  };

  const onSearchTypeChange = (searchIn) => {
    setSearchState({ ...search, searchIn });
  };

  const onFolderSelected = (folderId) => {
    setSearchState({ value: '', searchIn: 'searchInFolder' });
    onFileArchiveFolderSelected(folderId);
  };

  const searchDropdownOptions = (
    <Select
      key={search.searchIn}
      defaultValue={search.searchIn}
      getPopupContainer={mountingContainer}
      onChange={onSearchTypeChange}
    >
      <Option value="searchInAll">
        {gettextCatalog.getString('All folders')}
      </Option>
      <Option value="searchInFolder">
        {gettextCatalog.getString('Current folder')}
      </Option>
    </Select>
  );

  const getSearchOrderTitle = (orderKey) => {
    switch (orderKey) {
      case 'alphabeticalDesc':
        return gettextCatalog.getString('Alphabetical (Z-A)');
        break;
      case 'newestFirst':
        return gettextCatalog.getString('Newest first');
        break;
      case 'oldestFirst':
        return gettextCatalog.getString('Oldest first');
        break;
      default:
        return gettextCatalog.getString('Alphabetical (A-Z)');
        break;
    }
  };

  return (
    <Layout style={{ background: 'none' }}>
      <Content style={{ padding: '0 0 12px' }}>
        <Row align="middle" style={{ padding: '8px 12px 0' }}>
          <Col flex="1" style={{ margin: '6px 0' }}>
            <div style={{ display: 'flex' }}>
              <Upload
                style={{ marginRight: 10 }}
                onFinish={() => {
                  setOrderByState('newestFirst');
                  onOrderChange(orderSetting.newestFirst);
                  onFileArchivePageChange(1);
                }}
                folder={(() => {
                  if (breadcrumb.length === 0) return undefined;
                  return breadcrumb[breadcrumb.length - 1];
                })()}
                accept="image/*"
              />
              <CreateFolder
                folderId={selectedFolderId || -1}
                folderName={(() => {
                  if (breadcrumb.length === 0) {
                    return gettextCatalog.getString('Main folder');
                  }
                  return breadcrumb[breadcrumb.length - 1].title;
                })()}
                groupId={
                  breadcrumb.length === 0
                    ? null
                    : breadcrumb[breadcrumb.length - 1].groupId
                }
                onFinish={() => {
                  setOrderByState('newestFirst');
                  onOrderChange(orderSetting.newestFirst);
                  onFileArchivePageChange(1);
                }}
              />
            </div>
          </Col>
          <Col flex="1" style={{ textAlign: 'right' }}>
            <div style={{ display: 'flex' }}>
              <Select
                key={orderBy}
                style={{ marginRight: 10 }}
                defaultValue={orderBy}
                onChange={handleOrderChange}
                popupMatchSelectWidth={160}
                getPopupContainer={mountingContainer}
              >
                {Object.keys(orderSetting).map((orderKey) => (
                  <Select.Option value={orderKey} key={orderKey}>
                    {getSearchOrderTitle(orderKey)}
                  </Select.Option>
                ))}
              </Select>
              <Search
                addonBefore={searchDropdownOptions}
                enterButton
                placeholder={gettextCatalog.getString(
                  'Search files and folders'
                )}
                onSearch={onSearch}
                allowClear
                style={{ width: 350 }}
                key={search.value}
                defaultValue={search.value}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ padding: '16px 12px 8px' }}>
          <Col>
            {search.searchIn === 'searchInFolder' ? (
              <StyledBreadcrumb
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                separator=">"
              >
                <Breadcrumb.Item onClick={(e) => onBreadcrumbNavigation(e)}>
                  <FontAwesomeIcon icon={faHome} style={{ marginRight: 5 }} />{' '}
                  {gettextCatalog.getString('Main folder')}
                </Breadcrumb.Item>
                {(breadcrumb || []).map((folder) => (
                  <Breadcrumb.Item
                    key={`folder-${folder.id}`}
                    onClick={(e) => onBreadcrumbNavigation(e, folder.id)}
                  >
                    <span>{folder.title}</span>
                  </Breadcrumb.Item>
                ))}
              </StyledBreadcrumb>
            ) : null}
          </Col>
        </Row>
        {cdApp?.organization?.myGroups &&
        cdApp.organization.myGroups.length > 0 ? (
          <FileList
            items={items}
            currentPage={currentPage}
            selectedFile={selectedFile}
            showSizeChanger={false}
            count={count}
            pageSize={pageSize}
            loading={filesLoading}
            onFileSelected={onFileArchiveFileSelected}
            onFolderSelected={onFolderSelected}
            onPageSizeChange={onFileArchivePageSizeChange}
            onPageChange={onFileArchivePageChange}
          />
        ) : (
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '500px',
              alignItems: 'center',
            }}
          >
            <Col>
              <Alert
                description={gettextCatalog.getString(
                  'To access files you must be a group member!'
                )}
                type="warning"
                showIcon
              />
            </Col>
          </Row>
        )}
      </Content>
      <SideBar
        item={selectedFile}
        onImportFile={onImportFile}
        onEdit={() => setEditModalState(true)}
        onDelete={(item) => showDeleteConfirm(item)}
        isUsedFromEditor={isUsedFromEditor}
      />
      <EditModal
        key={selectedFile ? selectedFile.id : 1}
        file={selectedFile || {}}
        creditSettings={creditSettings}
        open={editModalState}
        onFileSave={onFileSave}
        onCancel={() => setEditModalState(false)}
        tagsList={tags}
        canChangeVisibility={canChangeVisibility}
      />
    </Layout>
  );
};

const connectedFileArchive: React.ComponentType<OwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileArchives);

export default connectedFileArchive;
