import { useState, useMemo } from 'react';
import { clone, orderBy } from 'lodash';
import moment from 'moment';
import { RecoilValue, useRecoilValueLoadable } from 'recoil';

import { CdTableData, onTableChangeProps } from './types';

import { OrderType, TableOrdering } from '@/react/shared/models/table';

export interface useCdTableProps<T> {
  recoilSource: RecoilValue<{ items: T[]; total: number }>;
  defaultPageSize?: number;
  filters?: ((item: T, index: number) => boolean)[];
  orderFunctions?: OrderFunctions<T>;
}

export interface OrderFunctions<T> {
  [orderType: string]: (order: TableOrdering, data: T[]) => T[];
}

export interface useCdTableResult<T> {
  data: CdTableData<T>;
  handleTableChange: (
    pageNumber: number,
    pageSize?: number,
    order?: TableOrdering
  ) => void;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const defaultOrderFunctions = <T>() => ({
  [OrderType.DATE]: (order: TableOrdering, data: T[]) =>
    orderBy(data, (item) => moment(item[order.orderBy]).unix(), [
      (order?.orderDirection?.toLowerCase() as 'asc' | 'desc') || 'asc',
    ]),

  [OrderType.STRING]: (order: TableOrdering, data: T[]) => {
    const orderDirection =
      (order?.orderDirection?.toLowerCase() as 'asc' | 'desc') || 'asc';
    const orderByField = order?.orderBy || 'name';
    return data.map(clone).sort((a, b) => {
      if (orderDirection === 'asc') {
        return a[orderByField]?.localeCompare(b[orderByField]);
      } else {
        return b[orderByField]?.localeCompare(a[orderByField]);
      }
    });
  },
  [OrderType.NUMBER]: (order: TableOrdering, data: T[]) => {
    const orderDirection =
      (order?.orderDirection?.toLowerCase() as 'asc' | 'desc') || 'asc';
    const orderByField = order?.orderBy || 'name';
    return data.map(clone).sort((a, b) => {
      if (orderDirection === 'asc') {
        return a[orderByField] - b[orderByField];
      } else {
        return b[orderByField] - a[orderByField];
      }
    });
  },
});

const orderFunc = <T>(
  order: TableOrdering,
  orderFunctions: OrderFunctions<T>,
  data: T[]
): T[] => {
  if (!order) {
    return data;
  }

  const combinedOrderFunctions: OrderFunctions<T> = {
    ...defaultOrderFunctions<T>(),
    ...orderFunctions,
  };
  if (combinedOrderFunctions[order.orderType]) {
    return combinedOrderFunctions[order.orderType](order, data);
  } else {
    const orderByField = order?.orderBy || 'name';
    if (
      data &&
      data[0] &&
      (typeof data[0][orderByField] === 'string' ||
        data[0][orderByField] instanceof String)
    ) {
      return combinedOrderFunctions[OrderType.STRING](order, data);
    } else {
      return orderBy(data, orderByField, [
        (order?.orderDirection?.toLowerCase() as 'asc' | 'desc') || 'asc',
      ]);
    }
  }
};

export function useCdTable<T>({
  recoilSource,
  defaultPageSize = 30,
  filters,
  orderFunctions,
}: useCdTableProps<T>) {
  const dataLoadable = useRecoilValueLoadable(recoilSource);
  const [order, setOrder] = useState<TableOrdering>(null);
  const [pageInfo, setPageInfo] = useState<{ pageSize; pageNumber }>({
    pageSize: defaultPageSize,
    pageNumber: 1,
  });
  const data = useMemo(() => {
    if (dataLoadable.state === 'hasValue') {
      let filteredData = dataLoadable.contents.items?.filter((item, idx) =>
        filters ? filters.some((filter) => filter(item, idx)) : true
      );
      if (order) {
        filteredData = orderFunc<T>(order, orderFunctions, filteredData);
      }
      const startPosition = (pageInfo.pageNumber - 1) * defaultPageSize;
      // in case that the last page is empty, we need to go back one page
      if (
        startPosition >= filteredData?.length &&
        startPosition >= defaultPageSize // don't go back if we are on the first page
      ) {
        setPageInfo({
          pageNumber: pageInfo.pageNumber - 1,
          pageSize: defaultPageSize,
        });
      }
      return {
        items: filteredData?.slice(
          startPosition,
          startPosition + defaultPageSize
        ),
        total: filteredData?.length,
      };
    } else {
      return { items: [], total: 0 };
    }
  }, [
    filters,
    dataLoadable.state,
    dataLoadable.contents.items,
    order,
    pageInfo,
    defaultPageSize,
    orderFunctions,
  ]);

  const handleTableChange: onTableChangeProps = (
    pageNumber,
    pageSize = defaultPageSize,
    ordering
  ) => {
    if (pageNumber > 0) {
      setPageInfo({ pageSize, pageNumber });
    }

    if (ordering) {
      setOrder(ordering);
    }
  };

  return {
    data: data,
    handleTableChange,
  } as useCdTableResult<T>;
}
