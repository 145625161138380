import React, { Suspense } from 'react';
import { Col, Form, Row, Space, Spin } from 'antd';

import { MINIMUM_COLUMN_WIDTH } from '../../constants';
import getTextCatalog from '../../../../services/I18nService';
import { FieldSection } from '../FieldSection';

import CommentList from './CommentList';
import CommentEditor from './CommentEditor';

interface CommentsProps {
  eventId: any;
}

const Comments = (props: CommentsProps) => {
  const { eventId } = props;

  return (
    <Row gutter={16}>
      <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
        <FieldSection>
          <Form.Item label={getTextCatalog.getString('Comments')}>
            <Suspense fallback={<Spin />}>
              <Space direction="vertical" style={{ width: '100%' }} size={16}>
                <CommentList eventId={eventId} />
                <CommentEditor eventId={eventId} />
              </Space>
            </Suspense>
          </Form.Item>
        </FieldSection>
      </Col>
    </Row>
  );
};

export default Comments;
