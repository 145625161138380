/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { Button, Form, Select, Input, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FoundationTypes } from '../../models/foundation';
import gettextCatalog from '../../../services/I18nService';
import { paymentMethods } from '../../shared/intentionCommon';
import { IntentionFee, IntentionFeeTypes } from '../../../settings/models/fee';
import { fetchIntentionFees } from '../../../settings/redux/intention-fees/Actions';
import { getIntentionFees } from '../../../settings/redux/intention-fees/Selectors';
import { getChurches } from '../../../shared/store/resources';
import { getFilteredFees } from '../../../settings/redux/intention-fees/data.converter';
import { StyledInlineForm } from '../../../shared/antd/StyledAntdComponents';

// Antd
const { Option } = Select;

// Props
interface FoundationsOverviewFiltersProps {
  foundationType: FoundationTypes;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
}

const FoundationsOverviewFilters: FunctionComponent<
  FoundationsOverviewFiltersProps
> = ({ foundationType, onChange }) => {
  // Redux
  const dispatch = useDispatch();

  const churches = useSelector(getChurches);
  const allIntentionFees = useSelector(getIntentionFees);
  const intentionFees: IntentionFee[] = getFilteredFees(allIntentionFees);
  const [numberTo, setNumberTo] = useState<number>();
  const [numberFrom, setNumberFrom] = useState<number>();
  const [form] = Form.useForm();

  const referenceNumberType =
    foundationType === FoundationTypes.STIFTUNGEN ? 'S' : 'L';

  useEffect(() => {
    dispatch(
      fetchIntentionFees({
        type: IntentionFeeTypes.INTENTIONEN,
        activeOnly: true,
      })
    );
  }, []);

  const search = (formValues) => {
    const filters = {
      churchIds: _.isEmpty(formValues.churchIds)
        ? undefined
        : formValues.churchIds,
      feeIds: _.isEmpty(formValues.feeIds) ? undefined : formValues.feeIds,
      paymentMethods: _.isEmpty(formValues.paymentMethods)
        ? undefined
        : formValues.paymentMethods,
      referenceNumberFrom: numberFrom ? numberFrom : undefined,
      referenceNumberTo: numberTo ? numberTo : undefined,
      searchValue: _.get(formValues, 'searchText')
        ? formValues.searchText
        : undefined,
      type: foundationType,
    };
    onChange(filters);
  };

  return (
    <React.Fragment>
      <StyledInlineForm
        id="foundationFiltersForm"
        onFinish={search}
        form={form}
        layout="inline"
        style={{ marginBottom: 10 }}
      >
        {/* Church filter */}
        <Form.Item name="churchIds">
          <Select
            mode="multiple"
            placeholder={gettextCatalog.getString('Parishes')}
            allowClear
            style={{ minWidth: 150 }}
            maxTagPlaceholder={(selectedItems) => {
              if (selectedItems.length > 1) {
                return `${selectedItems.length} ${gettextCatalog.getString(
                  'Parishes'
                )}`;
              } else {
                return `${selectedItems.length} ${gettextCatalog.getString(
                  'Parish'
                )}`;
              }
            }}
            maxTagCount={0}
          >
            {churches &&
              churches.map((church) => (
                <Option key={church.id} value={church.id}>
                  {_.get(church, 'name')}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {/* Intention types */}
        <Form.Item name="feeIds">
          <Select
            mode="multiple"
            placeholder="Intentionsart"
            allowClear
            style={{ minWidth: 150 }}
            maxTagPlaceholder={(selectedItems) => {
              if (selectedItems.length > 1) {
                return `${selectedItems.length} ${gettextCatalog.getString(
                  'Intention Types'
                )}`;
              } else {
                return `${selectedItems.length} ${gettextCatalog.getString(
                  'Intention Type'
                )}`;
              }
            }}
            maxTagCount={0}
          >
            {(intentionFees || []).map((fee) => (
              <Option key={fee.id} value={fee.id}>
                {_.get(fee, 'name')}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* Payment methods */}
        <Form.Item name="paymentMethods">
          <Select
            mode="multiple"
            placeholder={gettextCatalog.getString('Payment methods')}
            allowClear
            style={{ minWidth: 150 }}
            maxTagPlaceholder={(selectedItems) => {
              if (selectedItems.length > 1) {
                return `${selectedItems.length} ${gettextCatalog.getString(
                  'Methods'
                )}`;
              } else {
                return `${selectedItems.length} ${gettextCatalog.getString(
                  'Method'
                )}`;
              }
            }}
            maxTagCount={0}
          >
            {(paymentMethods || []).map((paymentMethod) => (
              <Option key={paymentMethod.id} value={paymentMethod.id}>
                {_.get(paymentMethod, 'name')}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* Founder or intention text */}
        <Form.Item name="searchText">
          <Input placeholder={gettextCatalog.getString('Text...')} allowClear />
        </Form.Item>
        {/* Search on number */}
        <Form.Item
          name="numberSearch"
          label={gettextCatalog.getString('Search on number')}
          style={{ minWidth: 'max-content', maxWidth: 'fit-content' }}
        >
          <Input.Group compact style={{ display: 'flex' }}>
            <Input
              style={{
                width: 35,
                borderRight: 0,
                pointerEvents: 'none',
                background: '#fff',
              }}
              placeholder={referenceNumberType}
              disabled
            />
            {/* Number from */}
            <InputNumber
              style={{ width: 100, textAlign: 'center' }}
              placeholder={gettextCatalog.getString('Minimum')}
              onChange={(numberFrom: number) => setNumberFrom(numberFrom)}
            />
            <Input
              style={{
                width: 45,
                borderRight: 0,
                pointerEvents: 'none',
                textAlign: 'center',
              }}
              placeholder={gettextCatalog.getString('to')}
              disabled
            />
            {/* Number tp */}
            <InputNumber
              style={{
                width: 100,
                textAlign: 'center',
              }}
              placeholder={gettextCatalog.getString('Maximum')}
              onChange={(numberTo: number) => setNumberTo(numberTo)}
            />
          </Input.Group>
        </Form.Item>

        {/* Search */}
        <Form.Item style={{ minWidth: 90 }}>
          <Button form="foundationFiltersForm" htmlType="submit" type="primary">
            <FontAwesomeIcon icon={faSearch} style={{ marginRight: 8 }} />
            {gettextCatalog.getString('Search')}
          </Button>
        </Form.Item>
      </StyledInlineForm>
    </React.Fragment>
  );
};

export default FoundationsOverviewFilters;
