import _ from 'lodash';
import { Select } from 'antd';
import styled from 'styled-components';
import { SelectProps } from 'antd/lib/select';
import { useSelector, useDispatch } from 'react-redux';
import React, { FunctionComponent, useEffect } from 'react';

import { CategoryType } from '../models/category';
import { fetchCategories } from '../store/Actions';
import { getAllCategories } from '../store/Selectors';
import gettextCatalog from '../../services/I18nService';
import { getCategoriesByType } from '../store/data.converter';

const { Option } = Select;

export const StyledDiv = styled.div`
  list-style-type: none;
  line-height: 25px;

  .bullet-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #eee;
    }

    .bullet-label {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      padding: 2px 0px;

      .label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 21px);
        padding: 0;
        color: #333;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6;

        .name {
          display: block;
          width: 100%;
          font-size: 13px;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }

        small {
          width: 100%;
          line-height: 1.4;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .bullet {
        margin-right: 5px;
        border-radius: 50%;
        min-width: 10px;
        min-height: 10px;
        display: block;
        cursor: pointer;
        border-color: transparent;

        &.color-888 {
          background-color: #333;
          border-color: #333;
          color: white;
        }
      }
    }

    .filter-actions {
      display: none;
      padding-right: 5px;
    }

    &:hover {
      .filter-actions {
        display: block;

        a:hover {
          i {
            color: #333;
          }
        }
      }
    }
  }
`;

const TaxonomySelect: FunctionComponent<SelectProps<number[]>> = (props) => {
  const dispatch = useDispatch();
  const categories = useSelector(getAllCategories);
  const eventCategories = getCategoriesByType(categories, CategoryType.event);
  let matchedTaxonomies = [];

  // Initialization
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleSearch = (searchVal: string) => {
    const processedSearchVal = _.lowerCase(_.trim(searchVal));
    if (!_.isEmpty(processedSearchVal)) {
      matchedTaxonomies = _.filter(eventCategories, (eventCategory) => {
        const processedEventCategoryName = _.lowerCase(eventCategory.name);
        return _.includes(processedEventCategoryName, processedSearchVal);
      });
    } else {
      matchedTaxonomies = [];
    }
  };

  return (
    <Select
      showSearch
      mode="multiple"
      placeholder={gettextCatalog.getString('Search for taxonomies')}
      onSearch={handleSearch}
      style={{ width: '100%' }}
      filterOption={false}
      notFoundContent={null}
      allowClear={true}
      {...props}
    >
      {(!_.isEmpty(matchedTaxonomies)
        ? matchedTaxonomies
        : eventCategories
      ).map((t) => (
        <Option key={t.id} value={t.id}>
          <StyledDiv>
            <div className="bullet-wrapper">
              <div className="bullet-label">
                <span className={`bullet color-${t.color || 0} bullet-full`} />
                <div className="label">
                  <span className="name">{t.name}</span>
                </div>
              </div>
            </div>
          </StyledDiv>
        </Option>
      ))}
    </Select>
  );
};

export default TaxonomySelect;
