import { InterfaceGroups } from '@/react/organization/services/Groups.service';
import cdApp from '@/react/config';

export const formatOrganizationGroups = (
  groups: InterfaceGroups[],
  VisibilityAllowedValues: string[]
) => {
  let validGroups = groups;
  const userGroups = cdApp.organization.myGroups;
  if (
    VisibilityAllowedValues.length < 3 &&
    VisibilityAllowedValues.includes('internal-group')
  ) {
    validGroups = groups.filter((group) => userGroups.includes(group.id));
  }
  return validGroups
    .map((item) => ({
      value: item.id,
      label: item.name,
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
};
