import { Alert } from 'antd';
import React from 'react';
import styled from 'styled-components';

import gettextCatalog from '@/react/services/I18nService';

const Container = styled.main`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
`;

const ErrorPage = () => (
  <Container>
    <Alert
      message={gettextCatalog.getString(
        'The link you clicked has expired. Please try again.'
      )}
      type="warning"
      showIcon
      style={{ height: '50px', width: '600px', marginTop: '100px' }}
    ></Alert>
  </Container>
);

export default ErrorPage;
