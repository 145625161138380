import { get } from 'lodash';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, List, Skeleton, Tag, Button } from 'antd';
import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';

import gettextCatalog from '../../services/I18nService';
import { navigate } from '../../services/StateServiceFactory';
import { CD_REDACTED_VALUE } from '../../shared/constants';
import { selectTaxonomyById } from '../../taxonomies/store';
import { getUserDisplayName } from '../../user/redux/selectors';
import { FEvent } from '../models/calendar';
import { AbsenceSelectors } from '../store/absences/absencesSlice';
import { fullCalendarHooks } from '../store/events/fullCalendarEventsSlice';

import { getAbsenceActions, AbsenceDescription } from './CalendarEventPopover';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const AbsenceListItem: FunctionComponent<{ id: number }> = ({ id }) => {
  const absence: FEvent = useSelector(AbsenceSelectors.absenceById(id));
  const user = useSelector(AbsenceSelectors.absenceUser(id));
  const userName = useSelector(getUserDisplayName(user ? user.id : -1));
  // const userImage = user.picture ? <Avatar size={25} src={user.picture} style={{ marginRight: 8 }} /> : <Avatar size={25} icon={<FontAwesomeIcon icon={faUser} />} style={{ marginRight: 8 }} />;
  const category = useSelector(
    selectTaxonomyById(absence ? absence.mainCategory : -1)
  );
  const subtitute = get(absence, 'substitute');
  const editEventDispatch = fullCalendarHooks.useEditEvent();
  const deleteEventDispatch = fullCalendarHooks.useDeleteEvent();

  const startEditEvent = useCallback(() => {
    editEventDispatch({ eventId: id, type: 'absence' });
  }, [editEventDispatch, id]);
  const startDeleteEvent = useCallback(() => {
    deleteEventDispatch(id);
  }, [deleteEventDispatch, id]);

  return !absence || !user ? (
    <div className="event-data__section">
      <Skeleton active avatar loading>
        <List.Item>
          <List.Item.Meta avatar={<Avatar />} title="......" />
        </List.Item>
      </Skeleton>
    </div>
  ) : (
    <List.Item
      actions={getAbsenceActions(absence, startEditEvent, startDeleteEvent)}
    >
      <List.Item.Meta
        avatar={
          // <Avatar size="small" src={user.picture ? user.picture : null} icon={!user.picture ? <FontAwesomeIcon icon={faUser} /> : null} />
          user.picture ? (
            <Avatar size={25} src={user.picture} style={{ marginRight: 8 }} />
          ) : (
            <Avatar
              size={25}
              icon={<FontAwesomeIcon icon={faUser} />}
              style={{ marginRight: 8 }}
            />
          )
        }
        title={
          <>
            <CdTooltip title={gettextCatalog.getString('Open absence')}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  alignItems: 'center',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                <Button
                  type="link"
                  onClick={() =>
                    navigate(`app.private.calendar.absence`, { id })
                  }
                  style={{ margin: 0, padding: 0 }}
                >
                  {userName}
                </Button>
              </div>
            </CdTooltip>

            <div className="u-flex">
              <Tag
                title={category ? category.name : CD_REDACTED_VALUE}
                color="default"
                style={{ marginLeft: 5, marginRight: 0, flex: 50 }}
              >
                <span
                  style={{
                    maxWidth: 80,
                    overflow: 'hidden',
                    display: 'block',
                    fontSize: '80%',
                  }}
                >
                  {category ? category.name : CD_REDACTED_VALUE}
                </span>
              </Tag>
              {subtitute ? (
                <Tag
                  title={subtitute}
                  color="default"
                  style={{ marginLeft: 5, marginRight: 0, flex: 50 }}
                >
                  <span
                    style={{
                      maxWidth: 150,
                      overflow: 'hidden',
                      display: 'block',
                      fontSize: '80%',
                    }}
                  >
                    {gettextCatalog.getString('Sub', null, 'Substitute') +
                      ': ' +
                      subtitute}
                  </span>
                </Tag>
              ) : null}
            </div>
          </>
        }
        description={
          <div className="text-sm">
            <AbsenceDescription absence={absence} />
          </div>
        }
      />
    </List.Item>
  );
};
