import React, { Suspense, useEffect } from 'react';
import { Button } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { useRecoilRefresher_UNSTABLE as useRecoilRefresher } from 'recoil';

import { FormLabels } from '../store/form';
import useCdFormLabel from '../hooks/useCdFormLabel';
import { FormLabel } from '../types';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import getTextCatalog from '@/react/services/I18nService';
import {
  ActionMenuDivider,
  ActionMenuItem,
  ActionMenuType,
} from '@/react/shared/components/cd-table';
import {
  CdDeleteIcon,
  CdEditIcon,
  CdEyeIcon,
  CdFormLabels,
} from '@/react/shared/components/Icons';
import { navigate } from '@/react/services/StateServiceFactory';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';
import { OrderType } from '@/react/shared/models/table';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

function FormLabelTable() {
  const { deleteLabel } = useCdFormLabel();
  const refresh = useRecoilRefresher(FormLabels);
  const labelActionButtons = (): (
    | ActionMenuItem<FormLabel>
    | ActionMenuDivider
  )[] => [
    {
      text: getTextCatalog.getString('View forms'),
      icon: <CdEyeIcon />,
      disabled: false,
      onClick: ({ id }) => navigate('app.private.forms.list', { labelId: id }),
    },
    {
      text: getTextCatalog.getString('Edit name'),
      icon: <CdEditIcon />,
      disabled: false,
      onClick: (label) => NiceModal.show('EditLabel', { label }),
    },
    {
      type: ActionMenuType.DIVIDER,
    },
    {
      text: getTextCatalog.getString('Delete label'),
      icon: <CdDeleteIcon />,
      disabled: false,
      onClick: (label) =>
        showConfirmModal({
          title: getTextCatalog.getString('Delete label'),
          message: getTextCatalog.getString(
            'Deleting the label will remove the label from the respective forms. That means the forms with this label will no longer have a label associated'
          ),
          icon: <CdDeleteIcon />,
          okButtonProps: { danger: true },
          okText: getTextCatalog.getString('Delete'),
          onOk: () => deleteLabel(label),
        }),
    },
  ];

  // Fetch fresh data on evey load of the page.
  useEffect(() => refresh(), [refresh]);

  return (
    <CdSimpleTable<FormLabel>
      actionButtons={labelActionButtons()}
      recoilSource={FormLabels}
      emptyStateProps={{
        title: getTextCatalog.getString('No labels created yet'),
        subtitle: getTextCatalog.getString(
          'You can use labels to organise your forms easily'
        ),
        buttonText: getTextCatalog.getString('Create'),
        onButtonClick: () => NiceModal.show('CreateLabel'),
        EmptyStateIcon: <CdFormLabels />,
      }}
      columns={[
        {
          title: getTextCatalog.getString('Label'),
          dataIndex: 'label',
          sorter: true,
          orderType: OrderType.STRING,
        },
        {
          title: getTextCatalog.getString('Forms'),
          dataIndex: 'formsCount',
          sorter: true,
          orderType: OrderType.NUMBER,
        },
      ]}
    />
  );
}

export function FormLabelList() {
  return (
    <CdPage
      pageHeaderProps={{
        title: getTextCatalog.getString('Labels'),
        extra: (
          <Button type="primary" onClick={() => NiceModal.show('CreateLabel')}>
            {getTextCatalog.getString('Create')}
          </Button>
        ),
      }}
    >
      <FormLabelTable />
    </CdPage>
  );
}

export default function FormLabelListHOC(props) {
  return (
    <Suspense fallback={<CdrPageLoader />}>
      <FormLabelList {...props} />
    </Suspense>
  );
}
