import React, { Dispatch, useEffect, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Result } from 'antd';
import styled from 'styled-components';

import gettextCatalog from '@/react/services/I18nService';
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;
interface ManageSubscriptionProps {
  $stateParams: { organizationId: number; token: string };
}
const ManageSubscription = ({ $stateParams }: ManageSubscriptionProps) => {
  const showManageSubscriptionsAndConsentsModal = (
    setResultMessage: Dispatch<boolean>,
    organizationId: number,
    token: string
  ) =>
    NiceModal.show('ManageSubscriptionsAndConsents', {
      setResultMessage,
      organizationId,
      token,
    });
  const [resultMessage, setResultMessage] = useState(false);
  const organizationId = $stateParams.organizationId;
  const token = $stateParams.token;
  useEffect(() => {
    showManageSubscriptionsAndConsentsModal(
      setResultMessage,
      organizationId,
      token
    );
  }, [organizationId, token]);

  return (
    <Container>
      {resultMessage && (
        <Result
          status="success"
          title={gettextCatalog.getString('Successfully saved!')}
        />
      )}
    </Container>
  );
};

export default ManageSubscription;
