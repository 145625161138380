import React, { useCallback } from 'react';
import moment, { Moment } from 'moment';
import { $injector } from 'ngimport';
import { Button, Col, Typography, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import getTextCatalog from '@/react/services/I18nService';
import FacebookSetting from '@/react/calendar/event-details/components/cd-share-facebook/FacebookSetting';
import {
  EventHasBeenPublished,
  EventIsBeingPublishedFailed,
  EventIsBeingPublishedInProgress,
} from '@/react/calendar/event-details/components/cd-share-facebook/FacebookStatusComponents';
import { CdCheckboxWithIcon } from '@/react/shared/components/cd-checkbox-with-icon/CdCheckboxWithIcon';
import { EventIcons } from '@/react/shared/components/Icons';
import cdApp from '@/react/config';

export enum FacebookExportStatus {
  SCHEDULED = 'scheduled',
  POSTED = 'posted',
  FAILED = 'failed',
  DELETED = 'deleted',
}

export interface FacebookOptions {
  message: string;
  publish: boolean;
  isScheduled: boolean;
  schedulingOptions: {
    duration: number;
    durationUnit: string;
    hour: number;
    minute: number;
  };
  publishOn: string;

  publishedOn: string;
  exportStatus: FacebookExportStatus;
}

const CdSharingFacebook = (props: {
  value?: FacebookOptions;
  onChange?: (value: Partial<FacebookOptions>) => void;
  startDate: Moment;
  canEdit: boolean;
  allowFacebookSharing: boolean;
  disabled?: boolean;
}) => {
  const facebook = props.value;
  const isFacebookConfigured = !!cdApp.organization.facebook;

  const getFacebookStatus = useCallback(() => {
    if (facebook) {
      const { publishOn, exportStatus } = facebook;
      if (exportStatus && exportStatus === FacebookExportStatus.FAILED) {
        return 'failed';
      } else if (exportStatus && exportStatus === FacebookExportStatus.POSTED) {
        return 'published';
      } else if (publishOn && moment(publishOn).isBefore(moment())) {
        return 'in_progress';
      }
      return 'can_share';
    } else {
      return 'can_share';
    }
  }, [facebook]);

  const connectToFacebook = () => {
    const facebookService: any = $injector.get('facebookService');
    facebookService.authenticate();
  };
  return (
    <CdCheckboxWithIcon
      Icon={EventIcons.Facebook}
      checked={props.value?.publish}
      onChange={(e: CheckboxChangeEvent) => {
        if (!e.target.checked) {
          return props.onChange({ publish: false });
        }
        return props.onChange({
          ...props.value,
          publish: true,
          isScheduled: false,
        });
      }}
      disabled={
        props.disabled ||
        !props.allowFacebookSharing ||
        !props.canEdit ||
        getFacebookStatus() !== 'can_share' ||
        !isFacebookConfigured
      }
      iconSize="small"
      title={getTextCatalog.getString('Share as a post on your Facebook page')}
      align="start"
    >
      {isFacebookConfigured ? (
        <Col span={19}>
          {getFacebookStatus() === 'in_progress' && (
            <EventIsBeingPublishedInProgress />
          )}
          {getFacebookStatus() === 'published' && (
            <EventHasBeenPublished facebookInfo={facebook} />
          )}
          {getFacebookStatus() === 'failed' && <EventIsBeingPublishedFailed />}
        </Col>
      ) : (
        <Space direction="vertical">
          <Typography.Text type="warning" strong>
            {getTextCatalog.getString(
              'Your ChurchDesk is not linked to a Facebook page'
            )}
          </Typography.Text>
          <Typography.Text style={{ fontWeight: 400 }}>
            {getTextCatalog.getString(
              'Sharing the event on Facebook allows more people to discover it. Sharing your events also allows others to share it or invite their friends.'
            )}
          </Typography.Text>
          <Button
            style={{ marginTop: '16px' }}
            onClick={connectToFacebook}
            size="small"
            disabled={!props.canEdit && props.disabled}
          >
            {getTextCatalog.getString('Set up')}
          </Button>
        </Space>
      )}
      {props.value?.publish && (
        <FacebookSetting
          value={facebook}
          startDate={props.startDate}
          onChange={props.onChange}
          hasPublishedToFacebook={getFacebookStatus() === 'published'}
          disabled={props.disabled}
        />
      )}
    </CdCheckboxWithIcon>
  );
};

export default CdSharingFacebook;
