import React from 'react';
import { Space, Input, Select } from 'antd';

import { SafeGuardingListQuery } from '../store/Safeguarding';
import gettextCatalog from '../../services/I18nService';
import {
  SafeguardingListInterface,
  safeguardingTypeDropDown,
} from '../types/SafeguardingTypes';
import { useSafeguarding } from '../hooks/useSafeguarding';
import CdUnPagedTable from '../../shared/components/cd-unpaged-table/CdUnPagedTable';

import { safeguardingTableColumns } from './SafeguardingTableColumns';

const { Search } = Input;

export const SafeguardingList = ({ filter }: { filter: string }) => {
  const { canEdit, onChangeHandler, filterHandler } = useSafeguarding();

  return (
    <CdUnPagedTable<SafeguardingListInterface>
      columns={safeguardingTableColumns({ canEdit })}
      dataResult={SafeGuardingListQuery}
      emptyStateProps={{
        title: gettextCatalog.getString('No safeguarding records found'),
      }}
      extraParams={{
        filter: filter,
      }}
      filterForm={
        <Space>
          <Select
            style={{ width: 304 }}
            size="large"
            defaultValue={filter}
            options={safeguardingTypeDropDown()}
            onChange={filterHandler}
          />
          <Search
            placeholder={gettextCatalog.getString('Search...')}
            size="large"
            style={{ width: 304 }}
            onChange={onChangeHandler}
            allowClear
          />
        </Space>
      }
    />
  );
};
