import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { hasEventFormChanged } from '../services/checkIfEventFormHasChange';

import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import getTextCatalog from '@/react/services/I18nService';
import { goBack } from '@/react/services/StateServiceFactory';
import { EventInitializationBaseOnFormFields } from '@/react/calendar/store/events/event';

export interface UponCloseInterface {
  skipUnSavedCheck?: boolean;
  skipRedirecting?: boolean;
}
export const useCloseModal = ({ form, modal, isMainPage }) => {
  const initialFormValues = useRecoilValue(EventInitializationBaseOnFormFields);
  const closeDrawer = useCallback(
    async (skipRedirecting?: boolean) => {
      // We are forced it to removed with modal.remove to be able to open
      // the modal again with the completely new form.
      modal.hide();
      if (isMainPage) {
        // Drawer is being rendered as the top page. Closing it will result in a grey screen
        if (!skipRedirecting) {
          goBack('app.private.calendar.full');
        }
      }
      modal.remove();
    },
    [isMainPage, modal]
  );
  const close = useCallback(
    async (props?: UponCloseInterface) => {
      if (props?.skipUnSavedCheck) {
        closeDrawer(props?.skipRedirecting);
        return;
      }
      if (initialFormValues) {
        const currentFormValues = form.getFieldsValue();
        if (
          hasEventFormChanged({
            currentFormValues,
            initialFormValues,
          })
        ) {
          // If the form has unsaved changes, prompt the user to confirm leaving without saving
          showConfirmModal({
            title: getTextCatalog.getString('Close without saving?'),
            message: getTextCatalog.getString(
              'Your changes will not be saved. Close anyway?'
            ),
            okText: getTextCatalog.getString('Leave without saving'),
            cancelText: getTextCatalog.getString('Continue editing'),
            onOk: () => closeDrawer(props?.skipRedirecting),
          });
        } else {
          // If the form has no unsaved changes, close the form immediately
          closeDrawer(props?.skipRedirecting);
        }
      } else {
        // when user is creating a new event, prompt the user to confirm leaving without saving
        showConfirmModal({
          title: getTextCatalog.getString('Close without saving?'),
          message: getTextCatalog.getString(
            'Your changes will not be saved. Close anyway?'
          ),
          okText: getTextCatalog.getString('Leave without saving'),
          cancelText: getTextCatalog.getString('Continue editing'),
          onOk: () => closeDrawer(props?.skipRedirecting),
        });
      }
    },
    [closeDrawer, form, initialFormValues]
  );

  return { close };
};
