'use strict';

import _ from 'lodash';

const PlanningService = (
  Calendar,
  toastr,
  gettextCatalog,
  Analytics,
  $uibModal
) => {
  /**
   * Show modal for assigning users to a task.
   *
   * @param {Number} calendarId - The id of the event that the task is related to.
   * @param {Number} taskId - The id of the task.
   */
  const showAssignUsersToTaskModal = (calendarId, taskId) =>
    $uibModal.open({
      component: 'cdAssignUsersToTaskModal',
      windowClass: 'modal-ui-select',
      resolve: {
        calendarId: () => calendarId,
        taskId: () => taskId,
      },
    }).result;

  const validActions = ['assign', 'un-assign'];

  function saveTaskManagementChanges(payload, action, affectsLoggedInUser) {
    if (!_.includes(validActions, action)) {
      throw new Error(
        `Invalid action '${action}' specified when saving changes in rota management. One of '${validActions.join(
          ', '
        )}' must be specified.`
      );
    }
    return Calendar.update(payload)
      .$promise.then((savedEvent) => {
        // Notify the user
        const message =
          action === 'assign'
            ? affectsLoggedInUser
              ? gettextCatalog.getString('You have been assigned to the rota.')
              : gettextCatalog.getString('The user has been assigned.')
            : affectsLoggedInUser
              ? gettextCatalog.getString('You have been unassigned.')
              : gettextCatalog.getString('The user has been unassigned.');
        toastr.success(message);
        // Notify Analytics
        const analyticsEvent =
          action === 'assign'
            ? `assign ${affectsLoggedInUser ? 'myself' : 'user'} to rota`
            : `unassign ${affectsLoggedInUser ? 'myself' : 'user'} from rota`;
        Analytics.sendFeatureEvent(analyticsEvent, { module: 'planning' });
        // Returned the updated object
        return savedEvent;
      })
      .catch((err) => {
        if (err.status === 409) {
          toastr.info(
            gettextCatalog.getString(
              'We found one or more conflicts with your bookings.'
            )
          );

          return $uibModal.open({
            component: 'cdDoubleBookingModal',
            resolve: {
              conflicts: () => err.data,
              allowConflicts:
                () =>
                /**
                 * Should return a function that performs the allow conflicts action
                 */
                () =>
                  saveTaskManagementChanges(
                    _.extend(payload, { allowDoubleBooking: true }),
                    action,
                    affectsLoggedInUser
                  ),
            },
          }).result;
        } else {
          const defaultErrorMsg =
            action === 'assign'
              ? affectsLoggedInUser
                ? gettextCatalog.getString(
                    'An error occurred and you could not be assigned.'
                  )
                : gettextCatalog.getString(
                    'An error occurred and the user(s) could not be assigned.'
                  )
              : affectsLoggedInUser
                ? gettextCatalog.getString(
                    'An error occurred and you could not be unassigned.'
                  )
                : gettextCatalog.getString(
                    'An error occurred and the user(s) could not be unassigned.'
                  );

          const errorMsg = _.get(err, 'data.message', defaultErrorMsg);
          toastr.error(errorMsg);
          throw err;
        }
      });
  }

  return {
    showAssignUsersToTaskModal,
    saveTaskManagementChanges,
  };
};
PlanningService.$inject = [
  'Calendar',
  'toastr',
  'gettextCatalog',
  'Analytics',
  '$uibModal',
];

angular.module('cdApp.shared').factory('Planning', PlanningService);
