import React, { Suspense } from 'react';

import getTextCatalog from '@/react/services/I18nService';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import cdApp from '@/react/config';
import { CdTimeRegistrationIcon } from '@/react/shared/components/Icons';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

export const TimeRegistrationLandingPage = () => {
  const language = cdApp.organization.locale.language;
  let iframeUrl: string;
  switch (language) {
    case 'da':
      iframeUrl = 'https://churchdesk.com/da/time-reg-app/';
      break;
    case 'de':
      iframeUrl = 'https://churchdesk.com/de/time-reg-app/';
      break;
    default:
      iframeUrl = 'https://churchdesk.com/en/time-reg-app/';
      break;
  }

  return (
    <iframe
      data-testid="timeRegistrationIframe"
      src={iframeUrl}
      style={{
        width: '100%',
        height: '100%',
        border: '0px',
      }}
    ></iframe>
  );
};

export const TimeRegistrationLandingPageWrapper = () => (
  <Suspense fallback={<CdrPageLoader marginLess />}>
    <TimeRegistrationLandingPage />
  </Suspense>
);

export const TimeRegistrationIcon = () => (
  <CdTimeRegistrationIcon size="lg" style={{ margin: 0, color: 'white' }} />
);

export const showTimeRegistrationDisabledModal = () =>
  showConfirmModal({
    title: getTextCatalog.getString('Time Registration'),
    message: getTextCatalog.getString(
      "Your user doesn't have time registration enabled. If needed, ask your organization administrator to enable it."
    ),
    okButtonProps: { style: { display: 'none' } },
    onOk: () => {},
  });
