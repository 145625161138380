import React, { FunctionComponent } from 'react';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';

import Store from '../../redux';
import gettextCatalog from '../../services/I18nService';

import CreateStole, { CreateStoleProps } from './stoles/CreateStole';
import EventStole, { EventStoleProps } from './stoles/EventStole';

export const CreateStoleComponent: FunctionComponent<CreateStoleProps> = ({
  updateParentOnChange,
  mainTaxonomy,
  eventYear,
  selectedChurchIds,
  selectedResourceIds,
  calendarId,
}) => {
  const store = Store.getStore();
  return (
    <Provider store={store}>
      <ConfigProvider locale={gettextCatalog.getANTDesignLocale()}>
        <CreateStole
          updateParentOnChange={updateParentOnChange}
          mainTaxonomy={mainTaxonomy}
          eventYear={eventYear}
          selectedChurchIds={selectedChurchIds}
          selectedResourceIds={selectedResourceIds}
          calendarId={calendarId}
        />
      </ConfigProvider>
    </Provider>
  );
};

export const EventStoleComponent: FunctionComponent<EventStoleProps> = ({
  event,
}) => {
  const store = Store.getStore();
  return (
    <Provider store={store}>
      <ConfigProvider locale={gettextCatalog.getANTDesignLocale()}>
        <EventStole event={event} />
      </ConfigProvider>
    </Provider>
  );
};
