'use strict';

class CreateGroupMessageModalComponent {
  constructor(_, $scope, $uibModal, gettextCatalog, GroupMessage) {
    'ngInject';

    this._ = _;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.gettextCatalog = gettextCatalog;
    this.GroupMessage = GroupMessage;
  }

  $onInit() {
    this.loading = false;

    this.message = new this.GroupMessage(
      this._.extend(
        {
          type: 'group_message',
          groupId: this.resolve.groupId,
        },

        this.resolve.message
      )
    );

    this.$scope.$on('modal.closing', (event, reason, closed) => {
      if (!closed && reason !== 'BYPASS_CONFIRMATION') {
        const isChanged = !this._.isEqualWith(
          this.message,
          this.message.$pristine,
          (oldMessage, newMessage) =>
            oldMessage.title === newMessage.title &&
            oldMessage.message === newMessage.message
        );

        if (!isChanged) return;

        // If the title or body of the message changed, abort closing the modal
        event.preventDefault();
        // And prompt the user to confirm that they will discard the changes
        this.$uibModal
          .open({
            component: 'cdSimpleModal',
            resolve: {
              title: () => this.gettextCatalog.getString('Discard changes?'),
              body: () =>
                this.gettextCatalog.getString(
                  'Are you sure? All your changes will be lost.'
                ),

              options: {
                confirmButtonText: this.gettextCatalog.getString('Discard'),
                closeButtonText: this.gettextCatalog.getString('Continue'),
                confirmButtonType: 'danger',
              },
            },
          })
          .result.then(() => {
            this.dismiss({ $value: 'BYPASS_CONFIRMATION' });
          });
      }
    });
  }

  createMessage() {
    this.loading = true;

    this.message.$save(({ id }) => {
      this.loading = false;
      this.close({ $value: this._.extend({}, this.message, { id }) });
    });
  }
}
CreateGroupMessageModalComponent.$inject = [
  '_',
  '$scope',
  '$uibModal',
  'gettextCatalog',
  'GroupMessage',
];

angular.module('cdApp.shared').component('cdCreateGroupMessageModal', {
  templateUrl:
    '@/app/shared/components/create-group-message/create-group-message.component.html',
  controller: CreateGroupMessageModalComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
