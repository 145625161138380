import { ComponentType } from 'react';
import { ColumnType as AntdColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { RowClassName } from 'rc-table/lib/interface';

import { CdrEmptyStateProps } from '../CdrEmptyState';
import { OrderType, TableOrdering } from '../../models/table';

export enum ActionMenuType {
  BUTTON = 'button',
  DIVIDER = 'divider',
}

export interface ActionMenuItem<T> {
  onClick: (entity?: T) => void;
  icon: JSX.Element | ((entity?: any) => JSX.Element);
  text:
    | string
    | ((entity?: T) => string)
    | ((entity?: any) => JSX.Element)
    | JSX.Element;
  type?: ActionMenuType.BUTTON;
  disabled?: boolean | ((entity?: T) => boolean);
}

export interface ActionMenuDivider {
  type: ActionMenuType.DIVIDER;
}

export type ActionMenu<T> = Array<ActionMenuDivider | ActionMenuItem<T>>;
export type ColumnType<T> = AntdColumnsType<T> & {
  // Whether the column is active in the column selector on initial render.
  active?: boolean;
  // Whether the column is displayed, regardless of whether it is active.
  hidden?: boolean;
  // Specify what type the field is to use the built in order function.
  orderType?: OrderType | string;
};
export type CdTableData<T> = { items: T[]; total: number };

export type onTableChangeProps = (
  pageNumber: number,
  pageSize: number,
  ordering: TableOrdering,
  changedProperty: 'pagination' | 'ordering'
) => void;

export interface CdTableProps<T> {
  columns: ColumnType<T>[];
  data: CdTableData<T>;
  onTableChange?: onTableChangeProps;
  isLoading?: boolean;
  headingText?: string;
  noStyle?: boolean;
  rowKey?: string;
  rowClassName?: RowClassName<T>;
  rowStyles?: any;
  emptyStateProps: CdrEmptyStateProps;
  actionButtons?: ActionMenu<T>;
  rowSelection?: TableRowSelection<T> & { initialSelectedRowKeys?: number[] };
  filterForm?: any;
  page?: [number, React.Dispatch<React.SetStateAction<number | string>>];
  pageSize?: 30 | 5;
  OverridePagination?: ComponentType<any>;
  onRow?: (record: T) => any;
  // If this is set, the column selector will be shown and they keys will be saved to local storage
  columnSelectorKey?: string;
}
