import { Button, Dropdown, Menu } from 'antd';
import React, { Suspense } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import {
  CdAngleDown,
  CdCalendar,
  CdDeleteIcon,
  CdExportToIcal,
  CdEyeIcon,
  CdPoster,
  CdPrint,
  CdStudioEventInvitation,
  CdVersionHistory,
} from '@/react/shared/components/Icons';
import gettextCatalog from '@/react/services/I18nService';
import { navigate } from '@/react/services/StateServiceFactory';
import AuthorizationService from '@/react/services/AuthorizationService';
import { getExternalLink } from '@/react/homepage/services/external-link.service';

export const EventMoreDropdown = (props) => (
  <Suspense fallback={<EventMoreDropdownComponent.Skeleton {...props} />}>
    <EventMoreDropdownComponent {...props} />
  </Suspense>
);

const EventMoreDropdownComponent = ({ event, exportEvent, deleteEvent }) => {
  const canAccessCalendar =
    AuthorizationService.hasPermission('canAccessCalendar');
  const print = () => window.print();
  const showOnWebsite = () =>
    window.open(
      event.url ? event.url : getExternalLink(event.id, 'event'),
      '_blank'
    );
  const openTemplateSelector = () =>
    NiceModal.show('CalendarPosterTemplates', { calendarId: event.id });
  const openEmailPosterTemplates = () =>
    NiceModal.show('CalendarEmailPosterTemplates', { calendarId: event.id });

  const menu = (
    <Menu>
      <Menu.Item
        icon={<CdExportToIcal fixedWidth />}
        key="export"
        onClick={() => exportEvent(event)}
      >
        {gettextCatalog.getString('Export iCal')}
      </Menu.Item>
      <Menu.Item
        icon={<CdEyeIcon fixedWidth />}
        key="show"
        onClick={showOnWebsite}
      >
        {gettextCatalog.getString('Show in website')}
      </Menu.Item>
      {canAccessCalendar && (
        <Menu.Item
          key="show-in-calendar"
          icon={<CdCalendar fixedWidth />}
          onClick={() =>
            navigate('app.private.calendar.full', {
              showEvent: event.id,
              eventDate: event.startDate,
            })
          }
        >
          {gettextCatalog.getString('Show in calendar')}
        </Menu.Item>
      )}
      {event.canEdit && (
        <Menu.Item
          icon={<CdVersionHistory fixedWidth />}
          key="version"
          onClick={() =>
            navigate('app.private.calendar.eventRevisions', { id: event.id })
          }
        >
          {gettextCatalog.getString('Version history')}
        </Menu.Item>
      )}
      <Menu.Divider />

      <Menu.Item
        icon={<CdPoster fixedWidth />}
        key="poster"
        onClick={openTemplateSelector}
      >
        {gettextCatalog.getString('Create poster')}
      </Menu.Item>

      <Menu.Item
        icon={<CdStudioEventInvitation fixedWidth />}
        key="poster-email"
        onClick={openEmailPosterTemplates}
      >
        {gettextCatalog.getString('Send invitation')}
      </Menu.Item>

      <Menu.Item icon={<CdPrint fixedWidth />} key="print" onClick={print}>
        {gettextCatalog.getString('Print')}
      </Menu.Item>
      {event.canDelete && (
        <>
          <Menu.Divider />
          <Menu.Item
            icon={<CdDeleteIcon fixedWidth />}
            key="delete"
            onClick={() => deleteEvent(event)}
          >
            {gettextCatalog.getString('Delete')}
          </Menu.Item>
        </>
      )}
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <Button>
        {gettextCatalog.getString('More')} <CdAngleDown />
      </Button>
    </Dropdown>
  );
};

EventMoreDropdownComponent.Skeleton = () => (
  <Dropdown overlay={null}>
    <Button disabled loading>
      {gettextCatalog.getString('More')} <CdAngleDown />
    </Button>
  </Dropdown>
);
// @ts-ignore typescript don't understand that a function in javascript is just a fancy object. and we are allowed to set props on it
EventMoreDropdownComponent.Skeleton.displayName = 'EventMoreComponentSkeleton';
