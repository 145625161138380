import React from 'react';

import { getString as getStringLegacy } from './I18nService';

/**
 * Returns the translated string for the given text, with optional parameters and message context.
 *
 * @example <caption>For React files</caption>
 * getTextCatalog.getString('Please select')
 *
 * @example <caption>Inserting variables in the string</caption>
 * gettextCatalog.getString("Hello {{name}}", { name: "Ruben" });
 *
 * @example <caption>Replacing variables with smart functions</caption>
 * getTextCatalog.getString(
 *   'The internal note will be automatically deleted {{ days }} days after the event on {{ expiredDate }} as configured in your <link>data retention policy</link>',
 *   {
 *     days: expirationEventInternalNote,
 *     expiredDate: internalNoteExpiredDate,
 *     link: (text) =>
 *       `<a href="${settingUrl}" target='_blank'>${text}</a>`,
 *   }
 * )
 *
 * @param {string} text - The text to translate.
 * @param {Object} [params] - Optional parameters to insert into the translated string.
 * @param {string} [msgctxt] - Optional message context for the translation.
 * @returns {string} The translated string.
 */
export const getString = (text: string, params?: any, msgctxt?: string) => (
  <span
    dangerouslySetInnerHTML={{
      __html: getStringLegacy(text, params, msgctxt),
    }}
  />
);
