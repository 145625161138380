import React from 'react';
import { Col, Row } from 'antd';
import moment, { Moment } from 'moment';

import { FieldSection } from '../FieldSection';
import getTextCatalog from '../../../../services/I18nService';
import { EventsRotaTableValue } from '../rotas/EventsRotaTable';

import IntentionsColumns from './IntentionsColumns';

import { AssignedIntentionsAtom } from '@/react/calendar/store/events/eventRotaDuties';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';

const AssignedIntentions = ({
  eventTitle,
  eventStartDate,
  maxNumberOfPrimaryIntentions,
  setExtraData,
}: {
  eventTitle: string;
  eventStartDate: Moment;
  maxNumberOfPrimaryIntentions: number;
  setExtraData: (value) => void;
}) => {
  const tableHeader = getTextCatalog.getString(
    'Assigned intentions to {{title}} {{eventStartDate}}',
    {
      title: eventTitle ? `<b>${eventTitle}</b>,` : null,
      eventStartDate: moment(eventStartDate).format('LLLL'),
    }
  );
  return (
    <Row
      style={{
        width: '100%',
        marginBottom: '32px',
      }}
    >
      <Col span={24} style={{ marginBottom: '24px' }}>
        <FieldSection>
          <span dangerouslySetInnerHTML={{ __html: tableHeader }}></span>
        </FieldSection>
      </Col>
      <Col span={24} style={{ paddingRight: '24px', paddingLeft: '24px' }}>
        <CdSimpleTable<
          EventsRotaTableValue['shiftsAndIntentions'][number]['intentions'][number]
        >
          noStyle
          emptyStateProps={{
            title: getTextCatalog.getString('No Intentions'),
          }}
          columns={
            IntentionsColumns({
              maxNumberOfPrimaryIntentions,
              setExtraData,
            }).getAssignedIntentionColumns
          }
          recoilSource={AssignedIntentionsAtom}
          pageSize={5}
          resetPaginationOnDataChange={false}
        />
      </Col>
    </Row>
  );
};
export default AssignedIntentions;
