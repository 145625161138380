import * as _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { ApiResponse } from 'apisauce';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import gettextCatalog from './I18nService';
import NotificationService from './NotificationService';

interface HttpError {
  message: string;
  status: number;
  statusCode: number;
}

/**
 * Helper function that sends an error to a third-party error management integration and displays it to the user.
 *
 * - Error object parameter:
 *    - If the error does NOT contain a "stats" or "statusCode" property (typically, set by the backend service),
 *      the error is sent to the third-party error management integration (Sentry) and shown to the user via toastr
 *    - If the error contains a "status" or "statusCode" property (typically, set by the backend service),
 *      the error is NOT sent to the third-party error management integration (Sentry) but it is shown to the user
 *      via toastr
 *
 * - String parameter:
 *    - The message is NOT sent to the third-party error management integration (Sentry) but it is shown to the user
 *      via toastr
 *
 * @param {HttpError | Error | string | ValidateErrorEntity} error The error
 * @returns {void}
 */
export function handleError(
  error: HttpError | Error | string | ApiResponse<any> | ValidateErrorEntity
): void {
  if (!error) return;

  const status = _.get(error, 'status');
  const statusCode = _.get(error, 'statusCode');
  const isStatusCodeSet = _.isFinite(status) || _.isFinite(statusCode);
  let errorMessage: string =
    (error as any)?.validation?.body?.message ||
    (error as ApiResponse<any>)?.data?.message ||
    (error as HttpError)?.message ||
    error;

  if ((error as any).errorFields) {
    errorMessage = gettextCatalog.getString(
      'Not all fields have been filled in properly.'
    );
  }

  if (
    [
      "Cannot read properties of undefined (reading 'headers')",
      "undefined is not an object (evaluating 'e.headers')",
    ].includes(errorMessage) ||
    // @ts-ignore:
    (_.isString(error?.stack) &&
      // @ts-ignore:
      error?.stack?.includes('formio.min.js') &&
      ['e is undefined'].includes(errorMessage))
  ) {
    // FormIO keeps emitting this error at random. It's not a real error, so we ignore it.
    // Also we dont want to patch at the moment. https://churchdesk.sentry.io/issues/3746677392
    return;
  }

  // Logging the error so as to get stack trace
  if (!isStatusCodeSet) {
    console.error('ErrorHandlingService.handleError', error);
  }
  /**
   * When running on "production" environment, if the error does NOT have a "status" or "statusCode"
   * property, send it to the third-party error management integration (Sentry)
   */
  const isProductionEnvironment =
    _.get(window, 'cdApp.config.environment') === 'production';
  const shouldSendToSentry = isProductionEnvironment && !isStatusCodeSet;
  if (shouldSendToSentry) {
    Sentry.captureException(error);
  }

  // Finally, show the error to the user (always)
  const defaultErrorMessage = gettextCatalog.getString(
    'An error occurred, please try again. If the problem persists, please contact our support.'
  );
  NotificationService.notifyError(
    _.isString(errorMessage) ? errorMessage : defaultErrorMessage
  );
}

/**
 * Quick helper function to be used as form.validateFields().catch(handleAntFormValidationErrors);
 * It will print the errors in seperate error boxes on the screen.
 *
 * @param {ValidateErrorEntity} err the error parsed from Antd form validation
 */
export function handleAntFormValidationErrors(err: ValidateErrorEntity) {
  if (err?.errorFields?.length > 0) {
    err.errorFields?.forEach((field) => {
      field.errors?.forEach((error) => {
        NotificationService.notifyError(error);
        throw err;
      });
    });
  } else {
    handleError(err);
  }
}

export default { handleError, handleAntFormValidationErrors };
