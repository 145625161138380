import { Button, Input } from 'antd';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { CdCopyIcon } from '../Icons';

import getTextCatalog from '@/react/services/I18nService';

export const CdInputWithCopy = (props) => {
  const [urlCopied, setUrlCopied] = useState(false);
  return (
    <Input
      {...props}
      addonAfter={
        <CopyToClipboard
          text={props.value}
          onCopy={() => {
            setUrlCopied(true);
          }}
        >
          <Button icon={<CdCopyIcon />} type="text" size="small">
            {urlCopied
              ? getTextCatalog.getString('Copied')
              : getTextCatalog.getString('Copy to clipboard')}
          </Button>
        </CopyToClipboard>
      }
    />
  );
};
