import React from 'react';
import { Button } from 'antd';

import { CdEditIcon, CdPlus } from '../../../shared/components/Icons';

import { showNewGroupModal } from './NewGroupModal';

import gettextCatalog from '@/react/services/I18nService';

export const NewGroupButtonComponent = (props) => (
  <Button
    type="primary"
    onClick={() =>
      showNewGroupModal({
        done: props.onNewGroupCreated,
        initialValue: props.group,
        isEdit: false,
      })
    }
    icon={<CdPlus />}
  >
    {gettextCatalog.getString('New group')}
  </Button>
);
export const NewGroupEditButtonComponent = (props) => (
  <Button
    style={{
      width: '100%',
      borderRadius: '0px',
      display: 'flex',
      alignItems: 'center',
    }} // style added to adjust the full width of the button on hover
    type="text"
    onClick={() =>
      showNewGroupModal({
        done: props.onNewGroupCreated,
        initialValue: props.group,
        isEdit: true,
      })
    }
    icon={<CdEditIcon />}
  >
    {gettextCatalog.getString('Edit group')}
  </Button>
);
export const NewGroupEditButtonInGroupDetails = (props) => (
  <Button
    style={{
      width: '100%',
      borderRadius: '0px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '20px',
    }} // the padding is added to adjust the position with other angular buttons
    type="text"
    onClick={() =>
      showNewGroupModal({
        done: props.onGroupUpdated,
        initialValue: props.group,
        isEdit: true,
      })
    }
    icon={<CdEditIcon />}
  >
    {gettextCatalog.getString('Edit group')}
  </Button>
);
