import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, Steps, Alert, Row, Col, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { each, isEmpty, map, reduce, get } from 'lodash';

import { ActionCreators } from '../../organization/redux/groups';
import { JobActionCreators } from '../../organization/redux/jobs';
import { AppState } from '../../redux';
import UserService from '../services/user.service';
import gettextCatalog from '../../services/I18nService';
import { FetchUsers } from '../redux/actions';
import ErrorHandlingService from '../../services/ErrorHandlingService';
import UserRolesService from '../services/user-roles.service';
import { getChurches } from '../../shared/store/resources/index';

import AssignUserRoles from './assign-user-roles/AssignUserRoles';
import { showNewGroupModal } from './group/NewGroupModal';
import { TimeRegistrationUserSettings } from './TimeRegistrationUserSettings';

import {
  useOrganization,
  useOrganizationRefreshUserList,
} from '@/react/organization/hooks/useOrganization';
import NotificationService from '@/react/services/NotificationService';
import CdSelect from '@/react/shared/components/cd-select/CdSelect';
import AuthorizationService from '@/react/services/AuthorizationService';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { useSafeguarding } from '@/react/safeguarding/hooks/useSafeguarding';
import StateServiceFactory from '@/react/services/StateServiceFactory';

interface CreateUserModalProps {
  initialValues?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
}

export const showCreateUserModal = createCdModal<CreateUserModalProps>(
  ({ setModalProps, initialValues }) => {
    const [form] = Form.useForm();
    const { refreshUserList } = useOrganizationRefreshUserList();
    const [currentStep, setCurrentStep] = useState(0);
    const groupState = useSelector(
      (state: AppState) => state.organization.groups
    );
    const churches = useSelector(getChurches);
    const stateService = StateServiceFactory();
    const dispatch = useDispatch();
    const showMultiChurch = get(window, 'cdApp.showChurchSelector');
    const userFirstName = Form.useWatch(['user', 'firstName'], form);
    const userEmail = Form.useWatch(['user', 'email'], form);
    const churchSwitches = Form.useWatch(['churchSwitches'], form);
    const adminRoleSwitch = Form.useWatch(['organization'], form);
    const { refreshSafeguardingList } = useSafeguarding();

    const { organizationSettings } = useOrganization();
    const getSupportLink = () => {
      switch (gettextCatalog.getCurrentLanguage()) {
        case 'da':
          return 'https://support.churchdesk.com/da/category/grupper-14czapd/';
        case 'de':
          return 'https://support.churchdesk.com/de/category/gruppen-16o6jkt/';
        case 'sv':
          return 'https://support.churchdesk.com/da/category/grupper-14czapd/';
        default:
          return 'https://support.churchdesk.com/en/category/groups-brtuz2/';
      }
    };

    useEffect(() => {
      dispatch(ActionCreators.FetchGroups());
      dispatch(JobActionCreators.FetchJobs());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const prevStep = useCallback(() => {
      if (currentStep === 0) return;
      setCurrentStep(currentStep - 1);
    }, [currentStep]);

    const nextStep = useCallback(() => {
      if (currentStep === 2) return;
      form.validateFields().then((values) => {
        if (!isEmpty(values.errorFields)) return false;
        setCurrentStep(currentStep + 1);
      });
    }, [currentStep, form]);
    const disbleTheSendInvitationButton = !(
      (churchSwitches &&
        Object.values(churchSwitches).some((value) => value === true)) ||
      (adminRoleSwitch &&
        Object.values(adminRoleSwitch).some((value) => value === true))
    );
    const save = useCallback(async () => {
      await form.validateFields();
      const values = form.getFieldsValue(true);
      if (!isEmpty(values.errorFields)) return false;
      try {
        const newUser = await UserService.createUser({
          email: values.user.email,
          ...(values.user.firstName && {
            firstName: values.user.firstName,
          }),
          ...(values.user.lastName && {
            lastName: values.user.lastName,
          }),
          groupIds: values.groups,
          timeRegistrationEnabled: values.timeRegistrationEnabled,
          initialTimeBalance: values.initialTimeBalance,
        });
        let name = `${values.user.firstName} ${values.user.lastName}`;
        if (name.length < 2) name = values.user.email;
        const userId = newUser.id;

        const payload = {};
        if (showMultiChurch) {
          each(values.church, (churchIds, roleId) => {
            each(churchIds, (churchId) => {
              if (!payload[churchId]) {
                payload[churchId] = {
                  churchId,
                  roles: [],
                };
              }
              payload[churchId].roles.push({ id: roleId });
            });
          });
        } else {
          const churchId = churches[0].id;
          payload[churchId] = {
            churchId,
            roles: [],
          };
          each(values.churchSwitches, (isEnabled, roleId) => {
            if (isEnabled) payload[churchId].roles.push({ id: roleId });
          });
        }

        const payloadAsArray = map(payload, (p) => p);
        // Organization roles
        const organizationRoles = reduce(
          values.organization,
          (accumulator, value, key) => {
            if (value) {
              accumulator.push({ id: key });
            }
            return accumulator;
          },
          []
        );
        await Promise.all([
          UserRolesService.saveUserOldRoles(userId, values.oldRoles),
          !isEmpty(payloadAsArray)
            ? UserRolesService.saveUserChurchRoles(userId, payloadAsArray)
            : Promise.resolve(),
          !isEmpty(organizationRoles)
            ? UserRolesService.saveUserOrganizationRoles(
                userId,
                organizationRoles
              )
            : Promise.resolve(),
        ]);
        dispatch(FetchUsers());
        refreshUserList();
        NotificationService.notifySuccess(
          gettextCatalog.getString('Invitation is sent!')
        );
        if (stateService.current.name !== 'app.private.settings.users.list') {
          stateService.go('app.private.settings.users.list');
        }
      } catch (error) {
        ErrorHandlingService.handleError(error);
      }
    }, [
      churches,
      dispatch,
      form,
      refreshUserList,
      showMultiChurch,
      stateService,
    ]);

    const hasUserAdminRole =
      AuthorizationService.hasPermission('canAdministerUsers');

    useEffect(() => {
      setModalProps({
        modalType: ModalType.MODAL,
        width: 800,
        okText: currentStep === 2 ? 'Send invite' : 'Next',
        cancelText: currentStep === 0 ? 'Cancel' : 'Back',
        okButtonProps: {
          disabled: disbleTheSendInvitationButton && currentStep === 2,
        },
        onOk:
          currentStep === 2
            ? save
            : () => {
                nextStep();
                // Don't close modal
                throw new Error();
              },
        onCancel:
          currentStep === 0
            ? refreshSafeguardingList
            : () => {
                prevStep();
                // Don't close modal
                throw new Error();
              },
      });
    }, [
      setModalProps,
      refreshSafeguardingList,
      currentStep,
      save,
      nextStep,
      prevStep,
      disbleTheSendInvitationButton,
    ]);

    const onClickLaunchNewGroupModal = async () => {
      await showNewGroupModal({});
      dispatch(ActionCreators.FetchGroups());
    };

    const hasNoSeatsLeft =
      organizationSettings.noOfEnabledTimeRegistrations >=
      organizationSettings.timeRegistrationCap;

    const steps = [
      {
        message: (
          <Row gutter={8}>
            <Col flex="auto">
              <Form.Item
                name={['user', 'firstName']}
                label={gettextCatalog.getString('First name')}
              >
                <Input placeholder={gettextCatalog.getString('e.g. John')} />
              </Form.Item>
              <Form.Item
                name={['user', 'lastName']}
                label={gettextCatalog.getString('Last name')}
              >
                <Input placeholder={gettextCatalog.getString('e.g. Smith')} />
              </Form.Item>
              <Form.Item
                name={['user', 'email']}
                label={gettextCatalog.getString('Email')}
                rules={[
                  {
                    type: 'email',
                    message: gettextCatalog.getString(
                      'The email address you entered is not valid.'
                    ),
                  },
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={gettextCatalog.getString(
                    'e.g. john.smith@example.com'
                  )}
                />
              </Form.Item>
            </Col>
            <Col flex="10px"></Col>
          </Row>
        ),
      },
      {
        message: (
          <Space size="middle" direction="vertical">
            <Alert
              message={gettextCatalog.getString(
                'Groups enable users to collaborate'
              )}
              description={
                <span>
                  {gettextCatalog.getString(
                    'With groups in ChurchDesk it is easy to collaborate on work in and around the church. Each group contains features that facilitate internal communication and collaboration.'
                  )}{' '}
                  {gettextCatalog.getString('Need more help?')}{' '}
                  <a
                    href={getSupportLink()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {gettextCatalog.getString('Click here.')}
                  </a>
                </span>
              }
              type="info"
              showIcon
            />
            <Row gutter={8} align="bottom">
              <Col flex="auto">
                <Form.Item
                  name={['groups']}
                  label={gettextCatalog.getString('Groups')}
                >
                  <CdSelect
                    mode="multiple"
                    placeholder={gettextCatalog.getString(
                      'Choose one or more groups...'
                    )}
                    options={groupState.groups.map((group) => ({
                      value: group.id,
                      label: group.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col flex="130px">
                <Form.Item name={['groupButton']} label="">
                  <Button onClick={onClickLaunchNewGroupModal}>
                    {gettextCatalog.getString('New group')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {hasUserAdminRole && (
              <TimeRegistrationUserSettings
                noOfEnabledTimeRegistrations={
                  organizationSettings?.noOfEnabledTimeRegistrations
                }
                timeRegistrationCap={organizationSettings?.timeRegistrationCap}
                hasNoSeatsLeft={hasNoSeatsLeft}
                removeMasqueradeIcon
              />
            )}
          </Space>
        ),
      },
      {
        message: <AssignUserRoles />,
      },
    ];

    return (
      <Form
        form={form}
        preserve
        initialValues={{ user: initialValues }}
        layout="vertical"
        className="cdx-create-user-modal"
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {currentStep === 1 &&
              gettextCatalog.getString('Settings for {{ name }}', {
                name: userFirstName || userEmail,
              })}
            {currentStep === 2 &&
              gettextCatalog.getString('Grant {{ name }} permissions', {
                name: userFirstName || userEmail,
              })}
            {currentStep !== 1 &&
              currentStep !== 2 &&
              gettextCatalog.getString('Who do you want to invite?')}
          </h4>
        </div>
        <div className="modal-body">
          <Space size="large" direction="vertical" style={{ display: 'block' }}>
            <Steps
              size="small"
              current={currentStep}
              style={{ marginBottom: '24px' }}
            >
              <Steps.Step title={gettextCatalog.getString('Information')} />
              <Steps.Step title={gettextCatalog.getString('Setup')} />
              <Steps.Step title={gettextCatalog.getString('Permissions')} />
            </Steps>
            {steps[currentStep].message}
          </Space>
        </div>
      </Form>
    );
  }
);
