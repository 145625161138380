import { Modal } from 'antd';
import React, { FunctionComponent } from 'react';
import { sagaModal } from 'redux-saga-modal';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { EventConflict } from '../models/calendar';
import AuthService from '../../services/AuthorizationService';
import gettextCatalog from '../../services/I18nService';
import MODAL_TYPES from '../sagas/ModalTypes';
import { getCurrentOrganization } from '../../config/store/Selector';

import BookingConflict from './BookingConflict';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

interface UpdateEventModalProps {
  isOpen: boolean;
  hide: () => void;
  submit: () => void;
  conflicts: EventConflict;
}

const UpdateEventConfirmation: FunctionComponent<UpdateEventModalProps> = ({
  isOpen,
  submit,
  hide,
  conflicts,
}) => {
  const organization = useSelector(getCurrentOrganization, _.isEqual);
  const userCanAllowOverbooking: boolean =
    AuthService.hasPermission('canDoubleBook');
  const tooltip = gettextCatalog.getString(
    'Any booking conflicts will be ignored.'
  );
  if (!conflicts) {
    return null;
  }
  // const state = StateServiceFactory();
  return (
    <Modal
      title={gettextCatalog.getString('Booking conflicts')}
      open={isOpen}
      onOk={submit}
      okButtonProps={{ disabled: userCanAllowOverbooking ? false : true }}
      okText={
        <CdTooltip title={tooltip}>
          <span>{gettextCatalog.getString('Allow conflicts')}</span>
        </CdTooltip>
      }
      onCancel={hide}
    >
      <BookingConflict conflicts={conflicts} organization={organization} />
    </Modal>
  );
};

export default sagaModal({
  destroy: false,
  name: MODAL_TYPES.ALLOW_OVERBOOKING,
})(UpdateEventConfirmation);
