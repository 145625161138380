import React from 'react';
import { Modal, Form, Space, FormProps, Input } from 'antd';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';

import { useTemplates } from '../../hooks/useTemplates';

import { CdNewTemplate } from '@/react/shared/components/Icons';
import gettextCatalog from '@/react/services/I18nService';

export const SaveNewTemplateModal = NiceModal.create(() => {
  const { saveTemplate } = useTemplates();
  const [form] = Form.useForm();
  const formItemLayout: FormProps = {
    layout: 'vertical',
  };
  const modal = useModal('SaveNewTemplate');

  return (
    <>
      <Modal
        {...antdModalV5(modal)}
        title={
          <Space>
            <CdNewTemplate />
            {gettextCatalog.getString('Create new template')}
          </Space>
        }
        maskTransitionName="maskTransitionName"
        destroyOnClose
        onOk={() =>
          form.validateFields().then(async (values) => {
            await saveTemplate(values.title);
            modal.hide();
          })
        }
        okText={gettextCatalog.getString('Create')}
        cancelText={gettextCatalog.getString('Cancel')}
      >
        <Form
          name="people_message_template_modal"
          form={form}
          {...formItemLayout}
        >
          <Form.Item>
            {gettextCatalog.getString(
              'Choose a name for your new template. The template will be visible for everyone in your organization.'
            )}
          </Form.Item>
          <Form.Item
            name="title"
            label={gettextCatalog.getString('Title')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});
NiceModal.register('SaveNewTemplate', SaveNewTemplateModal);
