import { Button, Row, Space, Timeline, Typography } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { EventAccessContext } from '../../hooks/use-event';

import { NotificationStatus } from './NotificationComponent';

import getTextCatalog from '@/react/services/I18nService';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const NotificationsTimeLine = styled(Timeline)`
  &&&& {
    max-height: 400px;
    overflow-y: scroll;
    padding-top: 8px;
    margin-bottom: 16px;
    .ant-timeline-item-last > .ant-timeline-item-content {
      min-height: auto;
    }
  }
`;

export const NotificationPopoverContent = (
  user,
  eventId,
  sendNotification,
  disabled
) => {
  const { createdAt, notifications } = user;
  const { eventAccess, disabledByVersionHistory } =
    useContext(EventAccessContext);
  const { canNotify } = eventAccess;

  const items = notifications.map((notification, index) => ({
    children: (
      <NotificationStatus
        smsLogs={notification.smsLogs}
        emailLogs={notification.emailLogs}
        notifiedAt={notification.notifiedAt}
        key={index}
      />
    ),
  }));

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Space>
        <Typography.Text strong>
          {getTextCatalog.getString('Booked on')}
        </Typography.Text>
        <Typography.Text>{moment(createdAt).format('lll')}</Typography.Text>
      </Space>
      {notifications.length > 0 ? (
        <>
          <Typography.Text style={{ paddingTop: '16px' }} strong>
            {getTextCatalog.getString('Notified via')}
          </Typography.Text>
          <NotificationsTimeLine items={items} reverse={true} />
        </>
      ) : (
        <Typography.Text style={{ paddingTop: '8px', paddingBottom: '16px' }}>
          {getTextCatalog.getString('Not notified yet!')}
        </Typography.Text>
      )}
      <Row justify="center">
        <CdTooltip
          title={
            !eventId
              ? getTextCatalog.getString('The event has not been saved yet.')
              : undefined
          }
        >
          <Button
            disabled={
              disabled || !eventId || !canNotify || disabledByVersionHistory
            }
            onClick={() => {
              showConfirmModalDeprecated({
                title: getTextCatalog.getString('Notify'),
                message: getTextCatalog.getString(
                  'Send notification to {{name}}?',
                  {
                    name: user.name,
                  }
                ),
                okButtonProps: { type: 'primary' },
                okText: getTextCatalog.getString('Send'),
                onOk: async () => await sendNotification(eventId, user.id),
                getContainer: document.getElementById(
                  `UserNotificationsPopOver-${user.id}`
                ),
              });
            }}
          >
            {getTextCatalog.getString('Notify now')}
          </Button>
        </CdTooltip>
      </Row>
    </div>
  );
};
