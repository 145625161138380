import { RoleInterface } from '../../organization/types/organization-role.types';
import { Resource } from '../../shared/models/resource';

export interface User {
  id: number;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  picture: string;
  roles: (number | string)[];
  churchRoles: Pick<RoleInterface, 'id' | 'name'>[];
  churches: Pick<Resource, 'id' | 'name'>[];
  organizationRoles: Pick<RoleInterface, 'id' | 'name'>[];
  status: number;
  lastAccess: Date;
  createdAt: Date;
  groups: number[];
  editUser: boolean;
  timeRegistrationEnabled: boolean;
  initialTimeBalance: number;
}

export interface CreateUserBody {
  email: string;
  firstName: string;
  lastName: string;
  groupIds: number[];
  jobs?: {
    jobId: number;
    organizationId: number;
  };
  timeRegistrationEnabled: boolean;
  initialTimeBalance: number;
}

export interface OldUserRoles {
  canAdministerOrganization: boolean;
  canAccessCalendar: boolean;
  canAccessPeople: boolean;
  canAccessContributions: boolean;
  canAccessWebsite: boolean;
  canPublish: boolean;
  canAccessGraveyard: boolean;
  graveyard: {
    canAdministerGraveyard: boolean;
  };
  forms: { access: boolean; sensitive: boolean };
  people: { sensitive: boolean };
  calendar: {
    canCreateInternalEvent: boolean;
    canEditAllEventsGlobal: boolean;
    canEditAllEventsLocal: boolean;
    booking: { canBook: boolean; canForceDoubleBooking: boolean };
    canViewAllAbsence: boolean;
    canManageAbsence: boolean;
    canAccessSensitiveInformation: boolean;
  };
}

export const defaultOldUserRoles: OldUserRoles = {
  canAdministerOrganization: false,
  canAccessCalendar: false,
  canAccessPeople: false,
  canAccessContributions: false,
  canAccessWebsite: false,
  canPublish: false,
  canAccessGraveyard: false,
  graveyard: {
    canAdministerGraveyard: false,
  },
  forms: { access: false, sensitive: false },
  people: { sensitive: false },
  calendar: {
    canCreateInternalEvent: false,
    canEditAllEventsGlobal: false,
    canEditAllEventsLocal: false,
    booking: { canBook: false, canForceDoubleBooking: false },
    canViewAllAbsence: false,
    canManageAbsence: false,
    canAccessSensitiveInformation: false,
  },
};
