import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import React from 'react';
import styled from 'styled-components';
import NiceModal from '@ebay/nice-modal-react';
import moment from 'moment';

import {
  safeguardingLevelChecks,
  safeguardingLevelCheckToSelectOption,
  safeguardingRoles,
} from '../types/SafeguardingTypes';
import { CdUserAvatar } from '../../user/components/cd-user-avatar/CdUserAvatar';
import getTextCatalog from '../../services/I18nService';
import gettextCatalog from '../../services/I18nService';
import { useSafeguardingForm } from '../hooks/useSafeguardingForm';
import { CdParishSelect } from '../../shared/components/cd-perish-select/CdParishSelect';

import { FileDisplayTable } from './FileDisplayTable';
import { FileDisplayTableColumns } from './FileDisplayTableColumn';

import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

const StyledCard = styled(Card)`
  &&&&.ant-card-bordered {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const SafeguardingForm = ({
  isEdit,
  currentUserId,
}: {
  isEdit?: boolean;
  currentUserId: number;
}) => {
  const {
    safeguardingForm,
    safeguardingData,
    onSafeguardingInfoSaveHandler,
    deleteFile,
    isMultiChurch,
  } = useSafeguardingForm(currentUserId);

  const fileDisplayTableRowDeleteHandler = (id: number) =>
    showConfirmModal({
      title: gettextCatalog.getString(
        'Are you sure you want to delete this record?'
      ),
      message: (
        <Typography.Paragraph>
          {gettextCatalog.getString('The record will be deleted permanently.')}
        </Typography.Paragraph>
      ),
      onOk: () => deleteFile(id),
    });

  return (
    <Form
      form={safeguardingForm}
      initialValues={safeguardingData}
      layout="vertical"
    >
      <Row style={{ marginBottom: '24px', marginTop: '24px' }}>
        <StyledCard>
          <Col span={24}>
            <Space direction="horizontal">
              <CdUserAvatar picture={safeguardingData?.pictureUri} size={80} />
              <Space direction="vertical" style={{ marginLeft: '24px' }}>
                <Typography.Title level={2}>
                  {safeguardingData?.fullName}
                </Typography.Title>
                {safeguardingData?.email}
                {safeguardingData?.birthdayDate &&
                  moment(safeguardingData?.birthdayDate).format('LL')}
              </Space>
            </Space>
          </Col>
        </StyledCard>
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2} style={{ margin: '0 0 24px' }}>
              {getTextCatalog.getString('Safeguarding information')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item
              name={['safeguardInfo', 'type']}
              label={getTextCatalog.getString('Work')}
              extra={getTextCatalog.getString('Select the type of employment')}
              rules={[
                {
                  required: true,
                  message: getTextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Select
                disabled={!isEdit}
                options={safeguardingRoles().map((role) => ({
                  label: role.label,
                  value: role.value,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={['safeguardInfo', 'workAndResponsibilities']}
              label={getTextCatalog.getString('Work field & responsibilities')}
              extra={getTextCatalog.getString(
                'Describe the work and responsibilities'
              )}
              rules={[
                {
                  required: true,
                  message: getTextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Input disabled={!isEdit} />
            </Form.Item>
          </Col>
          {isMultiChurch && (
            <Col span={24}>
              <Form.Item
                name={['safeguardInfo', 'parishIds']}
                label={gettextCatalog.getString('Parish')}
                extra={gettextCatalog.getString('Choose one or more parishes')}
                rules={[
                  {
                    required: true,
                    message: getTextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <CdParishSelect disabled={!isEdit} />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item
              name={['safeguardInfo', 'safeguardRequirement']}
              label={getTextCatalog.getString('Safeguarding measures required')}
              rules={[
                {
                  required: true,
                  message: getTextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Select
                disabled={!isEdit}
                options={safeguardingLevelChecks().map(
                  safeguardingLevelCheckToSelectOption
                )}
              />
            </Form.Item>
          </Col>
          {isEdit && (
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Space size="small">
                <Button
                  type="default"
                  onClick={() => NiceModal.hide('SafeguardingDrawer')}
                >
                  {getTextCatalog.getString('Close')}
                </Button>
                <Button type="primary" onClick={onSafeguardingInfoSaveHandler}>
                  {getTextCatalog.getString('Save')}
                </Button>
              </Space>
            </Col>
          )}
        </StyledCard>
        {/* Documents card */}
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2} style={{ margin: '0 0 24px' }}>
              {getTextCatalog.getString('Documents')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="documents">
              <FileDisplayTable
                currentUserId={currentUserId}
                fileType="document"
                tableColumns={FileDisplayTableColumns(
                  'document',
                  isEdit,
                  fileDisplayTableRowDeleteHandler
                )}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>
        </StyledCard>
        {/* Courses card */}
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2} style={{ margin: '0 0 24px' }}>
              {getTextCatalog.getString('Courses')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="courses">
              <FileDisplayTable
                currentUserId={currentUserId}
                fileType="course"
                tableColumns={FileDisplayTableColumns(
                  'course',
                  isEdit,
                  fileDisplayTableRowDeleteHandler
                )}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>
        </StyledCard>
        {/* Certificate of conduct card */}
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2} style={{ margin: '0 0 24px' }}>
              {getTextCatalog.getString('Certificate of Conduct')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="certificatesOfConduct">
              <FileDisplayTable
                currentUserId={currentUserId}
                fileType="certificateOfConduct"
                tableColumns={FileDisplayTableColumns(
                  'certificateOfConduct',
                  isEdit,
                  fileDisplayTableRowDeleteHandler
                )}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>
        </StyledCard>
      </Row>
    </Form>
  );
};
