import { Col, Row, Typography, Space, Avatar, Tag, Spin, theme } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Moment } from 'moment';
import SkeletonInput from 'antd/lib/skeleton/Input';

import { NotificationPopover } from '../../notifications/NotificationPopover';
import { useNotification } from '../../../hooks/use-notification';

import { useUserBooking } from './use-user-booking';

import { UsersDictionary } from '@/react/calendar/models/calendar';
import { CdGroupUserSelect } from '@/react/shared/components/cd-group-user-select/CdGroupUserSelect';
import getTextCatalog from '@/react/services/I18nService';
import {
  CdClose,
  CdUsersClock,
  EventIcons,
} from '@/react/shared/components/Icons';

const RowContainer = styled(Row)`
  &&&& {
    height: 56px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #eff0ed;
    }
  }
`;

interface UserBookingProps {
  value?: number[];
  onChange?: (value: number[]) => void;
  eventId: number;
  canEdit: boolean;
  startDate: Moment;
  endDate: Moment;
  rrule: string;
  bookedUsers: UsersDictionary;
  initialRrule?: string;
}

export const UserBooking = (props: UserBookingProps) => (
  <Space size="middle" direction="vertical" style={{ width: '100%' }}>
    <UserBookingInner {...props} />
  </Space>
);

export const UserBookingInner = (props: UserBookingProps) => {
  const users = props.value;
  const selectorValue = null;
  const { useToken } = theme;
  const { token } = useToken();
  const [keyword, setKeyword] = useState('');
  const { sendNotification } = useNotification(props.eventId);
  const {
    usersAndGroupsLoading,
    busyUsersLoading,
    groups,
    selectorOptionsUsers,
    showUsers,
    unavailableUsersCount,
    busyUsers,
  } = useUserBooking({
    startDate: props.startDate,
    endDate: props.endDate,
    eventId: props.eventId,
    users,
    rrule: props.rrule,
    initialRrule: props.initialRrule,
  });

  const onChange = (value, type: string) => {
    if (type === 'group') {
      const selectedGroup = groups.find((group) => group.id === value);
      const users = props.value.concat(selectedGroup.members);
      props.onChange(
        users.filter((user, index) => users.indexOf(user) === index)
      );
    } else {
      props.onChange([...props.value, value]);
    }
    setKeyword('');
  };
  const handleRemoveBookedUser = (userId) => {
    props.onChange(props.value.filter((id) => id !== userId));
  };
  if (!props.onChange && !props.value) {
    return null;
  }

  if (usersAndGroupsLoading) {
    return (
      <>
        <SkeletonInput block style={{ marginBottom: '22px' }} />
        <Space direction="vertical">
          <SkeletonInput />
          <SkeletonInput />
          <SkeletonInput />
        </Space>
      </>
    );
  }

  return (
    <>
      <CdGroupUserSelect
        placeholder={getTextCatalog.getString('Select user or group')}
        onChange={(value, type) => onChange(value, type)}
        value={selectorValue}
        users={selectorOptionsUsers}
        groups={groups}
        disabled={!props.canEdit}
        keyword={keyword}
        setKeyword={setKeyword}
      />
      <Spin spinning={busyUsersLoading}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Row>
            <Space>
              {users?.length > 0 && (
                <Typography.Text>
                  {getTextCatalog.getPlural(
                    showUsers.length,
                    '1 user booked',
                    '{{ count }} users booked',
                    {
                      count: showUsers.length,
                    }
                  )}
                </Typography.Text>
              )}
              {unavailableUsersCount > 0 && (
                <Typography.Text style={{ color: token.colorError }}>
                  {getTextCatalog.getPlural(
                    unavailableUsersCount,
                    '/ 1 user is unavailable',
                    '/ {{ count }} users are unavailable',
                    {
                      count: unavailableUsersCount,
                    }
                  )}
                </Typography.Text>
              )}
            </Space>
          </Row>
          {showUsers.length > 0 ? (
            showUsers.map((user) => (
              <RowContainer key={user.id}>
                <Col span={20}>
                  <NotificationPopover
                    user={user}
                    eventId={props.eventId}
                    sendNotification={sendNotification}
                    disabled={!props.bookedUsers[user.id]}
                  />
                </Col>
                <Col
                  span={3}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  {(!user.attending || user?.attending === 'no-answer') &&
                    busyUsers.includes(user.id) && (
                      <Tag color={token.colorError}>
                        <Space>
                          <CdUsersClock />
                          {getTextCatalog.getString('Busy')}
                        </Space>
                      </Tag>
                    )}
                  {(!user.attending || user?.attending === 'no-answer') &&
                    !busyUsers.includes(user.id) && (
                      <Tag>{getTextCatalog.getString('Available')}</Tag>
                    )}
                  {user?.attending === 'yes' && (
                    <Tag color="#00A36C">
                      {getTextCatalog.getString('Going')}
                    </Tag>
                  )}
                  {user?.attending === 'no' && (
                    <Tag color={token.colorError}>
                      {getTextCatalog.getString('Not going')}
                    </Tag>
                  )}
                  {user?.attending === 'maybe' && (
                    <Tag color="#f08a1c">
                      {getTextCatalog.getString('Maybe')}
                    </Tag>
                  )}
                </Col>
                {props.canEdit && (
                  <Col
                    data-testid="remove-user-button"
                    span={1}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveBookedUser(user.id)}
                  >
                    <CdClose style={{ color: 'gray' }} />
                  </Col>
                )}
              </RowContainer>
            ))
          ) : (
            <Space
              direction="vertical"
              style={{ paddingTop: '16px', alignItems: 'center' }}
            >
              <Avatar>
                <EventIcons.Users size="lg" />
              </Avatar>
              <Typography.Text strong>
                {getTextCatalog.getString('No users added yet')}
              </Typography.Text>
            </Space>
          )}
        </div>
      </Spin>
    </>
  );
};
