import { Button } from 'antd';
import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import styled from 'styled-components';

import { StatementType } from './ConsentCreateStatement';

import gettextCatalog from '@/react/services/I18nService';
import { CdEditIcon } from '@/react/shared/components/Icons';

const EditButton = styled(Button)`
  &:hover {
    background-color: #f8f7f7;
    border-radius: 0%;
  }
`;

const ConsentEditStatementButton = ({ id }) => {
  const showConsentCreateStatementModal = (
    statementType: StatementType,
    id: string
  ) => {
    NiceModal.show('ConsentCreateStatement', { statementType, id });
  };

  return (
    <EditButton
      onClick={() => showConsentCreateStatementModal(StatementType.Edit, id)}
      type="text"
      style={{ width: '100%', textAlign: 'start' }}
    >
      <CdEditIcon />
      {gettextCatalog.getString('Edit')}
    </EditButton>
  );
};

export default ConsentEditStatementButton;
