import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';

import { PeopleListByTypeAndSearchQuery } from '../store/newsletterListState';
import { Segment, SegmentFilterComponent } from '../types/segment.type';
import { peopleSegmentsState } from '../store/peopleSegment';
import later from '../services/promiseTimeout';

import gettextCatalog from '@/react/services/I18nService';
import NotificationService from '@/react/services/NotificationService';
import { mainApi } from '@/react/api';

const useNewsletterList = () => {
  const refreshLFilterList = useRecoilRefresher(
    PeopleListByTypeAndSearchQuery({ type: 'filter', searchParameter: '' })
  );
  const refreshNewletterList = useRecoilRefresher(
    PeopleListByTypeAndSearchQuery({ type: 'newsletter', searchParameter: '' })
  );
  const refreshParticipantList = useRecoilRefresher(
    PeopleListByTypeAndSearchQuery({ type: 'participant', searchParameter: '' })
  );

  const deleteList = useRecoilCallback(
    () => async (listId: number, type: string) => {
      const response = await mainApi.delete<Segment>(
        `/people/segments/${listId}`
      );
      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      } else {
        NotificationService.notifySuccess(
          gettextCatalog.getString('List was deleted!')
        );
        switch (type) {
          case 'newsletter':
            refreshNewletterList();
            break;
          case 'filter':
            refreshLFilterList();
            break;
          case 'participant':
            refreshParticipantList();
            break;
        }
      }
    },
    [refreshLFilterList, refreshNewletterList, refreshParticipantList]
  );

  const createList = useRecoilCallback(
    ({ refresh }) =>
      async (
        name: string,
        setup: {
          churchIds: number[];
        },
        filterIds: number[],
        type: string
      ) => {
        const response = await mainApi.post<Segment>(`/people/segments`, {
          name,
          setup,
          filterIds,
          type,
        });
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        } else {
          if (filterIds) await later(5000);
          NotificationService.notifySuccess(
            gettextCatalog.getString('List was created!')
          );
          refresh(peopleSegmentsState);
          refreshNewletterList();
        }
      },
    [refreshNewletterList]
  );

  const editListName = useRecoilCallback(
    () =>
      async (name: string, setup: SetUpType, listId: number, type: string) => {
        const response = await mainApi.put<Segment>(
          `/people/segments/${listId}`,
          {
            name,
            setup,
          }
        );
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        } else {
          NotificationService.notifySuccess(
            gettextCatalog.getString('List was updated!')
          );
          switch (type) {
            case 'newsletter':
              refreshNewletterList();
              break;
            case 'filter':
              refreshLFilterList();
              break;
            case 'participant':
              refreshParticipantList();
              break;
          }
        }
      },
    [refreshNewletterList, refreshLFilterList, refreshParticipantList]
  );
  const exportContacts = useRecoilCallback(
    () =>
      async (organizationId: number, filter: SetUpType, fileType: string) => {
        const response = await mainApi.post<any>(
          `/people/export/data?organizationId=${organizationId}`,
          {
            filter,
            fileType,
          }
        );
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        } else {
          NotificationService.notifySuccess(
            gettextCatalog.getString(
              'An email will be sent to you when the export is ready.'
            )
          );
        }
      },
    []
  );
  //
  const addContactsToLists = useRecoilCallback(
    () =>
      async (body: {
        filterIds: number[];
        fromPeopleListIds: number[];
        toPeopleListIds: number[];
      }) => {
        const response = await mainApi.post<Segment>(
          `/people/segments/add-people`,
          body
        );
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        } else {
          await later(5000);
          NotificationService.notifySuccess(
            gettextCatalog.getString('Contacts added!')
          );
        }
        refreshNewletterList();
        refreshParticipantList();
      },
    [refreshNewletterList, refreshParticipantList]
  );

  return {
    deleteList,
    refreshLFilterList,
    refreshNewletterList,
    createList,
    addContactsToLists,
    editListName,
    exportContacts,
  };
};

export default useNewsletterList;

export interface SetUpType {
  churchIds?: number[];
  comparison: string;
  filters: SegmentFilterComponent[];
}
