import React, { Suspense } from 'react';
import {
  Alert,
  Avatar,
  Col,
  Input,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  theme,
} from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import { Form } from 'antd';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import moment from 'moment';

import { useAddUserToRota } from '../../hooks/use-add-user-to rota';

import getTextCatalog from '@/react/services/I18nService';
import CdModal from '@/react/shared/components/cd-modal/CdModal';
import {
  CdClose,
  CdTime,
  CdUsersClock,
  EventIcons,
} from '@/react/shared/components/Icons';
import { CdGroupUserSelect } from '@/react/shared/components/cd-group-user-select/CdGroupUserSelect';
import { GetOrganizationUser } from '@/react/organization/store/organization';
import { DateInputValue } from '@/react/calendar/event-details/components/date-input/DateInput';
import { SlimUser } from '@/react/organization/types/organization';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { CdParishSelect } from '@/react/shared/components/cd-perish-select/CdParishSelect';

export interface RotaDetails {
  calendarId: number;
  taskId: number;
  date: Pick<DateInputValue, 'startDate' | 'endDate' | 'rrule'>;
  // Shift info
  note: string;
  users: { id: number; canUnAssign: boolean }[];
  // Rota info
  rotaDutyName: string;
  required: number;
}

const Container = styled.div`
  padding: 16px;
`;
const SelectContainer = styled(Form.Item)`
margin-bottom: 0;
`;
const DateBox = styled.div`
  border: 2px solid #008db6;
  border-radius: 5px;
  width: 48px;
  height: 48px;
  color: #008db6;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  line-height: 16px;

  span {
    box-sizing: content-box;
  }
`;
const RotaDutyTitle = styled(Typography.Text)`
  &&&& {
    color: #008db6;
    font-size: 18px;
  }
`;

const RowContainer = styled(Row)`
  &&&& {
    height: 50px;
    display: flex;
    align-items: center;
    width: 420px;
    border: 1px solid #eff0ed;
    &:hover {
      background-color: #eff0ed;
    }
  }
`;
const { TextArea } = Input;

type AddUserToRotaModalProps = {
  rotaDetails: RotaDetails;
  canEditUsers: boolean;
  initialRrule: string;
  disableOkButton?: boolean;
};

export const openAddUserToRotaModal = (props: AddUserToRotaModalProps) =>
  NiceModal.show('AddUserToRotaModal', {
    rotaDetails: props.rotaDetails,
    canEditUsers: props.canEditUsers,
    initialRrule: props.initialRrule,
    disableOkButton: props.disableOkButton,
  });

export const AddUserToRotaModal = NiceModal.create(
  ({
    rotaDetails,
    canEditUsers,
    initialRrule,
    disableOkButton,
  }: AddUserToRotaModalProps) => {
    const {
      modal,
      form,
      userAssignedList,
      userAssignedIds,
      busyUsers,
      loading,
      handleChange,
      handleRemoveAssignedUser,
      handleOnOk,
      keywordToSearchUsersByName,
      setKeywordToSearchusersByName,
      selectorOptionsUsers,
      filterParishIds,
      setFilterParishIds,
      startDate,
      token,
      isMultiParish,
    } = useAddUserToRota({ rotaDetails, initialRrule });

    return (
      <CdModal
        title={getTextCatalog.getString('Assign user')}
        okText={getTextCatalog.getString('Add')}
        onOk={handleOnOk}
        modal={modal}
        disabledOkButton={disableOkButton}
      >
        <Spin spinning={loading}>
          <Container>
            <Form layout="vertical" form={form}>
              <Row justify="center">
                <Space
                  direction="vertical"
                  align="center"
                  style={{ width: '100%' }}
                >
                  <Row>
                    <Space>
                      <DateBox>
                        <span style={{ fontSize: '18px' }}>
                          {moment(startDate).format('DD')}
                        </span>
                        <span>
                          {moment(startDate).format('MMM').toUpperCase()}
                        </span>
                      </DateBox>
                      <Space
                        direction="vertical"
                        style={{ lineHeight: '16px' }}
                        size={0}
                      >
                        <RotaDutyTitle>
                          {rotaDetails.rotaDutyName}
                        </RotaDutyTitle>
                        <div>
                          <Space>
                            <CdTime />
                            {moment(startDate).format('lll')}
                          </Space>
                        </div>
                      </Space>
                    </Space>
                  </Row>
                  <Form.Item
                    label={getTextCatalog.getString('Note')}
                    name="note"
                    initialValue={rotaDetails?.note}
                  >
                    <TextArea
                      rows={3}
                      style={{ width: '256px' }}
                      disabled={!canEditUsers}
                    />
                  </Form.Item>
                  {isMultiParish && (
                    <SelectContainer
                      label={getTextCatalog.getString('Filter by parish')}
                    >
                      <CdParishSelect
                        value={filterParishIds}
                        onChange={setFilterParishIds}
                        style={{ width: '420px' }}
                        disabled={!canEditUsers}
                      />
                    </SelectContainer>
                  )}
                  <SelectContainer
                    label={getTextCatalog.getString('Select user')}
                  >
                    <CdGroupUserSelect
                      placeholder={getTextCatalog.getString('Select user')}
                      onChange={(value) => handleChange(value)}
                      value={null}
                      users={selectorOptionsUsers}
                      disabled={
                        !canEditUsers ||
                        rotaDetails.required === userAssignedIds?.length ||
                        (rotaDetails.required &&
                          rotaDetails.required < userAssignedIds?.length)
                      }
                      keyword={keywordToSearchUsersByName}
                      setKeyword={setKeywordToSearchusersByName}
                      style={{ width: '420px' }}
                    />
                  </SelectContainer>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {(userAssignedIds?.length > 0 || rotaDetails.required) && (
                      <Row>
                        <Space style={{ width: '420px' }}>
                          <Typography.Text>
                            {getTextCatalog.getPlural(
                              userAssignedIds.length,
                              '1 user assigned',
                              '{{ count }} users assigned',
                              {
                                count: userAssignedIds.length,
                              }
                            )}
                          </Typography.Text>

                          {rotaDetails.required > userAssignedIds?.length && (
                            <Typography.Text
                              style={{ color: token.colorError }}
                            >
                              {getTextCatalog.getString(
                                '/ {{ count }} missing',
                                {
                                  count:
                                    rotaDetails.required -
                                    userAssignedIds.length,
                                }
                              )}
                            </Typography.Text>
                          )}
                        </Space>
                      </Row>
                    )}
                    <Suspense fallback={<Spin />}>
                      {userAssignedIds.length > 0 ? (
                        userAssignedList.map((user: SlimUser) => (
                          <AssignedUser
                            key={user.id}
                            userId={user.id}
                            busy={busyUsers.includes(user.id)}
                            handleRemoveAssignedUser={handleRemoveAssignedUser}
                            canEditUsers={canEditUsers}
                          />
                        ))
                      ) : (
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '32px',
                            width: '100%',
                          }}
                        >
                          <Avatar>
                            <EventIcons.Users size="lg" />
                          </Avatar>
                          <Typography.Title level={5}>
                            {getTextCatalog.getString('No users added')}
                          </Typography.Title>
                        </Row>
                      )}
                    </Suspense>
                  </div>
                  <div style={{ width: '420px' }}>
                    <Alert
                      message={getTextCatalog.getString(
                        'Everyone you add to the rota will be notified once you click “Save & notify”. They will also receive a reminder per email 3 days before the event.'
                      )}
                      type="info"
                    />
                  </div>
                </Space>
              </Row>
            </Form>
          </Container>
        </Spin>
      </CdModal>
    );
  }
);

const AssignedUser = (props: {
  userId: number;
  handleRemoveAssignedUser;
  busy: boolean;
  canEditUsers: boolean;
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const user = useRecoilValue(GetOrganizationUser({ id: props.userId }));

  const isUserBlocked = user && user.status === 'blocked';
  const isUserDeleted = !user;

  return (
    <RowContainer key={props.userId}>
      <Col span={20}>
        <Space data-testid="user-details" style={{ marginLeft: '8px' }}>
          <CdUserAvatar
            light
            name={user.name}
            picture={user.picture}
            blocked={isUserBlocked || isUserDeleted}
          />
        </Space>
      </Col>
      <Col span={3} style={{ display: 'flex', justifyContent: 'end' }}>
        {props.busy ? (
          <Tag color={token.colorError}>
            <Space>
              <CdUsersClock />
              {getTextCatalog.getString('Busy')}
            </Space>
          </Tag>
        ) : (
          <Tag>
            <Space>
              <CdUsersClock />
              {getTextCatalog.getString('Available')}
            </Space>
          </Tag>
        )}
      </Col>

      <Col
        data-testid="remove-user-button"
        span={1}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          props.canEditUsers && props.handleRemoveAssignedUser(props.userId)
        }
      >
        <CdClose style={{ color: 'gray' }} />
      </Col>
    </RowContainer>
  );
};
