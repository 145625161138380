import { Form, Input, Modal, Alert } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { destroyModal, sagaModal } from 'redux-saga-modal';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Rule } from 'antd/lib/form';

import gettextCatalog from '../../services/I18nService';
import {
  CreateCalendarSubscriptionAction,
  UpdateCalendarSubscriptionAction,
} from '../store/main-view/Actions';
import MODAL_TYPES from '../sagas/ModalTypes';

import ColorSelector from './ColorSelector';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { StyledCheckbox } from '@/react/shared/antd/StyledAntdComponents';

interface CalendarSubscriptionProps {
  isOpen: boolean;
  calendarSubscription?: any;
}

const StyledInput = styled(Input)`
  &&&& {
    input {
      height: auto;
    }
  }
`;

const CalendarSubscriptionModal: FunctionComponent<CalendarSubscriptionProps> =
  ({ isOpen, calendarSubscription }) => {
    const urlFieldRules: Rule[] = [
      {
        required: true,
        message: gettextCatalog.getString('Please provide a valid url.'),
        type: 'url',
      },
    ];
    const nameFieldRules = [
      {
        required: true,
        message: gettextCatalog.getString('Please give the calendar a name.'),
      },
    ];
    const isCreate = !calendarSubscription;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const defaultColor = isCreate ? 0 : calendarSubscription.color;
    const [colorIndex, setColorIndex] = useState(defaultColor);
    const onSave = (values) => {
      if (isCreate) {
        dispatch(CreateCalendarSubscriptionAction(values));
      } else {
        dispatch(
          UpdateCalendarSubscriptionAction({
            ...values,
            checkBookingConflict: !!values.checkBookingConflict,
            id: calendarSubscription.id,
          })
        );
      }
    };
    let initialValues = {};
    if (!isCreate) {
      initialValues = {
        name: calendarSubscription.name,
        url: calendarSubscription.url,
        checkBookingConflict: calendarSubscription.checkBookingConflict,
      };
    }

    const onColorChange = (index) => {
      setColorIndex(index);
    };
    return (
      <Modal
        open={isOpen}
        title={gettextCatalog.getString('Add external calendar')}
        style={{ top: 20 }}
        okText={
          isCreate
            ? gettextCatalog.getString('Add calendar')
            : gettextCatalog.getString('Save')
        }
        onCancel={() => {
          dispatch(destroyModal(MODAL_TYPES.CALENDAR_SUBSCRIPTION_MODAL));
        }}
        onOk={() => {
          form.validateFields().then((values) => {
            onSave({
              name: values.name,
              url: values.url,
              color: colorIndex,
              checkBookingConflict: values.checkBookingConflict,
            });
          });
        }}
      >
        <Alert
          type="info"
          icon={<FontAwesomeIcon icon={faInfoCircle} />}
          message={gettextCatalog.getString(
            'Add external calendars to your personal ChurchDesk Calendar to align your work and private life calendars. Events from external calendars can only be seen by you. It may take 10-15 min. before events start to appear.'
          )}
          banner
        />
        <Form
          className="u-mt-20"
          form={form}
          name="calendar_subscription_modal"
          layout="vertical"
          initialValues={initialValues}
        >
          <Form.Item
            name="name"
            label={gettextCatalog.getString('Calendar name')}
            rules={nameFieldRules}
          >
            <Input placeholder={gettextCatalog.getString('Enter a name')} />
          </Form.Item>
          <Form.Item
            name="url"
            label={gettextCatalog.getString('Calendar URL')}
            rules={urlFieldRules}
            extra={
              isCreate
                ? gettextCatalog.getString(
                    'Enter the URL of the calendar you want to add.'
                  )
                : null
            }
          >
            <StyledInput
              style={{ paddingTop: 0, paddingBottom: 0 }}
              placeholder={gettextCatalog.getString(
                'https://example.com/calendar.ics'
              )}
              prefix={<FontAwesomeIcon icon={faLink} size="sm" />}
              disabled={!isCreate}
            />
          </Form.Item>
          <CdTooltip
            title={
              !isCreate
                ? gettextCatalog.getString(
                    'Can only be marked busy when the calendar gets added. Please remove the calendar and add it again.'
                  )
                : ''
            }
          >
            <Form.Item
              name="checkBookingConflict"
              label={gettextCatalog.getString('Mark as busy')}
              valuePropName="checked"
            >
              <StyledCheckbox disabled={!isCreate}>
                {gettextCatalog.getString(
                  'Events from your private calendar will mark you as busy if someone tries to book or assign you for an event or rota duty.'
                )}
              </StyledCheckbox>
            </Form.Item>
          </CdTooltip>
        </Form>
        <ColorSelector
          onChange={onColorChange}
          selectedValue={colorIndex}
        ></ColorSelector>
      </Modal>
    );
  };

export default sagaModal({
  // unique name for the modal
  name: MODAL_TYPES.CALENDAR_SUBSCRIPTION_MODAL,
})(CalendarSubscriptionModal);
