import { Select, Space, theme } from 'antd';
import React, { useContext } from 'react';
import {
  useRecoilValue,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';

import { useAnswerToEvent } from '../../hooks/use-answer-to-event';
import { EventAccessContext } from '../../hooks/use-event';

import { EventIcons } from '@/react/shared/components/Icons';
import getTextCatalog from '@/react/services/I18nService';
import cdApp from '@/react/config';
import { eventsDetailsHooks } from '@/react/calendar/store/events/eventsDetailsSlice';
import { EventQuery } from '@/react/calendar/store/events/event';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const UserAnswerToEvent = ({ eventId, bookingUsers }) => {
  const isloginUserBooking = bookingUsers?.includes(cdApp.me.id);
  const { disabledByVersionHistory } = useContext(EventAccessContext);
  const { useToken } = theme;
  const { token } = useToken();
  const loginUserAlreadyBooked = useRecoilValue(
    EventQuery({ calendarId: eventId, clientVersion: 3 })
  )?.users[cdApp.me.id];
  const eventRefresher = useRecoilRefresher(
    EventQuery({ calendarId: eventId, clientVersion: 3 })
  );
  const changeAttendingStatus = eventsDetailsHooks.useChangeAttendingStatus();
  const { sendAnswerToEvent } = useAnswerToEvent(eventId);
  const onChange = async (value) => {
    await sendAnswerToEvent(value);
    changeAttendingStatus({
      eventId,
      eventType: 'event',
      status: value,
    });
    eventRefresher();
  };

  if (!isloginUserBooking) {
    return null;
  }
  const userResponse =
    loginUserAlreadyBooked?.attending === 'no-answer'
      ? getTextCatalog.getString('No answer')
      : loginUserAlreadyBooked?.attending;

  return (
    <CdTooltip
      title={
        !loginUserAlreadyBooked && isloginUserBooking
          ? getTextCatalog.getString('The event must be saved first.')
          : undefined
      }
    >
      <Select
        defaultValue={[loginUserAlreadyBooked ? userResponse : null]}
        placeholder={getTextCatalog.getString('Your answer')}
        onChange={onChange}
        style={{
          width: '168px' /* This ensures that german strings are not cut off */,
        }}
        disabled={
          (!loginUserAlreadyBooked && isloginUserBooking) ||
          disabledByVersionHistory
        }
        options={[
          {
            value: 'yes',
            label: (
              <Space>
                <EventIcons.AttendanceStatusGoing
                  style={{ color: '#00A36C' }}
                />
                {getTextCatalog.getString('Going')}
              </Space>
            ),
          },
          {
            value: 'no',
            label: (
              <Space>
                <EventIcons.AttendanceStatusNotGoing
                  style={{ color: token.colorError }}
                />
                {getTextCatalog.getString('Not going')}
              </Space>
            ),
          },
          {
            value: 'maybe',
            label: (
              <Space>
                <EventIcons.AttendanceStatusMaybe
                  style={{ color: '#f08a1c' }}
                />
                {getTextCatalog.getString('Maybe')}
              </Space>
            ),
          },
        ]}
      />
    </CdTooltip>
  );
};
