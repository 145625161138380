import {
  Button,
  Form,
  Input,
  Modal,
  ModalProps,
  Select,
  Space,
  Spin,
  Steps,
  TreeSelect,
  Upload,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';

import gettextCatalog from '../../services/I18nService';
import { uploadFiles, fetchFolders } from '../services/FileService';
import ErrorHandlingService from '../../services/ErrorHandlingService';
import { CdrFile, FileVisibility, Folder } from '../models/files';

import CdTooltip from './cd-tooltip/CdTooltip';
import { CdCopyrightIcon, CdFolderIcon, CdUploadIcon } from './Icons';

import { GetUserGroups } from '@/react/user/store/user-session';
import cdApp from '@/react/config';
import { OrganizationState } from '@/react/user/store/user-session';
import { useGetFilePermissionsQuery } from '@/react/files/store/files';
export type CdrUploadFilesModalProps = {
  filesToUpload: UploadFile[];
  modalGetContainer?: ModalProps['getContainer'];
};

enum CdrUploadFilesModalSteps {
  ADD_FILES = 0,
  SETTINGS = 1,
}

export const launchCdrUploadFilesModal = (props: CdrUploadFilesModalProps) =>
  NiceModal.show<{ files: CdrFile[] }, CdrUploadFilesModalProps>(
    'CdrUploadFilesModal',
    props
  );

export const CdrUploadFilesModal = NiceModal.create<CdrUploadFilesModalProps>(
  ({ filesToUpload, modalGetContainer }) => {
    const modal = useModal('CdrUploadFilesModal');

    const myGroupsLoadable = useRecoilValueLoadable(GetUserGroups);
    const myGroups =
      myGroupsLoadable.state === 'hasValue' ? myGroupsLoadable.contents : [];
    const currentOrganization = useRecoilValue(OrganizationState);
    const { filePermissionsQuery, filePermissionsQueryIsLoading } =
      useGetFilePermissionsQuery();

    const queriesLoading =
      myGroupsLoadable.state === 'loading' || filePermissionsQueryIsLoading;

    // State
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<CdrUploadFilesModalSteps>(
      CdrUploadFilesModalSteps.ADD_FILES
    );
    const [folders, setFolders] = useState<Folder[]>([]);
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [disableFinish, setDisableFinish] = useState<boolean>(true);

    const requireImageCredits = cdApp.organization.settings.requireImageCredits;

    // Form
    const [form] = Form.useForm();

    const onSubmit = (formValues) => {
      setIsLoading(true);
      uploadFiles({
        files,
        groupId: formValues.groupId,
        folderId: formValues.folderId || -1,
        visibility: formValues.visibility,
        copyright: formValues.copyright || null,
        organizationId: currentOrganization.id,
      })
        .then((files) => {
          modal.resolve({ files } as { files: CdrFile[] });
          modal.hide();
        })
        .catch(ErrorHandlingService.handleError)
        .finally(() => {
          setIsLoading(false);
          modal.remove();
        });
    };

    // Using depth-first algoirthm to add the value prop and icon to title for all the folders in tree
    const addValueToFolders = (folders) => {
      const foldersCopy = cloneDeep(folders);
      const stack = [...foldersCopy];
      while (stack.length) {
        const folder = stack.pop();
        folder.value = folder.id;
        folder.title = (
          <div>
            <CdFolderIcon style={{ marginRight: '8px' }} /> {folder.title}
          </div>
        );
        // Add children to stack
        if (folder.children?.length) {
          stack.push(...folder.children);
        }
      }
      return foldersCopy;
    };

    const updateFolders = (groupId) => {
      if (!groupId) {
        setFolders([]);
      } else {
        fetchFolders()
          .then((folders) => {
            const groupFolders = folders[groupId];
            if (groupFolders) {
              const updatedGroupFolders = addValueToFolders(groupFolders);
              setFolders(updatedGroupFolders);
            } else {
              setFolders([]);
            }
          })
          .catch(ErrorHandlingService.handleError);
      }
    };

    const complexDepsCheckFileUpload = filesToUpload
      ? JSON.stringify(filesToUpload.map((file) => file.uid))
      : null;
    // Setting files to initial files
    useEffect(() => {
      if (filesToUpload.length) {
        setFiles(filesToUpload);
        setCurrentStep(CdrUploadFilesModalSteps.SETTINGS);
      }
    }, [filesToUpload, complexDepsCheckFileUpload]);

    const numberOfFiles = files.length ? `(${files.length})` : '';
    const noFilesAdded = !files.length;
    return (
      <Modal
        {...antdModalV5(modal)}
        width={700}
        centered
        getContainer={modalGetContainer}
        title={
          <Steps
            style={{ marginRight: 50, width: '80%', marginBottom: 32 }}
            direction="horizontal"
            size="small"
            current={currentStep}
            onChange={setCurrentStep}
          >
            <Steps.Step
              title={`${gettextCatalog.getString(
                'Add Files'
              )} ${numberOfFiles}`}
            />
            <Steps.Step
              title={gettextCatalog.getString('Settings')}
              disabled={noFilesAdded}
            />
          </Steps>
        }
        destroyOnClose
        footer={
          currentStep === CdrUploadFilesModalSteps.ADD_FILES ? (
            <Button
              type="primary"
              onClick={() => setCurrentStep(CdrUploadFilesModalSteps.SETTINGS)}
              disabled={noFilesAdded}
            >
              {gettextCatalog.getString('Next')}
            </Button>
          ) : (
            <Space>
              <Button
                onClick={() =>
                  setCurrentStep(CdrUploadFilesModalSteps.ADD_FILES)
                }
                disabled={isLoading}
              >
                {gettextCatalog.getString('Back')}
              </Button>
              <Button
                type="primary"
                onClick={() => form.submit()}
                loading={isLoading}
                disabled={disableFinish || isLoading}
              >
                {gettextCatalog.getString('Upload')}
              </Button>
            </Space>
          )
        }
        maskTransitionName="maskTransitionName"
      >
        <Spin spinning={isLoading || queriesLoading}>
          {currentStep === CdrUploadFilesModalSteps.ADD_FILES ? (
            <Upload.Dragger
              listType="picture"
              multiple
              fileList={files}
              showUploadList={{
                showPreviewIcon: false,
                showDownloadIcon: false,
                showRemoveIcon: true,
              }}
              beforeUpload={() => false}
              onChange={({ fileList }) => setFiles(fileList)}
            >
              <p className="ant-upload-drag-icon">
                <CdUploadIcon />
              </p>
              <p className="ant-upload-text">
                {gettextCatalog.getString(
                  'Click or drag files to this area to upload'
                )}
              </p>
              <p className="ant-upload-hint">
                {gettextCatalog.getString('Single and bulk upload.')}
              </p>
            </Upload.Dragger>
          ) : (
            <Form
              form={form}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 12,
              }}
              initialValues={{ visibility: FileVisibility.GROUP }}
              layout="horizontal"
              onFinish={onSubmit}
              onValuesChange={(changedValues) => {
                // Check if groupId was changed
                if (changedValues['groupId']) {
                  updateFolders(changedValues['groupId']);
                }
                // Error check
                form.validateFields().catch((err) => {
                  err.errorFields.length > 0
                    ? setDisableFinish(true)
                    : setDisableFinish(false);
                });
              }}
            >
              <CdTooltip
                title={
                  filePermissionsQuery &&
                  !filePermissionsQuery['change visibility'] &&
                  gettextCatalog.getString(
                    'You are not allowed to change visibility to public'
                  )
                }
              >
                <Form.Item
                  name="visibility"
                  label={gettextCatalog.getString('Visibility')}
                  rules={[
                    {
                      required: true,
                      message: gettextCatalog.getString(
                        'Please choose visibility'
                      ),
                    },
                  ]}
                >
                  <Select
                    placeholder={gettextCatalog.getString('Choose Visibility')}
                    disabled={
                      filePermissionsQuery &&
                      !filePermissionsQuery['change visibility']
                    }
                    options={[
                      {
                        label: gettextCatalog.getString('Public'),
                        value: FileVisibility.PUBLIC,
                      },
                      {
                        label: gettextCatalog.getString(
                          'Only share with group'
                        ),
                        value: FileVisibility.GROUP,
                      },
                    ]}
                  />
                </Form.Item>
              </CdTooltip>
              <Form.Item
                name="groupId"
                label={gettextCatalog.getString('Share with group')}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString('Please select a group'),
                  },
                ]}
              >
                <Select
                  placeholder={gettextCatalog.getString('Select group')}
                  allowClear
                  options={myGroups.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                />
              </Form.Item>

              <Form.Item
                name="folderId"
                label={gettextCatalog.getString('Folder')}
              >
                <TreeSelect
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={folders}
                  placeholder={gettextCatalog.getString('Select a folder')}
                  disabled={isEmpty(folders)}
                />
              </Form.Item>

              <Form.Item
                name="copyright"
                label={gettextCatalog.getString('Copyright')}
                rules={
                  requireImageCredits
                    ? [
                        {
                          required: files?.some((file) =>
                            file.type.includes('image/')
                          ),
                          message: gettextCatalog.getString(
                            'Copyright is required'
                          ),
                        },
                      ]
                    : []
                }
              >
                <Input
                  placeholder={gettextCatalog.getString(
                    'i.e. Photo by John Smith'
                  )}
                  addonBefore={<CdCopyrightIcon />}
                />
              </Form.Item>
            </Form>
          )}
        </Spin>
      </Modal>
    );
  }
);
