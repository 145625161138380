/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tabs, Space, Dropdown, Button, Menu } from 'antd';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
  faAngleDown,
  faEye,
  faTrash,
  faHistory,
} from '@fortawesome/free-solid-svg-icons';

import { getStoles } from '../../redux/stoles/Selectors';
import { isLoading as isLoadingSelector } from '../../../shared/loading/redux/Selectors';
import { Stole, StoleStatusTypes } from '../../models/stole';
import { fetchStoles, restoreStole } from '../../redux/stoles/Actions';
import {
  StyledTabs,
  StyledTable,
} from '../../../shared/antd/StyledAntdComponents';
import gettextCatalog from '../../../services/I18nService';
import { PaymentMethodTypes } from '../../models/intention';
import { navigate } from '../../../services/StateServiceFactory';
import StoleService from '../../services/StoleService';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import { handleSuccessMessage } from '../../../shared/utils';
import { OrderDirection, TableOrdering } from '../../../shared/models/table';

import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

// Antd
const { TabPane } = Tabs;

// Tab constants
enum StolesTabNames {
  OPEN = 'open',
  COMPLETED = 'completed',
  ALL = 'all',
}

const invalidateMouseEvent = (event: React.MouseEvent): void => {
  if (!event) return;
  event.preventDefault();
  event.stopPropagation();
};

const navigateToEvent = (stole: Stole, event: React.MouseEvent) => {
  invalidateMouseEvent(event);
  if (!isStoleEventDeleted(stole)) {
    navigate('app.private.calendar.event', { id: stole.calendarId });
  }
};

const isStoleDeleted = (stole: Stole) => stole && !_.isNil(stole.deletedAt);

const isStoleEventDeleted = (stole: Stole) => stole && _.isNil(stole.event);

const isStoleCompleted = (stole: Stole) =>
  stole && stole.status === StoleStatusTypes.COMPLETED;

// Props
interface StolesOverviewProps {
  filters?: Record<string, any>;
}

const StolesOverview: FunctionComponent<StolesOverviewProps> = ({
  filters,
}) => {
  // Redux
  const dispatch = useDispatch();

  // Retrieve required entities from Redux store
  const _stoles = useSelector(getStoles);
  const stoles = _stoles && _stoles.asMutable({ deep: true });
  const isLoading: boolean = useSelector(isLoadingSelector);

  // Define initialization hook and entity reload hooks
  const [currentTab, setCurrentTab] = useState<StolesTabNames>();

  const [tableOrdering, setTableOrdering] = useState<Partial<TableOrdering>>();

  // Default filters to empty if does not exist
  if (!filters) filters = {};

  // Adding include deleted to filters always
  _.extend(filters, { includeDeleted: true });

  // Retrieve required entities
  useEffect(() => {
    const defaultTab = StolesTabNames.OPEN;

    setCurrentTab(defaultTab);

    // Setting the default ordering
    setTableOrdering({
      orderBy: 'referenceNumber',
      orderDirection: OrderDirection.DESC,
    });

    refreshStolesTable(defaultTab);
  }, []);

  // Update intentions based on filter change
  useEffect(() => {
    if (!_.isEmpty(filters)) {
      refreshStolesTable(currentTab);
    }
  }, [JSON.stringify(filters)]);

  // Refresh table when sort updates
  useEffect(() => {
    if (tableOrdering) {
      refreshStolesTable(currentTab);
    }
  }, [JSON.stringify(tableOrdering)]);

  const onTabChange = (tab: StolesTabNames) => {
    // Reset table sort
    setCurrentTab(tab);
    refreshStolesTable(tab);
  };

  const handleTableChange = (_pagination, _filters, sorter) => {
    // Only set field if order is provided
    const newTableOrdering: TableOrdering = {
      // formattedReferenceNumber is not a database column
      orderBy: sorter.order
        ? sorter.field === 'formattedReferenceNumber'
          ? 'referenceNumber'
          : sorter.field
        : null,
      orderDirection:
        sorter.order === 'ascend'
          ? OrderDirection.ASC
          : sorter.order === 'descend'
            ? OrderDirection.DESC
            : null,
    };

    // Setting ordering if it has changed
    if (!_.isEqual(newTableOrdering, tableOrdering)) {
      setTableOrdering(newTableOrdering);
    }
  };

  const refreshStolesTable = (tab: StolesTabNames) => {
    const includeDeleted = tab === StolesTabNames.ALL;
    const status = includeDeleted ? null : (tab as any);

    const params = _.extend({
      ...filters,
      status,
      includeDeleted,
      ...tableOrdering,
    });

    dispatch(fetchStoles(params));
  };

  const removeStole = (stole: Stole, event: React.MouseEvent) => {
    invalidateMouseEvent(event);
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Delete stole'),
      message: gettextCatalog.getString(
        'Do you want to delete the stole {{referenceNumber}}?',
        {
          referenceNumber: stole.formattedReferenceNumber,
        }
      ),
      onOk() {
        StoleService.deleteStole(stole.id)
          .then(() => {
            handleSuccessMessage(
              gettextCatalog.getString('Successfully deleted stole.')
            );
            refreshStolesTable(currentTab);
          })
          .catch(ErrorHandlingService.handleError);
      },
    });
  };
  // Tabs CONFIG

  const stolesTableTabs = [
    {
      name: gettextCatalog.getString('Open'),
      key: StolesTabNames.OPEN,
    },
    {
      name: gettextCatalog.getString('Completed'),
      key: StolesTabNames.COMPLETED,
    },
    {
      name: gettextCatalog.getString('All'),
      key: StolesTabNames.ALL,
    },
  ];

  // Get table config based on state
  const columns: any = [
    {
      title: 'Nummer',
      dataIndex: 'formattedReferenceNumber',
      key: 'formattedReferenceNumber',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Stoltext',
      dataIndex: 'stoleText',
      key: 'stoleText',
    },
    {
      title: 'Bemerkung',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Stolart',
      dataIndex: ['fee', 'name'],
      key: 'fee',
    },
    {
      title: gettextCatalog.getString('Parish'),
      dataIndex: ['church', 'name'],
      key: 'church',
    },
    {
      title: gettextCatalog.getString('Resource'),
      dataIndex: ['resource', 'name'],
      key: 'resource',
    },
    {
      title: 'Zahlungsmethode',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: function columnRender(paymentMethod: PaymentMethodTypes) {
        return (
          <div>
            {paymentMethod === PaymentMethodTypes.PAIDBYCASH
              ? 'Bar'
              : 'Überweisung'}
          </div>
        );
      },
    },
    {
      title: 'Zahlungsstatus',
      dataIndex: 'paid',
      key: 'paid',
      align: 'center',
      render: function columnRender(paid: boolean) {
        return (
          <div>
            {paid ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} />
            )}
          </div>
        );
      },
    },
    {
      title: 'Kalendereintrag',
      dataIndex: null,
      key: 'event',
      render: function columnRender(stole: Stole) {
        return (
          <div>
            {isStoleEventDeleted(stole) ? null : (
              <a
                onClick={(event: React.MouseEvent) =>
                  navigateToEvent(stole, event)
                }
              >
                <Space direction="vertical">
                  <label>
                    {gettextCatalog.formatDate(stole.event.startDate, 'LLL')}
                  </label>
                  <span>{stole.event.title}</span>
                </Space>
              </a>
            )}
          </div>
        );
      },
    },
    {
      title: null,
      dataIndex: null,
      key: 'actionsMenu',
      fixed: 'right',
      width: 240,
      render: function columnRender(stole: Stole) {
        return (
          <Space style={{ display: 'flex', flexDirection: 'row' }}>
            {isStoleDeleted(stole) ? (
              <Button
                disabled={isStoleEventDeleted(stole)}
                danger
                size="small"
                style={{ marginRight: 8 }}
                onClick={(event: React.MouseEvent) =>
                  revertDeletion(stole, event)
                }
              >
                <FontAwesomeIcon icon={faHistory} style={{ marginRight: 8 }} />
                {gettextCatalog.getString('Restore')}
              </Button>
            ) : (
              <Dropdown
                disabled={isStoleEventDeleted(stole)}
                overlay={getActionsMenu(stole)}
                trigger={['click']}
              >
                <Button size="small" onClick={invalidateMouseEvent}>
                  {gettextCatalog.getString('Actions')}{' '}
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    style={{ marginLeft: 8 }}
                  />
                </Button>
              </Dropdown>
            )}
          </Space>
        );
      },
    },
  ];

  const revertDeletion = (stole: Stole, event: React.MouseEvent) => {
    invalidateMouseEvent(event);
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Restore stole'),
      message: gettextCatalog.getString(
        'Do you want to restore the deleted stole {{referenceNumber}}?',
        {
          referenceNumber: stole.formattedReferenceNumber,
        }
      ),
      onOk() {
        dispatch(restoreStole({ id: stole.id }));
      },
    });
  };

  // Menus

  const getActionsMenu = (stole: Stole) => (
    <Menu>
      <Menu.Item key="view">
        <a onClick={(event: React.MouseEvent) => navigateToEvent(stole, event)}>
          <FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />
          {gettextCatalog.getString('View')}
        </a>
      </Menu.Item>
      {!isStoleCompleted(stole) && !isStoleDeleted(stole) ? (
        <Menu.Item key="delete">
          <a onClick={(event: React.MouseEvent) => removeStole(stole, event)}>
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: 8 }} />
            {gettextCatalog.getString('Delete')}
          </a>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <StyledTabs
      activeKey={currentTab}
      defaultActiveKey={StolesTabNames.OPEN}
      size="large"
      onChange={onTabChange}
      type="card"
    >
      {stolesTableTabs.map((stolesTableTab) => (
        <TabPane tab={stolesTableTab.name} key={stolesTableTab.key}>
          <StyledTable
            id="stolesOverviewTable"
            size="small"
            columns={columns}
            dataSource={stoles}
            loading={isLoading}
            scroll={{ x: true }}
            bordered={true}
            rowKey="id"
            rowClassName={(stole: Stole) =>
              !_.isNil(stole.deletedAt) ? 'errorRow' : null
            }
            onRow={(stole: Stole) => ({
              onClick: (event: React.MouseEvent) =>
                navigateToEvent(stole, event),
            })}
            onChange={handleTableChange}
          />
        </TabPane>
      ))}
    </StyledTabs>
  );
};

export default StolesOverview;
