import React from 'react';
import { useSelector } from 'react-redux';

import gettextCatalog from '../../services/I18nService';
import { isCategorySelected } from '../store/filters/Selectors';
import { useOnToggleCategory } from '../hooks';

import { FilterListItem } from './FilterItem';
import { showCalendarSubscribeModal } from './CalendarSubscribeModal';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { CdExportToIcal } from '@/react/shared/components/Icons';

interface DisplayFilterItem {
  name: string;
  color?: number;
  churchId: number;
  categoryId: number;
  isDisabled: boolean;
}

export const getClassName = (isSelected: boolean, color: number): string =>
  `bullet color-${color || 0} ${isSelected ? 'bullet-full' : 'bullet-empty'}`;

const ChurchCalendarItem = ({
  name,
  color,
  churchId,
  categoryId,
  isDisabled,
}: DisplayFilterItem) => {
  const onToggleCategory = useOnToggleCategory();
  const isSelected = useSelector(isCategorySelected(categoryId, churchId));
  const content = (
    <FilterListItem
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      isDisabled={isDisabled}
    >
      <div className="bullet-wrapper">
        <div
          className="bullet-label"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            !isDisabled && onToggleCategory({ categoryId, churchId });
          }}
        >
          <span className={getClassName(isSelected, color)} />
          <div className="label">
            <span className="name">{name}</span>
          </div>
        </div>
        <span className="filter-actions">
          <CdTooltip title={gettextCatalog.getString('Export to iCal')}>
            <CdExportToIcal
              onClick={() =>
                showCalendarSubscribeModal({
                  feedType: 'categories',
                  feedId: categoryId,
                })
              }
              style={{ marginRight: 5, cursor: 'pointer' }}
            />
          </CdTooltip>
        </span>
      </div>
    </FilterListItem>
  );
  if (isDisabled) {
    return (
      <CdTooltip
        title={gettextCatalog.getString(
          'This calendar cannot be enabled for this view'
        )}
      >
        {content}
      </CdTooltip>
    );
  }
  return content;
};

export default ChurchCalendarItem;
