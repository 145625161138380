import React, { Suspense, useEffect, useState } from 'react';
import { Card, Col, Form, FormInstance, Layout, Row, Spin } from 'antd';
import type { TabsProps } from 'antd';
import { ThemeProvider } from 'styled-components';

import { CdCard } from '@/react/shared/components/CdCard';
import { CdDesktop, CdMobile } from '@/react/shared/components/Icons';
import {
  CdTabPaneTab,
  CdTabs,
} from '@/react/shared/components/tab-container/TabContainer';
import { IframeMailViewer } from '@/react/people/message-editor/shared/containers/IframeMailViewer';
import {
  MessageStatisticsFilters,
  MessageType,
} from '@/react/people/types/message';
import { MobilePreview } from '@/react/people/message-editor/advanced-editor/MobilePreview';
import { Statistics } from '@/react/people/message-editor/statistics/Statistics';
import { useMailEditor, useMessage } from '@/react/people/hooks';
import getTextCatalog from '@/react/services/I18nService';
import MessageFormDetails from '@/react/people/message-editor/shared/containers/MessageDetails';
import NavigationButtons from '@/react/people/message-editor/advanced-editor/NavigationButtons';
import { useOrganization } from '@/react/organization/hooks/useOrganization';
import { useHandleMessageSave } from '@/react/people/hooks/useMailEditor';

interface MailContainerProps {
  messageId?: number;
  type: MessageType;
  to: any[];
  contactIds: number[];
  children: JSX.Element;
  form: FormInstance;
  statisticsFilter: MessageStatisticsFilters;
}

export default function MailContainer({
  messageId,
  to,
  contactIds,
  children,
  type,
  form,
  statisticsFilter,
}: MailContainerProps) {
  const [current, setCurrent] = useState('design');

  const {
    message,
    saveMessage,
    deleteMessage,
    cloneMessage,
    sendMessage,
    sendTestMessage,
    refetchMessage,
    canViewStatistics,
  } = useMessage(messageId, type);

  const { debouncedOnFormChange } = useMailEditor({
    type,
    form,
    to,
    contactIds,
    message,
    saveMessage,
  });

  let tabNumber = 0;

  const { handleSave } = useHandleMessageSave(saveMessage, form);

  const {
    organizationSettings: { newsletterColor },
  } = useOrganization();
  const messageNewsletterColor = message.content?.newsletterColor;

  const isScheduled = message.scheduled;
  const isSent = message.sent;
  useEffect(() => {
    if (isScheduled && canViewStatistics) {
      setCurrent('statistics');
    } else if (isScheduled || !canViewStatistics) {
      setCurrent('toAndFrom');
    }
  }, [canViewStatistics, isScheduled, isSent]);

  const onChange = (current) => {
    if (!isSent) {
      handleSave();
    }
    setCurrent(current);
  };

  const themeItems: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <CdDesktop />
          {getTextCatalog.getString('Desktop')}
        </>
      ),
      children: (
        <div style={{ flexGrow: 1 }}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <IframeMailViewer
                minHeight="748px"
                value={getFieldValue('rendered')}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <>
          <CdMobile />
          {getTextCatalog.getString('Mobile')}
        </>
      ),
      children: (
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <MobilePreview value={getFieldValue('rendered')} />
          )}
        </Form.Item>
      ),
    },
  ];

  const designItem = {
    key: 'design',
    label: (
      <CdTabPaneTab
        title={getTextCatalog.getString('Design')}
        subTitle=""
        tabNumber={!isSent ? ++tabNumber : tabNumber}
      />
    ),
    children: children,
  };

  const originalItems: TabsProps['items'] = [
    {
      key: 'toAndFrom',
      label: (
        <CdTabPaneTab
          title={getTextCatalog.getString('To and from')}
          subTitle=""
          tabNumber={++tabNumber}
        />
      ),
      children: (
        <CdCard $fill>
          <Row justify="center">
            <Col style={{ maxWidth: '800px' }} flex="1 1 auto">
              <MessageFormDetails isSent={!!isSent} />
            </Col>
          </Row>
          <Row style={{ height: '100%' }}>
            <Col span={24}>
              <CdTabs
                defaultActiveKey="1"
                type="card"
                size="large"
                centered
                $fluidHeight
                items={themeItems}
              />
            </Col>
          </Row>
        </CdCard>
      ),
    },
  ];

  const statisticItem = {
    key: 'statistics',
    label: (
      <CdTabPaneTab
        title={getTextCatalog.getString('Statistics')}
        subTitle={getTextCatalog.getString(
          'Analyze the performance of your message'
        )}
        tabNumber={isScheduled && canViewStatistics ? ++tabNumber : tabNumber}
      />
    ),
    children: (
      <Card style={{ width: '100%' }}>
        <Suspense
          fallback={
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          }
        >
          <Statistics
            message={message}
            refetchMessage={refetchMessage}
            defaultFilter={statisticsFilter}
          />
        </Suspense>
      </Card>
    ),
  };

  if (!isSent) originalItems.splice(0, 0, designItem);
  if (isScheduled && canViewStatistics) {
    originalItems.push(statisticItem);
  }

  return (
    <Layout.Content className="app-main" style={{ padding: 0, height: '100%' }}>
      <ThemeProvider
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            newsletter: {
              main: messageNewsletterColor || newsletterColor,
            },
          },
        })}
      >
        <Form
          form={form}
          labelCol={{ lg: { span: 3 } }}
          onValuesChange={debouncedOnFormChange}
          style={{ height: '100%' }}
        >
          <CdTabs
            activeKey={current}
            onChange={onChange}
            tabBarStyle={{ marginBottom: 0 }}
            $fluidHeight={type !== MessageType.SIMPLE}
            tabBarExtraContent={{
              right: (
                <NavigationButtons
                  message={message}
                  handleCopy={cloneMessage}
                  handleDelete={deleteMessage}
                  sendMessage={sendMessage}
                  sendTestMessage={sendTestMessage}
                  form={form}
                  saveMessage={saveMessage}
                  formValidationError={() => setCurrent('toAndFrom')}
                />
              ),
            }}
            items={originalItems}
          />
        </Form>
      </ThemeProvider>
    </Layout.Content>
  );
}
