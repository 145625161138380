import React from 'react';
import {
  Modal,
  Spin,
  DatePicker,
  Row,
  Space,
  Checkbox,
  Radio,
  Form,
  InputNumber,
  TimePicker,
} from 'antd';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import { useRecoilValueLoadable } from 'recoil';
import moment from 'moment';

import { ConsentTypeQuery } from '../../../people/store/consentState';
import NotificationService from '../../../services/NotificationService';
import config from '../../../config';

import { FieldSection } from './FieldSection';
import { FormItemNoErrorMessage } from './form-items/FormItemNoErrorMessage';

import {
  EventFormConfiguration,
  PaymentMethodType,
} from '@/react/calendar/types/event';
import gettextCatalog from '@/react/services/I18nService';

export type CdNewSignUpFormComponentProps = {
  onChange: ({
    configuration,
    id,
  }: {
    configuration: EventFormConfiguration;
    id: string;
  }) => void;
};

export const CdNewSignUpFormModal =
  NiceModal.create<CdNewSignUpFormComponentProps>((props) => (
    <CdNewSignUpFormComponent {...props} />
  ));
export const CdNewSignUpFormComponent = ({
  onChange,
}: CdNewSignUpFormComponentProps) => {
  const [form] = Form.useForm();
  const modal = useModal('CdNewSignUpFormComponent');
  const datePickerFormat = gettextCatalog.getLongDateFormat('ll');
  const allConsentTypeLoadable = useRecoilValueLoadable(ConsentTypeQuery);
  const allConsentType = allConsentTypeLoadable.contents;
  const formatter = new Intl.NumberFormat(config.organization.locale.language, {
    style: 'currency',
    currency: config.organization.currencyIso,
  });
  const ticketPrice = Form.useWatch(['signUpForm', 'pricePerTickets'], form);

  const onOkHandler = React.useCallback(() => {
    form
      .validateFields()
      .then(() => {
        const { signUpForm } = form.getFieldsValue();
        const updateStartDate = moment(
          `${signUpForm.startDate.format(
            'YYYY-MM-DD'
          )} ${signUpForm.startTime.format('HH:mm')}`,
          'YYYY-MM-DD HH:mm'
        );
        const updateEndDate = signUpForm.endDate
          ? moment(
              `${signUpForm.endDate.format(
                'YYYY-MM-DD'
              )} ${signUpForm.endTime.format('HH:mm')}`,
              'YYYY-MM-DD HH:mm'
            )
          : undefined;
        onChange({
          configuration: {
            ...signUpForm,
            startDate: updateStartDate,
            endDate: updateEndDate,
            endTime: undefined,
            startTime: undefined,
          },
          id: undefined,
        });
        modal.hide();
      })
      .catch((err) => {
        if (err.errorFields?.length > 0) {
          err.errorFields?.forEach((field) => {
            field?.errors?.forEach((error) => {
              NotificationService.notifyError(error);
            });
          });
        }
      });
  }, [form, modal, onChange]);

  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        maskClosable: false,
        closable: false,
        width: 750,
        title: gettextCatalog.getString(`Configure event sign-up form`),
        bodyStyle: { padding: 8 },
        onOk: onOkHandler,
      }}
    >
      <Spin spinning={allConsentTypeLoadable.state === 'loading'}>
        <Form form={form} layout="vertical" style={{ padding: '16px' }}>
          <Row style={{ marginBottom: 24 }}>
            <FieldSection>
              <Space>
                <FormItemNoErrorMessage
                  name={['signUpForm', 'startDate']}
                  initialValue={moment().startOf('day')}
                  label={gettextCatalog.getString('Open form')}
                  required
                  rules={[
                    {
                      required: true,
                      message: gettextCatalog.getString(
                        'The field is required.'
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    placeholder={gettextCatalog.getString('Start date')}
                    format={datePickerFormat}
                    allowClear
                  />
                </FormItemNoErrorMessage>
                <FormItemNoErrorMessage
                  name={['signUpForm', 'startTime']}
                  style={{ paddingTop: '31px' }}
                  label={null}
                  initialValue={moment().startOf('day')}
                  rules={[
                    {
                      required: true,
                      message: gettextCatalog.getString(
                        'The field is required.'
                      ),
                    },
                  ]}
                >
                  <TimePicker format="HH:mm" />
                </FormItemNoErrorMessage>
                <FormItemNoErrorMessage
                  name={['signUpForm', 'endDate']}
                  label={gettextCatalog.getString('Close form')}
                  dependencies={['signUpForm', 'startDate']}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        if (
                          value &&
                          form
                            .getFieldValue('signUpForm')
                            .startDate?.isAfter(value)
                        ) {
                          return Promise.reject(
                            gettextCatalog.getString(
                              'Close form date should be after open form date.'
                            )
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    placeholder={gettextCatalog.getString('End date')}
                    format={datePickerFormat}
                    allowClear
                    onChange={(date) => {
                      if (!form.getFieldValue('signUpForm')?.endTime) {
                        form.setFieldsValue({
                          signUpForm: {
                            ...form.getFieldValue('signUpForm'),
                            endTime: moment(date.toString()).endOf('day'),
                          },
                        });
                      }
                    }}
                  />
                </FormItemNoErrorMessage>
                <FormItemNoErrorMessage
                  label=" "
                  name={['signUpForm', 'endTime']}
                  dependencies={['signUpForm', 'endDate']}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        const startDate = form
                          .getFieldValue('signUpForm')
                          .startDate?.format('YYYY-MM-DD');
                        const endDate = form
                          .getFieldValue('signUpForm')
                          .endDate?.format('YYYY-MM-DD');
                        const startTime =
                          form.getFieldValue('signUpForm')?.startTime;

                        if (
                          value &&
                          moment(startDate)?.isSameOrAfter(moment(endDate)) &&
                          moment(startTime, 'HH:mm').isSameOrAfter(
                            moment(value, 'HH:mm')
                          )
                        ) {
                          return Promise.reject(
                            gettextCatalog.getString(
                              'Close form time should be after open form time.'
                            )
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <TimePicker format="HH:mm" />
                </FormItemNoErrorMessage>
              </Space>
            </FieldSection>
          </Row>

          <Row>
            <Space>
              <FieldSection>
                <Form.Item
                  name={['signUpForm', 'isPhoneNumberRequired']}
                  label={gettextCatalog.getString('Contact information')}
                  style={{
                    paddingBottom: '0px',
                    marginBottom: '0px',
                  }}
                  valuePropName="checked"
                >
                  <Checkbox style={{ fontWeight: 'lighter' }}>
                    {gettextCatalog.getString(
                      'Make phone number a required field'
                    )}
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name={['signUpForm', 'askForAddress']}
                  valuePropName="checked"
                >
                  <Checkbox style={{ fontWeight: 'lighter' }}>
                    {gettextCatalog.getString('Ask for address details')}
                  </Checkbox>
                </Form.Item>
              </FieldSection>
            </Space>
          </Row>
          <Row>
            <FieldSection>
              <Form.Item
                name={['signUpForm', 'consentTypeIds']}
                label={gettextCatalog.getString('Ask for Consents')}
              >
                <Checkbox.Group
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  {allConsentType?.items?.map((consent) => (
                    <Checkbox
                      value={consent.id}
                      style={{ fontWeight: 'lighter', marginLeft: 0 }}
                      key={consent.id}
                    >
                      {gettextCatalog.getString(
                        'I agree to {{ consentLabel }}',
                        { consentLabel: consent.label }
                      )}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </FieldSection>
          </Row>
          <FieldSection>
            <Form.Item
              name={['signUpForm', 'numberOfTickets']}
              rules={[
                { type: 'integer' },
                {
                  required: true,
                  message: gettextCatalog.getString('The field is required.'),
                },
                () => ({
                  validator(rule, value) {
                    if (value && value < 1) {
                      return Promise.reject(
                        gettextCatalog.getString(
                          'The value is not valid. Please specify the number of tickets available for this event.'
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              label={gettextCatalog.getString('Number of tickets')}
            >
              <InputNumber style={{ width: '300px' }} />
            </Form.Item>
          </FieldSection>
          <FieldSection>
            <Form.Item
              name={['signUpForm', 'maxTicketsPerOrder']}
              rules={[
                { type: 'integer' },
                {
                  required: true,
                  message: gettextCatalog.getString('The field is required.'),
                },
                () => ({
                  validator(rule, value) {
                    if (
                      value &&
                      value > form.getFieldValue('signUpForm').numberOfTickets
                    ) {
                      return Promise.reject(
                        gettextCatalog.getString(
                          'Tickets per order should be less than total number of tickets'
                        )
                      );
                    } else if (value && value < 1) {
                      return Promise.reject(
                        gettextCatalog.getString(
                          'The value is not valid. Please specify the number of tickets that can be ordered per transaction.'
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              label={gettextCatalog.getString('Maximum tickets per order')}
            >
              <InputNumber style={{ width: '300px' }} />
            </Form.Item>
          </FieldSection>

          <FieldSection>
            <Form.Item
              name={['signUpForm', 'pricePerTickets']}
              dependencies={['signUpForm', 'paymentMethod']}
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value && value < 1) {
                      return Promise.reject(
                        gettextCatalog.getString(
                          'Please choose a valid ticket price.'
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              label={gettextCatalog.getString('Price per ticket')}
              extra={
                ticketPrice
                  ? gettextCatalog.getString('You have entered {{ price }}', {
                      price: formatter.format(ticketPrice),
                    })
                  : gettextCatalog.getString('Leave blank if event is free')
              }
            >
              <InputNumber
                style={{
                  width: '300px',
                }}
                type="number"
              />
            </Form.Item>
          </FieldSection>
          <FieldSection>
            <Form.Item
              name={['signUpForm', 'paymentMethod']}
              label={gettextCatalog.getString('Payment options for tickets')}
              dependencies={['signUpForm', 'pricePerTickets']}
              rules={[
                () => ({
                  validator(rule, value) {
                    if (
                      value === undefined &&
                      form.getFieldValue('signUpForm').pricePerTickets !==
                        undefined
                    ) {
                      return Promise.reject(
                        gettextCatalog.getString(
                          'Please select a payment option'
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Radio.Group style={{ display: 'flex', flexDirection: 'column' }}>
                <Radio value={PaymentMethodType.Online}>
                  {gettextCatalog.getString(
                    'Online payment options via ChurchDesk'
                  )}
                </Radio>
                <Radio value={PaymentMethodType.Cash}>
                  {gettextCatalog.getString(
                    'Cash (other payment options handled manually'
                  )}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </FieldSection>
        </Form>
      </Spin>
    </Modal>
  );
};
