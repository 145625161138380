import React from 'react';
import styled from 'styled-components';
import { readableColor } from 'polished';

import gettextCatalog from '@/react/services/I18nService';

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.newsletter.main};
  color: ${({ theme }) => readableColor(theme.colors.newsletter.main)};
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  padding: 26px;

  p {
    margin: 0;
    font-family: 'Lato';
  }
`;

interface NewsletterEditorFooterProps {
  name: string;
  address: string;
  email: string;
}

export default function Footer({
  name,
  address,
  email,
}: NewsletterEditorFooterProps) {
  return (
    <FooterContainer>
      <p>{name}</p>
      <p style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{address}</p>
      <p style={{ textAlign: 'center' }}>{email}</p>
      <p style={{ paddingTop: '16px' }}>
        [{gettextCatalog.getString('Unsubscribe')}]
      </p>
    </FooterContainer>
  );
}
