import React, { useContext } from 'react';
import { Button, Space, Typography } from 'antd';
import NiceModal from '@ebay/nice-modal-react';

import { FieldSection } from '../../components/FieldSection';
import { MINIMUM_COLUMN_WIDTH } from '../../constants';
import { EventAccessContext } from '../../hooks/use-event';

import getTextCatalog from '@/react/services/I18nService';
import { CalendarPosterList } from '@/react/calendar/containers/event/CalendarPosterList';
import { navigate } from '@/react/services/StateServiceFactory';

export const PosterTab = ({
  eventId,
  saveAndClose,
}: {
  eventId: number;
  saveAndClose: any;
}) => {
  const { eventAccess } = useContext(EventAccessContext);
  const { canEdit, canCreate } = eventAccess;
  const handleCreatePoster = () => {
    saveAndClose({
      title: getTextCatalog.getString('Save before creating a poster'),
      message: getTextCatalog.getString(
        'To create a poster you will first have to save the event.'
      ),
      okText: getTextCatalog.getString('Save & continue'),
      cancelText: getTextCatalog.getString('Cancel'),
      skipRedirecting: false,
    }).then(() =>
      NiceModal.show('CalendarPosterTemplates', {
        calendarId: eventId,
      })
    );
  };
  const handleEditPoster = (posterId) => {
    saveAndClose({
      title: getTextCatalog.getString('Save before editing a poster'),
      message: getTextCatalog.getString(
        'To edit a poster you will first have to save the event.'
      ),
      okText: getTextCatalog.getString('Save & continue'),
      cancelText: getTextCatalog.getString('Cancel'),
      skipRedirecting: true,
    }).then(() =>
      navigate('app.private.calendar.poster', {
        posterId,
        calendarId: eventId,
      })
    );
  };
  return (
    <FieldSection>
      <Space direction="vertical">
        <Typography.Text strong>
          {getTextCatalog.getString('Posters')}
        </Typography.Text>
        <Button
          type="primary"
          onClick={handleCreatePoster}
          disabled={!(canCreate || canEdit)}
        >
          {getTextCatalog.getString('Create poster')}
        </Button>
      </Space>
      <div
        style={{
          border: '1px solid 	#E5E4E2',
          borderRadius: '4px',
          marginTop: '16px',
          width: `${MINIMUM_COLUMN_WIDTH + 24}px`,
        }}
      >
        <CalendarPosterList
          eventId={eventId}
          isFromEventDetailsPage={true}
          handleCreatePoster={handleCreatePoster}
          handleEditPoster={handleEditPoster}
        />
      </div>
    </FieldSection>
  );
};
