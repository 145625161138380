import React, { useState, FunctionComponent } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import styled from 'styled-components';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import ErrorHandlingService from '../../services/ErrorHandlingService';
import gettextCatalog from '../../services/I18nService';
import { RolesActions } from '../store/roleSlice';

interface FormValues {
  name: string;
  roleContext: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  onCreate: (values: FormValues) => void;
  onCancel: () => void;
}

const IconInButton = styled(FontAwesomeIcon)`
  &&&& {
    margin-right: 8px;
  }
`;

const CreateRoleModal: FunctionComponent<CollectionCreateFormProps> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={visible}
      title={gettextCatalog.getString('Create a new role')}
      okText={gettextCatalog.getString('Create')}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as FormValues);
          })
          .catch(ErrorHandlingService.handleError);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ roleContext: 'organization' }}
      >
        <Form.Item
          name="name"
          label={gettextCatalog.getString('Role name')}
          rules={[
            {
              required: true,
              message: `${gettextCatalog.getString(
                'Please give the role a name'
              )}`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const CreateRoleButton: FunctionComponent = () => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const onCreate = (values) => {
    dispatch(RolesActions.addRole({ name: values.name, context: 'church' }));
    setVisible(false);
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
        <IconInButton icon={faPlusCircle} />
        {gettextCatalog.getString('Create role')}
      </Button>
      <CreateRoleModal
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};
