import React from 'react';
import { Form, Row, Col, Typography, Skeleton } from 'antd';

import gettextCatalog from '../../services/I18nService';

const SkeletonInput = (props) => (
  <Skeleton.Input {...props} active style={{ width: '100%' }} block />
);

export const EditSafeguardingSuspended = () => (
  <Form layout="vertical">
    <Row gutter={[16, 16]} style={{ marginBottom: '24px', marginTop: '24px' }}>
      <Col span={24}>
        <Typography.Title level={3}>
          {gettextCatalog.getString('Safeguarding')}
        </Typography.Title>
        <Form.Item name="type">
          <SkeletonInput />
        </Form.Item>
        <Form.Item name="type">
          <SkeletonInput />
        </Form.Item>
        <Form.Item name="type">
          <SkeletonInput />
        </Form.Item>
        <Form.Item name="type">
          <SkeletonInput />
        </Form.Item>
        <Form.Item name="type">
          <SkeletonInput />
        </Form.Item>
        <Form.Item label={gettextCatalog.getString('Documents')}>
          <SkeletonInput />
        </Form.Item>
      </Col>
    </Row>
  </Form>
);
