'use strict';
import ErrorHandlingService from '@/react/services/ErrorHandlingService';
import { FetchUserPermission } from '@/react/user/redux/actions';
import { getUserPermission } from '@/react/user/redux/selectors';
import { PeopleRelationType } from '@/react/shared/models/people';
import { MessageType } from '@/react/people/types/message';
import { showModal } from '@/react/angular/ReactModalBridge';

class PeopleDetailController {
  constructor(
    _,
    $filter,
    $state,
    $stateParams,
    $uibModal,
    toastr,
    gettextCatalog,
    Authorization,
    People,
    PeopleActivities,
    PeopleTags,
    Forms,
    relationService,
    stepsFactory,
    $ngRedux,
    $scope,
    cdApp
  ) {
    this._ = _;
    this.$filter = $filter;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.Authorization = Authorization;
    this.People = People;
    this.PeopleActivities = PeopleActivities;
    this.PeopleTags = PeopleTags;
    this.Forms = Forms;
    this.relationService = relationService;
    this.stepsFactory = stepsFactory;
    this.cdApp = cdApp;

    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  /**
   * Lifecycle hook used for initialization work
   */
  $onInit() {
    const _ = this._;
    this.createMode = !this.person.id;

    this.showChurchSelector = this.cdApp.showChurchSelector;

    // Fetch all tags
    this.tags = this.PeopleTags.query(() => {
      this.person.tags = _.map(this.person.tags, (tag) =>
        _.find(this.tags, { id: tag.id })
      );
    });

    // formSubmissions might be redacted and in that case will just be a string of '****'.
    if (!_.isString(this.person.formSubmissions)) {
      // Ignore form activities other than created and updated
      _.remove(
        this.person.formSubmissions,
        (formSubmission) =>
          !_.includes(['created', 'updated'], formSubmission.action)
      );

      // Fetch information about the forms related to an activity
      if (!_.isEmpty(this.person.formSubmissions)) {
        const uniqueFormIds = _(this.person.formSubmissions)
          .map('formId')
          .uniq()
          .value();

        this.Forms.getPublic({}, { formIds: uniqueFormIds }, (forms) => {
          this.person.formSubmissions = _.map(
            this.person.formSubmissions,
            (submission) => {
              const correspondingForm = _.find(forms, {
                id: submission.formId,
              });

              if (!correspondingForm) {
                return _.extend({}, submission, {
                  link: `<span data-form-id="${
                    submission.formId
                  }">${this.gettextCatalog.getString('(deleted form)')}</span>`,
                });
              }

              return _.extend({}, submission, {
                link: `<a href="${this.$state.href('app.private.forms.view', {
                  id: correspondingForm.id,
                })}" data-form-id="${submission.formId}">${
                  correspondingForm.title
                }</a>`,
              });
            }
          );
        });
      }
    }

    /**
     * Generate the steps view
     */
    this.stepsInstance = new this.stepsFactory(
      [
        {
          key: 'basic',
          title: this.gettextCatalog.getString('Profile'),
          description: this.gettextCatalog.getString(
            'View and edit personal information'
          ),

          isEnabled: true,
          isShown: true,
        },

        {
          key: 'activity',
          title: this.gettextCatalog.getString('Activity'),
          description: this.gettextCatalog.getString(
            'View messages sent, contributions received, etc.'
          ),

          isEnabled: true,
          isShown: !this.createMode,
        },

        {
          key: 'settings',
          title: this.gettextCatalog.getString('Settings'),
          description: this.gettextCatalog.getString(
            'Manage tags and adjust message settings'
          ),

          isEnabled: true,
          isShown: true,
        },

        {
          key: 'pastoral-notes',
          title: this.gettextCatalog.getString('Pastoral Notes'),
          description: this.gettextCatalog.getString('Manage pastoral notes'),
          isEnabled: true,
          isShown: true,
        },
      ],

      {
        initialStep: this.$stateParams.step,
        onChange: (index) => {
          this.$state.go(this.$state.current, {
            step: this.stepsInstance.steps[index].key,
          });
        },
      }
    );

    /**
     * Fetch family information
     */
    this.relations = this.relationService.getRelations();
    this.getRelationInfo = this.relationService.getRelationInfo;

    this.shouldShowFamilyInformation = () => {
      const canViewRelations = this.person.fields.relations.canView;
      const isPersonSharingContactInformation = _.some(
        this.person.relations,
        (relation) => relation.isEmailShared || relation.isPhoneShared
      );

      return canViewRelations || isPersonSharingContactInformation;
    };

    this.shouldShowRelationDescription = (relation) => {
      const canViewRelations = this.person.fields.relations.canView;
      const isValidRelation = relation !== PeopleRelationType.UNSPECIFIED;
      return canViewRelations && isValidRelation;
    };

    this.typeOfEmail = (message) =>
      [MessageType.BASIC, MessageType.SIMPLE, MessageType.ADVANCED].includes(
        message.type
      );

    /**
     * Activity tabs
     */
    this.tabs = {
      all: {},
      email: {
        type: 'email',
      },

      sms: {
        type: 'sms',
      },
    };

    /**
     * Change the activity tab
     */
    this.changeTab = (tab) => (this.tabs.$active = tab);
    this.changeTab(this.tabs.all);

    this.filterMessageTab = (message) => {
      switch (this.tabs.$active.type) {
        case 'email':
          return this.typeOfEmail(message);
        case 'sms':
          return message.type === 'sms';
        default:
          return true;
      }
    };

    /**
     * Fetch all activities
     */
    this.activities = this.PeopleActivities.get(
      { id: this.$stateParams.id },
      () => {
        this.tabs.$count = _.mapValues(
          this.tabs,
          (filter) => _.filter(this.activities.messages, filter).length
        );
      }
    );

    this.FetchUserPermission({ permission: 'people.communicate' });
    this.FetchUserPermission({ permission: 'people.export gdpr' });
  }

  goToSettingsTab(jsEvent) {
    jsEvent.preventDefault();
    this.$state.go(this.$state.current, { step: 'settings' });
  }

  acceptsEmailCommunication() {
    return this.person.emailNotification && !this._.isNil(this.person.email);
  }

  acceptsSMSCommunication() {
    return this.person.smsNotification && !this._.isNil(this.person.phone);
  }

  createUserFromContact() {
    showModal('createUserModal', {
      firstName: this.person.firstName,
      lastName: this.person.lastName,
      email: this.person.email,
    });
  }

  /**
   * Delete the person
   */
  removePerson() {
    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Remove contact'),
          body: () =>
            this.gettextCatalog.getString(
              'Are you sure you want to remove this contact from the system?'
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Remove'),
            closeButtonText: this.gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        this.person.$remove(
          () => {
            this.toastr.success(
              this.gettextCatalog.getString('Contact deleted.')
            );

            this.$state.go('app.private.people.contacts.list');
          },
          (response) => {
            this.toastr.error(
              this.gettextCatalog.getString(
                'Contact not deleted: {{errorMessage}}'
              ),

              {
                errorMessage: response.data.message,
              }
            );
          }
        );
      });
  }

  /**
   * Launch a modal where the person's tags can be updated
   */
  updatePersonTags() {
    this.$uibModal
      .open({
        component: 'cdUpdatePersonTagsModal',
        resolve: {
          person: () => this._.cloneDeep(this.person),
          tags: () => this.PeopleTags.query().$promise,
        },

        windowClass: 'modal-scrollable',
        size: 'lg',
      })
      .result.then((newTags) => {
        this.person.tags = newTags;
        this.updatePerson();
      });
  }

  /**
   * Remove a tag from the person
   *
   * @param {Object} tag - The tab object
   */
  removeTag(tag) {
    this.person.tags = _.reject(this.person.tags, { id: tag.id });
    this.updatePerson();
  }

  /**
   * Get the url of a state
   *
   * @param {String} name - The name of the state
   * @param {Object} params - The state parameters
   *
   * @return {String} - The path of the state. If no `name` is given, the path of the current state is returned.
   */
  getStateUrl(name, params) {
    return this.$state.href(
      name || this.$state.current.name,
      params || this.$stateParams
    );
  }

  getMessageLink(message, display = 'view', filter) {
    switch (message.type) {
      case 'email':
      case 'sms':
        return this.$state.href(`app.private.people.messages.${display}`, {
          id: message.id,
          filter,
        });

      default:
        return this.$state.href(
          `app.private.people.messages.editorV2.${display}`,
          {
            type: message.type,
            messageId: message.id,
            display,
            filter,
          }
        );
    }
  }

  /**
   * Edit the person
   *
   * @param {String="profile,custom,family"} context - The type of information being edited
   */
  editPerson(context) {
    this.$state.go('app.private.people.contacts.view.edit', { context });
  }

  /**
   * Update the person
   *
   * @param {Boolean} reload - Whether to reload the state after saving
   */
  updatePerson(reload) {
    this.person.$save(
      [{ condition: !this.showPrefixField, field: 'prefix' }],
      (newPerson) => {
        this.toastr.success(
          this.gettextCatalog.getString('Your changes have been saved.')
        );

        if (this.createMode) {
          this.$state.go('app.private.people.contacts.view', {
            id: newPerson.id,
            step: this.$stateParams.step,
          });
        } else {
          if (reload) this.$state.reload();
        }
      }
    );
  }

  /**
   * Navigate to the correct step when the URL parameter changes
   */
  uiOnParamsChanged() {
    const stepKey = this.$stateParams.step;
    const stepExists = _(this.stepsInstance.steps).map('key').includes(stepKey);
    if (stepExists) this.stepsInstance.go(stepKey);
  }

  /**
   * Enable or disable message settings for a person
   *
   * @param {string} messageType The type of the message settings, 'emailNotification' or 'smsNotification'
   * @param {object} jsEvent The original click event
   */
  updateMessageSetting(messageType, jsEvent) {
    jsEvent.preventDefault();

    const { _, $filter, gettextCatalog, $uibModal } = this;

    const currentSetting = _.get(this.person, messageType);
    const personName = $filter('getName')(this.person);

    $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title() {
            if (messageType === 'emailNotification') {
              return currentSetting
                ? gettextCatalog.getString('Disable e-mails')
                : gettextCatalog.getString('Enable e-mails');
            }
            return currentSetting
              ? gettextCatalog.getString('Disable SMS')
              : gettextCatalog.getString('Enable SMS');
          },
          body() {
            if (messageType === 'emailNotification') {
              return currentSetting
                ? gettextCatalog.getString(
                    'Are you sure you want to disable sending e-mails to {{ personName }}?',
                    { personName }
                  )
                : gettextCatalog.getString(
                    'Are you sure you want to enable sending e-mails to {{ personName }}?',
                    { personName }
                  );
            }
            return currentSetting
              ? gettextCatalog.getString(
                  'Are you sure you want to disable sending SMS to {{ personName }}?',
                  { personName }
                )
              : gettextCatalog.getString(
                  'Are you sure you want to enable sending SMS to {{ personName }}?',
                  { personName }
                );
          },
          options: {
            confirmButtonText: currentSetting
              ? gettextCatalog.getString('Disable')
              : gettextCatalog.getString('Enable'),
            closeButtonText: gettextCatalog.getString('Cancel'),
            confirmButtonType: currentSetting ? 'danger' : 'success',
          },
        },
      })
      .result.then(() => {
        _.set(this.person, messageType, !currentSetting);
        this.updatePerson();
      });
  }

  updateNewsletterSubscriptions = (newsletterLists) => {
    this.person.peopleNewsletterLists = newsletterLists.peopleNewsletterLists;
    this.updatePerson(true);
  };
  updateParticipatingIn = (participantLists) => {
    this.person.participantLists = participantLists.participantLists;
    this.updatePerson(true);
  };
  updateReceiveCommunicationFromFilters = () => {
    this.person.receiveCommunicationFromFilters = true;
    this.updatePerson(true);
  };

  /**
   * Export data
   */
  exportData() {
    this.People.getExportToken().$promise.then(({ token }) => {
      const url = new Uri(this.cdApp.config.api.main);
      url.setPath(`${url.path()}/people/people/export/${this.person.id}`);
      url.addQueryParam('exportToken', token);
      url.addQueryParam('organizationId', this.cdApp.organization.id);

      window.location = url.toString();
    });
  }

  reSubscribeToList(list, jsEvent) {
    jsEvent.preventDefault();

    const { $filter, gettextCatalog, $uibModal } = this;

    const personName = $filter('getName')(this.person);
    $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title() {
            return gettextCatalog.getString('Resubscribe');
          },
          body() {
            return gettextCatalog.getString(
              '<strong>{{personName}}</strong> will receive messages from the list <strong>{{ listName }}</strong> again.',
              { listName: list.listName, personName }
            );
          },
          options: {
            confirmButtonText: gettextCatalog.getString('Resubscribe'),
            closeButtonText: gettextCatalog.getString('Cancel'),
            confirmButtonType: 'primary',
          },
        },
      })
      .result.then(
        () =>
          this.People.reSubscribeToList(
            { id: this.person.id },
            { segmentIds: [list.listId] }
          ).$promise
      )
      .then(() => this.$state.reload())
      .catch(ErrorHandlingService.handleError);
  }

  // AngularJS <-> Redux mapping functions
  mapStateToScope = (state) => ({
    canGDPRExport: getUserPermission(state, 'people.export gdpr'),
  });

  mapDispatchToScope = (dispatch) => ({
    FetchUserPermission: (permission) =>
      dispatch(FetchUserPermission(permission)),
  });
}
PeopleDetailController.$inject = [
  '_',
  '$filter',
  '$state',
  '$stateParams',
  '$uibModal',
  'toastr',
  'gettextCatalog',
  'Authorization',
  'People',
  'PeopleActivities',
  'PeopleTags',
  'Forms',
  'relationService',
  'stepsFactory',
  '$ngRedux',
  '$scope',
  'cdApp',
];

PeopleDetailController.$inject = [
  '_',
  '$filter',
  '$state',
  '$stateParams',
  '$uibModal',
  'toastr',
  'gettextCatalog',
  'Authorization',
  'People',
  'PeopleActivities',
  'PeopleTags',
  'Forms',
  'relationService',
  'stepsFactory',
  '$ngRedux',
  '$scope',
  'cdApp',
];

angular.module('cdApp.people').component('cdPeopleDetailState', {
  templateUrl: '@/app/people/people-detail/people-detail.component.html',
  controller: PeopleDetailController,
  bindings: {
    person: '<',
    tags: '<',
    customFields: '<',
    customFieldsData: '<',
    staticFields: '<',
    staticFieldsOptions: '<',
  },
});
