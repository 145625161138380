/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button, Card, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarMinus,
  faCalendarPlus,
} from '@fortawesome/free-solid-svg-icons';
import { PaginationProps } from 'antd/es/pagination';

import gettextCatalog from '../../../services/I18nService';
import { StyledTable } from '../../../shared/antd/StyledAntdComponents';
import {
  navigate,
  StateParamsServiceFactory,
} from '../../../services/StateServiceFactory';
import {
  clearEventIntentions,
  fetchEventIntentions,
  fetchIntentions,
  updateEventPriority,
  clearIntentions,
  updateIntention,
} from '../../redux/intentions/Actions';
import {
  getEventIntentions,
  getIntentions,
  getIntentionsCount,
} from '../../redux/intentions/Selectors';
import {
  Intention,
  IntentionFilters,
  IntentionStatusTypes,
  IntentionPriorityTypes,
} from '../../models/intention';
import { isLoading as isLoadingSelector } from '../../../shared/loading/redux/Selectors';
import { DetailedEvent } from '../../../calendar/models/calendar';
import IntentionService from '../../services/IntentionService';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import { handleSuccessMessage } from '../../../shared/utils';
import IntentionsOverviewFilters from '../../components/intentions/IntentionsOverviewFilters';
import { getEvent } from '../../../shared/store/Selectors';
import { clearEvent, fetchEvent } from '../../../shared/store/Actions';

import TableColumns from './ManageIntentionsTableColumns';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';

interface ManageIntentionsStateParam {
  id: string;
}

// Constants
const tableStyle = { padding: 8 };
// "true as true" because ant table scroll expects (string | number | true)
const tableScroll = { x: true as const };
const OFFSET_DEFAULT = 0;
const CURRENT_PAGE_DEFAULT = 1;
const PAGE_SIZE_DEFAULT = 10;

const maximumNumberOfIntentionsString = (max) => {
  if (max === 0) {
    return gettextCatalog.getString('No primary intentions allowed');
  } else if (max === 1) {
    return gettextCatalog.getString('Maximum 1 primary intention allowed');
  } else {
    return gettextCatalog.getString(
      'Maximum {{num}} primary intentions allowed',
      { num: max }
    );
  }
};

const ManageIntentions: FunctionComponent = () => {
  const dispatch = useDispatch();
  const eventIntentions = useSelector(getEventIntentions);
  const intentions = useSelector(getIntentions);
  const intentionsCount = useSelector(getIntentionsCount);
  const event = useSelector(getEvent, shallowEqual) as Partial<DetailedEvent>;
  const isLoading: boolean = useSelector(isLoadingSelector);
  const [tablePagination, setTablePagination] = useState<PaginationProps>();
  const [rowSelection, setRowSelection] = useState({});
  const [searchFilters, setSearchFilters] = useState<IntentionFilters>();
  const [maxNumberOfPrimaryIntentions, setMaxNumberOfPrimaryIntentions] =
    useState<number>(0);

  const stateParams = StateParamsServiceFactory<ManageIntentionsStateParam>();
  const calendarId = stateParams.id;

  const eventData: Partial<Event>[] = event ? [event] : null;
  const eventIntentionsData: any = eventIntentions ? eventIntentions : null;
  const intentionsData: Intention[] = intentions.asMutable({ deep: true });
  const intentionColumns = TableColumns.getIntentionColumns();
  const eventColumns = TableColumns.getEventColumns();

  const searchIntentionColumns = _.cloneDeep(intentionColumns);
  searchIntentionColumns.splice(searchIntentionColumns.length + 1, 0, {
    title: null,
    dataIndex: null,
    key: 'assign',
    fixed: 'right',
    render: function columnRender(intention: Intention) {
      return (
        <Space style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            type="primary"
            size="small"
            style={{ marginRight: 8 }}
            onClick={() => assignIntentionToEvent(intention)}
          >
            <FontAwesomeIcon icon={faCalendarPlus} style={{ marginRight: 8 }} />
            {gettextCatalog.getString('Assign')}
          </Button>
        </Space>
      );
    },
  });

  const eventIntentionColumns = _.cloneDeep(intentionColumns);
  eventIntentionColumns.splice(eventIntentionColumns.length + 1, 0, {
    title: null,
    dataIndex: null,
    key: 'unassign',
    fixed: 'right',
    render: function columnRender(intention: Intention) {
      return (
        <Space style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            danger
            size="small"
            style={{ marginRight: 8 }}
            onClick={() => unassignIntentionFromEvent(intention)}
          >
            <FontAwesomeIcon
              icon={faCalendarMinus}
              style={{ marginRight: 8 }}
            />
            {gettextCatalog.getString('Unassign')}
          </Button>
        </Space>
      );
    },
  });

  const goToEvent = () => {
    navigate('app.private.calendar.event', { id: calendarId });
  };

  const getPageHeaderExtra = () => (
    <Button onClick={goToEvent}>
      {gettextCatalog.getString('Back to event')}
    </Button>
  );

  const updateSearches = () => {
    // Update current intentions list
    dispatch(fetchEventIntentions(parseInt(calendarId)));
    // Update intention search
    if (!_.isEmpty(intentions)) {
      searchIntentions(searchFilters, tablePagination, false);
    }
    // Update event info to reflect number of intentions change
    dispatch(fetchEvent(parseInt(calendarId)));
  };

  const unassignIntentionFromEvent = (intention) => {
    IntentionService.unassignIntention(intention.id)
      .then(() => {
        handleSuccessMessage(
          gettextCatalog.getString('Successfully unassigned intention.')
        );
      })
      .then(() => updateSearches())
      .catch(ErrorHandlingService.handleError);
  };

  const assignIntentionToEvent = (intention) => {
    IntentionService.assignIntention({ id: intention.id, calendarId })
      .then(() => {
        handleSuccessMessage(
          gettextCatalog.getString('Successfully assigned intention.')
        );
      })
      .then(() => updateSearches())
      .catch(ErrorHandlingService.handleError);
  };

  const searchIntentions = (
    filters: IntentionFilters = searchFilters,
    pagination: PaginationProps = tablePagination,
    resetPagination?: boolean
  ) => {
    // Calculate pagination
    let limit, offset;
    if (resetPagination) {
      // Reset limit, offset and pagination if there is a filter change
      setTablePagination({
        current: CURRENT_PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT,
      });
      offset = OFFSET_DEFAULT;
      limit = PAGE_SIZE_DEFAULT;
    } else {
      const currentPage = _.get(pagination, 'current') || CURRENT_PAGE_DEFAULT;
      limit = _.get(pagination, 'pageSize') || PAGE_SIZE_DEFAULT;
      offset = (currentPage - 1) * limit;
    }

    const params = _.extend({
      ...filters,
      limit,
      offset,
      status: IntentionStatusTypes.UNASSIGNED,
    });

    dispatch(fetchIntentions(params));
  };

  const updateFilters = (filters: IntentionFilters) => {
    setSearchFilters(filters);
    searchIntentions(filters, tablePagination, true);
  };

  const handleTableChange = (newPagination) => {
    // Setting pagination if it has changed
    if (!_.isEqual(newPagination, tablePagination)) {
      setTablePagination(newPagination);
    }

    searchIntentions(searchFilters, newPagination);
  };

  const calculateNumberOfPrimaryIntentionsAllowed = (calendarId: number) => {
    IntentionService.getMaxNumberOfPrimaryIntentions(calendarId)
      .then((res) => {
        setMaxNumberOfPrimaryIntentions(res);
      })
      .catch(ErrorHandlingService.handleError);
  };

  // There can only be one primary intention at once so deactivate the active one if there is one and activate the new
  const intentionPriorityChange = (intention, selected) => {
    // If there are checkboxes
    if (maxNumberOfPrimaryIntentions > 1) {
      const updatePayload = {
        priority: selected
          ? IntentionPriorityTypes.PERSOLVIERT
          : IntentionPriorityTypes.WEITERLEITUNG,
      };
      dispatch(
        updateIntention({
          id: intention.id,
          updatePayload,
          calendarId: parseInt(calendarId),
          shouldNavigate: false,
        })
      );
    } else {
      // Change the priorities for the view with a radio button
      const currentPrimaryIntention = _.find(eventIntentions, [
        'priority',
        IntentionPriorityTypes.PERSOLVIERT,
      ]);

      dispatch(
        updateEventPriority({
          newPrimaryIntentionId: intention.id,
          oldPrimaryIntentionId:
            currentPrimaryIntention && currentPrimaryIntention.id,
          calendarId: parseInt(calendarId),
        })
      );
    }
  };

  // Retrieve required entities
  useEffect(() => {
    // Clear intentions on startup
    dispatch(clearIntentions());
    // Fetch event you are managing
    dispatch(fetchEvent(parseInt(calendarId)));
    // Initialize intention data in case of creation or update/view
    dispatch(fetchEventIntentions(parseInt(calendarId)));

    // Initialize pagination for table
    setTablePagination({
      current: CURRENT_PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT,
    });

    // Cleanup function to be executed on component un-mounting
    return () => {
      dispatch(clearEventIntentions());
      dispatch(clearEvent());
    };
  }, []);

  // Retrieve required entities
  useEffect(() => {
    if (event) {
      // Calculate number of primary intentions allowed
      calculateNumberOfPrimaryIntentionsAllowed(event.id);
    }
  }, [event ? event.id : null]);

  useEffect(() => {
    if (!_.isEmpty(eventIntentions) || maxNumberOfPrimaryIntentions) {
      // Primary intention row selection default
      const primaryIntentionIds = _.map(
        _.filter(eventIntentions, [
          'priority',
          IntentionPriorityTypes.PERSOLVIERT,
        ]),
        'id'
      );
      if (primaryIntentionIds) {
        setRowSelection({
          type: maxNumberOfPrimaryIntentions <= 1 ? 'radio' : 'checkbox',
          selectedRowKeys: primaryIntentionIds,
          columnTitle: gettextCatalog.getString('Primary Intention'),
          onSelect: intentionPriorityChange,
        });
      }
    }
  }, [_.map(eventIntentions, 'id').toString(), maxNumberOfPrimaryIntentions]);

  // Update pagination on search
  useEffect(() => {
    if (intentionsCount) {
      // Updating total intentions
      setTablePagination({
        current: _.get(tablePagination, 'current') || CURRENT_PAGE_DEFAULT,
        pageSize: _.get(tablePagination, 'pageSize') || PAGE_SIZE_DEFAULT,
        total: intentionsCount,
      });
    }
  }, [intentionsCount]);

  return (
    <CdPage
      pageHeaderProps={{
        title: gettextCatalog.getString('Manage intentions for event'),
        extra: getPageHeaderExtra(),
      }}
    >
      {/* Event info */}
      <Card>
        <StyledTable
          id="currentEvent"
          style={tableStyle}
          dataSource={eventData}
          columns={eventColumns}
          pagination={false}
          scroll={tableScroll}
          bordered={true}
          rowKey="id"
        />
      </Card>
      <Card title={gettextCatalog.getString('Current intentions')}>
        {maximumNumberOfIntentionsString(maxNumberOfPrimaryIntentions)}
        {/* Current intentions info */}
        <StyledTable
          id="currentIntentions"
          style={tableStyle}
          dataSource={eventIntentionsData}
          columns={eventIntentionColumns}
          loading={isLoading}
          pagination={false}
          scroll={tableScroll}
          bordered={true}
          rowKey="id"
          rowSelection={
            maxNumberOfPrimaryIntentions === 0 ? null : rowSelection
          }
        />
      </Card>
      {/* Add intentions to event */}
      <Card title={gettextCatalog.getString('Add intentions to event')}>
        {/* Intention filters */}
        {event && (
          <IntentionsOverviewFilters onSearch={updateFilters} event={event} />
        )}
        {/* Intention results */}
        <StyledTable
          id="intentionTable"
          style={tableStyle}
          dataSource={intentionsData}
          columns={searchIntentionColumns}
          loading={isLoading}
          pagination={tablePagination}
          scroll={tableScroll}
          bordered={true}
          rowKey="id"
          onChange={handleTableChange}
        />
      </Card>
    </CdPage>
  );
};

export default ManageIntentions;
