'use strict';

class AddGroupMemberModalComponent {
  constructor(_, $http, $uibModal, Users, toastr, gettextCatalog) {
    'ngInject';

    this._ = _;
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.Users = Users;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
  }

  $onInit() {
    this.loading = false;

    this.groupId = this._.parseInt(this.resolve.groupId);
    this.groupName = this.resolve.groupName;
    this.existingMembers = this.resolve.existingMembers || [];

    this.Users.query().$promise.then((users) => {
      this.users = this._.filter(
        users,
        (user) =>
          user.status === 1 && !this._.includes(this.existingMembers, user.id)
      );
    });
  }

  addMember() {
    this.loading = true;

    this.$http
      .put(
        `${cdApp.config.api.main}/groups/${this.groupId}/users/${this.selectedUser.id}/membership`,
        {
          status: 'active',
          isAdmin: false,
        }
      )
      .then(() => {
        this.loading = false;
        this.close({ $value: this.selectedUser });
      })
      .catch(() => {
        this.loading = false;
        this.toastr.warning(
          this.gettextCatalog.getString(
            'The user could not be added to the group.'
          )
        );
      });
  }

  createUser() {
    this.$uibModal
      .open({
        component: 'cdCreateUserModal',
        size: 'lg',
        windowClass: 'modal-ui-select',
        resolve: {
          groupIds: () => [this.groupId],
        },
      })
      .result.then((newUser) => {
        const _ = this._;

        const outputUser = _.extend(_.pick(newUser, ['id', 'email']), {
          status: 'active',
          isAdmin: false,
          name: _.trim(_.join([newUser.firstName, newUser.lastName], ' ')),
        });

        this.close({ $value: outputUser });
      });
  }
}
AddGroupMemberModalComponent.$inject = [
  '_',
  '$http',
  '$uibModal',
  'Users',
  'toastr',
  'gettextCatalog',
];

angular.module('cdApp.shared').component('cdAddGroupMemberModal', {
  templateUrl:
    '@/app/shared/components/add-group-member/add-group-member.component.html',
  controller: AddGroupMemberModalComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
