import React, { useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Button, Form, Row, Typography } from 'antd';
import { isEmpty } from 'lodash';

import { createCdDrawer } from '../../shared/components/cd-drawer/CdDrawer';
import getTextCatalog from '../../services/I18nService';

import { TimeRegistrationCalendar } from './TimeRegistrationCalendar';
import { useTimeRegistrationDrawer } from './hooks/useTimeRegistrationDrawer';
import { WorkingHours } from './WorkingHours';
import { StandardWorkingHours } from './StandardWorkingHours';

export const showTimeRegistrationDrawer = () =>
  NiceModal.show('TimeRegistrationDrawer');
export const TimeRegistrationDrawer = createCdDrawer({
  modalName: 'TimeRegistrationDrawer',
  InnerDrawer: ({ setDrawerProps }) => {
    const {
      selectedDate,
      setSelectedDate,
      timeRegistrationCalendarData,
      timeRegistrationForm,
      onHoursSaveHandler,
      initialValues,
      selectedDateInsight,
      standardWorkingHoursData,
      hasChanges,
      setHasChanges,
    } = useTimeRegistrationDrawer();

    useEffect(() => {
      setDrawerProps({
        title: getTextCatalog.getString('Time registration'),
        width: 500,
        footerStyle: { textAlign: 'right' },
        bodyStyle: { paddingTop: '0' },
        footer: (
          <Button
            type="primary"
            onClick={() => onHoursSaveHandler(selectedDate)}
          >
            {getTextCatalog.getString('Save')}
          </Button>
        ),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDrawerProps, selectedDate, hasChanges, standardWorkingHoursData]);

    const onValuesChange = React.useCallback(
      (value) => {
        if (value.comment) {
          setHasChanges(true);
        }
      },
      [setHasChanges]
    );

    return (
      <Form
        colon={false}
        form={timeRegistrationForm}
        initialValues={initialValues}
        style={{ width: '100%' }}
        onValuesChange={onValuesChange}
      >
        {isEmpty(standardWorkingHoursData) ? (
          <>
            <Typography.Title level={2}>
              {getTextCatalog.getString('Add your standard working hours')}
            </Typography.Title>
            <StandardWorkingHours />
          </>
        ) : (
          <Row
            gutter={[12, 12]}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <TimeRegistrationCalendar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              userTimeRegistrationCalendar={timeRegistrationCalendarData}
            />

            <WorkingHours
              selectedDateInsight={selectedDateInsight}
              timeRegistrationForm={timeRegistrationForm}
              standardWorkingHoursData={standardWorkingHoursData}
            />
          </Row>
        )}
      </Form>
    );
  },
});

NiceModal.register('TimeRegistrationDrawer', TimeRegistrationDrawer);
