import React from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { compose } from 'redux';
import { Modal, Form } from 'antd';
import { useRecoilValueLoadable } from 'recoil';

import useCdForm from '../hooks/useCdForm';
import { FormLabels } from '../store/form';

import getTextCatalog from '@/react/services/I18nService';
import CdSelect from '@/react/shared/components/cd-select/CdSelect';

export default NiceModal.create(({ cdForm }: { cdForm: any }) => {
  const { update } = useCdForm();
  const modal = useModal('AssignLabel');
  const labelsLodable = useRecoilValueLoadable(FormLabels);
  const [form] = Form.useForm();
  const onSubmit = compose(modal.hide, form.submit);
  const labels =
    labelsLodable.state === 'hasValue' && labelsLodable.contents.items;

  return (
    <Modal
      {...antdModalV5(modal)}
      title={
        cdForm.labelId
          ? getTextCatalog.getString('Edit label')
          : getTextCatalog.getString('Add label')
      }
      onOk={onSubmit}
    >
      <Form
        form={form}
        initialValues={cdForm}
        onFinish={({ labelId }) =>
          update({
            ...cdForm,
            churches: cdForm.churches.map(({ id }) => id),
            labelId: labelId || null,
          })
        }
      >
        <Form.Item name="labelId" label={getTextCatalog.getString('Label')}>
          <CdSelect
            placeholder={getTextCatalog.getString('Select label')}
            allowClear
            loading={labelsLodable.state === 'loading'}
            options={labels?.map(({ id, label }) => ({
              value: id,
              label,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});
