import React from 'react';
import { Select, Space } from 'antd';

import gettextCatalog from '../../../services/I18nService';
import { AnniversaryInterface } from '../../../calendar/types/anniversaryTypes';
import useAnniversary from '../hooks/useAnniversary';
import CdSimpleTable from '../../../shared/components/cd-simple-table/CdSimpleTable';

import { anniversaryColumns } from './AnniversaryTableColumn';

export const Anniversary = () => {
  const { setAnniversaryType, anniversaryTypes, anniversaryListQuery } =
    useAnniversary();

  return (
    <>
      <CdSimpleTable<AnniversaryInterface>
        columnSelectorKey="Anniversary"
        columns={anniversaryColumns}
        recoilSource={anniversaryListQuery}
        emptyStateProps={{
          title: gettextCatalog.getString('No upcoming anniversary'),
        }}
        filterForm={
          <Space>
            <Select
              defaultValue={gettextCatalog.getString('Birthday')}
              style={{ width: 200 }}
              onChange={setAnniversaryType}
              options={anniversaryTypes}
              fieldNames={{ label: 'label', value: 'property' }}
            />
          </Space>
        }
      />
    </>
  );
};
