'use strict';

const checkAccessHomePage = ($state, Authorization) => {
  'ngInject';
  if (!Authorization.hasPermission('canAccessHomepage')) {
    return $state.target('app.private.website.default', {
      errorCode: 'HOMEPAGE_ACCESS',
    });
  }
};
checkAccessHomePage.$inject = ['$state', 'Authorization'];

angular.module('cdApp').config([
  '$stateProvider',
  ($stateProvider) => {
    'ngInject';

    // Website proxy state
    $stateProvider
      .state('app.private.website', {
        abstract: true,
        url: '/website',
        template: `
            <cd-app-nav class="app-nav"></cd-app-nav>
            <div class="app-view" ui-view></div>
        `,
      })
      .state('app.private.website.default', {
        url: '?target',
        params: {
          target: {
            value: 'homepage',
            squash: true,
          },
        },
        component: 'cdHomepage',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Website');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base),
                  false
                ),
              };
            },
          ],
        },
        onEnter: [
          '$stateParams',
          'AuthenticationService',
          'cdApp',
          function ($stateParams, AuthenticationService, cdApp) {
            AuthenticationService.redirectExternal(
              cdApp.organization.id,
              $stateParams.target
            );

            return false;
          },
        ],
      })
      .state('app.private.website.list', {
        url: '/list',
        component: 'websiteLandingPage',
        params: {
          errorCode: null,
        },
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Website');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
          errorCode: ['$stateParams', ($stateParams) => $stateParams.errorCode],
        },
      })
      .state('app.private.website.widgets', {
        url: '/widgets',
        component: 'homepageWidgetsPage',
        params: {
          errorCode: null,
        },
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Widgets');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
          errorCode: ['$stateParams', ($stateParams) => $stateParams.errorCode],
        },
      })
      .state('app.private.website.blogs', {
        url: '/blogs',
        templateUrl: '@/app/intranet/blog/templates/List.html',
        controller: 'BlogListController',
        params: {
          errorCode: null,
        },
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Blogs');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
          errorCode: ['$stateParams', ($stateParams) => $stateParams.errorCode],
        },
      })
      .state('app.private.website.analytics', {
        url: '/analytics',
        component: 'analyticsPage',
        params: {
          errorCode: null,
        },
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Analytics');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
          errorCode: ['$stateParams', ($stateParams) => $stateParams.errorCode],
        },
      });
  },
]);
