import { find, isEqual, isUndefined, omitBy, isEmpty } from 'lodash';

/**
 * Returns an object where only changes to the shifts and intentions are included.
 * If there are no changes to the shifts or intentions, then the shiftsAndIntentions property is undefined.
 *
 * @param previousRotasAndIntentions
 * @param currentRotaAndIntentions
 * @returns
 */
export const getRotaAndIntentionsChanges = (
  previousRotasAndIntentions: { shiftsAndIntentions: any[]; rotas: any[] },
  currentRotaAndIntentions: { shiftsAndIntentions: any[]; rotas: any[] }
) => {
  // remove the already assigned intentions that user decide to remove them now
  const updatedCurrentRotaAndIntentions = {
    rotas: currentRotaAndIntentions.rotas,
    shiftsAndIntentions: currentRotaAndIntentions.shiftsAndIntentions.map(
      (item) => {
        const newIntentions = item?.intentions?.filter(
          (intention) => !intention?.isRemoved
        );
        return { ...item, intentions: newIntentions };
      }
    ),
  };
  // Makes the structure compatible with backend
  const previousShiftsAndIntentions = normalizeShiftsAndIntentions(
    previousRotasAndIntentions.shiftsAndIntentions
  );
  const currentShiftsAndIntentions = normalizeShiftsAndIntentions(
    updatedCurrentRotaAndIntentions.shiftsAndIntentions
  );

  if (isEqual(currentShiftsAndIntentions, previousShiftsAndIntentions)) {
    // Early break out. No changes to Shifts or Intentions.
    return {
      rotas: updatedCurrentRotaAndIntentions.rotas,
      shiftsAndIntentions: undefined,
    };
  }

  const newShiftsAndIntentions = currentShiftsAndIntentions.reduce(
    (accumulator, event) => {
      const previousEvent = find(previousShiftsAndIntentions, {
        calendarId: event.calendarId,
      });
      // Early break out if the previous event is identical to the current event.
      if (isEqual(event, previousEvent)) {
        return accumulator;
      }

      let shifts;
      if (
        (isEmpty(event?.shifts) && isEmpty(previousEvent?.shifts)) ||
        (previousEvent && isEqual(event.shifts, previousEvent.shifts))
      ) {
        // Early break out if the previous shifts is identical to the current shifts.
        shifts = undefined;
      } else {
        shifts = event.shifts.reduce((shiftAccumulator, shift) => {
          const previousShift = find(previousEvent?.shifts, {
            taskId: shift.taskId,
          });

          const newNow =
            shift.note === previousShift?.note ? undefined : shift.note;
          const newUsers = isEqual(shift.users, previousShift?.users)
            ? undefined
            : shift.users;
          // Check for differences in the shift and push the differences.
          if (newNow || newUsers) {
            shiftAccumulator.push(
              omitBy(
                {
                  taskId: shift.taskId,
                  note: newNow,
                  users: newUsers,
                },
                isUndefined
              )
            );
          }

          return shiftAccumulator;
        }, []);
      }

      let intentions;
      if (
        (isEmpty(event?.intentions) && isEmpty(previousEvent?.intentions)) ||
        (previousEvent && isEqual(event.intentions, previousEvent.intentions))
      ) {
        // Early break out if the previous intention is identical to the current intention.
        intentions = undefined;
      } else {
        intentions = event.intentions.reduce(
          (intentionAccumulator, intention) => {
            const previousIntention = find(previousEvent?.intentions, {
              id: intention.id,
            });

            // Check for differences in the intention and push the differences.
            intentionAccumulator.push(
              omitBy(
                {
                  id: intention.id,
                  priority:
                    intention.priority === previousIntention?.priority
                      ? undefined
                      : intention.priority,
                },
                isUndefined
              )
            );

            return intentionAccumulator;
          },
          []
        );
      }

      const newShifts =
        (!previousEvent || previousEvent?.shifts?.length === 0) &&
        (!shifts || shifts?.length === 0)
          ? undefined
          : shifts;
      const newIntentions =
        (!previousEvent || previousEvent?.intentions?.length === 0) &&
        (!intentions || intentions?.length === 0)
          ? undefined
          : intentions;
      if (newShifts || newIntentions) {
        accumulator.push(
          omitBy(
            {
              calendarId: event.calendarId,
              shifts: newShifts,
              intentions: newIntentions,
            },
            isUndefined
          )
        );
      }

      return accumulator;
    },
    []
  );

  return {
    rotas: updatedCurrentRotaAndIntentions.rotas,
    shiftsAndIntentions: newShiftsAndIntentions,
  };
};

const normalizeShiftsAndIntentions = (shiftsAndIntentions) =>
  shiftsAndIntentions?.map((event) => {
    const { calendarId, shifts, intentions } = event;
    const newEvent = {
      calendarId,
      shifts: (shifts || []).map((shift) => ({
        taskId: shift.taskId,
        note: shift.note,
        users: shift.users?.map((user) => ({ id: user.id })),
      })),
      intentions: (intentions || []).map((intention) => ({
        id: intention.id,
        priority: intention.priority,
      })),
    };
    return newEvent;
  });
