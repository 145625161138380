import { useRecoilCallback } from 'recoil';

import { peopleSegmentsState } from '../store/peopleSegment';
import { PeopleListByTypeAndSearchQuery } from '../store/newsletterListState';
import later from '../services/promiseTimeout';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import gettextCatalog from '@/react/services/I18nService';
import ErrorHandlingService from '@/react/services/ErrorHandlingService';

const useManageContacts = () => {
  const manageTags = useRecoilCallback(
    () =>
      async (body: {
        filter;
        tags: {
          add: number[];
          remove: number[];
        };
        people: number[];
      }) => {
        const response = await mainApi.put(`/people/people/tags`, body);
        if (!response.ok) {
          ErrorHandlingService.handleError(response.originalError);
        } else {
          NotificationService.notifySuccess(
            gettextCatalog.getString('Tags updated successfully')
          );
        }
      },
    []
  );
  const manageParishes = useRecoilCallback(
    () =>
      async (body: {
        filter;
        churches: {
          add: number[];
          remove: number[];
        };
        people: number[];
      }) => {
        const response = await mainApi.put(`/people/people/churches`, body);
        if (!response.ok) {
          ErrorHandlingService.handleError(response.originalError);
        } else {
          NotificationService.notifySuccess(
            gettextCatalog.getString('Parishes updated successfully')
          );
        }
      },
    []
  );
  const manageNewsletterAndParticipantLists = useRecoilCallback(
    ({ refresh }) =>
      async (body: {
        filter;
        peopleLists: {
          add: number[];
          remove: number[];
        };
        personIds: number[];
      }) => {
        // If personIds are defined, we ignore the incoming filter.
        const updatedBody = {
          ...body,
          filter: body.personIds ? undefined : body.filter,
        };
        const response = await mainApi.put(
          `/people/people/people-lists`,
          updatedBody
        );
        if (!response.ok) {
          ErrorHandlingService.handleError(response.originalError);
        } else {
          await later(5000);
          NotificationService.notifySuccess(
            gettextCatalog.getString('Lists updated successfully')
          );
          refresh(peopleSegmentsState);
          refresh(
            PeopleListByTypeAndSearchQuery({
              type: 'participant',
              searchParameter: '',
            })
          );
          refresh(
            PeopleListByTypeAndSearchQuery({
              type: 'newsletter',
              searchParameter: '',
            })
          );
        }
      },
    []
  );

  return {
    manageTags,
    manageParishes,
    manageNewsletterAndParticipantLists,
  };
};

export default useManageContacts;
