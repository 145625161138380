import { Avatar, Popover, Space } from 'antd';
import React from 'react';

import { NotificationPopoverContent } from './NotificationPopoverContent';

import getTextCatalog from '@/react/services/I18nService';
import { EventIcons } from '@/react/shared/components/Icons';

export const NotificationPopover = ({
  user,
  eventId,
  sendNotification,
  disabled,
}) => {
  const { name, notifications, picture, status } = user;
  const lastNotifIndex = notifications.length - 1;
  const numberOfNotifications = notifications.length;
  const numberOfTimesNotifiedByEmailInLastTime =
    notifications[lastNotifIndex]?.emailLogs?.length;
  const numberOfTimesNotifiedBySMSInLastTime =
    notifications[lastNotifIndex]?.smsLogs?.length;
  return (
    <Popover
      content={NotificationPopoverContent(
        user,
        eventId,
        sendNotification,
        disabled
      )}
      id={`UserNotificationsPopOver-${user.id}`}
    >
      <Space data-testid="user-details">
        <Avatar
          icon={
            <EventIcons.UserBooking
              style={{
                color: 'lightgray',
                width: '30px',
                height: '30px',
              }}
            />
          }
          src={picture || undefined}
          style={{ backgroundColor: 'white', marginLeft: '4px' }}
        />
        <Space direction="vertical" size={1}>
          <Space>
            <span
              className={(status === 'blocked' && 'text-danger') || undefined}
            >
              {name}
            </span>
            {status === 'blocked' && (
              <small className="text-danger">
                ({getTextCatalog.getString('Blocked')})
              </small>
            )}
          </Space>
          {numberOfNotifications > 0 && (
            <div>
              {numberOfTimesNotifiedByEmailInLastTime > 0 &&
                numberOfTimesNotifiedBySMSInLastTime === 0 && (
                  <span style={{ color: 'gray' }}>
                    {getTextCatalog.getString('Notified via Email')}
                  </span>
                )}
              {numberOfTimesNotifiedByEmailInLastTime === 0 &&
                numberOfTimesNotifiedBySMSInLastTime > 0 && (
                  <span style={{ color: 'gray' }}>
                    {getTextCatalog.getString('Notified via SMS')}
                  </span>
                )}
              {numberOfTimesNotifiedByEmailInLastTime > 0 &&
                numberOfTimesNotifiedBySMSInLastTime > 0 && (
                  <span style={{ color: 'gray' }}>
                    {getTextCatalog.getString('Notified via Email & SMS')}
                  </span>
                )}
              {numberOfTimesNotifiedByEmailInLastTime === 0 &&
                numberOfTimesNotifiedBySMSInLastTime === 0 && (
                  <Space>
                    <span style={{ color: 'gray' }}>
                      {getTextCatalog.getString('Disabled notifications')}
                    </span>
                  </Space>
                )}
            </div>
          )}
        </Space>
      </Space>
    </Popover>
  );
};
