import React from 'react';
import styled from 'styled-components';

import gettextCatalog from '../../services/I18nService';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const FilterSectionHeaaderCheckbox = styled.div<{
  $addonAfterPressent: boolean;
  $addonAfterShowOnHover: boolean;
}>`

  .filter-section-header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 45px;
    &:hover,
    &:focus {
      border-radius: 0;

      .filter-section-header__addonAfter {
        opacity: 100;
      }
    }

    .filter-section-header__label {
      max-width: ${({ $addonAfterPressent }) =>
        $addonAfterPressent ? '110px' : '145px'};
      padding: 0.7em 0;
      color: #333;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.6;
      font-size: 14px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .filter-section-header__addonAfter {
      opacity: ${({ $addonAfterShowOnHover }) =>
        $addonAfterShowOnHover ? 0 : 100};
    }
  }
`;

const FilterSectionHeaderButtonWrapper = styled.div<{
  $isDisabled: boolean;
  $isAbsence?: boolean;
}>`
  max-height: 100px;
  width: 100%;
  padding: 0 !important;
  border: none;

  .filter-checkbox {
    padding: 0.9em 1em;
    position: absolute;

    &:hover,
    &:focus {
      background-color: rgb(220, 220, 220);
      border-radius: 0;
    }

    .filter-checkbox__button {
      border-radius: 4px;
      width: 16px;
      height: 16px;
      display: block;
      cursor: pointer;
      background-color: transparent;
      border: 2px solid;
      border-color: ${({ $isAbsence }) => ($isAbsence ? '#d50000' : '#333')};
      color: white;

      &::after {
        font: normal normal normal 10px/1 'Font Awesome 5 Pro';
        content: none;
        color: #fff;
        position: relative;
      }
      &.some-selected,
      &.all-selected {
        border: none;
        background-color: ${({ $isAbsence }) =>
          $isAbsence ? '#d50000' : '#333'};
      }
      &.some-selected::after {
        left: 4px;
        top: -4px;
        content: '\f068';
      }
      &.all-selected::after {
        left: 3px;
        top: -4px;
        content: '\f00c';
      }
    }
  }


  ${({ $isDisabled }) =>
    $isDisabled
      ? `
    .filter-checkbox, filter-section-header__wrapper {
      opacity: 0.4;
    }
  `
      : ''}
`;

export const FilterSectionHeader = ({
  name,
  isAllSelected,
  onClick,
  addonAfter,
  addonAfterShowOnHover,
  isDisabled,
  isAbsence,
}: {
  name: string | React.ReactNode;
  isAllSelected: boolean | null;
  onClick: () => void;
  addonAfter?: React.ReactNode;
  addonAfterShowOnHover?: boolean;
  isDisabled: boolean;
  isAbsence?: boolean;
}) => (
  <CdTooltip
    title={
      isDisabled &&
      gettextCatalog.getString('This calendar cannot be enabled for this view')
    }
  >
    <FilterSectionHeaderButtonWrapper
      $isDisabled={isDisabled}
      $isAbsence={isAbsence}
    >
      <FilterSectionHeaaderCheckbox
        $addonAfterPressent={!!addonAfter}
        $addonAfterShowOnHover={addonAfterShowOnHover}
      >
        <div
          className="filter-checkbox"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onClick();
          }}
        >
          {isAllSelected === null ? (
            <span className="filter-checkbox__button some-selected" />
          ) : isAllSelected ? (
            <span className="filter-checkbox__button all-selected" />
          ) : (
            <span className="filter-checkbox__button" />
          )}
        </div>

        <div className="filter-section-header__wrapper">
          <div className="filter-section-header__label">{name}</div>
          <div className="filter-section-header__addonAfter">{addonAfter}</div>
        </div>
      </FilterSectionHeaaderCheckbox>
    </FilterSectionHeaderButtonWrapper>
  </CdTooltip>
);
