import { Button, Drawer, Layout, Radio, Space, Spin } from 'antd';
import React, { Suspense, useState } from 'react';
import NiceModal, { antdDrawerV5, useModal } from '@ebay/nice-modal-react';
import styled from 'styled-components';

import gettextCatalog from '@/react/services/I18nService';
import {
  TemplateAuthor,
  TemplateType,
} from '@/react/people/types/message-template.type';
import {
  useTemplateslist,
  useTemplatesSelectUnlayer,
} from '@/react/people/hooks/useTemplates';
import AuthorizationService from '@/react/services/AuthorizationService';
import { StudioLandingPage } from '@/react/people/message-editor/shared/containers/StudioLandingPage';
import { PeopleMessageRecipient } from '@/react/people/types/message';
import {
  ISelectableCardItem,
  SelectableCards,
} from '@/react/shared/components/SelectableCards';
import { CdDeleteIcon, CdStudio } from '@/react/shared/components/Icons';
import CdrEmptyState from '@/react/shared/components/CdrEmptyState';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
const { Sider, Content } = Layout;

const StyledDrawer = styled(Drawer)`
  &&&& {
    .ant-drawer-body {
      padding: 0px;
      display: flex;
    }

    .ant-layout {
      .ant-layout-sider {
        padding: 8px 24px;
      }
      .ant-layout-content {
        padding: 8px;
        overflow: auto;
      }
    }
  }
`;

export const TemplateSelector = NiceModal.create(
  ({ recipients }: { recipients?: PeopleMessageRecipient[] }) => {
    const [authoredBy, setAuthoredBy] = useState<TemplateAuthor | 'all'>('all');
    const { templates, templatesLoading, deleteTemplate } = useTemplateslist({
      type: TemplateType.PEOPLE_MESSAGE_ADVANCED,
      authoredBy: authoredBy === 'all' ? null : authoredBy,
    });
    const modal = useModal('TemplateSelectorModal');
    const { selectTemplate } = useTemplatesSelectUnlayer();
    const [selectedTemplate, setSelectedTemplate] = useState<string>(null);
    const [processingSelection, setProcessingSelection] =
      useState<boolean>(false);
    const hasStudio = AuthorizationService.hasPackage('studio');

    const confirmDeleteModal = (templateId: string, templateName: string) =>
      showConfirmModalDeprecated({
        title: gettextCatalog.getString('Delete template'),
        message: gettextCatalog.getString(
          'Confirm that you want to delete this template {{ templateName }} permanently for all users in this organisation. The template can not be restored.',
          {
            templateName,
          }
        ),
        icon: <CdDeleteIcon />,
        okType: 'danger',
        okText: gettextCatalog.getString('Delete'),
        onOk: () => deleteTemplate(templateId),
      });

    const confirmTemplateSelection = async () => {
      setProcessingSelection(true);
      if (selectedTemplate) {
        await selectTemplate({ id: selectedTemplate, recipients }).then(
          modal.hide
        );
      }
      setProcessingSelection(false);
    };
    return (
      <StyledDrawer
        {...antdDrawerV5(modal)}
        title={
          hasStudio
            ? gettextCatalog.getString('Select template')
            : gettextCatalog.getString(
                'ChurchDesk Studio - An add-on for designing your message'
              )
        }
        width="75vw"
        footerStyle={{ textAlign: 'right' }}
        footer={
          <Space>
            {hasStudio && (
              <Button
                disabled={!selectedTemplate || processingSelection}
                onClick={confirmTemplateSelection}
                loading={processingSelection}
                type="primary"
              >
                {gettextCatalog.getString('Confirm')}
              </Button>
            )}
            <Button onClick={() => modal.hide()}>
              {gettextCatalog.getString('Cancel')}
            </Button>
          </Space>
        }
      >
        {(!hasStudio && (
          <Suspense fallback={<Spin />}>
            <Layout>
              <Content style={{ height: '100%', padding: '30px' }}>
                <StudioLandingPage />
              </Content>
            </Layout>
          </Suspense>
        )) || (
          <Layout>
            <Sider theme="light">
              <Radio.Group
                value={authoredBy}
                onChange={(e) => setAuthoredBy(e.target.value)}
              >
                <Space direction="vertical">
                  <Radio value="all">{gettextCatalog.getString('All')}</Radio>
                  <Radio value={TemplateAuthor.CHURCHDESK}>
                    {gettextCatalog.getString('ChurchDesk Templates')}
                  </Radio>
                  <Radio value={TemplateAuthor.USER}>
                    {gettextCatalog.getString('Your templates')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Sider>
            <Content style={{ height: '100%' }}>
              <SelectableCards
                loading={templatesLoading}
                emptyText={
                  <CdrEmptyState
                    title={gettextCatalog.getString('No templates found')}
                    EmptyStateIcon={<CdStudio />}
                  />
                }
                dataSource={templates.map(
                  (template) =>
                    ({
                      ...template,
                      isSelectable: true,
                      isDeletable:
                        template.templateSource !== TemplateAuthor.CHURCHDESK,
                      subtitle:
                        template.templateSource === TemplateAuthor.CHURCHDESK
                          ? gettextCatalog.getString('By ChurchDesk')
                          : gettextCatalog.getString('By {{ authorName }}', {
                              authorName: template.author?.contact?.fullName,
                            }),
                    }) as ISelectableCardItem
                )}
                onSelect={setSelectedTemplate}
                onDelete={confirmDeleteModal}
                value={selectedTemplate}
              />
            </Content>
          </Layout>
        )}
      </StyledDrawer>
    );
  }
);
