import React from 'react';
import { Space, Typography } from 'antd';
import { isEmpty } from 'lodash';

import { useOrganization } from '@/react/organization/hooks/useOrganization';
import gettextCatalog from '@/react/services/I18nService';
import { navigate } from '@/react/services/StateServiceFactory';
import { CdrColorPicker } from '@/react/shared/components/cd-color-picker/CdrColorPicker';

const { Text, Link } = Typography;

const ColorSelectorWithReset = ({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (value: string) => void;
}) => {
  const {
    organizationSettings: { newsletterColor },
  } = useOrganization();

  return (
    <>
      <Space align="center">
        <CdrColorPicker value={value || newsletterColor} onChange={onChange} />
        <Text type="secondary">
          {gettextCatalog.getString(`Change the color for this message only.`)}
        </Text>
      </Space>
      <div>
        {(newsletterColor !== value && !isEmpty(value) && (
          <Link
            onClick={() => {
              onChange(newsletterColor);
            }}
          >
            {gettextCatalog.getString('Revert back to your default color.')}
          </Link>
        )) || (
          <Link
            onClick={() => {
              navigate('app.private.settings.people');
            }}
          >
            {gettextCatalog.getString(
              'Go to settings to change default color.'
            )}
          </Link>
        )}
      </div>
    </>
  );
};

export default ColorSelectorWithReset;
