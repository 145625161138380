import React from 'react';
import { Button, Space, Typography } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { useRecoilValue } from 'recoil';

import { FieldSection } from '../../components/FieldSection';
import { MINIMUM_COLUMN_WIDTH } from '../../constants';

import { EventInvitationList } from './EventInvitationList';

import { UserHasPermissionAnywhereQuery } from '@/react/user/store/permissions';
import { MessageType } from '@/react/people/types/message';
import { navigate } from '@/react/services/StateServiceFactory';
import getTextCatalog from '@/react/services/I18nService';

export const InviteTab = ({
  eventId,
  saveAndClose,
}: {
  eventId: number;
  saveAndClose: any;
}) => {
  const canCommunicate = useRecoilValue(
    UserHasPermissionAnywhereQuery('people.communicate')
  );
  const handleCreateInvitation = () => {
    saveAndClose({
      title: getTextCatalog.getString('Save before creating an invite'),
      message: getTextCatalog.getString(
        'To create an invite you will first have to save the event.'
      ),
      okText: getTextCatalog.getString('Save & continue'),
      cancelText: getTextCatalog.getString('Cancel'),
      skipRedirecting: false,
    }).then(() => {
      NiceModal.show('CalendarEmailPosterTemplates', { calendarId: eventId });
    });
  };
  const handleEditInvitation = (invitationId) => {
    saveAndClose({
      title: getTextCatalog.getString('Save before editing an invite'),
      message: getTextCatalog.getString(
        'To edit an invite you will first have to save the event.'
      ),
      okText: getTextCatalog.getString('Save & continue'),
      cancelText: getTextCatalog.getString('Cancel'),
      skipRedirecting: true,
    }).then(() => {
      navigate(
        'app.private.people.messages.editorV2.view',
        { messageId: invitationId, type: MessageType.EVENT_INVITATION },
        { reload: false }
      );
    });
  };
  return (
    <FieldSection>
      <Space direction="vertical">
        <Typography.Text strong>
          {getTextCatalog.getString('Invites')}
        </Typography.Text>
        <Button
          type="primary"
          onClick={handleCreateInvitation}
          disabled={!canCommunicate}
        >
          {getTextCatalog.getString('Create invite')}
        </Button>
      </Space>
      <div
        style={{
          border: '1px solid 	#E5E4E2',
          borderRadius: '4px',
          marginTop: '16px',
          width: `${MINIMUM_COLUMN_WIDTH + 24}px`,
        }}
      >
        <EventInvitationList
          eventId={eventId}
          handleCreateInvitation={handleCreateInvitation}
          handleEditInvitation={handleEditInvitation}
        />
      </div>
    </FieldSection>
  );
};
