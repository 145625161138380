import { Card, Form, FormInstance, InputNumber } from 'antd';
import React from 'react';

import { WidgetInterface } from '../../types/widget.type';
import getTextCatalog from '../../../../services/I18nService';
import { CdImagePicker } from '../../../../calendar/event-details/components/CdImagePicker';

export const SlideshowWidgetForm = ({
  widgetData,
  widgetForm,
}: {
  widgetData: WidgetInterface;
  widgetForm: FormInstance;
}) => (
  <Form
    form={widgetForm}
    layout="vertical"
    initialValues={widgetData}
    style={{ marginTop: 30 }}
  >
    <Form.Item
      rules={[
        {
          type: 'integer',
        },
        {
          required: true,
          message: getTextCatalog.getString('The field is required.'),
        },
        {
          validator: (rule, value) => {
            if (value && value > 20) {
              return Promise.reject(
                getTextCatalog.getString('The maximum number of slides is 20.')
              );
            }
            return Promise.resolve();
          },
        },
      ]}
      name={['configuration', 'pageSize']}
      label={getTextCatalog.getString('Number of slides in the slideshow')}
    >
      <InputNumber min={1} style={{ width: '100%' }} />
    </Form.Item>
    <Card bodyStyle={{ paddingBottom: '0px' }} style={{ marginBottom: '16px' }}>
      <Form.Item
        name={['configuration', 'fallBackImageId']}
        label={getTextCatalog.getString('Choose default image')}
        extra={getTextCatalog.getString(
          'Default image if there is no image available for slideshow'
        )}
      >
        <CdImagePicker
          entityId={widgetData?.id}
          entityType="Slideshow"
          canEdit={true}
          defaultImageUrl="https://assets.churchdesk.com/widgetDefaultImage/Slideshow_fallback_image.jpg?class=large"
          canCrop={false}
        />
      </Form.Item>
    </Card>
  </Form>
);
