import {
  Card,
  List,
  Image,
  Button,
  Typography,
  Space,
  Dropdown,
  Menu,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

import { CdCard } from './CdCard';
import { CdAngleDown, CdDeleteIcon } from './Icons';

import gettextCatalog from '@/react/services/I18nService';
const { Text } = Typography;

// This ensures that cards have the same height.
const StyledList = styled(List)`
  &&&& {
    .ant-row {
      div {
        display: flex;
        flex-direction: column;
      }
      .ant-list-item {
        display: flex;
        flex: 1;
        .ant-card {
          flex: 1;
        }
      }
    }
  }
`;

export interface ISelectableCardItem {
  id: string;
  title: string;
  isSelectable: boolean;
  isDeletable?: boolean;
  screenshotUrl: string;
  subtitle?: string;
  description?: string | React.ReactNode;
}

export const SelectableCards = ({
  loading,
  dataSource,
  onDelete,
  onSelect,
  value,
  emptyText,
  showBorderedCover,
}: {
  loading: boolean;
  dataSource: ISelectableCardItem[];
  onSelect: (string) => void;
  onDelete?: (entityId: number | string, entityName: string) => void;
  value: number | string;
  emptyText: any;
  showBorderedCover?: boolean;
}) => (
  <StyledList
    loading={loading}
    locale={{
      emptyText,
    }}
    grid={{
      gutter: 8,
      xs: 1,
      sm: 1,
      md: 1,
      lg: 2,
      xl: 3,
      xxl: 4,
    }}
    dataSource={dataSource as ISelectableCardItem[]}
    renderItem={(item: ISelectableCardItem) => (
      <List.Item>
        <CdCard
          $selected={value === item.id}
          $bordered
          $showBorderedCover={showBorderedCover}
          onClick={() => item.isSelectable && onSelect(item.id)}
          style={{ borderColor: '#FFF' }}
          cover={
            <Image
              alt={item.title}
              src={item.screenshotUrl || 'error'}
              fallback="/img/placeholder-square.png"
            />
          }
        >
          <Card.Meta
            title={item.title}
            description={
              <>
                {item.subtitle && <Text type="secondary">{item.subtitle}</Text>}
                {item.description}
                {item.isSelectable && (
                  <Space style={{ marginTop: '8px', alignItems: 'stretch' }}>
                    <Button
                      type="default"
                      onClick={() => onSelect(item.id)}
                      disabled={value === item.id}
                    >
                      {value === item.id
                        ? gettextCatalog.getString('Selected')
                        : gettextCatalog.getString('Select template')}
                    </Button>
                    {onDelete && (
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              disabled={!item.isDeletable}
                              key="delete"
                              icon={<CdDeleteIcon />}
                              onClick={() => onDelete(item.id, item.title)}
                            >
                              {gettextCatalog.getString('Delete')}
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button type="default" size="small">
                          {gettextCatalog.getString('More')} <CdAngleDown />
                        </Button>
                      </Dropdown>
                    )}
                  </Space>
                )}
              </>
            }
          />
        </CdCard>
      </List.Item>
    )}
  />
);
