import React, { useEffect, useState } from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import { Form, Input, Radio, Modal, Spin } from 'antd';
import styled from 'styled-components';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { useRecoilValueLoadable } from 'recoil';

import Redactor from '../message-editor/shared/containers/Redactor';
import useConsentStatement from '../hooks/useConsentStatement';
import {
  DefaultConsentBodyContent,
  FindStatementFromAllStatements,
} from '../store/consentState';
import { Consent } from '../types/consent.type';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { CdSearch } from '@/react/shared/components/Icons';
import gettextCatalog from '@/react/services/I18nService';

const Container = styled.div`
  margin: 0 32px 48px;
`;
const Subtitle = styled.p`
  color: gray;
  text-align: justify;
`;
const ConsentTypeSubtitle = styled.p`
  color: gray;
  text-align: justify;
  margin-top: -16px;
`;
const Header = styled.p`
  font-size: 14px;
`;
const ConsentPartHeader = styled.p`
  font-size: 14px;
  padding-top: 16px;
  font-weight: 600;
`;
const Shadowbox = styled.div`
  background-color: #dcf0fa;
  margin: 16px 0;
  border-radius: 4px;
  padding: 16px;
  color: #3481a8;
`;

export const ConsentCreateStatementModal = NiceModal.create(
  ({ statementType, id }: { statementType: StatementType; id?: string }) => (
    <ConsentCreateStatement statementType={statementType} id={id} />
  )
);
export interface ConsentCreateStatementProps {
  statementType: StatementType;
  id?: string;
}

enum ConsentType {
  BodyContent = 'bodyContent',
  LinkContent = 'linkContent',
}

export enum StatementType {
  Create = 'Create',
  Edit = 'Edit',
}
export const ConsentCreateStatement = ({
  statementType,
  id,
}: {
  statementType: StatementType;
  id?: string;
}) => {
  const modal = useModal('ConsentCreateStatement');
  const [consentType, setConsentType] = useState(null);
  const [form] = Form.useForm();
  const close = () => {
    modal.remove();
  };
  const { createStatement, editStatement } = useConsentStatement();
  const statementLoadable = useRecoilValueLoadable(
    FindStatementFromAllStatements(id)
  );
  const defaultConsentContentLodable = useRecoilValueLoadable(
    DefaultConsentBodyContent
  );
  const isLoading =
    defaultConsentContentLodable.state !== 'hasValue' &&
    statementLoadable.state === 'loading';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultConsentContent =
    defaultConsentContentLodable.state === 'hasValue'
      ? defaultConsentContentLodable.contents
      : { body: '', label: '' };

  const statement: Consent = statementLoadable.contents;
  const isSetDefaultCheckboxDisabled =
    statementType !== StatementType.Create && statement.defaultConsent;

  const showConfirm = (
    statementType: StatementType,
    body: {
      label: string;
      body?: string;
      link?: string;
      defaultConsent: boolean;
    },
    id?: string
  ) => {
    NiceModal.show('ConfirmDefaultConsentCreationOnContacts', {
      statementType,
      body,
      id,
    });
  };

  const onFinish = async () => {
    const body = {
      label: form.getFieldValue('label'),
      body:
        consentType === ConsentType.BodyContent
          ? form.getFieldValue('body')
          : undefined,
      link:
        consentType === ConsentType.LinkContent
          ? form.getFieldValue('link')
          : undefined,
      defaultConsent: form.getFieldValue('defaultConsent'),
    };
    await form
      .validateFields()
      .then(async () => {
        if (
          form.getFieldValue('defaultConsent') &&
          !statement?.defaultConsent
        ) {
          if (statementType === StatementType.Create) {
            showConfirm(StatementType.Create, body);
          } else {
            showConfirm(StatementType.Edit, body, id);
          }
        } else if (statementType === StatementType.Create) {
          await createStatement(body);
        } else {
          await editStatement(id, body);
        }
        close();
      })
      .catch((error) => {
        if (error.errorFields.length > 0) throw error;
      });
  };
  useEffect(() => {
    if (
      defaultConsentContentLodable.state === 'hasValue' &&
      statementType === StatementType.Create
    ) {
      form.setFieldsValue({
        body: defaultConsentContent.body,
        label: defaultConsentContent.label,
        defaultConsent: false,
      });
      setConsentType(ConsentType.BodyContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultConsentContentLodable]);

  useEffect(() => {
    if (
      statementLoadable.state === 'hasValue' &&
      statementType === StatementType.Edit
    ) {
      form.setFieldsValue({
        body: statement.body ? statement.body : defaultConsentContent.body,
        label: statement.label,
        link: statement.link,
        defaultConsent: statement.defaultConsent,
      });
      if (statement.body) {
        setConsentType(ConsentType.BodyContent);
      } else {
        setConsentType(ConsentType.LinkContent);
      }
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statementLoadable, statement]);

  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        maskClosable: false,
        width: 800,
        title:
          statementType === StatementType.Create
            ? gettextCatalog.getString(`Create statement`)
            : gettextCatalog.getString('Edit statement'),
        bodyStyle: { padding: 0 },
        onCancel: close,
        okText: gettextCatalog.getString('Save'),
        onOk: () => {
          onFinish();
        },
      }}
    >
      <Spin spinning={isLoading}>
        <Form
          style={{ marginTop: '16px' }}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Container>
            <Form.Item
              name="label"
              label={gettextCatalog.getString('Consent summary - I agree to:')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('Please input a name.'),
                },
              ]}
              style={{ width: '100%' }}
            >
              <Input />
            </Form.Item>
            <Shadowbox>
              <Header>{gettextCatalog.getString('Preview:')}</Header>
              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.label !== currentValues.label
                }
                noStyle
                valuePropName="checked"
              >
                {({ getFieldValue }) => (
                  <Checkbox style={{ color: '#3481A8' }}>
                    {gettextCatalog.getString('I agree to {{consent}}', {
                      consent: getFieldValue('label'),
                    })}
                  </Checkbox>
                )}
              </Form.Item>
            </Shadowbox>

            <CdTooltip
              title={
                isSetDefaultCheckboxDisabled &&
                gettextCatalog.getString(
                  'Choose which consent will be the default consent for future newsletters.'
                )
              }
            >
              <span>
                <Form.Item
                  name="defaultConsent"
                  noStyle
                  valuePropName="checked"
                >
                  <Checkbox disabled={isSetDefaultCheckboxDisabled}>
                    {gettextCatalog.getString(
                      'Make this consent default for all newsletter sign-ups'
                    )}
                  </Checkbox>
                </Form.Item>
              </span>
            </CdTooltip>

            <Subtitle>
              {gettextCatalog.getString(
                'If you tick this option then all contacts that subscribe to any of your newsletters will going forward also consent to this statement as they sign-up. Only one of your consent types can be tied to your newsletters.'
              )}
            </Subtitle>
            <ConsentPartHeader>
              {gettextCatalog.getString('How will you create this consent?')}
            </ConsentPartHeader>
            <Radio.Group
              value={consentType}
              onChange={(e) => setConsentType(e.target.value)}
            >
              <Radio value={ConsentType.BodyContent}>
                <Header>
                  {gettextCatalog.getString(
                    `Provide consent statement as text`
                  )}
                </Header>
                <ConsentTypeSubtitle>
                  {gettextCatalog.getString(
                    `You can provide the full statement here or use our template`
                  )}
                </ConsentTypeSubtitle>
              </Radio>
              <Radio value="linkContent">
                <Header>
                  {gettextCatalog.getString(
                    'Provide consent statement as link'
                  )}
                </Header>
                <ConsentTypeSubtitle>
                  {gettextCatalog.getString(
                    '..or you can provide a link to your current consent statement'
                  )}
                </ConsentTypeSubtitle>
              </Radio>
            </Radio.Group>

            {consentType === ConsentType.BodyContent && (
              <Form.Item
                name="body"
                label={gettextCatalog.getString('Full consent statement')}
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'Please provide a consent statement'
                    ),
                  },
                ]}
              >
                <Redactor
                  imagePluginEnabled={false}
                  messagePlaceHolderEnabled={false}
                />
              </Form.Item>
            )}

            {consentType === ConsentType.LinkContent && (
              <Form.Item
                style={{ width: '100%' }}
                name="link"
                label={gettextCatalog.getString(
                  'Alternatively you can provide link to your consent statement page'
                )}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'Please provide a link to your consent statement'
                    ),
                  },
                  {
                    type: 'url',
                    message: gettextCatalog.getString(
                      'Please provide a valid url'
                    ),
                  },
                ]}
              >
                <Input addonBefore={<CdSearch />} placeholder="https://...." />
              </Form.Item>
            )}

            <Subtitle>
              {gettextCatalog.getString(
                'This statement will be available via a link to everyone who give the option to opt-in to this consent'
              )}
            </Subtitle>
          </Container>
        </Form>
      </Spin>
    </Modal>
  );
};
