import { UploadRequestOption } from 'rc-upload/lib/interface';
import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';

import { mainApi } from '@/react/api';
import {
  CalendarPosterTemplatesQuery,
  PosterQuery,
} from '@/react/calendar/store/events/event';
import { CalendarPoster } from '@/react/calendar/types/event';
import { PeopleMessageFile } from '@/react/people/types/message';
import { handleError } from '@/react/services/ErrorHandlingService';
import NotificationService from '@/react/services/NotificationService';
import { navigate } from '@/react/services/StateServiceFactory';
import gettextCatalog from '@/react/services/I18nService';
import { StudioState } from '@/react/calendar/services/studioService';
import { GetEventPosters } from '@/react/calendar/store/events/calendarPoster';

export interface AttachFileInterface
  extends Pick<
    UploadRequestOption<PeopleMessageFile>,
    'onSuccess' | 'onProgress' | 'onError' | 'file'
  > {
  fileName?: string;
}

const deletePosterApi = async ({
  posterId,
  calendarId,
}: {
  posterId: number;
  calendarId: number;
}) => {
  const response = await mainApi.delete<CalendarPoster>(
    `/calendar/${calendarId}/poster/${posterId}`
  );

  if (!response.ok) {
    handleError(response);
    return null;
  }
  NotificationService.notifySuccess(
    gettextCatalog.getString('The poster was deleted')
  );
  return response.data;
};

export const useCalendarPosterTemplate = ({ calendarId }) => {
  const refreshStudioState = useRecoilRefresher(StudioState);
  const eventPosterRefresher = useRecoilRefresher(
    GetEventPosters({ eventId: calendarId })
  );
  const selectTemplate = useRecoilCallback(
    ({}) =>
      async ({
        templateId,
        calendarId,
      }: {
        templateId: string;
        calendarId: number;
      }) => {
        const response = await mainApi.post<CalendarPoster>(
          `/calendar/${calendarId}/poster?templateId=${templateId}`
        );

        if (!response.ok) {
          handleError(response);
          throw response;
        }
        navigate('app.private.calendar.poster', {
          posterId: response.data.id,
          calendarId,
        });
        refreshStudioState();
        eventPosterRefresher();
      },
    [eventPosterRefresher, refreshStudioState]
  );

  const templatesLoadable = useRecoilValueLoadable(
    CalendarPosterTemplatesQuery
  );
  const templatesLoading = templatesLoadable.state === 'loading';
  const templates =
    templatesLoadable.state === 'hasValue' ? templatesLoadable.contents : [];
  return { selectTemplate, templatesLoading, templates };
};

export const useDeleteCalendarPoster = ({ eventId }) => {
  const eventPosterRefresher = useRecoilRefresher(GetEventPosters({ eventId }));
  const deletePoster = useRecoilCallback(
    () =>
      async ({ posterId }) => {
        await deletePosterApi({ posterId, calendarId: eventId });
        eventPosterRefresher();
      },
    [eventId, eventPosterRefresher]
  );

  return { deletePoster };
};

export const useEditCalendarPoster = ({
  calendarId,
  posterId,
}: {
  calendarId: number;
  posterId: number;
}) => {
  const refresh = useRecoilRefresher(PosterQuery({ calendarId, posterId }));
  const eventPosterRefresher = useRecoilRefresher(
    GetEventPosters({ eventId: calendarId })
  );

  const poster = useRecoilValue(PosterQuery({ calendarId, posterId }));

  const updatePoster = useRecoilCallback(
    () =>
      async ({ poster }: { poster: CalendarPoster }) => {
        const response = await mainApi.put<CalendarPoster>(
          `/calendar/${calendarId}/poster/${posterId}`,
          poster
        );

        refresh();
        if (!response.ok) {
          handleError(response);
          return null;
        }
        NotificationService.notifySuccess(
          gettextCatalog.getString('The poster was saved')
        );
        eventPosterRefresher();
        return response.data;
      },
    [calendarId, posterId, refresh, eventPosterRefresher]
  );

  const deletePoster = useRecoilCallback(() => deletePosterApi, []);

  return { updatePoster, deletePoster, poster };
};

export const useCalendarPoster = (posterId) => {
  const attachFile = useRecoilCallback(
    () =>
      async ({
        onSuccess,
        onProgress,
        onError,
        file,
        fileName,
      }: AttachFileInterface) => {
        const fileForm = new FormData();
        fileForm.append('file', file as any, fileName);
        try {
          const res = await mainApi.post<PeopleMessageFile>(
            `/v2/people/messages/${posterId}/files`,
            fileForm,
            {
              onUploadProgress: (e) =>
                onProgress({ percent: (e.loaded / e.total) * 100, ...e }),
            }
          );
          if (res.ok) {
            onSuccess(res.data, undefined);
            return res.data;
          }

          let errorMessage: string = (res?.data as any)?.message;
          if (res.status === 406) {
            errorMessage = gettextCatalog.getString(
              'The file type is not allowed.'
            );
          }
          NotificationService.notifyError(errorMessage);
          onError(new Error(errorMessage));
        } catch (e) {
          NotificationService.notifyError((e as any)?.data?.message);
          onError(e);
        }
      },
    [posterId]
  );
  return { attachFile };
};
