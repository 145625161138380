/* eslint-disable react-hooks/exhaustive-deps */
import { intersection, isEmpty, keys, map } from 'lodash';
import { Space, Button, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faPencilAlt,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { showModal } from 'redux-saga-modal';

import gettextCatalog from '../../../services/I18nService';
import { getEventStole } from '../../redux/stoles/Selectors';
import AuthorizationService from '../../../services/AuthorizationService';
import { fetchEventStole, clearEventStole } from '../../redux/stoles/Actions';
import { FEvent } from '../../../calendar/models/calendar';
import MODAL_TYPES from '../../sagas/modal-types';
import { getStoleFeeTaxonomyIds } from '../../../settings/redux/intention-fees/Selectors';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import { handleSuccessMessage } from '../../../shared/utils';
import StoleService from '../../services/StoleService';

import EventStoleDetails from './EventStoleDetails';
import CreateStoleModal from './CreateStoleModal';

import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

// Props
export interface EventStoleProps {
  event: FEvent;
}

const EventStole: FunctionComponent<EventStoleProps> = ({ event }) => {
  // Redux
  const dispatch = useDispatch();

  // Retrieve required entities from Redux store
  const stoleFeeTaxonomyIds = useSelector(getStoleFeeTaxonomyIds);
  const _eventStole = useSelector(getEventStole);
  const eventStole = _eventStole && _eventStole.asMutable({ deep: true });

  const hasIntentionAccess = () =>
    AuthorizationService.hasPackage('intentions');

  const editStole = () =>
    dispatch(showModal(MODAL_TYPES.CREATE_STOLE, { event, stole: eventStole }));

  const createStole = () =>
    dispatch(showModal(MODAL_TYPES.CREATE_STOLE, { event }));

  const deleteStole = useCallback(() => {
    showConfirmModalDeprecated({
      title: gettextCatalog.getString(
        'Do you want to delete the stole {{referenceNumber}}?',
        {
          referenceNumber: eventStole.formattedReferenceNumber,
        }
      ),
      okText: gettextCatalog.getString('Delete'),
      okButtonProps: { danger: true },
      getContainer: document.getElementById('calendar-event-popover'),
      onOk() {
        StoleService.deleteStole(eventStole.id)
          .then(() => {
            handleSuccessMessage(
              gettextCatalog.getString('Successfully deleted stole.')
            );
            dispatch(clearEventStole());
          })
          .catch(ErrorHandlingService.handleError);
      },
    });
  }, [eventStole]);

  const canAddStoles = useMemo(() => {
    if (!event) return false;
    const eventTaxonomyIds = map(keys(event.taxonomies), parseInt);
    const sharesEventCommonTaxonomiesWithStoleFees = !isEmpty(
      intersection(eventTaxonomyIds, stoleFeeTaxonomyIds)
    );
    return hasIntentionAccess && sharesEventCommonTaxonomiesWithStoleFees;
  }, [event, stoleFeeTaxonomyIds]);

  const hasStole = !isEmpty(eventStole);

  useEffect(() => {
    if (event.id) {
      dispatch(fetchEventStole(event.id));
    } else {
      dispatch(clearEventStole());
    }
  }, []);

  return (
    <div>
      {canAddStoles ? (
        <Card
          title={
            <div>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className="text-muted"
                style={{ marginRight: 8 }}
              />{' '}
              {gettextCatalog.getString('Stole')}
            </div>
          }
          extra={
            <Space>
              <Button
                size="small"
                type="primary"
                style={{ marginRight: 8 }}
                onClick={hasStole ? editStole : createStole}
              >
                <FontAwesomeIcon
                  icon={hasStole ? faPencilAlt : faPlus}
                  style={{ marginRight: 8 }}
                />
                {hasStole
                  ? gettextCatalog.getString('Edit Stole')
                  : gettextCatalog.getString('Create Stole')}
              </Button>
              {hasStole && (
                <Button
                  size="small"
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={deleteStole}
                >
                  <FontAwesomeIcon icon={faTrash} style={{ marginRight: 8 }} />
                  {gettextCatalog.getString('Delete stole')}
                </Button>
              )}
            </Space>
          }
          bodyStyle={{ display: hasStole ? 'block ' : 'none' }}
        >
          {eventStole && <EventStoleDetails stole={eventStole} />}
        </Card>
      ) : null}
      <CreateStoleModal />
    </div>
  );
};

export default EventStole;
