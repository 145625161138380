import { selectorFamily, selector } from 'recoil';

import {
  AnniversaryInterface,
  AnniversaryTypeInterface,
} from '../../../calendar/types/anniversaryTypes';

import { ApiSearchResult, mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const AnniversaryListQuery = selectorFamily<
  ApiSearchResult<AnniversaryInterface>,
  { type: string }
>({
  key: 'AnniversaryListQuery',
  get:
    ({ type }) =>
    async () => {
      const response = await mainApi.get<AnniversaryInterface[]>(
        `/people/people/anniversaries`,
        {
          anniversaryType: type,
        }
      );
      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }
      return { items: response.data, total: response.data?.length };
    },
});
export const AnniversaryTypeListQuery = selector<AnniversaryTypeInterface[]>({
  key: 'AnniversaryTypeListQuery',
  get: async () => {
    const response = await mainApi.get<AnniversaryTypeInterface[]>(
      `/people/people/life-event-fields`
    );
    if (!response.ok) {
      handleError(response);
      return [];
    }
    return response.data;
  },
});
