import React, { FunctionComponent, useEffect } from 'react';
import { Button, Select, Typography, Form, Table, Switch } from 'antd';
import { every, filter, get, isEmpty, reduce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { showModal } from 'redux-saga-modal';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';

import {
  getUserOldRoles,
  getUserChurchRoles,
  getUserOrganizationRoles,
} from '../../redux/selectors';
import { isAnyLegacyPermissionEnabled } from '../../utils/LegacyPermissionsUtil';
import { RoleInterface } from '../../../organization/types/organization-role.types';
import MODAL_TYPES from '../../redux/modal-types';
import { getChurches } from '../../../shared/store/resources/index';
import { useFetchRoles } from '../../../organization/screens/manage-roles/hooks';
import gettextCatalog from '../../../services/I18nService';
import { FETCH_USER_ROLES, SaveUserRoles } from '../../redux/actions';
import {
  RolesActions,
  roleSelectors,
} from '../../../organization/store/roleSlice';
import { selectIsModulesLoading } from '../../../shared/loading/redux/Selectors';
import AuthorizationService from '../../../services/AuthorizationService';

import UserPermissionsModal from './UserPermissionsModal';
import AssignUserPermissions from './AssignUserPermissions';

import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

const { Paragraph, Title, Text } = Typography;
const { Option } = Select;

const StyledTable = styled(Table)`
  &&&& {
    .legacy-permissions {
      background: #dcf3f5;
    }
  }
  &&&&.role-table__grey-background .ant-table {
    background: #f3f3f3;
  }
`;

const legacyId = 'legacy';

interface RoleTableDataInterface extends RoleInterface {
  rank?: number;
  rowClass?: string;
}

const AssignUserRoles: FunctionComponent<{
  userId?: number;
}> = ({ userId }) => {
  const dispatch = useDispatch();
  const organizationChurchRoles = useSelector(roleSelectors.selectChurchRoles);
  const organizationRoles = useSelector(roleSelectors.selectOrganizationRoles);
  const userOrganizationRoles = useSelector(getUserOrganizationRoles);
  const userOldRoles = useSelector(getUserOldRoles);
  const churches = useSelector(getChurches);
  const userChurchRoles = useSelector(getUserChurchRoles);
  const isLoading = useSelector(
    selectIsModulesLoading([RolesActions.fetchRoles.type, FETCH_USER_ROLES])
  );

  const hasUserAdminRole =
    AuthorizationService.hasPermission('canAdministerUsers');
  const showMultiChurch = get(window, 'cdApp.showChurchSelector');
  const loading = isLoading;

  const fetchRoles = useFetchRoles();
  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let mutableChurchRoles: RoleTableDataInterface[] = filter(
    organizationChurchRoles,
    {
      isEnabled: true,
    }
  ).sort((a, b) => {
    const a_name = a.name.toUpperCase();
    const b_name = b.name.toUpperCase();
    return a_name < b_name ? -1 : a_name > b_name ? 1 : 0;
  });
  const mutableOrganizationRoles: RoleTableDataInterface[] = filter(
    organizationRoles,
    {
      isEnabled: true,
    }
  ).sort((a, b) => {
    const a_name = a.name.toUpperCase();
    const b_name = b.name.toUpperCase();
    return a_name < b_name ? -1 : a_name > b_name ? 1 : 0;
  });
  if (
    isAnyLegacyPermissionEnabled(userOldRoles) &&
    isEmpty(userChurchRoles) &&
    isEmpty(userOrganizationRoles)
  ) {
    mutableChurchRoles = [
      {
        id: legacyId,
        rank: 1,
        rowClass: 'legacy-permissions',
        name: gettextCatalog.getString(
          'Current permissions (Please choose a new role)'
        ),
        description: gettextCatalog.getString(
          `ChurchDesk has changed to a new role based permission system and all users should now get assigned roles. Until then the old permission settings still works. These permissions can no longer be edited.`
        ),
        context: 'none',
      },
      ...mutableChurchRoles,
    ];
  }
  const launchLegacyExplainer = () => {
    dispatch(showModal(MODAL_TYPES.USER_ROLES_SUMMARY, { userId }));
  };

  return (
    <div id="userAssignRoles">
      {true ? (
        <>
          <StyledTable
            showHeader={showMultiChurch}
            dataSource={mutableChurchRoles}
            columns={roleChurchColumns(
              loading,
              churches,
              launchLegacyExplainer,
              hasUserAdminRole,
              showMultiChurch
            )}
            pagination={false}
            size="middle"
            loading={loading}
            style={{ marginBottom: 30, padding: '0 5px' }}
            rowKey="id"
            rowClassName={(row: RoleTableDataInterface) => row.rowClass}
          />
          <StyledTable
            dataSource={mutableOrganizationRoles}
            columns={roleOrganizationColumns(hasUserAdminRole)}
            showHeader={false}
            pagination={false}
            size="middle"
            loading={loading}
            style={{ marginBottom: 30 }}
            rowKey="id"
            className="role-table__grey-background"
            rowClassName={(row: RoleTableDataInterface) => row.rowClass}
          />
          <UserPermissionsModal />
        </>
      ) : (
        <>
          {/* Old permissions, Legacy */}
          <Title level={2}>{gettextCatalog.getString('Permissions')}</Title>
          {true ? (
            <Paragraph
              ellipsis={{
                symbol: gettextCatalog.getString('Expand'),
                rows: 2,
                expandable: true,
              }}
              type="secondary"
            >
              {gettextCatalog.getString(
                'The following permissions can not be assigned per parish'
              )}
            </Paragraph>
          ) : null}
          <AssignUserPermissions loadingUserRolesLoading={loading} />
        </>
      )}
    </div>
  );
};

export const beforeSaveChecks = (
  form: FormInstance,
  userBeingUpdated: number,
  currentLoggedInUserId: number,
  existingUserOldRoles: any,
  existingUserChurchRoles: any
): Promise<boolean> => {
  const value = form.getFieldsValue();
  const beforeChecks = [Promise.resolve(true)];
  if (
    currentLoggedInUserId === userBeingUpdated &&
    value.oldRoles &&
    !value.oldRoles.canAdministerOrganization
  ) {
    beforeChecks.push(
      new Promise((resolve) => {
        showConfirmModalDeprecated({
          title: gettextCatalog.getString(
            'Want to remove yourself as organisation Administrator?'
          ),
          message: gettextCatalog.getString(
            'Do you wish to remove yourself as an organisation administrator? Once removed you cannot reinstate yourself.'
          ),
          okText: gettextCatalog.getString('Remove'),
          onCancel: () => {
            value.oldRoles.canAdministerOrganization = true;
            form.setFieldsValue(value);
            resolve(false);
          },
          onOk: () => {
            resolve(true);
          },
        });
      })
    );
  }
  if (
    isAnyLegacyPermissionEnabled(existingUserOldRoles) &&
    isEmpty(existingUserChurchRoles) &&
    !isEmpty(value.church)
  ) {
    beforeChecks.push(
      new Promise((resolve) => {
        showConfirmModalDeprecated({
          title: gettextCatalog.getString(
            'Want to replace current permissions?'
          ),
          message: gettextCatalog.getString(
            "New roles will replace old permissions. Please make sure that you're granting the user the right roles as old permissions cannot be restored. If you have any questions then please contact support."
          ),
          okText: gettextCatalog.getString('Replace'),
          onCancel: () => {
            resolve(false);
          },
          onOk: () => {
            resolve(true);
          },
        });
      })
    );
  }
  return Promise.all(beforeChecks).then(every);
};

const roleChurchColumns = (
  loading: boolean,
  churches,
  launchLegacyExplainer,
  hasUserAdminRole: boolean,
  showMultiChurch: boolean
): ColumnsType<RoleTableDataInterface> => [
  {
    title: <></>,
    dataIndex: 'churchRoleSwitch',
    key: 'churchRoleSwitch',
    width: 80,
    // eslint-disable-next-line react/display-name
    render: (text, record) =>
      record.id === legacyId ? null : (
        <>
          <Form.Item
            name={['churchSwitches', record.id]}
            valuePropName="checked"
            style={{ margin: 'auto 0' }}
          >
            <Switch
              disabled={!hasUserAdminRole}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faTimes} />}
            />
          </Form.Item>
        </>
      ),
  },
  {
    title: (
      <>
        <Text strong>{gettextCatalog.getString('Parish Roles')}</Text>
        <Text type="secondary" style={{ display: 'block' }}>
          {gettextCatalog.getString('Choose which roles the user should have')}
        </Text>
      </>
    ),
    dataIndex: 'churchRole',
    key: 'churchRole',
    defaultSortOrder: 'ascend',
    // eslint-disable-next-line react/display-name
    render: (text, record) =>
      record.id === legacyId ? (
        <div className="u-flex u-align-items-center">
          <div className="u-flex u-flex-column u-mr-10">
            <Text strong>{record.name}</Text>
            <Paragraph
              ellipsis={{
                symbol: gettextCatalog.getString('Expand'),
                rows: 2,
                expandable: true,
              }}
              type="secondary"
            >
              {record.description}
            </Paragraph>
          </div>
          <Button onClick={launchLegacyExplainer}>
            {gettextCatalog.getString('View permissions')}
          </Button>
        </div>
      ) : (
        <>
          <Text strong>{record.name}</Text>
          <Paragraph
            ellipsis={{
              symbol: gettextCatalog.getString('Expand'),
              rows: 2,
              expandable: true,
            }}
            type="secondary"
          >
            {record.description}
          </Paragraph>
        </>
      ),
  },
  {
    title: showMultiChurch ? (
      <>
        <Text strong>{gettextCatalog.getString('Role in parishes')}</Text>
        <Text type="secondary" style={{ display: 'block' }}>
          {gettextCatalog.getString(
            'Select in which parishes the user should have this role'
          )}
        </Text>
      </>
    ) : (
      <></>
    ),
    dataIndex: 'churches',
    key: 'churches',
    width: '50%',
    defaultSortOrder: 'descend',
    // eslint-disable-next-line react/display-name
    render: (text, record) => {
      if (!showMultiChurch) return <></>;
      return record.id === legacyId ? (
        <>
          <Text strong>{gettextCatalog.getString(`In all parishes`)}</Text>
          <Paragraph type="secondary">
            {gettextCatalog.getString(
              `These permissions are granted for all parishes and not limited to specific parishes.`
            )}
          </Paragraph>
        </>
      ) : (
        <>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              get(prevValues.churchSwitches, record.id) !==
              get(currentValues.churchSwitches, record.id)
            }
          >
            {({ getFieldValue }) =>
              get(getFieldValue('churchSwitches'), record.id, false) ? (
                <Form.Item
                  name={['church', record.id]}
                  style={{ margin: 'auto 0' }}
                  rules={[
                    {
                      validator() {
                        if (
                          get(
                            getFieldValue('churchSwitches'),
                            record.id,
                            false
                          ) &&
                          isEmpty(get(getFieldValue('church'), record.id, []))
                        ) {
                          return Promise.reject(
                            gettextCatalog.getString(
                              'Select in which parishes the user should have this role...'
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    autoFocus={true}
                    defaultOpen={isEmpty(
                      get(getFieldValue('church'), record.id, [])
                    )}
                    placeholder={gettextCatalog.getString(
                      'Select in which parishes the user should have this role...'
                    )}
                    allowClear={true}
                    loading={loading}
                    disabled={!hasUserAdminRole}
                    getPopupContainer={() =>
                      document.getElementById('userAssignRoles')
                    }
                  >
                    {churches?.map((church) => (
                      <Option key={church.id} value={church.id}>
                        {church.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null
            }
          </Form.Item>
        </>
      );
    },
  },
];

const roleOrganizationColumns = (
  hasUserAdminRole
): ColumnsType<RoleTableDataInterface> => [
  {
    dataIndex: 'organizations',
    key: 'organizations',
    width: 80,
    // eslint-disable-next-line react/display-name
    render: (text, record) => (
      <>
        <Form.Item
          valuePropName="checked"
          name={['organization', record.id]}
          style={{ margin: 'auto 0' }}
        >
          <Switch
            disabled={!hasUserAdminRole}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faTimes} />}
          />
        </Form.Item>
      </>
    ),
  },
  {
    dataIndex: 'organizationRole',
    key: 'organizationRole',
    defaultSortOrder: 'ascend',
    // eslint-disable-next-line react/display-name
    render: (text, record) => (
      <>
        <Text strong>{record.name}</Text>
        <Paragraph
          ellipsis={{
            symbol: gettextCatalog.getString('Expand'),
            rows: 2,
            expandable: true,
          }}
          type="secondary"
        >
          {record.description}
        </Paragraph>
      </>
    ),
  },
];

export const saveUserRoles = (
  dispatch,
  form,
  mode: 'inline' | 'modal',
  userId,
  currentLoggedInUserId,
  oldRoles,
  churchRoles,
  churches,
  userName
) =>
  form.validateFields().then(async (values) => {
    if (!isEmpty(values.errorFields)) return;
    const showMultiChurch = get(window, 'cdApp.showChurchSelector');
    const checksPassed: boolean = await beforeSaveChecks(
      form,
      userId,
      currentLoggedInUserId,
      oldRoles,
      churchRoles
    );
    if (!checksPassed) return;
    const valueAfterSaveChecks = form.getFieldsValue();
    if (!showMultiChurch) {
      // When the installation has only one church, only the switches are visible. In that case we assign the one church automatically
      valueAfterSaveChecks.church = reduce(
        valueAfterSaveChecks.churchSwitches,
        (accumulator, switchEnabled, key) => {
          accumulator[key] = switchEnabled ? [churches[0].id] : [];
          return accumulator;
        },
        {}
      );
    }
    dispatch(
      SaveUserRoles({
        name: userName,
        newUser: false,
        mode,
        userId,
        data: {
          oldRoles: valueAfterSaveChecks.oldRoles,
          churchRoles: valueAfterSaveChecks.church,
          organizationRoles: valueAfterSaveChecks.organization,
        },
      })
    );
  });

export default AssignUserRoles;
