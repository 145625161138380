import React, { useEffect } from 'react';
import {
  Divider,
  Form,
  Input,
  FormInstance,
  Typography,
  DatePicker,
  Radio,
  Select,
  InputNumber,
  Spin,
  Space,
} from 'antd';
import styled from 'styled-components';

import { ConflictingPeopleRelationComponent } from '../ConflictingPeopleRelationComponent';
import {
  ConflictingPersonWithRelation,
  ContactInformationType,
} from '../../services/people.service';

import { PhoneInput } from './PhoneInput';

import getTextCatalog from '@/react/services/I18nService';
import { CdCountrySelect } from '@/react/shared/components/cd-country-select/CdCountrySelect';
const CustomForm = styled(Form)`
.ant-divider-horizontal{
  margin-top:8px;
  margin-bottom:16px;
  }
`;
const FormItem = styled(Form.Item)`
  margin-bottom: 8px;
  `;
const ConfilictingPeoplePart = styled(Form.Item)`

`;
export const EditPersonalInformationForm = ({
  contactId,
  form,
  accessFields,
  personRelations,
  initialValues,
  isPending,
  isRefetching,
  setConflictingPeopleByEmail,
  setConflictingPeopleByPhone,
  conflictingPeopleByEmail,
  conflictingPeopleByPhone,
}: {
  contactId: number;
  form: FormInstance;
  accessFields: any;
  personRelations: any;
  initialValues: any;
  isPending: boolean;
  isRefetching: boolean;
  setConflictingPeopleByEmail: (
    selectedValues: ConflictingPersonWithRelation[]
  ) => void;
  setConflictingPeopleByPhone: (
    selectedValues: ConflictingPersonWithRelation[]
  ) => void;
  conflictingPeopleByEmail: ConflictingPersonWithRelation[];
  conflictingPeopleByPhone: ConflictingPersonWithRelation[];
}) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending, isRefetching, initialValues]);
  const labels = {
    email: getTextCatalog.getString('Email'),
    phone: getTextCatalog.getString('Mobile phone'),
    homePhone: getTextCatalog.getString('Home phone'),
    workPhone: getTextCatalog.getString('Work phone'),
    street: getTextCatalog.getString('Street address'),
    city: getTextCatalog.getString('City'),
    zipcode: getTextCatalog.getString('Postal code'),
    country: getTextCatalog.getString('Country'),
    birthday: getTextCatalog.getString('Birthday'),
    gender: getTextCatalog.getString('Gender'),
    occupation: getTextCatalog.getString('Occupation'),
    note: getTextCatalog.getString('Internal note'),
    civilStatus: getTextCatalog.getString('Civil status'),
    nationalIdentificationNumber: getTextCatalog.getString(
      'National identification number'
    ),
    allergy: getTextCatalog.getString('Allergies and intolerances'),
    dietary: getTextCatalog.getString('Diet'),
    medicalNote: getTextCatalog.getString('Medical note'),
  };
  const setConflictingPeopleByEmailSelectedValues = (
    selectedValues: ConflictingPersonWithRelation[]
  ): void => {
    setConflictingPeopleByEmail(selectedValues);
  };

  const setConflictingPeopleByPhoneSelectedValues = (
    selectedValues: ConflictingPersonWithRelation[]
  ): void => {
    setConflictingPeopleByPhone(selectedValues);
  };
  return (
    <Spin spinning={isPending}>
      <CustomForm
        form={form}
        colon={false}
        labelCol={{ span: 6 }}
        labelAlign="right"
      >
        <Typography.Title level={5}>
          {getTextCatalog.getString('Contact')}
        </Typography.Title>
        <Divider />
        {accessFields?.email?.canEdit ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <FormItem
              name="email"
              label={labels.email}
              rules={[{ type: 'email' }]}
            >
              <Input />
            </FormItem>
            <ConfilictingPeoplePart
              label={' '}
              shouldUpdate
              noStyle={conflictingPeopleByEmail.length === 0}
            >
              {({ getFieldValue }) => (
                <ConflictingPeopleRelationComponent
                  emailValue={getFieldValue('email')}
                  phoneValue={
                    getFieldValue('phone') &&
                    `+${
                      getFieldValue('phone').code + getFieldValue('phone').phone
                    }`
                  }
                  valueType={ContactInformationType.EMAIL}
                  shouldListSingleConflictingPeople={false}
                  omittingPersonId={contactId}
                  existingRelations={personRelations}
                  setSelectedValues={setConflictingPeopleByEmailSelectedValues}
                />
              )}
            </ConfilictingPeoplePart>
          </Space>
        ) : (
          <AccessErrorMessage label={labels.email} />
        )}
        {accessFields?.phone?.canEdit ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <PhoneInput name="phone" label={labels.phone} />
            <Form.Item
              label=" "
              shouldUpdate
              noStyle={conflictingPeopleByPhone.length === 0}
            >
              {({ getFieldValue }) => (
                <ConflictingPeopleRelationComponent
                  emailValue={getFieldValue('email')}
                  phoneValue={
                    getFieldValue('phone') &&
                    `+${
                      getFieldValue('phone').code + getFieldValue('phone').phone
                    }`
                  }
                  valueType={ContactInformationType.PHONE}
                  shouldListSingleConflictingPeople={true}
                  omittingPersonId={contactId}
                  existingRelations={personRelations}
                  setSelectedValues={setConflictingPeopleByPhoneSelectedValues}
                />
              )}
            </Form.Item>
          </Space>
        ) : (
          <AccessErrorMessage label={labels.phone} />
        )}
        {accessFields?.homePhone?.canEdit ? (
          <PhoneInput name="homePhone" label={labels.homePhone} />
        ) : (
          <AccessErrorMessage label={labels.homePhone} />
        )}
        {accessFields?.workPhone?.canEdit ? (
          <PhoneInput name="workPhone" label={labels.workPhone} />
        ) : (
          <AccessErrorMessage label={labels.workPhone} />
        )}
        <Typography.Title level={5}>
          {getTextCatalog.getString('Address')}
        </Typography.Title>
        <Divider />
        {accessFields?.street?.canEdit ? (
          <FormItem
            name="street"
            label={getTextCatalog.getString('Street address')}
          >
            <Input />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.street} />
        )}
        {accessFields?.city?.canEdit ? (
          <FormItem name="city" label={getTextCatalog.getString('City')}>
            <Input />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.city} />
        )}
        {accessFields?.zipcode?.canEdit ? (
          <FormItem
            name="zipcode"
            label={getTextCatalog.getString('Postal code')}
          >
            <Input />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.zipcode} />
        )}
        {accessFields?.country.canEdit ? (
          <FormItem label={getTextCatalog.getString('Country')} name="country">
            <CdCountrySelect
              placeholder={getTextCatalog.getString('Country')}
              allowClear={false}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.country} />
        )}

        <Typography.Title level={5}>
          {getTextCatalog.getString('Other Details')}
        </Typography.Title>
        <Divider />
        {accessFields?.birthday?.canEdit ? (
          <FormItem
            name="birthday"
            label={getTextCatalog.getString('Birthday')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={getTextCatalog.getLongDateFormat('L')}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.birthday} />
        )}
        {accessFields?.gender?.canEdit ? (
          <FormItem name="gender" label={getTextCatalog.getString('Gender')}>
            <Radio.Group>
              <Radio value="male">{getTextCatalog.getString('Male')}</Radio>
              <Radio value="female">{getTextCatalog.getString('Female')}</Radio>
              <Radio value="other">{getTextCatalog.getString('Other')}</Radio>
            </Radio.Group>
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.gender} />
        )}
        {accessFields?.occupation?.canEdit ? (
          <FormItem
            name="occupation"
            label={getTextCatalog.getString('Occupation')}
          >
            <Input />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.occupation} />
        )}
        {accessFields?.note?.canEdit ? (
          <FormItem
            name="note"
            label={getTextCatalog.getString('Internal note')}
            style={{ marginBottom: '14px' }}
          >
            <Input.TextArea />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.note} />
        )}
        {accessFields?.civilStatus?.canEdit ? (
          <FormItem
            name="civilStatus"
            label={getTextCatalog.getString('Civil status')}
          >
            <Select
              options={[
                { label: getTextCatalog.getString('Single'), value: 'single' },
                {
                  label: getTextCatalog.getString('In a relationship'),
                  value: 'inARelationship',
                },
                { label: getTextCatalog.getString('Marrid'), value: 'married' },
                {
                  label: getTextCatalog.getString(
                    'Registered partnership/Civil union'
                  ),
                  value: 'registeredPartnership',
                },
                {
                  label: getTextCatalog.getString('Divorced/Separated'),
                  value: 'divorced',
                },
                {
                  label: getTextCatalog.getString('Widowed'),
                  value: 'widowed',
                },
              ]}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.civilStatus} />
        )}
        <Typography.Title level={5}>
          {getTextCatalog.getString('Health')}
        </Typography.Title>
        <Divider />
        {accessFields?.nationalIdentificationNumber?.canEdit ? (
          <FormItem
            name="nationalIdentificationNumber"
            label={getTextCatalog.getString('National identification number')}
          >
            <InputNumber style={{ width: '100%' }} />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.nationalIdentificationNumber} />
        )}
        {accessFields?.allergy?.canEdit ? (
          <FormItem
            name="allergy"
            label={getTextCatalog.getString('Allergies and intolerances')}
          >
            <Select
              mode="multiple"
              options={[
                {
                  label: getTextCatalog.getString('Nuts (peanuts & tree nuts)'),
                  value: 'nuts',
                },
                {
                  label: getTextCatalog.getString('Eggs'),
                  value: 'eggs',
                },
                {
                  label: getTextCatalog.getString(
                    'Dairy (incl. lactose intolerant)'
                  ),
                  value: 'dairy',
                },
                {
                  label: getTextCatalog.getString('Fish'),
                  value: 'fish',
                },
                {
                  label: getTextCatalog.getString('Shellfish'),
                  value: 'shellfish',
                },
                {
                  label: getTextCatalog.getString(
                    'Wheat (incl. gluten intolerant)'
                  ),
                  value: 'wheat',
                },
                { label: getTextCatalog.getString('Soy'), value: 'soy' },
              ]}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.allergy} />
        )}
        {accessFields?.dietary?.canEdit ? (
          <FormItem name="dietary" label={getTextCatalog.getString('Diet')}>
            <Select
              options={[
                { label: getTextCatalog.getString('Vegan'), value: 'vegan' },
                {
                  label: getTextCatalog.getString('Vegetarian'),
                  value: 'vegetarian',
                },
                {
                  label: getTextCatalog.getString('Pescatarian'),
                  value: 'pescatarian',
                },
              ]}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.dietary} />
        )}
        {accessFields?.medicalNote?.canEdit ? (
          <FormItem
            name="medicalNote"
            label={getTextCatalog.getString('Medical note')}
          >
            <Input.TextArea />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.medicalNote} />
        )}
      </CustomForm>
    </Spin>
  );
};

const AccessErrorMessage = ({ label }) => (
  <FormItem label={label}>
    <i>
      {getTextCatalog.getString(
        'You do not have the permission to edit sensitive fields.'
      )}
    </i>
  </FormItem>
);
