import React from 'react';
import { Row, Col, Alert, Button, Space } from 'antd';
import { useRecoilValue } from 'recoil';

import cdApp from '../../config';

import { WebsiteCard } from './WebsiteCard';
import { useWebsite } from './hooks/use-website';
import { WebsiteCardDrupal } from './WebsiteCardDrupal';
import { WebsiteCardBuyExtra } from './WebsiteCardBuyExtra';
import { MigratedWebsiteCard } from './MigratedWebsiteCard';
import { WebsiteCardCreate } from './WebsiteCardCreate';
import { openConnectToAccountManagerModal } from './ConnectToAccountManagerModal';

import gettextCatalog from '@/react/services/I18nService';
import { FeatureFlagQuery } from '@/react/services/FeatureToggleService';
import AuthorizationService from '@/react/services/AuthorizationService';
import { useOrganization } from '@/react/organization/hooks/useOrganization';

export const ManageWebsiteList = () => {
  const { organizationSettings } = useOrganization();
  const { websiteList, goToMonoWebsite, websiteLoginLoading, deleteWebsite } =
    useWebsite();
  const isOldWebsite = !!cdApp.organization.installationUrl;
  const hasHomePagePackage = AuthorizationService.hasPackage('homepage');
  const editDrupalSiteException = useRecoilValue(
    FeatureFlagQuery('allow-edit-of-old-drupal-site')
  );

  const canEditDrupalWebsite =
    isOldWebsite && hasHomePagePackage && editDrupalSiteException;

  const canAccessHomepage =
    AuthorizationService.hasPermission('canAccessHomepage');

  let siteCount = websiteList.sites.length;
  if (
    organizationSettings.boughtMonoMigration &&
    !organizationSettings.monoMigrationFinished
  ) {
    siteCount++;
  }

  const anySitesTrialing = websiteList.sites.some(
    (site) => site.isTrial || site.isLocked
  );
  const canCreateWebsite =
    canAccessHomepage &&
    hasHomePagePackage &&
    siteCount <= websiteList.sitesCap &&
    websiteList.sitesCap > 0;
  return (
    <>
      <Row gutter={[16, 16]}>
        {anySitesTrialing && (
          <Col span={24}>
            <Alert
              type="warning"
              message={
                <Space size={0}>
                  {gettextCatalog.getString(
                    'You have more sites than are currently included in the subscription. Delete one of your sites (deactivate the associated domain first) or buy more sites to keep all your sites.'
                  )}
                  <Button
                    onClick={openConnectToAccountManagerModal}
                    type="link"
                  >
                    {gettextCatalog.getString(
                      'Click here to buy an additional site.'
                    )}
                  </Button>
                </Space>
              }
            />
          </Col>
        )}
        {websiteList.sites.map((website) => (
          <WebsiteCard
            key={website?.id}
            website={website}
            websiteLoginLoading={websiteLoginLoading}
            goToMonoWebsite={goToMonoWebsite}
            deleteWebsite={deleteWebsite}
          />
        ))}
        {canEditDrupalWebsite && (
          <Col>
            <WebsiteCardDrupal />
          </Col>
        )}
        {organizationSettings?.boughtMonoMigration &&
          !organizationSettings?.monoMigrationFinished && (
            <Col>
              <MigratedWebsiteCard />
            </Col>
          )}
        {canCreateWebsite && (
          <Col>
            <WebsiteCardCreate
              onCap={websiteList.sites.length === websiteList.sitesCap}
            />
          </Col>
        )}
        <Col>
          <WebsiteCardBuyExtra />
        </Col>
      </Row>
    </>
  );
};
