import { FunctionComponent } from 'react';
import { map, toPath } from 'lodash';
import React from 'react';
import { Typography, Checkbox, Spin, Form } from 'antd';
import { useSelector } from 'react-redux';

import AuthorizationService from '../../../services/AuthorizationService';
import gettextCatalog from '../../../services/I18nService';
import { getCurrentCountryCode } from '../../../config/store/Selector';
const { Title } = Typography;

const AssignUserPermissions: FunctionComponent<{
  loadingUserRolesLoading?: boolean;
  fieldsDisabled?: boolean;
}> = ({ loadingUserRolesLoading = false, fieldsDisabled = false }) => {
  const hasUserAdminRole =
    AuthorizationService.hasPermission('canAdministerUsers');
  const checkBoxesDisabled = !hasUserAdminRole || fieldsDisabled;
  const countryCode = useSelector(getCurrentCountryCode);
  return (
    <>
      {map(tableOldRoles(countryCode), (tableOldRole, key) =>
        tableOldRole.hidden ? null : (
          <div key={key}>
            <Title level={3}>{tableOldRole.headerName}</Title>
            <Spin spinning={loadingUserRolesLoading}>
              {map(tableOldRole.tableSource, (record) => {
                const formItemProps: any = {
                  key: record.key,
                  name: getFormItemKeyForLegacyPermissions(record.key),
                  valuePropName: 'checked',
                  extra: (
                    <span style={{ marginLeft: 24 }}>{record.description}</span>
                  ),
                };
                if (record.dependencies) {
                  const keyOfDependency = getFormItemKeyForLegacyPermissions(
                    record.dependencies
                  );
                  formItemProps.dependencies = [keyOfDependency];
                  formItemProps.rules = [
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && !getFieldValue(keyOfDependency)) {
                          return Promise.reject(
                            <span style={{ marginLeft: 24 }}>
                              {record.dependencyMessage}
                            </span>
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ];
                }
                return (
                  <Form.Item {...formItemProps} style={{ minHeight: 0 }}>
                    <Checkbox disabled={checkBoxesDisabled}>
                      {record.title}
                    </Checkbox>
                  </Form.Item>
                );
              })}
            </Spin>
          </div>
        )
      )}
    </>
  );
};

const getFormItemKeyForLegacyPermissions = (key) =>
  ['oldRoles'].concat(toPath(key));

interface LegacyPermissionsElements {
  headerName: string;
  hidden: boolean;
  tableSource: {
    key: string | string[];
    title: string;
    description: string;
    suggestions?: any;
    dependencies?: string;
    dependencyMessage?: string;
    hidden?: boolean;
  }[];
}
const tableOldRoles = (countryCode: string): LegacyPermissionsElements[] => [
  {
    headerName: gettextCatalog.getString('Administrator'),
    hidden: false,
    tableSource: [
      {
        key: 'canAdministerOrganization',
        title: gettextCatalog.getString('Organization Administrator'),
        description: gettextCatalog.getString(
          'An organization administrator has access to billing, can manage all user permissions, people settings (incl. consents), calendar settings (resources, categories, etc.) and edit the website information.'
        ),
      },
    ],
  },
  // People
  {
    headerName: gettextCatalog.getString('People'),
    hidden: false,
    tableSource: [
      {
        key: 'canAccessPeople',
        title: gettextCatalog.getString('People access'),
        description: gettextCatalog.getString(
          'Users with access to People can view, add, edit and delete contacts, tags and lists. They can also send messages to people.'
        ),
        suggestions: [
          {
            text: gettextCatalog.getString('Recommended with Contribution.'),
            shown: () =>
              AuthorizationService.hasPermission('canAccessContributions') &&
              !AuthorizationService.hasPermission('canAccessPeople'),
          },
          {
            text: gettextCatalog.getString(
              'Recommended with Organization Administrator.'
            ),
            shown: () =>
              AuthorizationService.hasPermission('canAdministerOrganization') &&
              !AuthorizationService.hasPermission('canAccessPeople'),
          },
        ],
        hidden: !AuthorizationService.hasPackage('people'),
      },
      {
        dependencies: 'canAccessPeople',
        dependencyMessage: gettextCatalog.getString(
          'People access is required'
        ),
        key: 'people.sensitive',
        title: gettextCatalog.getString('Can access Sensitive Information'),
        description: gettextCatalog.getString(
          'Users with this permission can view sensitive contact information.'
        ),
      },
    ],
  },
  // Forms
  {
    headerName: gettextCatalog.getString('Forms'),
    hidden: !AuthorizationService.hasPackage('forms'),
    tableSource: [
      {
        key: 'forms.access',
        title: gettextCatalog.getString('Forms access'),
        description: gettextCatalog.getString(
          'Form access allows the user to create and manage forms, as well as view received responses.'
        ),
      },

      {
        key: 'forms.sensitive',
        dependencies: 'forms.access',
        dependencyMessage: gettextCatalog.getString('Forms access is required'),
        title: gettextCatalog.getString('Can access Sensitive Information'),
        description: gettextCatalog.getString(
          'Users with this permission can view sensitive information on form responses.'
        ),
      },
    ],
  },
  {
    // Calendar
    headerName: gettextCatalog.getString('Calendar'),
    hidden: false,
    tableSource: [
      {
        key: 'canAccessCalendar',
        title: gettextCatalog.getString('Can view Calendar'),
        description: gettextCatalog.getString(
          'Users with calendar access can view all bookings and resources for all events. Events can also be created and shared with groups which this user is a member of.'
        ),
        dependencies: 'canAccessCalendar',
        dependencyMessage: gettextCatalog.getString(
          'Calendar access is required'
        ),
      },
      {
        key: 'calendar.canCreateInternalEvent',
        title: gettextCatalog.getString('Can share events with all users'),
        description: gettextCatalog.getString(
          'The user will be able to create and share events across all groups.'
        ),
        dependencies: 'canAccessCalendar',
        dependencyMessage: gettextCatalog.getString(
          'Calendar access is required'
        ),
      },
      {
        key: 'calendar.canEditAllEventsGlobal',
        title: gettextCatalog.getString('Can edit all events'),
        description: gettextCatalog.getString(
          'The user will have permission to edit all events (except private).'
        ),
        dependencies: 'canAccessCalendar',
        dependencyMessage: gettextCatalog.getString(
          'Calendar access is required'
        ),
      },
      {
        key: 'calendar.canEditAllEventsLocal',
        title: gettextCatalog.getString('Can edit group events'),
        description: gettextCatalog.getString(
          'Users will only have permission to edit events shared with groups the user is a member of.'
        ),
        dependencies: 'canAccessCalendar',
        dependencyMessage: gettextCatalog.getString(
          'Calendar access is required'
        ),
      },
      {
        key: 'calendar.booking.canBook',
        title: gettextCatalog.getString('Can book and plan'),
        description: gettextCatalog.getString(
          'The user can book (users and resources) and assign tasks across all groups.'
        ),
        dependencies: 'canAccessCalendar',
        dependencyMessage: gettextCatalog.getString(
          'Calendar access is required'
        ),
        suggestions: [
          {
            text: gettextCatalog.getString(
              'Recommended with Can share events with all users.'
            ),
            shown: () =>
              AuthorizationService.hasPermission(
                'calendar.canCreateInternalEvent'
              ) &&
              !AuthorizationService.hasPermission('calendar.booking.canBook'),
          },
        ],
      },
      {
        key: 'calendar.booking.canForceDoubleBooking',
        dependencies: 'calendar.booking.canBook',
        dependencyMessage: gettextCatalog.getString(
          'You need to have Can book and plan permission to enable this.'
        ),
        title: gettextCatalog.getString('Can force double booking'),
        description: gettextCatalog.getString(
          'The user can force double booking of users and resources on events.'
        ),
        suggestions: [
          {
            text: gettextCatalog.getString(
              'Recommended with Can share events with all users.'
            ),
            shown: () =>
              AuthorizationService.hasPermission(
                'calendar.canCreateInternalEvent'
              ) &&
              !AuthorizationService.hasPermission(
                'calendar.booking.canForceDoubleBooking'
              ),
          },
        ],
      },
      {
        key: 'calendar.canViewAllAbsence',
        title: gettextCatalog.getString('Can view absences'),
        description: gettextCatalog.getString(
          'The user can see all absences for groups they are a member of.'
        ),
        dependencies: 'canAccessCalendar',
        dependencyMessage: gettextCatalog.getString(
          'Calendar access is required'
        ),
      },
      {
        key: 'calendar.canManageAbsence',
        title: gettextCatalog.getString('Can manage absences'),
        description: gettextCatalog.getString(
          'Users can create, edit, delete and see absences for all users across all groups. They can also manage absence type.'
        ),
        dependencies: 'canAccessCalendar',
        dependencyMessage: gettextCatalog.getString(
          'Calendar access is required'
        ),
      },
      {
        key: 'calendar.canAccessSensitiveInformation',
        title: gettextCatalog.getString('Can Access Sensitive Information'),
        description: gettextCatalog.getString(
          'Users with access to "sensitive information" can see, edit and delete sensitive information on events the user can view.'
        ),
        dependencies: 'canAccessCalendar',
        dependencyMessage: gettextCatalog.getString(
          'Calendar access is required'
        ),
      },
    ],
  },
  // Website
  {
    headerName: gettextCatalog.getString('Website'),
    hidden: false,
    tableSource: [
      {
        key: 'canAccessWebsite',
        title: gettextCatalog.getString('Can edit Website'),
        description: gettextCatalog.getString(
          'The user can access all tools concerning the Website. This includes changing the theme, and adding, editing and deleting pages.'
        ),
        suggestions: [
          {
            text: gettextCatalog.getString(
              'Recommended with Organization Administrator.'
            ),
            shown: () =>
              AuthorizationService.hasPermission('canAdministerOrganization') &&
              !AuthorizationService.hasPermission('canAccessWebsite'),
          },
        ],
        hidden: !AuthorizationService.hasPackage('homepage'),
      },
      {
        key: 'canPublish',
        title: gettextCatalog.getString('Can Publish'),
        description: gettextCatalog.getString(
          'The user can set events, files and blogs to public. This allows, for example, events to be shown on the Website.'
        ),
      },
    ],
  },
  // Payments & Giving
  {
    headerName: gettextCatalog.getString('Payments & Giving'),
    hidden: !AuthorizationService.hasPackage('contributions'),
    tableSource: [
      {
        key: 'canAccessContributions',
        title: gettextCatalog.getString('Can access Contribution'),
        description: gettextCatalog.getString(
          'Contributions access allows the user to create and manage projects, and view received contributions and transfers.'
        ),
        suggestions: [
          {
            text: gettextCatalog.getString(
              'Recommended with Organization Administrator.'
            ),
            shown: () =>
              AuthorizationService.hasPermission('canAdministerOrganization') &&
              !AuthorizationService.hasPermission('canAccessContributions'),
          },
        ],
      },
    ],
  },
  {
    // Graveyard
    headerName: gettextCatalog.getString('Graveyard'),
    hidden: !(
      (countryCode && countryCode.toLowerCase() === 'dk') ||
      AuthorizationService.hasPackage('graveyard')
    ),
    tableSource: [
      {
        key: 'canAccessGraveyard',
        title: gettextCatalog.getString('Can access Graveyard'),
        description: gettextCatalog.getString(
          'User can access the Graveyard app and create registrations.'
        ),
      },
      {
        key: ['graveyard', 'canAdministerGraveyard'],
        title: gettextCatalog.getString('Is Graveyard Administrator'),
        description: gettextCatalog.getString(
          'The Graveyard Administrator has access to setup up the Graveyard app, including managing graveyards.'
        ),
      },
    ],
  },
];

export default AssignUserPermissions;
