import { Button, Dropdown, MenuProps, Space } from 'antd';
import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { ManagementContactType } from './manage-contacts-drawer/ManageContactsDrawer';

import gettextCatalog from '@/react/services/I18nService';
import {
  CdAngleDown,
  CdChurch,
  CdParticipantList,
  CdTags,
} from '@/react/shared/components/Icons';

const ManageContactsDropDown = ({
  isDisabled,
  selectedPeople,
  isGlobalSelect,
  filter,
}) => {
  const showManageContactsDrawer = (
    type: ManagementContactType,
    selectedPeople,
    isGlobalSelect: boolean,
    filter
  ) => {
    NiceModal.show('ManageContactsDrawer', {
      type,
      selectedPeople,
      isGlobalSelect,
      filter,
    });
  };
  const items: MenuProps['items'] = [
    {
      key: 'manageTags',
      icon: <CdTags fixedWidth />,
      onClick: () =>
        showManageContactsDrawer(
          ManagementContactType.Tag,
          selectedPeople,
          isGlobalSelect,
          filter
        ),
      label: gettextCatalog.getString('Add/remove tags from contacts'),
    },
    {
      key: 'manageParishes',
      icon: <CdChurch fixedWidth />,
      onClick: () =>
        showManageContactsDrawer(
          ManagementContactType.Parish,
          selectedPeople,
          isGlobalSelect,
          filter
        ),
      label: gettextCatalog.getString('Add/remove contacts from parishes'),
    },
    {
      key: 'manageLists',
      icon: <CdParticipantList fixedWidth />,
      onClick: () =>
        showManageContactsDrawer(
          ManagementContactType.Newsletter,
          selectedPeople,
          isGlobalSelect,
          filter
        ),
      label: gettextCatalog.getString('Add/remove contacts from lists'),
    },
  ];
  return (
    <Dropdown
      key="create"
      menu={{ items }}
      placement="bottomLeft"
      disabled={isDisabled}
    >
      <Button>
        <Space>
          {gettextCatalog.getString('Manage contacts')}
          <CdAngleDown />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default ManageContactsDropDown;
