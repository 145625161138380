export const getCRMContactDetails = (language: string) => {
  let bookingPhone;
  const extra: { multiParishLearnMoreUrl?: string } = {};
  // Phone
  switch (language) {
    case 'da':
      bookingPhone = '7070 2670';
      break;
    case 'sv':
      bookingPhone = '+45 7070 2670';
      break;
    case 'de':
      bookingPhone = '+49 30 568373604';
      break;
    default:
      bookingPhone = '+44 20 3808 5097';
      break;
  }
  return { bookingPhone, extra };
};
