import React from 'react';
import { Button } from 'antd';

import getTextCatalog from '../../../services/I18nService';

import {
  CdExclamationTriangleIcon,
  CdFormRemove,
} from '@/react/shared/components/Icons';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

const RemoveFormFromEvent = ({
  onOk,
  canEdit,
}: { onOk: () => void; canEdit: boolean }) => {
  const showDeleteConfirm = () => {
    showConfirmModalDeprecated({
      title: (
        <strong>
          {getTextCatalog.getString(
            'Are you sure you want to remove the form from the event?'
          )}
        </strong>
      ),
      message: (
        <>
          {getTextCatalog.getString(
            'The form will still exist in the forms module, but you will not be able to see any information related to the form on this event.'
          )}
        </>
      ),
      icon: <CdExclamationTriangleIcon />,
      onOk,
    });
  };

  return (
    <Button
      disabled={!canEdit}
      icon={<CdFormRemove />}
      onClick={showDeleteConfirm}
    >
      {getTextCatalog.getString('Remove form from event')}
    </Button>
  );
};

export default RemoveFormFromEvent;
