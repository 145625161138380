import {
  Alert,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Row,
  Switch,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { cloneDeep, merge } from 'lodash';

import { WidgetResourceLocation } from '../../WidgetResourceLocation';
import getTextCatalog from '../../../../services/I18nService';
import usePortalWidget from '../../hooks/use-portal-widget';
import { EventStylePreview } from '../EventStylePreview';
import { WidgetInterface } from '../../types/widget.type';

import { CdEventCategorySelect } from '@/react/calendar/event-details/components/CdEventCategorySelect';
import { CdImagePicker } from '@/react/calendar/event-details/components/CdImagePicker';
import { CdrColorPicker } from '@/react/shared/components/cd-color-picker/CdrColorPicker';
import { useOrganization } from '@/react/organization/hooks/useOrganization';
import { PortalCategoryType } from '@/react/portal/state/portal';
import { Organization } from '@/react/portal/types/portal.type';
import CdSelect from '@/react/shared/components/cd-select/CdSelect';

const { RangePicker } = DatePicker;

export default function EventWidgetForm({
  widgetData,
  widgetForm,
}: {
  widgetData: WidgetInterface;
  widgetForm: FormInstance;
}) {
  const { organizationSettings } = useOrganization();
  const {
    organizationPortals,
    selectedPortal,
    onFormValuesChange,
    timeRangeType,
    onTimeRangeTypeChange,
  } = usePortalWidget(widgetData, widgetForm);

  const [includeFrom, changeIncludeFrom] = useState(
    widgetData?.configuration?.collaboration
      ? 'portalCollaboration'
      : 'ownOrganization'
  );

  const initialFormValues = React.useMemo(() => {
    const initialData = {
      customizeColorScheme:
        widgetData?.configuration?.cardColor ||
        widgetData?.configuration?.primaryColor
          ? true
          : false,
      configuration: {
        primaryColor: organizationSettings.primaryColor,
        cardColor: organizationSettings.cardColor,
        collaboration: {
          portalId: selectedPortal?.id,
          portalCategoryIds: [],
          organizationIds: [],
        },

        // Unique to Event Widget
        pageSize: 5,
        showImage: true,
        showUserFiltering: false,
      },
    };

    const clonedWidgetData = cloneDeep(widgetData);

    return merge(initialData, clonedWidgetData);
  }, [
    selectedPortal?.id,
    organizationSettings.cardColor,
    organizationSettings.primaryColor,
    widgetData,
  ]);

  return (
    <Form
      form={widgetForm}
      layout="vertical"
      initialValues={initialFormValues}
      onValuesChange={onFormValuesChange}
      style={{ marginTop: 30 }}
    >
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: getTextCatalog.getString('The field is required.'),
          },
        ]}
        label={getTextCatalog.getString('Widget name')}
        extra={getTextCatalog.getString('Internal use only.')}
      >
        <Input size="large" />
      </Form.Item>

      <Divider />

      <Typography.Title level={2} style={{ marginBottom: '20px' }}>
        {getTextCatalog.getString('Events to include in the feed')}
      </Typography.Title>

      {!widgetData?.legacy ? (
        <>
          <Form.Item label={getTextCatalog.getString('Include events from')}>
            <Radio.Group
              value={includeFrom}
              onChange={(e) => changeIncludeFrom(e.target.value)}
            >
              <Radio value="ownOrganization">
                {getTextCatalog.getString('Own calendar')}
              </Radio>
              <Radio
                value="portalCollaboration"
                disabled={
                  selectedPortal === undefined || selectedPortal === null
                }
              >
                {getTextCatalog.getString('Portal community')}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            shouldUpdate={(prevValues, currentValue) =>
              prevValues.configuration.includeFrom !==
              currentValue.configuration.includeFrom
            }
            noStyle
          >
            {includeFrom === 'ownOrganization' ? (
              <>
                <Form.Item
                  name={['configuration', 'resourceIds']}
                  label={getTextCatalog.getString('Locations')}
                  extra={getTextCatalog.getString(
                    'Leave empty to include all event locations.'
                  )}
                >
                  <WidgetResourceLocation />
                </Form.Item>

                <Form.Item
                  name={['configuration', 'categoryIds']}
                  label={getTextCatalog.getString('Event categories')}
                  extra={getTextCatalog.getString(
                    'Leave empty to include all event categories.'
                  )}
                >
                  <CdEventCategorySelect
                    type={PortalCategoryType.EVENT}
                    mode="multiple"
                    placeholder={getTextCatalog.getString('Please select')}
                  />
                </Form.Item>
              </>
            ) : (
              includeFrom === 'portalCollaboration' && (
                <>
                  <Form.Item
                    label={getTextCatalog.getString('Select portal community')}
                    name={['configuration', 'collaboration', 'portalId']}
                  >
                    <CdSelect
                      placeholder={getTextCatalog.getString('Please select')}
                      options={organizationPortals?.portals?.map((portal) => ({
                        label: portal.name,
                        value: portal.id,
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    shouldUpdate={(prevValues, currentValue) =>
                      prevValues.configuration?.collaboration?.portalId !==
                      currentValue.configuration?.collaboration?.portalId
                    }
                    noStyle
                  >
                    {({ getFieldValue }) =>
                      getFieldValue([
                        'configuration',
                        'collaboration',
                        'portalId',
                      ]) && (
                        <>
                          <Form.Item
                            label={getTextCatalog.getString(
                              'Select portal categories'
                            )}
                            name={[
                              'configuration',
                              'collaboration',
                              'portalCategoryIds',
                            ]}
                            extra={getTextCatalog.getString(
                              'Leave empty to include all portal categories.'
                            )}
                          >
                            <CdSelect
                              mode="multiple"
                              placeholder={getTextCatalog.getString(
                                'Please select'
                              )}
                              maxTagCount="responsive"
                              options={selectedPortal?.categories
                                .filter(
                                  (category) =>
                                    category.type ===
                                      PortalCategoryType.EVENT ||
                                    category.type === null
                                )
                                .map((category) => ({
                                  value: category.id,
                                  label: category.name,
                                }))}
                            />
                          </Form.Item>

                          <Form.Item
                            label={getTextCatalog.getString(
                              'Include events from these organizations'
                            )}
                            name={[
                              'configuration',
                              'collaboration',
                              'organizationIds',
                            ]}
                            extra={getTextCatalog.getString(
                              'Leave empty to include all organizations.'
                            )}
                          >
                            <CdSelect
                              mode="multiple"
                              placeholder={getTextCatalog.getString(
                                'Please select'
                              )}
                              maxTagCount="responsive"
                              options={selectedPortal?.organizations.map(
                                (organization: Organization) => ({
                                  value: organization.id,
                                  label: organization.name,
                                })
                              )}
                            />
                          </Form.Item>
                        </>
                      )
                    }
                  </Form.Item>
                </>
              )
            )}
          </Form.Item>
        </>
      ) : (
        <Alert
          type="info"
          message={getTextCatalog.getString(
            'This is a legacy widget. You cannot change its contents.'
          )}
        />
      )}

      <Form.Item
        label={getTextCatalog.getString('Time range')}
        style={{ marginBottom: '8px' }}
      >
        <Radio.Group
          value={timeRangeType}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          onChange={(e) => onTimeRangeTypeChange(e.target.value)}
        >
          <Radio value="all">
            {getTextCatalog.getString('All upcoming events')}
          </Radio>
          <Radio value="days">
            {getTextCatalog.getString('Number of days ahead')}
          </Radio>
          <Radio value="dateRange">
            {getTextCatalog.getString('Date range')}
          </Radio>
        </Radio.Group>
      </Form.Item>

      {timeRangeType === 'days' && (
        <Form.Item
          name={['configuration', 'timeRange']}
          rules={[
            {
              required: true,
              message: getTextCatalog.getString(
                'It is a numeric field and minimum 1 day is required'
              ),
            },
          ]}
          extra={getTextCatalog.getString(
            'Number of days ahead to show events from.'
          )}
        >
          <InputNumber min={1} style={{ width: '100%' }} />
        </Form.Item>
      )}
      {timeRangeType === 'dateRange' && (
        <Row justify="space-between">
          <Col span={24}>
            <Form.Item
              name={['configuration', 'timeRange']}
              rules={[
                {
                  required: true,
                  message: getTextCatalog.getString('The field is required.'),
                },
              ]}
            >
              <RangePicker
                style={{ width: '100%' }}
                format={getTextCatalog.getLongDateFormat()}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Divider />

      <Typography.Title level={2} style={{ marginBottom: '20px' }}>
        {getTextCatalog.getString('Style of event feed')}
      </Typography.Title>

      <Form.Item
        rules={[
          {
            type: 'integer',
          },
          {
            required: true,
            message: getTextCatalog.getString('The field is required.'),
          },
        ]}
        name={['configuration', 'pageSize']}
        label={getTextCatalog.getString('Number of events per page')}
      >
        <InputNumber max={20} min={1} style={{ width: '100%' }} />
      </Form.Item>

      <HorizontalFormItem
        name={['configuration', 'showLocationAddress']}
        valuePropName="checked"
        label={getTextCatalog.getString('Show location address')}
      >
        <Switch />
      </HorizontalFormItem>

      <HorizontalFormItem
        name={['configuration', 'showImage']}
        valuePropName="checked"
        label={getTextCatalog.getString('Show event image')}
      >
        <Switch />
      </HorizontalFormItem>

      <Form.Item
        shouldUpdate={(prevValues, currentValue) =>
          prevValues.configuration?.showImage !==
          currentValue.configuration?.showImage
        }
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue(['configuration', 'showImage']) && (
            <Card
              bodyStyle={{ paddingBottom: '0px' }}
              style={{ marginBottom: '16px' }}
            >
              <Form.Item
                name={['configuration', 'fallBackImageId']}
                label={getTextCatalog.getString('Choose default image')}
                extra={getTextCatalog.getString(
                  'Default image if there is no image available for an event'
                )}
              >
                <CdImagePicker
                  entityId={widgetData?.id}
                  entityType="Widgetevent"
                  canEdit={true}
                  defaultImageUrl="https://assets.churchdesk.com/widgetDefaultImage/Calendar_fallback_image.jpg?class=large"
                  canCrop={false}
                />
              </Form.Item>
            </Card>
          )
        }
      </Form.Item>

      <HorizontalFormItem
        name={['configuration', 'showUserFiltering']}
        valuePropName="checked"
        label={getTextCatalog.getString('Allow visitors to filter')}
      >
        <Switch />
      </HorizontalFormItem>

      <HorizontalFormItem
        name="customizeColorScheme"
        valuePropName="checked"
        label={getTextCatalog.getString('Customize color scheme')}
      >
        <Switch />
      </HorizontalFormItem>

      <Form.Item
        shouldUpdate={(prevValues, currentValue) =>
          prevValues.customizeColorScheme !== currentValue.customizeColorScheme
        }
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue('customizeColorScheme') && (
            <Card bodyStyle={{ paddingBottom: '0px' }}>
              <Form.Item
                name={['configuration', 'primaryColor']}
                label={getTextCatalog.getString('Primary color')}
              >
                <CdrColorPicker
                  defaultColor={organizationSettings.primaryColor}
                />
              </Form.Item>

              <Form.Item
                name={['configuration', 'cardColor']}
                label={getTextCatalog.getString('Card background color')}
              >
                <CdrColorPicker
                  transparentControls={false}
                  defaultColor={organizationSettings.cardColor}
                />
              </Form.Item>
            </Card>
          )
        }
      </Form.Item>

      <Form.Item
        shouldUpdate
        label={getTextCatalog.getString('Preview')}
        style={{ marginTop: '16px' }}
      >
        {({ getFieldValue }) => (
          <EventStylePreview
            primaryColor={getFieldValue('configuration').primaryColor}
            cardColor={getFieldValue('configuration').cardColor}
            showEventImage={getFieldValue('configuration')?.showImage}
            pageSize={getFieldValue('configuration').pageSize}
            settings={organizationSettings}
          />
        )}
      </Form.Item>
    </Form>
  );
}

const HorizontalFormItem = styled(Form.Item).attrs({
  wrapperCol: { span: 8 },
  labelCol: { span: 16 },
})`
  &&&& {
    margin-bottom: 8px;
    .ant-form-item-row {
      flex-direction: row;

      .ant-form-item-label {
        margin: auto;
        padding-bottom: 0;
      }
      .ant-form-item-control {
        align-items: end;
      }
    }
  }
`;
