import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import {
  CalendarPosterExportFormat,
  CalendarPosterPDFExportSize,
} from '@/react/calendar/types/event';
import cdApp from '@/react/config';
import {
  CdPrint,
  CdPoster,
  CdAngleDown,
} from '@/react/shared/components/Icons';
import gettextCatalog from '@/react/services/I18nService';

// Is used when Menu.Item wraps a <Button type="link">
const MenuItemNoPadding = styled(Menu.Item)`
  &&&& {
    padding: 0;
  }
`;

const calendarPosterExportUrl = (
  calendarId: number,
  exportToken: string,
  {
    format,
    pdfSize,
  }: {
    format: CalendarPosterExportFormat;
    pdfSize?: CalendarPosterPDFExportSize;
  }
) => {
  switch (format) {
    case CalendarPosterExportFormat.PDF:
      return `${cdApp.config.api.main}/calendar/${calendarId}/poster/export?format=${format}&pdfSize=${pdfSize}&exportToken=${exportToken}`;
    case CalendarPosterExportFormat.JPG:
      return `${cdApp.config.api.main}/calendar/${calendarId}/poster/export?format=${format}&exportToken=${exportToken}`;
  }
};

export const CalendarPosterPrintDropDown = ({
  calendarId,
  exportToken,
  buttonSize,
}: {
  calendarId: number;
  exportToken: string;
  buttonSize: SizeType;
}) => {
  const menu = (
    <Menu>
      <MenuItemNoPadding key="pdfA3">
        <Button
          icon={<CdPrint />}
          type="link"
          href={calendarPosterExportUrl(calendarId, exportToken, {
            format: CalendarPosterExportFormat.PDF,
            pdfSize: CalendarPosterPDFExportSize.A3,
          })}
        >
          {gettextCatalog.getString('Print A3')}
        </Button>
      </MenuItemNoPadding>
      <MenuItemNoPadding key="pdfA4">
        <Button
          icon={<CdPrint />}
          type="link"
          href={calendarPosterExportUrl(calendarId, exportToken, {
            format: CalendarPosterExportFormat.PDF,
            pdfSize: CalendarPosterPDFExportSize.A4,
          })}
        >
          {gettextCatalog.getString('Print A4')}
        </Button>
      </MenuItemNoPadding>
      <MenuItemNoPadding key="image">
        <Button
          icon={<CdPoster />}
          type="link"
          href={calendarPosterExportUrl(calendarId, exportToken, {
            format: CalendarPosterExportFormat.JPG,
          })}
        >
          {gettextCatalog.getString('Download Image')}
        </Button>
      </MenuItemNoPadding>
    </Menu>
  );

  return (
    <Dropdown key="print" overlay={menu}>
      <Button icon={<CdPrint />} size={buttonSize}>
        {gettextCatalog.getString('Print')} <CdAngleDown />
      </Button>
    </Dropdown>
  );
};
