import React from 'react';
import styled from 'styled-components';
import { Upload } from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';

import NotificationService from '@/react/services/NotificationService';
import gettextCatalog from '@/react/services/I18nService';
interface UploadFileProps {
  value?: IUploadFile[];
  onChange?: (value: IUploadFile[]) => void;
  uploadFile: (args: UploadRequestOption) => Promise<any>;
  deleteFile?: (file: IUploadFile) => Promise<boolean>;
  disabled?: boolean;
  children?: React.ReactNode | React.ReactNodeArray;
  multiple?: boolean;
  /**
   * @field {number} the allowed filesize in megabytes
   */
  maxFileSize?: number;
  allowedFileExtensions?: string[];
  showUploadList?: boolean;
  maxCount?: number;
}

const UploadContainer = styled(Upload.Dragger)`
  &&&& {
    .ant-upload-list {
      display: flex;
      flex-flow: wrap;
    }
    .ant-upload-list-item-container {
      width: 100%;
    }
  }
`;

const validateSize = (sizeInK: number, maxSizeInMb: number) =>
  sizeInK / 1024 / 1024 < maxSizeInMb;

const validateExtension = (
  fileExtension: string,
  allowedExtensions: string[]
) => allowedExtensions.includes(fileExtension.split('/')[1].toUpperCase());

export default function UploadFile({
  children = null,
  deleteFile,
  disabled = false,
  multiple = false,
  onChange,
  maxFileSize,
  showUploadList = false,
  uploadFile,
  value,
  allowedFileExtensions,
  maxCount,
}: UploadFileProps) {
  if (!onChange && !value) {
    return null;
  }

  const customRequest = ({
    file,
    onError,
    ...args
  }: UploadRequestOption & { file: IUploadFile }) => {
    let errorMessage: string;
    if (
      allowedFileExtensions?.length &&
      !validateExtension(file.type, allowedFileExtensions)
    ) {
      errorMessage = gettextCatalog.getString('The file type is not allowed.');
    }
    if (maxFileSize && !validateSize(file.size, maxFileSize)) {
      errorMessage = gettextCatalog.getString(
        'The file is larger than the allowed size of {{ maxFileSize }}mb.',
        { maxFileSize }
      );
    }
    if (errorMessage) {
      NotificationService.notifyError(errorMessage);
      return onError(new Error(errorMessage));
    }
    return uploadFile({ ...args, file, onError });
  };

  return (
    <UploadContainer
      multiple={multiple}
      fileList={value}
      showUploadList={
        showUploadList && {
          showPreviewIcon: true,
          showDownloadIcon: false,
          showRemoveIcon: true,
        }
      }
      maxCount={maxCount}
      onRemove={deleteFile}
      customRequest={customRequest}
      disabled={disabled}
      onChange={(opt) => {
        const { fileList } = opt;
        const newFileList = (fileList as any).reduce((accumulator, file) => {
          if (file.status === 'error') return accumulator;
          if (!file.url) {
            file.url = file?.response?.url;
          }
          if (!file.id) {
            file.id = file?.response?.id;
          }
          accumulator.push(file);
          return accumulator;
        }, []);
        onChange(newFileList);
      }}
    >
      {children}
    </UploadContainer>
  );
}
