import { Form } from 'antd';
import React, { useEffect } from 'react';

import gettextCatalog from '../../services/I18nService';
import { useTimeBalance } from '../hooks/useTimeBalance';

import { TimeRegistrationUserSettings } from './TimeRegistrationUserSettings';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';

export const showUserTimeRegistrationModal = createCdModal<{
  currentUserId: number;
  userName: string;
  userEmail: string;
  timeRegistrationEnabled: boolean;
  initialTimeBalance: number;
}>(
  ({
    setModalProps,
    currentUserId,
    userName,
    userEmail,
    timeRegistrationEnabled,
    initialTimeBalance,
  }) => {
    const {
      onOk,
      isValueChanged,
      timeBalanceForm,
      organizationSettings,
      formValueChanged,
      timeBalanceFormInitialValue,
    } = useTimeBalance({
      currentUserId,
      timeRegistrationEnabled,
      initialTimeBalance,
    });

    const timeRegistrationCapError = timeBalanceForm.getFieldError([
      'timeRegistrationEnabled',
    ]);

    useEffect(() => {
      setModalProps({
        modalType: ModalType.MODAL,
        title: gettextCatalog.getString('Time registration for {{name}}', {
          name: userName || userEmail,
        }),
        width: 500,
        okText: gettextCatalog.getString('Save'),
        onOk,
        okButtonProps: {
          disabled: !isValueChanged || timeRegistrationCapError.length > 0,
        },
      });
    }, [
      setModalProps,
      userName,
      isValueChanged,
      onOk,
      userEmail,
      timeRegistrationCapError.length,
    ]);

    const hasNoSeatsLeft =
      organizationSettings.noOfEnabledTimeRegistrations >=
        organizationSettings.timeRegistrationCap && !timeRegistrationEnabled;

    return (
      <Form
        form={timeBalanceForm}
        onValuesChange={formValueChanged}
        initialValues={timeBalanceFormInitialValue}
        style={{ paddingTop: 16 }}
        colon={false}
        layout="vertical"
      >
        <TimeRegistrationUserSettings
          noOfEnabledTimeRegistrations={
            organizationSettings?.noOfEnabledTimeRegistrations
          }
          timeRegistrationCap={organizationSettings?.timeRegistrationCap}
          initialTimeBalance={timeBalanceFormInitialValue.initialTimeBalance}
          hasNoSeatsLeft={hasNoSeatsLeft}
        />
      </Form>
    );
  }
);
