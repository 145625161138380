import { selector, selectorFamily } from 'recoil';
import { useSuspenseQuery } from '@tanstack/react-query';

import { ConsentType, ContributionProject } from '../types/contribution';
import { PaymentProjectStats } from '../types/payment-project-stats';

import { registerRecoilRefresher } from '@/app/cdRecoilRefresher';
import { handleError } from '@/react/services/ErrorHandlingService';
import { contributionsApi, mainApi } from '@/react/api';
import ResourceService from '@/react/shared/services/ResourceService';

export const QRCodeQuery = selector({
  key: 'QRCodeQuery',
  get: async () => {
    const res = await contributionsApi.get<string>(
      'contributions/qrcode?type=landingPage'
    );
    if (!res.ok) {
      handleError(res);
      throw res;
    }
    return res.data;
  },
});

export const QRCodeAliasQuery = selectorFamily<string, string>({
  key: 'QRCodeAliasQuery',
  get: (alias: string) => async () => {
    const response = await contributionsApi.get('/contributions/qrcode', {
      contributionAlias: alias,
    });
    if (response.ok) {
      return response.data as string;
    }
    throw response.data;
  },
});

export const PosterTokenQuery = selectorFamily<string, { contributionId }>({
  key: 'PosterTokenQuery',
  get:
    ({ contributionId }) =>
    async () => {
      const response = await mainApi.get<{ token: string }>(
        `/p/contributions/${contributionId}/poster/token`
      );
      if (response.ok) {
        return response.data.token;
      } else {
        throw response.data;
      }
    },
});

export const GetCurrentContributionProject = selectorFamily<
  ContributionProject,
  string
>({
  key: 'GetCurrentContributionProject',
  get:
    (contributionId: string) =>
    async ({ getCallback }) => {
      const response = await contributionsApi.get<ContributionProject>(
        `/contributions/${contributionId}`
      );

      registerRecoilRefresher(
        GetCurrentContributionProject(contributionId),
        getCallback(
          ({ refresh }) =>
            () =>
              refresh(GetCurrentContributionProject(contributionId))
        )
      );

      if (response.ok) {
        return response.data;
      } else {
        throw response.data;
      }
    },
});

export const GetCurrentContributionStats = selectorFamily<
  PaymentProjectStats,
  string
>({
  key: 'GetCurrentContributionStats',
  get: (contributionId: string) => async () => {
    const response = await contributionsApi.get<PaymentProjectStats>(
      `/contributions/${contributionId}/stats`
    );
    if (response.ok) {
      return response.data;
    } else {
      throw response.data;
    }
  },
});

export const getChurchesV3 = async () => {
  try {
    const res = await ResourceService.getChurchesV3('contribution', 'access');
    return res.churches;
  } catch (err) {
    handleError(err);
  }
};

export const getConsentTypes = async () => {
  try {
    const response = await mainApi.get<ConsentType[]>(`/people/consent-types`);
    if (response.ok) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (err) {
    handleError(err);
  }
};

export const useConsentTypes = () =>
  useSuspenseQuery({
    queryKey: ['useConsentTypes'],
    queryFn: async () => {
      const response = await mainApi.get<ConsentType[]>(
        `/people/consent-types`
      );
      if (response.ok) {
        return response.data;
      } else {
        handleError(response.data as any);
        throw response.data;
      }
    },
  });

export const useChurchesV3 = () =>
  useSuspenseQuery({
    queryKey: ['useChurchesV3'],
    queryFn: getChurchesV3,
  });
