import { AutoComplete, Input, Space } from 'antd';
import moment, { Moment } from 'moment';
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import getTextCatalog from '@/react/services/I18nService';

const isTimeValid = (value: string, displayFormat: string) =>
  moment(`2022-01-01 ${value}`, `YYYY-MM-DD ${displayFormat}`, true).isValid();

export const CdTimePicker = (props: {
  id: string;
  value?: string;
  onChange?: any;
  beginLimit?: string;
  endLimit?: string;
  step?: number;
  isInvalid?: boolean;
  diffStartDate?: Moment;
  diffEndDate?: Moment;
  disabled?: boolean;
}) => {
  const displayFormat = moment.localeData().longDateFormat('LT');

  const valueInLocalFormat = useCallback(
    (inputValue) =>
      moment(`2022-01-01 ${inputValue}`, `YYYY-MM-DD HH:mm`, true).format(
        displayFormat
      ),
    [displayFormat]
  );
  const valueInStandardFormat = (inputValue) =>
    moment(
      `2022-01-01 ${inputValue}`,
      `YYYY-MM-DD ${displayFormat}`,
      true
    ).format('HH:mm');
  const endLimit = props.endLimit || '23:59';
  const step = props.step || 30;
  const beginLimit = props.beginLimit || '06:00';
  const [localValue, setLocalValue] = useState(valueInLocalFormat(props.value));

  useEffect(() => {
    setLocalValue(valueInLocalFormat(props.value));
    setSearchValue(valueInLocalFormat(props.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, valueInLocalFormat]);

  useEffect(() => {
    setSearchValue(valueInLocalFormat(props.value));
  }, [props.value, valueInLocalFormat]);

  const options = useMemo(
    () =>
      getTimeSelectionOptions({
        beginLimit,
        displayFormat,
        step,
        endLimit,
        diffStartDate: props.diffStartDate,
        diffEndDate: props.diffEndDate,
      }),
    [
      beginLimit,
      displayFormat,
      step,
      endLimit,
      props.diffStartDate,
      props.diffEndDate,
    ]
  );

  const isInvalid = props.isInvalid || !isTimeValid(localValue, displayFormat);

  const onChange = (value) => {
    const correctedValue = value.replace(/[^\d.:PMpmAMam ]/g, '');
    setLocalValue(correctedValue);
    if (isTimeValid(correctedValue, displayFormat)) {
      props.onChange(valueInStandardFormat(correctedValue));
      setSearchValue(localValue);
      setOpen(false);
    }
  };

  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);

  const handleSearch = (value) => {
    setSearchValue(value);
    const possibleValue = moment(
      `2022-01-01 ${value}`,
      `YYYY-MM-DD LT a`,
      false
    ).format(displayFormat);
    setLocalValue(possibleValue);
  };

  const onInputBlur = () => {
    // check all possible conditions || /[a-zA-Z]/.test(searchValue)
    if (searchValue === '') {
      setOpen(false);
      setSearchValue(valueInLocalFormat(props.value));
    } else if (searchValue === valueInLocalFormat(props.value)) {
      setTimeout(() => {
        setOpen(false);
      }, 500);
    } else if (!isTimeValid(localValue, displayFormat)) {
      setOpen(false);
      setSearchValue(valueInLocalFormat(props.value));
    } else {
      onChange(localValue);
    }
  };

  return (
    <Space size={0}>
      <AutoComplete
        aria-label={`Time picker for ${props.id}`}
        options={options}
        open={open}
        style={{ width: 100 }}
        value={localValue}
        onSelect={(value) => onChange(value)}
        popupMatchSelectWidth={150}
        status={isInvalid ? 'error' : undefined}
        backfill={true}
        optionFilterProp="label"
        tabIndex={-1}
        getPopupContainer={(trigger) => trigger.parentNode}
      />
      <Input
        aria-label={`Time input for ${props.id}`}
        value={searchValue}
        onChange={(e) => handleSearch(e.target.value)}
        style={{ marginLeft: '-100px', width: '100px', zIndex: 999 }}
        onBlur={onInputBlur}
        onFocus={() => setOpen(true)}
        disabled={props?.disabled}
      />
    </Space>
  );
};

const getTimeSelectionOptions = ({
  beginLimit,
  displayFormat,
  step,
  endLimit,
  diffStartDate,
  diffEndDate,
}) => {
  let timeValue = beginLimit;
  let lastValue;
  const getLabel = () => moment(timeValue, 'HH:mm').format(displayFormat);
  const options = [
    {
      label: getLabel(),
      value: getLabel(),
    },
  ];
  const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
    const timeValueIsEarlier =
      moment(timeValue, 'HH:mm')
        .add(step, 'minutes')
        .diff(moment(endLimit, 'HH:mm')) < 0;
    const timeValueIsLaterThanLastValue =
      lastValue === undefined
        ? true
        : moment(lastValue, 'HH:mm').diff(moment(timeValue, 'HH:mm')) < 0;
    return timeValueIsEarlier && timeValueIsLaterThanLastValue;
  };

  const getDiffLabel = () => {
    if (diffStartDate) {
      const endDateWithTimeValue = moment(
        `${diffEndDate.format('YYYY-MM-DD')} ${timeValue}`,
        'YYYY-MM-DD HH:mm'
      );

      const diffMinutes = endDateWithTimeValue.diff(diffStartDate, 'minutes');
      const labelMinutes = getTextCatalog.getString('{{diffMinutes}} mins', {
        diffMinutes,
      });
      const labelHours = getTextCatalog.getPlural(
        diffMinutes / 60,
        '1 hr',
        '{{diffHours}} hrs',
        { diffHours: diffMinutes / 60 }
      );
      if (diffStartDate.day() !== diffEndDate.day()) return '';
      if (diffMinutes > 0 && diffMinutes < 60) return ` (${labelMinutes})`;
      if (diffMinutes >= 60) return ` (${labelHours})`;
    }
    return '';
  };

  while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
    lastValue = timeValue;
    timeValue = moment(timeValue, 'HH:mm').add(step, 'minutes').format('HH:mm');
    options.push({
      label: getLabel() + getDiffLabel(),
      value: getLabel(),
    });
  }
  return options;
};
