/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  DatePicker,
  Input,
  Button,
  Form,
  Row,
  Col,
  Select,
  InputNumber,
  Card,
  Descriptions,
  Space,
  Checkbox,
} from 'antd';

import { IntentionFee } from '../../../settings/models/fee';
import { Resource } from '../../../shared/models/resource';
import gettextCatalog from '../../../services/I18nService';
import { PaymentMethodTypes } from '../../models/intention';
import { getStiftung } from '../../redux/foundations/Selectors';
import FoundationService from '../../services/FoundationService';
import { getIntentionFees } from '../../../settings/redux/intention-fees/Selectors';
import { getFilteredFees } from '../../../settings/redux/intention-fees/data.converter';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import IntentionsOverview from '../../components/intentions/IntentionsOverview';
import { getChurches, getResources } from '../../../shared/store/resources';
import {
  Foundation,
  FoundationFields,
  FoundationStatusTypes,
  FoundationTypes,
} from '../../models/foundation';
import StateServiceFactory, {
  StateParamsServiceFactory,
} from '../../../services/StateServiceFactory';
import {
  clearStiftung,
  createFoundation,
  fetchFoundation,
  generateFoundationIntentionsReport,
  updateFoundation,
} from '../../redux/foundations/Actions';
import { paymentMethods } from '../../shared/intentionCommon';

// Antd
const { TextArea } = Input;
const { Option } = Select;

interface CreateStiftungStateParam {
  currentState: string;
  id: string;
}

const CreateStiftung: FunctionComponent = () => {
  const dispatch = useDispatch();

  const stateService = StateServiceFactory();
  const stateParams = StateParamsServiceFactory<CreateStiftungStateParam>();
  const currentState = stateParams.currentState;
  const stiftungId = stateParams.id;
  const [form] = Form.useForm();

  const datePickerFormat = gettextCatalog.getLongDateFormat();

  // Define labels
  const submitButtonLabel =
    currentState === 'create'
      ? gettextCatalog.getString('Save')
      : gettextCatalog.getString('Update');

  // Retrieve required entities from Redux store
  const resources = useSelector(getResources);
  const churches = useSelector(getChurches);
  const allIntentionFees = useSelector(getIntentionFees);
  const intentionFees: IntentionFee[] = getFilteredFees(allIntentionFees);
  /**
   * TODO: Consider passing on to this view, the stiftung to be viewed/updated, since all of its required data
   * has already been fetched when listing stiftungen. In this way, an unnecessary asynchronous call to the
   * backend can be avoided, the first "useEffect" block can be avoided, and the logic of the "useEffect" block
   * down below (the one listening on changes of the stiftung) can be simplified.
   */
  const _stiftung = useSelector(getStiftung);
  const stiftung = _stiftung && _stiftung.asMutable({ deep: true });

  // Define initialization hook and entity reload hooks
  const [churchResources, setChurchResources] = useState<Resource[]>([]);
  const [church, setChurch] = useState<Resource>();

  const [shouldShowPermissionAlert, setShouldShowPermissionAlert] =
    useState<boolean>(false);

  // Retrieve required entities
  useEffect(() => {
    // Initialize stiftung data in case of creation or update/view
    if (currentState === 'create') {
      dispatch(clearStiftung());
    } else {
      dispatch(
        fetchFoundation({ type: FoundationTypes.STIFTUNGEN, id: stiftungId })
      );
    }
    // Cleanup function to be executed on component un-mounting
    return () => {
      dispatch(clearStiftung());
    };
  }, []);

  // Update the form with the retrieved intention (update/view)
  useEffect(() => {
    if (currentState !== 'create' && stiftung) {
      setShouldShowPermissionAlert(
        stiftung.status === FoundationStatusTypes.INACTIVE &&
          stiftung.intentionsCount > 0
      );

      loadChurchDependentEntities(stiftung.churchId);

      form.setFieldsValue({
        nextReferenceNumber: _.get(stiftung, 'formattedReferenceNumber'),
        preferredDate: stiftung.preferredDate
          ? moment(stiftung.preferredDate, 'YYYY-MM-DD')
          : null,
        preferredNote: stiftung.preferredNote,
        intentionText: stiftung.intentionText,
        internalNote: stiftung.internalNote,
        startBalance: stiftung.startBalance,
        paymentMethod: stiftung.paymentMethod,
        paid: stiftung.paid,
        paidAt: stiftung.paidAt ? moment(stiftung.paidAt) : null,
        totalYears: stiftung.totalYears,
        intentionsPerYear: stiftung.intentionsPerYear,
        startYear: stiftung.startYear
          ? moment().year(stiftung.startYear)
          : null,
        church: stiftung.churchId,
        resource: stiftung.resourceId,
        fee: stiftung.feeId,
        founder: stiftung.founder,
      });
    } else {
      form.setFieldsValue({
        founder: null,
        church: null,
        resource: null,
        paymentMethod: PaymentMethodTypes.PAIDBYCASH,
        paid: true,
        paidAt: moment(),
        totalYears: 20,
        intentionsPerYear: 1,
      });
    }
  }, [stiftung ? stiftung.id : null]);

  useEffect(() => {
    if (!_.isEmpty(resources)) {
      if (currentState !== 'create' && stiftung) {
        loadChurchDependentEntities(stiftung.churchId);
      }
    }
  }, [_.sortBy(_.map(resources, 'id')).join('-')]);

  // When creating an intention, auto-select the default fee, and if none, use the first fee in the list
  useEffect(() => {
    if (currentState === 'create' && !_.isEmpty(intentionFees)) {
      const defaultFee = _.find(
        intentionFees,
        (intentionFee) => intentionFee.isDefault
      );
      form.setFieldsValue({
        fee: _.get(defaultFee || _.first(intentionFees), 'id'),
      });
    }
  }, [_.sortBy(_.map(intentionFees, 'id')).join('-')]);

  // Form functions
  const updateChurch = (churchId: number) => {
    // Clear form values that depend on the value of the previously selected church ID
    form.setFieldsValue({
      resource: null,
    });

    // Re-load resources and fees
    loadChurchDependentEntities(churchId);

    // Reload the reference number
    if (churchId) {
      FoundationService.getFoundationsNextReferenceNumber(
        churchId,
        FoundationTypes.STIFTUNGEN
      )
        .then((value) => {
          form.setFieldsValue({ nextReferenceNumber: value });
        })
        .catch(ErrorHandlingService.handleError);
    }
  };

  const loadChurchDependentEntities = (churchId: number) => {
    // Load church's resources
    const church = _.find(resources, ['id', churchId]);
    const churchResources = _.get(church, 'resources', []);
    const orderedChurchResources = _.orderBy(churchResources, 'name');
    setChurch(church);
    setChurchResources(orderedChurchResources);
  };

  const saveForm = (shouldActivate) => {
    if (shouldActivate) {
      form.setFieldsValue({
        status: FoundationStatusTypes.ACTIVE,
      });
    }

    form.submit();
  };

  const save = (formValues) => {
    const oldChurchId = _.get(stiftung, 'churchId');
    const oldResourceId = _.get(stiftung, 'resourceId');
    const oldFeeId = _.get(stiftung, 'feeId');
    const associations = {
      church: formValues.church
        ? { id: formValues.church }
        : oldChurchId
          ? null
          : undefined,
      resource: formValues.resource
        ? { id: formValues.resource }
        : oldResourceId
          ? null
          : undefined,
      fee: formValues.fee
        ? { id: formValues.fee }
        : oldFeeId
          ? null
          : undefined,
    };

    // Format form inputs
    formValues.startYear = formValues.startYear
      ? formValues.startYear.year()
      : formValues.startYear;
    formValues.preferredDate = formValues.preferredDate
      ? formValues.preferredDate.format('YYYY-MM-DD')
      : formValues.preferredDate;
    formValues.preferredNote = _.isEmpty(formValues.preferredNote)
      ? null
      : formValues.preferredNote;
    formValues.intentionText = _.isEmpty(formValues.intentionText)
      ? null
      : formValues.intentionText;
    formValues.internalNote = _.isEmpty(formValues.internalNote)
      ? null
      : formValues.internalNote;

    /**
     * Manually retrieve the status "hidden" field that might have been set
     * in the case of updating and activating the stiftung (its value is NOT
     * submitted along with the other values in the "formValues" parameter)
     */
    const status = form.getFieldValue('status');
    const defaultStatus =
      currentState === 'create' ? FoundationStatusTypes.INACTIVE : undefined;
    const payload: Partial<Foundation> = _.extend(
      {
        type: FoundationTypes.STIFTUNGEN,
        status: status ? status : defaultStatus,
      },
      formValues,
      associations
    );

    if (currentState === 'create') {
      dispatch(createFoundation(payload));
    } else {
      dispatch(
        updateFoundation({
          id: stiftungId,
          type: FoundationTypes.STIFTUNGEN,
          updatePayload: payload,
        })
      );
    }
  };

  const generateStiftungIntentionsReport = () => {
    dispatch(
      generateFoundationIntentionsReport({
        id: stiftung.id,
        type: FoundationTypes.STIFTUNGEN,
      })
    );
  };

  const back = () => {
    stateService.go('app.private.intention.stiftungen.overview', {
      tab: FoundationStatusTypes.ACTIVE,
    });
  };

  // Get page title depending on current state
  let pageTitle;
  switch (currentState) {
    case 'edit':
      pageTitle = 'Stiftung Bearbeiten';
      break;
    default:
      // Create
      pageTitle = gettextCatalog.getString('Create Stiftung');
  }

  // Only disable when editing stiftung and field "canEdit" property is set to false OR when creating stiftung under a few cases
  const shouldDisable = (fieldName) => {
    if (stiftung) {
      // Editing
      return !_.get(stiftung, `fields[${fieldName}].canEdit`, false);
    } else {
      // Creating
      if (fieldName === FoundationFields.referenceNumber) {
        if (church && church.migrationLegatReferenceNumber) {
          return false;
        }
        return true;
      }
      return false;
    }
  };

  // Return the create stiftung form
  return (
    <React.Fragment>
      <div className="app-main">
        <PageHeader
          style={{ padding: '0px 0px 16px 0px' }}
          className="site-page-header-responsive"
          title={pageTitle}
          extra={
            <Space>
              <Button
                type="primary"
                value="save"
                htmlType="button"
                onClick={() => saveForm(false)}
              >
                {submitButtonLabel}
              </Button>
              {_.get(stiftung, 'status') === FoundationStatusTypes.INACTIVE ? (
                <Button
                  type="primary"
                  value="saveAndActivate"
                  htmlType="button"
                  onClick={() => saveForm(true)}
                >
                  {gettextCatalog.getString('Update and Activate')}
                </Button>
              ) : null}
              {!_.isNil(stiftung) ? (
                <Button onClick={() => generateStiftungIntentionsReport()}>
                  {gettextCatalog.getString('Persolvierungsliste')}
                </Button>
              ) : null}
              <Button onClick={back}>{gettextCatalog.getString('Back')}</Button>
            </Space>
          }
        />

        {shouldShowPermissionAlert ? (
          <Alert
            message={gettextCatalog.getString(
              'This foundation has been activated in the past and intentions have been generated for it, therefore some of its fields cannot be updated, in spite of the fact it is currently inactive.'
            )}
            type="warning"
            showIcon={true}
            closable={false}
            style={{ marginBottom: 16 }}
          />
        ) : null}

        <Form
          onFinish={save}
          form={form}
          labelCol={{ xs: 24, sm: 24, md: 6, lg: 8 }}
          wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14 }}
          size="middle"
        >
          <Row>
            {/* Start of left column */}
            <Col xs={24} lg={10} style={{ display: 'flex' }}>
              <Card
                bordered
                style={{ marginBottom: 16, marginRight: 16, flex: 1 }}
              >
                {/* Church - Required */}
                <Form.Item name="church" label="Pfarrei" required={true}>
                  <Select
                    placeholder={gettextCatalog.getString('Select a church...')}
                    onChange={updateChurch}
                    disabled={shouldDisable(FoundationFields.churchId)}
                    allowClear
                  >
                    {churches &&
                      churches.map((church) => (
                        <Option key={church.id} value={church.id}>
                          {_.get(church, 'name')}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                {/* Resources */}
                <Form.Item name="resource" label="Ressource">
                  <Select
                    placeholder={gettextCatalog.getString(
                      'Select a resource...'
                    )}
                    disabled={
                      _.isEmpty(churchResources) ||
                      shouldDisable(FoundationFields.resourceId)
                    }
                    allowClear
                  >
                    {churchResources &&
                      churchResources.map((resource) => (
                        <Option key={resource.id} value={resource.id}>
                          {_.get(resource, 'name')}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                {/* Preferred Date */}
                <Form.Item name="preferredDate" label="Wunschdatum">
                  <DatePicker
                    style={{ width: '100%' }}
                    format={datePickerFormat}
                    placeholder="Wunschdatum..."
                    disabled={shouldDisable(FoundationFields.preferredDate)}
                  />
                </Form.Item>

                {/* Preferred Date Note */}
                <Form.Item name="preferredNote" label="Terminwunsch">
                  <TextArea
                    rows={3}
                    placeholder="Terminwunsch..."
                    disabled={shouldDisable(FoundationFields.preferredNote)}
                  />
                </Form.Item>

                {/* Intention text - required */}
                <Form.Item
                  name="intentionText"
                  label="Intentiontext"
                  required={true}
                  style={{ marginBottom: 0 }}
                >
                  <TextArea
                    rows={4}
                    placeholder="Intentiontext..."
                    disabled={shouldDisable(FoundationFields.intentionText)}
                  />
                </Form.Item>
              </Card>
            </Col>

            <Col xs={24} lg={14} className="create-stiftung__content__right">
              <Card bordered style={{ marginBottom: 16 }}>
                <Row justify="space-between" gutter={8} align="bottom">
                  <Col span={11}>
                    {/* Start balance - Required */}
                    <Form.Item
                      name="startBalance"
                      label="Betrag"
                      labelCol={{ span: 12 }}
                    >
                      <Input
                        type="number"
                        prefix={_.get(window, 'cdApp.organization.currency')}
                        style={{
                          width: '100%',
                          maxHeight: 'initial',
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        placeholder="Betrag..."
                        disabled={shouldDisable(FoundationFields.startBalance)}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={13}>
                    {/* Next reference number */}
                    <Form.Item
                      name="nextReferenceNumber"
                      label={gettextCatalog.getString('Number')}
                      labelCol={{ span: 8 }}
                    >
                      <Input
                        placeholder={gettextCatalog.getString('Number...')}
                        disabled={shouldDisable(
                          FoundationFields.referenceNumber
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between" gutter={8} align="bottom">
                  <Col span={11}>
                    {/* Total years - Required */}
                    <Form.Item
                      name="totalYears"
                      label="Anzahl an Jahren"
                      required={true}
                      labelCol={{ span: 12 }}
                    >
                      <InputNumber
                        placeholder={gettextCatalog.getString(
                          'Anzahl an Jahren...'
                        )}
                        style={{ width: '100%' }}
                        disabled={shouldDisable(FoundationFields.totalYears)}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={13}>
                    {/* Fee - Required */}
                    <Form.Item
                      name="fee"
                      label="Intentionsart"
                      required={true}
                      labelCol={{ span: 8 }}
                    >
                      <Select
                        placeholder={gettextCatalog.getString(
                          'Select a type...'
                        )}
                        disabled={
                          _.isEmpty(intentionFees) ||
                          shouldDisable(FoundationFields.feeId)
                        }
                        allowClear
                        style={{ width: '100%' }}
                      >
                        {intentionFees &&
                          intentionFees.map((intentionFee) => (
                            <Option
                              key={intentionFee.id}
                              value={intentionFee.id}
                            >
                              {_.get(intentionFee, 'name')}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between" gutter={8} align="bottom">
                  <Col span={11}>
                    {/* Intentions per year */}
                    <Form.Item
                      name="intentionsPerYear"
                      label="Intentionen pro Jahr"
                      labelCol={{ span: 12 }}
                    >
                      <InputNumber
                        step={1}
                        min={0}
                        placeholder={gettextCatalog.getString(
                          'Anzahl der Intentionen pro Jahr...'
                        )}
                        style={{ width: '100%' }}
                        disabled={shouldDisable(
                          FoundationFields.intentionsPerYear
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={13}>
                    {/* Start year */}
                    <Form.Item
                      name="startYear"
                      label="Startjahr"
                      labelCol={{ span: 8 }}
                    >
                      <DatePicker
                        picker="year"
                        placeholder={gettextCatalog.getString('Startjahr...')}
                        style={{ width: '100%' }}
                        disabled={shouldDisable(FoundationFields.startYear)}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between" gutter={8} align="bottom">
                  <Col span={11}>
                    {/* Payment method */}
                    <Form.Item
                      name="paymentMethod"
                      label={gettextCatalog.getString('Payment method')}
                      style={{ marginBottom: 0 }}
                      labelCol={{ span: 12 }}
                    >
                      <Select
                        placeholder={gettextCatalog.getString(
                          'Select a payment method...'
                        )}
                        style={{ width: '100%' }}
                        disabled={
                          _.isEmpty(paymentMethods) ||
                          shouldDisable(FoundationFields.paymentMethod)
                        }
                        allowClear
                      >
                        {paymentMethods &&
                          paymentMethods.map((paymentMethod) => (
                            <Option
                              key={paymentMethod.id}
                              value={paymentMethod.id}
                            >
                              {_.get(paymentMethod, 'name')}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={13}>
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ marginBottom: 0 }}
                    >
                      <Col span={8} style={{ textAlign: 'end' }}>
                        <label style={{ marginRight: 8 }}>
                          {gettextCatalog.getString('Payment date')}:
                        </label>
                      </Col>

                      <Col span={16}>
                        <Space direction="horizontal">
                          {/* Payment date */}
                          <Form.Item name="paidAt" style={{ marginBottom: 0 }}>
                            <DatePicker
                              format={datePickerFormat}
                              placeholder={`${gettextCatalog.getString(
                                'Payment date'
                              )}...`}
                              disabled={shouldDisable(FoundationFields.paidAt)}
                            />
                          </Form.Item>

                          {/* Payment check */}
                          <Form.Item
                            name="paid"
                            valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Checkbox
                              disabled={shouldDisable(FoundationFields.paid)}
                            >
                              {gettextCatalog.getString('Paid?')}
                            </Checkbox>
                          </Form.Item>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>

              <Card bordered style={{ marginBottom: 16 }}>
                {/* Founder */}
                <Form.Item
                  name="founder"
                  label={gettextCatalog.getString('Founder')}
                >
                  <Input
                    style={{ width: '100%' }}
                    placeholder={gettextCatalog.getString('Founder...')}
                    disabled={shouldDisable(FoundationFields.founder)}
                  />
                  {/* <AddPeople placeholder={gettextCatalog.getString('Select a Stifter...')} /> */}
                </Form.Item>

                {/* Internal Note */}
                <Form.Item
                  name="internalNote"
                  label="Bemerkung"
                  style={{ marginBottom: 0 }}
                >
                  <TextArea
                    rows={3}
                    placeholder="Bemerkung..."
                    disabled={shouldDisable(FoundationFields.internalNote)}
                  />
                </Form.Item>
              </Card>
              {_.get(stiftung, 'createdBy') ||
              _.get(stiftung, 'createdAt') ||
              _.get(stiftung, 'assignedBy') ||
              _.get(stiftung, 'assignedAt') ||
              _.get(stiftung, 'updatedBy') ||
              _.get(stiftung, 'updatedAt') ||
              _.get(stiftung, 'deletedAt') ? (
                <Card bordered style={{ marginBottom: 16 }}>
                  {/* Entity metadata */}
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Descriptions
                      title={gettextCatalog.getString('Activity')}
                      column={1}
                      size="small"
                    >
                      {_.get(stiftung, 'createdBy') ? (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Created by')}
                        >
                          {_.get(stiftung, 'createdBy.contact.fullName')}
                        </Descriptions.Item>
                      ) : null}
                      {_.get(stiftung, 'createdAt') ? (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Created on')}
                        >
                          {moment(_.get(stiftung, 'createdAt')).format('lll')}
                        </Descriptions.Item>
                      ) : null}
                      {_.get(stiftung, 'assignedBy') ? (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Assigned by')}
                        >
                          {_.get(stiftung, 'assignedBy.contact.fullName')}
                        </Descriptions.Item>
                      ) : null}
                      {_.get(stiftung, 'assignedAt') ? (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Assigned on')}
                        >
                          {moment(_.get(stiftung, 'assignedAt')).format('lll')}
                        </Descriptions.Item>
                      ) : null}
                      {_.get(stiftung, 'updatedBy') ? (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Updated by')}
                        >
                          {_.get(stiftung, 'updatedBy.contact.fullName')}
                        </Descriptions.Item>
                      ) : null}
                      {_.get(stiftung, 'updatedAt') ? (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Updated on')}
                        >
                          {moment(_.get(stiftung, 'updatedAt')).format('lll')}
                        </Descriptions.Item>
                      ) : null}
                      {_.get(stiftung, 'deletedAt') ? (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Deleted on')}
                        >
                          {moment(_.get(stiftung, 'deletedAt')).format('lll')}
                        </Descriptions.Item>
                      ) : null}
                    </Descriptions>
                  </Form.Item>
                </Card>
              ) : null}
            </Col>
          </Row>
          {currentState === 'edit' ? (
            <Row>
              <Col span={24}>
                <IntentionsOverview stiftungId={stiftungId} />
              </Col>
            </Row>
          ) : null}
        </Form>
      </div>
    </React.Fragment>
  );
};

export default CreateStiftung;
