angular.module('cdApp.calendar').run([
  '$templateCache',
  ($templateCache) => {
    $templateCache.put(
      '@/app/calendar/absence-detail/absence-detail.component.html',
      require('./absence-detail/absence-detail.component.html')
    );

    $templateCache.put(
      '@/app/calendar/absence-detail/absence-revisions.component.html',
      require('./absence-detail/absence-revisions.component.html')
    );

    $templateCache.put(
      '@/app/calendar/attendance/attendance.component.html',
      require('./attendance/attendance.component.html')
    );

    $templateCache.put(
      '@/app/calendar/attendance/components/attendance-cell/attendance-cell.component.html',
      require('./attendance/components/attendance-cell/attendance-cell.component.html')
    );

    $templateCache.put(
      '@/app/calendar/attendance/components/attendance-modal/attendance-modal.component.html',
      require('./attendance/components/attendance-modal/attendance-modal.component.html')
    );

    $templateCache.put(
      '@/app/calendar/attendance/components/attendance-overview/attendance-overview.component.html',
      require('./attendance/components/attendance-overview/attendance-overview.component.html')
    );

    $templateCache.put(
      '@/app/calendar/attendance/components/get-started-modal/get-started-modal.component.html',
      require('./attendance/components/get-started-modal/get-started-modal.component.html')
    );

    $templateCache.put(
      '@/app/calendar/attendance/components/select-categories-modal/select-categories-modal.component.html',
      require('./attendance/components/select-categories-modal/select-categories-modal.component.html')
    );

    $templateCache.put(
      '@/app/calendar/calendar-log/calendar-log.component.html',
      require('./calendar-log/calendar-log.component.html')
    );

    $templateCache.put(
      '@/app/calendar/calendar-print/calendar-print.component.html',
      require('./calendar-print/calendar-print.component.html')
    );

    $templateCache.put(
      '@/app/calendar/calendar-print/order-of-service-template-modal/order-of-service-template.modal.html',
      require('./calendar-print/order-of-service-template-modal/order-of-service-template.modal.html')
    );

    $templateCache.put(
      '@/app/calendar/calendar.component.html',
      require('./calendar.component.html')
    );

    $templateCache.put(
      '@/app/calendar/feed-detail/feed-detail.component.html',
      require('./feed-detail/feed-detail.component.html')
    );

    $templateCache.put(
      '@/app/calendar/external-event-detail/external-event-detail.component.html',
      require('./external-event-detail/external-event-detail.component.html')
    );

    $templateCache.put(
      '@/app/calendar/event-detail/event-detail.component.html',
      require('./event-detail/event-detail.component.html')
    );

    $templateCache.put(
      '@/app/calendar/event-detail/event-revisions.component.html',
      require('./event-detail/event-revisions.component.html')
    );

    $templateCache.put(
      '@/app/calendar/event-detail/templates/notify.modal.html',
      require('./event-detail/templates/notify.modal.html')
    );

    $templateCache.put(
      '@/app/calendar/event-detail/templates/subscribe.modal.html',
      require('./event-detail/templates/subscribe.modal.html')
    );

    $templateCache.put(
      '@/app/calendar/event-detail/templates/copy-link.modal.html',
      require('./event-detail/templates/copy-link.modal.html')
    );

    $templateCache.put(
      '@/app/calendar/full-calendar/full-calendar.component.html',
      require('./full-calendar/full-calendar.component.html')
    );

    $templateCache.put(
      '@/app/calendar/full-calendar/templates/modals/event-export.modal.html',
      require('./full-calendar/templates/modals/event-export.modal.html')
    );

    $templateCache.put(
      '@/app/calendar/personal-ical-feed/personal-ical-feed.component.html',
      require('./personal-ical-feed/personal-ical-feed.component.html')
    );

    $templateCache.put(
      '@/app/calendar/shared/components/calendar-feed/calendar-feed.component.html',
      require('./shared/components/calendar-feed/calendar-feed.component.html')
    );

    $templateCache.put(
      '@/app/calendar/shared/components/double-booking-modal/double-booking-modal.html',
      require('./shared/components/double-booking-modal/double-booking-modal.html')
    );

    $templateCache.put(
      '@/app/calendar/shared/components/event-delete-modal/event-delete-modal.component.html',
      require('./shared/components/event-delete-modal/event-delete-modal.component.html')
    );

    $templateCache.put(
      '@/app/calendar/shared/components/event-popup/event-popup-create/event-popup-create.component.html',
      require('./shared/components/event-popup/event-popup-create/event-popup-create.component.html')
    );

    $templateCache.put(
      '@/app/calendar/shared/components/event-popup/event-popup.component.html',
      require('./shared/components/event-popup/event-popup.component.html')
    );

    $templateCache.put(
      '@/app/calendar/shared/components/repeat-rule-summary/repeat-rule-summary.component.html',
      require('./shared/components/repeat-rule-summary/repeat-rule-summary.component.html')
    );

    $templateCache.put(
      '@/app/calendar/workplan-detail/templates/workplan-copy.modal.html',
      require('./workplan-detail/templates/workplan-copy.modal.html')
    );

    $templateCache.put(
      '@/app/calendar/workplan-detail/templates/workplan-create.modal.html',
      require('./workplan-detail/templates/workplan-create.modal.html')
    );

    $templateCache.put(
      '@/app/calendar/workplan-detail/workplan-detail.component.html',
      require('./workplan-detail/workplan-detail.component.html')
    );

    $templateCache.put(
      '@/app/calendar/workplan-overview/workplan-overview.component.html',
      require('./workplan-overview/workplan-overview.component.html')
    );
  },
]);
