/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Dropdown, Button, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  faAngleDown,
  faTrash,
  faPencilAlt,
  faHistory,
  faToggleOn,
  faToggleOff,
  faEye,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { PaginationProps } from 'antd/es/pagination';

import { FoundationTypes } from '../../models/foundation';
import gettextCatalog from '../../../services/I18nService';
import { handleSuccessMessage } from '../../../shared/utils';
import { OrderDirection, TableOrdering } from '../../../shared/models/table';
import { IntentionData } from '../../services/IntentionService';
import { navigate } from '../../../services/StateServiceFactory';
import FoundationService from '../../services/FoundationService';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import { Foundation, FoundationStatusTypes } from '../../models/foundation';
import {
  PaymentMethodTypes,
  IntentionStatusTypes,
} from '../../models/intention';
import {
  StyledTabs,
  StyledTable,
} from '../../../shared/antd/StyledAntdComponents';
import { isLoading as isLoadingSelector } from '../../../shared/loading/redux/Selectors';
import {
  fetchFoundations,
  changeFoundationStatus,
} from '../../redux/foundations/Actions';
import {
  getStiftungen,
  getLegate,
  getStiftungenCount,
  getLegateCount,
} from '../../redux/foundations/Selectors';

import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

// Constants
const OFFSET_DEFAULT = 0;
const CURRENT_PAGE_DEFAULT = 1;
const PAGE_SIZE_DEFAULT = 10;

// Antd
const { TabPane } = Tabs;

// Tab constants
enum FoundationsTabNames {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  COMPLETED = 'completed',
  ALL = 'all',
}

const invalidateMouseEvent = (event: React.MouseEvent): void => {
  if (!event) return;
  event.preventDefault();
  event.stopPropagation();
};

const isFoundationDeleted = (foundation: Foundation) =>
  foundation && !_.isNil(foundation.deletedAt);

const isFoundationCompleted = (foundation: Foundation) =>
  foundation && foundation.status === FoundationStatusTypes.COMPLETED;

const hasPermissions = (foundation: Foundation) =>
  foundation.canEdit ||
  foundation.canActivate ||
  foundation.canDeactivate ||
  foundation.canComplete ||
  foundation.canDelete;

// Props
interface FoundationsOverviewProps {
  foundationType: FoundationTypes;
  filters?: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  updateParentOnChange?: Function;
}

const FoundationsOverview: FunctionComponent<FoundationsOverviewProps> = ({
  foundationType,
  filters,
  updateParentOnChange,
}) => {
  // Redux
  const dispatch = useDispatch();

  // Retrieve required entities from Redux store
  const stiftungen = useSelector(getStiftungen);
  const stiftungenCount: number = useSelector(getStiftungenCount);
  const legate = useSelector(getLegate);
  const legateCount: number = useSelector(getLegateCount);
  const isLoading: boolean = useSelector(isLoadingSelector);

  // Define initialization hook and entity reload hooks
  const [currentTab, setCurrentTab] = useState<FoundationsTabNames>();
  const [queryParams, setQueryParams] = useState<any>();
  // When query pagination is changed, a search is triggered.
  // Whereas when table pagination and ordering are changed, the cosmetics of the table change
  const [queryPagination, setQueryPagination] = useState<PaginationProps>();
  const [tablePagination, setTablePagination] = useState<PaginationProps>();
  const [tableOrdering, setTableOrdering] = useState<Partial<TableOrdering>>();

  let foundations: Foundation[];
  if (foundationType === FoundationTypes.STIFTUNGEN) {
    foundations = stiftungen.asMutable({ deep: true });
  } else if (foundationType === FoundationTypes.LEGATE) {
    foundations = legate.asMutable({ deep: true });
  }

  // Retrieve required entities
  useEffect(() => {
    const defaultTab = FoundationsTabNames.ACTIVE;

    setCurrentTab(defaultTab);
    if (updateParentOnChange) updateParentOnChange({ status: defaultTab });

    // Setting the default table properties
    setTablePagination({
      current: CURRENT_PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT,
    });

    // Setting the default ordering
    setTableOrdering({
      orderBy: 'referenceNumber',
      orderDirection: OrderDirection.DESC,
    });
  }, []);

  // Update foundations based on filter change
  useEffect(() => {
    // The FoundationsOverviewFilters are set by default which triggers a refresh of the table.
    // Then when the filters are changed in the future they also trigger a refresh
    if (filters) {
      refreshFoundationsTable(currentTab, true);
    }
  }, [JSON.stringify(filters)]);

  // Refresh table when pagination updates (Change page or receive total # of foundations)
  useEffect(() => {
    if (queryPagination) {
      setTablePagination(queryPagination);
      refreshFoundationsTable(currentTab, false);
    }
  }, [JSON.stringify(queryPagination)]);

  // Refresh table when sort updates
  useEffect(() => {
    if (tableOrdering) {
      refreshFoundationsTable(currentTab, false);
    }
  }, [JSON.stringify(tableOrdering)]);

  useEffect(() => {
    if (stiftungenCount && foundationType === FoundationTypes.STIFTUNGEN) {
      // Updating total foundations
      setTablePagination({
        current: _.get(tablePagination, 'current') || CURRENT_PAGE_DEFAULT,
        pageSize: _.get(tablePagination, 'pageSize') || PAGE_SIZE_DEFAULT,
        total: stiftungenCount,
      });
    }
  }, [stiftungenCount]);

  useEffect(() => {
    if (legateCount && foundationType === FoundationTypes.LEGATE) {
      // Updating total foundations
      setTablePagination({
        current: _.get(tablePagination, 'current') || CURRENT_PAGE_DEFAULT,
        pageSize: _.get(tablePagination, 'pageSize') || PAGE_SIZE_DEFAULT,
        total: legateCount,
      });
    }
  }, [legateCount]);

  const onTabChange = (tab: FoundationsTabNames) => {
    // Reset table sort
    setCurrentTab(tab);
    refreshFoundationsTable(tab, true);
    if (updateParentOnChange) updateParentOnChange({ status: tab });
  };

  const refreshFoundationsTable = (
    tab: FoundationsTabNames,
    resetPagination: boolean
  ) => {
    const includeDeleted = tab === FoundationsTabNames.ALL;
    const status = includeDeleted ? null : (tab as any);

    // Calculate pagination
    let limit, offset;
    if (resetPagination) {
      // Reset limit, offset and table view pagination
      setTablePagination({
        current: CURRENT_PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT,
      });
      offset = OFFSET_DEFAULT;
      limit = PAGE_SIZE_DEFAULT;
    } else {
      const currentPage =
        _.get(queryPagination, 'current') || CURRENT_PAGE_DEFAULT;
      limit = _.get(queryPagination, 'pageSize') || PAGE_SIZE_DEFAULT;
      offset = (currentPage - 1) * limit;
    }

    const params = _.extend(
      {
        ...filters,
        status,
        includeDeleted,
        type: foundationType,
        offset,
        limit,
      },
      tableOrdering
    );

    setQueryParams(params);
    dispatch(fetchFoundations(params));
  };

  // FOUNDATION ACTIONS

  const removeFoundation = (
    foundation: Foundation,
    event: React.MouseEvent
  ) => {
    invalidateMouseEvent(event);
    showConfirmModalDeprecated({
      title:
        foundation.type === FoundationTypes.LEGATE
          ? gettextCatalog.getString('Delete legat')
          : gettextCatalog.getString('Delete stiftung'),
      message:
        foundation.type === FoundationTypes.LEGATE
          ? gettextCatalog.getString(
              'Do you want to delete the legat {{referenceNumber}}?',
              {
                referenceNumber: foundation.formattedReferenceNumber,
              }
            )
          : gettextCatalog.getString(
              'Do you want to delete the stiftung {{referenceNumber}}?',
              {
                referenceNumber: foundation.formattedReferenceNumber,
              }
            ),
      onOk() {
        FoundationService.deleteFoundation(foundation.id)
          .then(() => {
            handleSuccessMessage(
              foundation.type === FoundationTypes.LEGATE
                ? gettextCatalog.getString('Successfully deleted legat.')
                : gettextCatalog.getString('Successfully deleted stiftung.')
            );
            refreshFoundationsTable(currentTab, false);
          })
          .catch(ErrorHandlingService.handleError);
      },
    });
  };

  const restoreFoundation = (
    foundation: Foundation,
    event: React.MouseEvent
  ) => {
    invalidateMouseEvent(event);
    showConfirmModalDeprecated({
      title:
        foundation.type === FoundationTypes.LEGATE
          ? gettextCatalog.getString('Restore legat')
          : gettextCatalog.getString('Restore stiftung'),
      message:
        foundation.type === FoundationTypes.LEGATE
          ? gettextCatalog.getString(
              'Do you want to restore the legat {{referenceNumber}}?',
              {
                referenceNumber: foundation.formattedReferenceNumber,
              }
            )
          : gettextCatalog.getString(
              'Do you want to restore the stiftung {{referenceNumber}}?',
              {
                referenceNumber: foundation.formattedReferenceNumber,
              }
            ),
      onOk() {
        FoundationService.restoreFoundation(foundation.id)
          .then(() => {
            handleSuccessMessage(
              foundation.type === FoundationTypes.LEGATE
                ? gettextCatalog.getString('Successfully restored legat.')
                : gettextCatalog.getString('Successfully restored stiftung.')
            );
            refreshFoundationsTable(currentTab, false);
          })
          .catch(ErrorHandlingService.handleError);
      },
    });
  };

  const updateFoundationStatus = (
    foundation: Foundation,
    newStatus: FoundationStatusTypes
  ) => {
    if (!foundation) return;

    let modalTitle, modalBody;

    if (newStatus === FoundationStatusTypes.ACTIVE) {
      modalTitle =
        foundation.type === FoundationTypes.LEGATE
          ? gettextCatalog.getString('Activate legat')
          : gettextCatalog.getString('Activate stiftung');

      modalBody =
        foundation.type === FoundationTypes.LEGATE
          ? gettextCatalog.getString(
              'Do you want to activate the legat {{referenceNumber}}?',
              {
                referenceNumber: foundation.formattedReferenceNumber,
              }
            )
          : gettextCatalog.getString(
              'Do you want to activate the stiftung {{referenceNumber}}?',
              {
                referenceNumber: foundation.formattedReferenceNumber,
              }
            );
    } else if (newStatus === FoundationStatusTypes.INACTIVE) {
      modalTitle =
        foundation.type === FoundationTypes.LEGATE
          ? gettextCatalog.getString('Deactivate legat')
          : gettextCatalog.getString('Deactivate stiftung');

      modalBody =
        foundation.type === FoundationTypes.LEGATE
          ? gettextCatalog.getString(
              'Do you want to deactivate the legat {{referenceNumber}}?',
              {
                referenceNumber: foundation.formattedReferenceNumber,
              }
            )
          : gettextCatalog.getString(
              'Do you want to deactivate the stiftung {{referenceNumber}}?',
              {
                referenceNumber: foundation.formattedReferenceNumber,
              }
            );
    } else if (newStatus === FoundationStatusTypes.COMPLETED) {
      modalTitle =
        foundation.type === FoundationTypes.LEGATE
          ? gettextCatalog.getString('Complete legat')
          : gettextCatalog.getString('Complete stiftung');

      modalBody =
        foundation.type === FoundationTypes.LEGATE
          ? gettextCatalog.getString(
              'Do you want to complete the legat {{referenceNumber}}?',
              {
                referenceNumber: foundation.formattedReferenceNumber,
              }
            )
          : gettextCatalog.getString(
              'Do you want to complete the stiftung {{referenceNumber}}?',
              {
                referenceNumber: foundation.formattedReferenceNumber,
              }
            );
    }

    showConfirmModalDeprecated({
      title: modalTitle,
      message: modalBody,
      onOk() {
        if (newStatus !== FoundationStatusTypes.ACTIVE) {
          FoundationService.fetchIntentionsForFoundation({
            id: foundation.id,
            type: foundation.type,
          })
            .then((intentions: IntentionData) => {
              if (intentions.count > 0) {
                const numUnassignedIntentions = _.filter(
                  intentions.items,
                  (intention) =>
                    intention.status === IntentionStatusTypes.UNASSIGNED
                ).length;

                const numAssignedIntentions = _.filter(
                  intentions.items,
                  (intention) =>
                    intention.status === IntentionStatusTypes.ASSIGNED
                ).length;

                const numCompletedIntentions = _.filter(
                  intentions.items,
                  (intention) =>
                    intention.status === IntentionStatusTypes.COMPLETED
                ).length;

                const confirmTitle =
                  newStatus === FoundationStatusTypes.INACTIVE
                    ? gettextCatalog.getString('Deactivate {{ type }}', {
                        type: foundation.type,
                      })
                    : gettextCatalog.getString('Complete {{ type }}', {
                        type: foundation.type,
                      });

                const confirmContentParams = {
                  type: foundation.type,
                  numUnassignedIntentions,
                  numAssignedIntentions,
                  numCompletedIntentions,
                };
                const confirmContent =
                  newStatus === FoundationStatusTypes.INACTIVE
                    ? gettextCatalog.getString(
                        'Your {{ type }} has the following intentions: {{numUnassignedIntentions}} unassigned, {{numAssignedIntentions}} assigned and {{numCompletedIntentions}} completed. Deactivation will not change any of those intentions in any way. Are you sure you want to deactivate?',
                        confirmContentParams
                      )
                    : gettextCatalog.getString(
                        'Your {{ type }} has the following intentions: {{numUnassignedIntentions}} unassigned, {{numAssignedIntentions}} assigned and {{numCompletedIntentions}} completed. Completion will not change any of those intentions in any way. Are you sure you want to complete?',
                        confirmContentParams
                      );

                showConfirmModalDeprecated({
                  title: confirmTitle,
                  message: confirmContent,
                  onOk() {
                    dispatch(
                      changeFoundationStatus({
                        id: foundation.id,
                        type: foundation.type,
                        newStatus,
                        oldStatus: foundation.status,
                        startYear: foundation.startYear,
                        filters: queryParams,
                      })
                    );
                  },
                });
              } else {
                dispatch(
                  changeFoundationStatus({
                    id: foundation.id,
                    type: foundation.type,
                    newStatus,
                    oldStatus: foundation.status,
                    startYear: foundation.startYear,
                    filters: queryParams,
                  })
                );
              }
            })
            .catch(ErrorHandlingService.handleError);
        } else {
          dispatch(
            changeFoundationStatus({
              id: foundation.id,
              type: foundation.type,
              newStatus,
              oldStatus: foundation.status,
              startYear: foundation.startYear,
              filters: queryParams,
            })
          );
        }
      },
    });
  };

  const activateFoundation = (
    foundation: Foundation,
    event: React.MouseEvent
  ) => {
    invalidateMouseEvent(event);
    updateFoundationStatus(foundation, FoundationStatusTypes.ACTIVE);
  };

  const deactivateFoundation = (
    foundation: Foundation,
    event: React.MouseEvent
  ) => {
    invalidateMouseEvent(event);
    updateFoundationStatus(foundation, FoundationStatusTypes.INACTIVE);
  };

  const completeFoundation = (
    foundation: Foundation,
    event: React.MouseEvent
  ) => {
    invalidateMouseEvent(event);
    updateFoundationStatus(foundation, FoundationStatusTypes.COMPLETED);
  };

  const handleTableChange = (newPagination, _filters, sorter) => {
    // Setting pagination if it has changed
    if (!_.isEqual(newPagination, tablePagination)) {
      setQueryPagination(newPagination);
    }

    // Only set field if order is provided
    const newTableOrdering: TableOrdering = {
      // formattedReferenceNumber is not a database column
      orderBy: sorter.order
        ? sorter.field === 'formattedReferenceNumber'
          ? 'referenceNumber'
          : sorter.field
        : null,
      orderDirection:
        sorter.order === 'ascend'
          ? OrderDirection.ASC
          : sorter.order === 'descend'
            ? OrderDirection.DESC
            : null,
    };

    // Setting ordering if it has changed
    if (!_.isEqual(newTableOrdering, tableOrdering)) {
      setTableOrdering(newTableOrdering);
      if (updateParentOnChange) updateParentOnChange(newTableOrdering);
    }
  };

  const navigateToFoundation = (
    foundationId: string,
    event: React.MouseEvent
  ) => {
    invalidateMouseEvent(event);
    if (foundationType === FoundationTypes.STIFTUNGEN) {
      navigate('app.private.intention.stiftungen.edit', {
        id: foundationId,
        currentState: 'edit',
      });
    } else if (foundationType === FoundationTypes.LEGATE) {
      navigate('app.private.intention.legate.edit', {
        id: foundationId,
        currentState: 'edit',
      });
    }
  };
  // Tabs CONFIG

  const foundationsTableTabs = [
    {
      name: gettextCatalog.getString('Active'),
      key: FoundationsTabNames.ACTIVE,
    },
    {
      name: gettextCatalog.getString('Inactive'),
      key: FoundationsTabNames.INACTIVE,
    },
    {
      name: gettextCatalog.getString('Completed'),
      key: FoundationsTabNames.COMPLETED,
    },
    {
      name: gettextCatalog.getString('All'),
      key: FoundationsTabNames.ALL,
    },
  ];

  // Get table config based on state
  const sharedColumns: any = [
    {
      title: 'Nummer',
      dataIndex: 'formattedReferenceNumber',
      key: 'formattedReferenceNumber',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Intentionstext',
      dataIndex: 'intentionText',
      key: 'intentionText',
    },
    {
      title: gettextCatalog.getString('Start year'),
      dataIndex: 'startYear',
      key: 'startYear',
    },
    {
      title: 'Zahlungsmethode',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: function columnRender(paymentMethod: PaymentMethodTypes) {
        return (
          <div>
            {paymentMethod === PaymentMethodTypes.PAIDBYCASH
              ? 'Bar'
              : 'Überweisung'}
          </div>
        );
      },
    },
    {
      title: 'Zahlungsstatus',
      dataIndex: 'paid',
      key: 'paid',
      align: 'center',
      render: function columnRender(paid: boolean) {
        return (
          <div>
            {paid ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} />
            )}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Payment date'),
      dataIndex: 'paidAt',
      key: 'paidAt',
      render: function columnRender(paidAt: Date) {
        return <div>{paidAt ? moment(paidAt).format('YYYY-MM-DD') : null}</div>;
      },
    },
    {
      title: gettextCatalog.getString('Parish'),
      dataIndex: ['church', 'name'],
      key: 'church',
    },
    {
      title: gettextCatalog.getString('Resource'),
      dataIndex: ['resource', 'name'],
      key: 'resource',
    },
    {
      title: 'Intentionsart',
      dataIndex: ['fee', 'name'],
      key: 'fee',
    },
    {
      title: gettextCatalog.getString('Founder'),
      dataIndex: 'founder',
      key: 'founder',
    },
    {
      title: null,
      key: 'actionsMenu',
      fixed: 'right',
      width: 120,
      render: function columnRender(foundation: Foundation) {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {isFoundationDeleted(foundation) ? (
              <Button
                danger
                size="small"
                style={{ marginRight: 8 }}
                onClick={(event: React.MouseEvent) =>
                  restoreFoundation(foundation, event)
                }
              >
                <FontAwesomeIcon icon={faHistory} style={{ marginRight: 8 }} />
                {gettextCatalog.getString('Restore')}
              </Button>
            ) : null}

            <Dropdown
              overlay={getActionsMenu(foundation)}
              trigger={['click']}
              disabled={!hasPermissions(foundation)}
            >
              <Button size="small" onClick={invalidateMouseEvent}>
                {gettextCatalog.getString('Actions')}{' '}
                <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: 8 }} />
              </Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  let columns: any;
  if (foundationType === FoundationTypes.STIFTUNGEN) {
    columns = _.cloneDeep(sharedColumns);
    columns.splice(3, 0, {
      title: 'Anzahl an Jahren',
      dataIndex: 'totalYears',
      key: 'totalYears',
    });
  } else if (foundationType === FoundationTypes.LEGATE) {
    columns = sharedColumns;
  }

  // Menus

  const getActionsMenu = (foundation: Foundation) => (
    <Menu>
      {foundation.canEdit ? (
        <Menu.Item key="edit">
          <a
            onClick={(event: React.MouseEvent) =>
              navigateToFoundation(foundation.id, event)
            }
          >
            <FontAwesomeIcon
              icon={isFoundationCompleted(foundation) ? faEye : faPencilAlt}
              style={{ marginRight: 8 }}
            />
            {isFoundationCompleted(foundation)
              ? gettextCatalog.getString('View')
              : gettextCatalog.getString('Edit')}
          </a>
        </Menu.Item>
      ) : null}
      {foundation.canActivate ? (
        <Menu.Item key="activate">
          <a
            onClick={(event: React.MouseEvent) =>
              activateFoundation(foundation, event)
            }
          >
            <FontAwesomeIcon icon={faToggleOn} style={{ marginRight: 8 }} />
            {gettextCatalog.getString('Activate')}
          </a>
        </Menu.Item>
      ) : null}
      {foundation.canDeactivate ? (
        <Menu.Item key="deactivate">
          <a
            onClick={(event: React.MouseEvent) =>
              deactivateFoundation(foundation, event)
            }
          >
            <FontAwesomeIcon icon={faToggleOff} style={{ marginRight: 8 }} />
            {gettextCatalog.getString('Deactivate')}
          </a>
        </Menu.Item>
      ) : null}
      {foundation.canComplete ? (
        <Menu.Item key="complete">
          <a
            onClick={(event: React.MouseEvent) =>
              completeFoundation(foundation, event)
            }
          >
            <FontAwesomeIcon icon={faToggleOff} style={{ marginRight: 8 }} />
            {gettextCatalog.getString('Complete')}
          </a>
        </Menu.Item>
      ) : null}
      {foundation.canDelete ? (
        <Menu.Item key="delete">
          <a
            onClick={(event: React.MouseEvent) =>
              removeFoundation(foundation, event)
            }
          >
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: 8 }} />
            {gettextCatalog.getString('Delete')}
          </a>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <StyledTabs
      activeKey={currentTab}
      defaultActiveKey={FoundationsTabNames.ACTIVE}
      size="large"
      onChange={onTabChange}
      type="card"
    >
      {foundationsTableTabs.map((foundationsTableTab) => (
        <TabPane tab={foundationsTableTab.name} key={foundationsTableTab.key}>
          <StyledTable
            id="foundationsOverviewTable"
            size="small"
            columns={columns}
            dataSource={foundations}
            loading={isLoading}
            scroll={{ x: true }}
            pagination={tablePagination}
            bordered={true}
            rowKey="id"
            rowClassName={(record: Foundation) =>
              !_.isNil(record.deletedAt) ? 'errorRow' : null
            }
            onRow={(foundation: Foundation) => ({
              onClick: (event: React.MouseEvent) =>
                isFoundationDeleted(foundation)
                  ? null
                  : navigateToFoundation(foundation.id, event),
            })}
            onChange={handleTableChange}
          />
        </TabPane>
      ))}
    </StyledTabs>
  );
};

export default FoundationsOverview;
