import React, { Dispatch, useEffect, useState } from 'react';
import {
  Input,
  Modal,
  Spin,
  Alert,
  Button,
  Form,
  Switch,
  Divider,
  Select,
} from 'antd';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { useRecoilValueLoadable } from 'recoil';

import {
  ManageSubscription,
  ManageSubscriptionByTokenQuery,
} from '../store/ManageSubscriptionState';
import useManageSubscription from '../hooks/useManageSubscriptin';
import { OrganizationInfoByIdQuery } from '../../organization/store/organization';

import { CdCheckIcon, CdClose } from '@/react/shared/components/Icons';
import gettextCatalog from '@/react/services/I18nService';

const Container = styled.div`
  margin: 0 32px 0 48px;
`;
const Subtitle = styled.p`
  color: gray;
  line-height: 20px;
`;
const Header = styled.p`
  font-size: 16px;
`;
const Group = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
const Link = styled.a`
  color: black;
`;
const { Option } = Select;

export const ManageSubscriptionsAndConsentsModal = NiceModal.create(
  ({
    setResultMessage,
    organizationId,
    token,
  }: {
    setResultMessage: Dispatch<boolean>;
    organizationId: number;
    token: string;
  }) => (
    <ManageSubscriptionsAndConsents
      setResultMessage={setResultMessage}
      organizationId={organizationId}
      token={token}
    />
  )
);

export interface ConsentCreateStatementProps {
  setResultMessage: Dispatch<boolean>;
  organizationId: number;
  token: string;
}
export const ManageSubscriptionsAndConsents = ({
  setResultMessage,
  organizationId,
  token,
}: {
  setResultMessage: Dispatch<boolean>;
  organizationId: number;
  token: string;
}) => {
  const modal = useModal('ManageSubscriptionsAndConsents');
  const [contactsInfo, setContactsInfo] = useState<ManageSubscription[]>([]);
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
  const [contactId, setContactId] = useState<number>(
    contactsInfo.find((contact) => contact.receiver === true)?.id
  );
  const { confirmSubscriptions } = useManageSubscription();
  const organizationInfoLoadable = useRecoilValueLoadable(
    OrganizationInfoByIdQuery(+organizationId)
  );
  const contactsInfoLoadable = useRecoilValueLoadable(
    ManageSubscriptionByTokenQuery(token)
  );

  const isLoading = organizationInfoLoadable.state === 'loading';
  const currentContact = contactId
    ? contactsInfo.find((contact) => contact.id === contactId)
    : contactsInfo.find((contact) => contact.receiver === true);
  const email = currentContact?.email;
  const organizationName =
    organizationInfoLoadable.state === 'hasValue' &&
    organizationInfoLoadable.contents.name;

  const [form] = Form.useForm();

  useEffect(() => {
    if (
      contactsInfoLoadable.state === 'hasValue' &&
      contactsInfoLoadable?.contents?.length > 0
    ) {
      setContactsInfo(contactsInfoLoadable.contents);
    }
  }, [contactsInfoLoadable]);
  useEffect(() => {
    if (
      contactsInfoLoadable.state === 'hasValue' &&
      contactsInfoLoadable?.contents?.length === 0
    ) {
      setIsTokenExpired(true);
    }
  }, [contactsInfoLoadable]);

  useEffect(() => {
    if (contactId) {
      form.setFieldsValue(
        contactsInfo.find((contact) => contact.id === contactId)
      );
    } else {
      setContactId(
        contactsInfo.find((contact) => contact.receiver === true)?.id
      );
      form.setFieldsValue(
        contactsInfo.find((contact) => contact.receiver === true)
      );
    }
    // eslint-disable-next-line
  }, [contactId, form, contactsInfo]);

  const close = () => {
    modal.remove();
  };

  const onFinish = async () => {
    await confirmSubscriptions(contactsInfo, token);
    setResultMessage(true);
  };

  const updateContactsInfo = (id: number) => {
    const {
      filters,
      newsletters,
      consents,
      receiveCommunicationFromFilters,
      participantLists,
    } = form.getFieldsValue();
    setContactsInfo((newContactsInfoPrev) =>
      newContactsInfoPrev.map((newContactInfoPrev) =>
        newContactInfoPrev.id === id
          ? {
              ...newContactInfoPrev,
              filters: filters || [],
              newsletters: newsletters || [],
              participantLists: participantLists || [],
              consents: consents || [],
              receiveCommunicationFromFilters,
            }
          : newContactInfoPrev
      )
    );
  };

  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        maskClosable: false,
        closable: false,
        width: 600,
        title: isTokenExpired
          ? ''
          : gettextCatalog.getString(`Settings for communication`),
        bodyStyle: { padding: 8 },
        footer: null,
      }}
    >
      <Spin spinning={isLoading}>
        {!isTokenExpired ? (
          <>
            {contactsInfo.length > 1 && (
              <Container>
                <Subtitle>
                  {gettextCatalog.getString(
                    'Several contacts in our system share your email address {{email}} - select the contact you want to make changes for in the dropdown',
                    { email }
                  )}
                </Subtitle>

                <Select
                  value={contactId}
                  style={{ width: '50%' }}
                  onChange={(value) => setContactId(value)}
                  defaultValue={
                    contactsInfo.find((contact) => contact.receiver === true).id
                  }
                >
                  {contactsInfo.map((contact) => (
                    <Option key={contact.id} value={contact.id}>
                      {contact.name}
                    </Option>
                  ))}
                </Select>

                <Divider />
              </Container>
            )}
            <Form
              labelAlign="left"
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              form={form}
              onFieldsChange={() => {
                const values = form.getFieldsValue();
                const { consents, receiveCommunicationFromFilters } = values;
                const defalutConsentId = form
                  .getFieldValue('consents')
                  ?.find((consent) => consent.defaultConsent === true)?.id;
                if (
                  consents?.find((consent) => consent.id === defalutConsentId)
                    ?.consented === false
                ) {
                  form.setFieldsValue({
                    ...values,
                    newsletters: values.newsletters?.map((newsletter) => {
                      newsletter.subscribed = false;
                      return newsletter;
                    }),
                  });
                }
                if (receiveCommunicationFromFilters === false) {
                  form.setFieldsValue({
                    ...values,
                    filters: values.filters?.map((filter) => {
                      filter.subscribed = false;
                      return filter;
                    }),
                  });
                }
                if (contactId) {
                  updateContactsInfo(contactId);
                } else {
                  updateContactsInfo(contactsInfo[0]?.id);
                }
              }}
            >
              <Container>
                <Header>
                  {gettextCatalog.getString(
                    'Email subscriptions and comunication'
                  )}
                </Header>
                <Subtitle>
                  {gettextCatalog.getString(
                    'Here is all the newsletters you can subscribe to for "{{organizationName}}". Your current subscriptions are enabled.',
                    { organizationName }
                  )}
                </Subtitle>
                {currentContact && currentContact?.newsletters?.length > 0 && (
                  <>
                    <Header>
                      {gettextCatalog.getString('Newsletter lists')}
                    </Header>
                    <SectionPart
                      currentContact={currentContact}
                      categories="newsletters"
                      valuePropName="subscribed"
                      label=""
                      subLabel=""
                    />
                  </>
                )}
                {currentContact &&
                  currentContact?.participantLists?.length > 0 && (
                    <>
                      <Divider />
                      <Header>
                        {gettextCatalog.getString('Participant lists')}
                      </Header>
                      <SectionPart
                        currentContact={currentContact}
                        categories="participantLists"
                        valuePropName="subscribed"
                        label=""
                        subLabel=""
                      />
                    </>
                  )}
                <Divider />
                {currentContact && (
                  <SectionPart
                    currentContact={currentContact}
                    categories="filters"
                    valuePropName="subscribed"
                    label={gettextCatalog.getString('Communication about')}
                    subLabel=""
                  />
                )}

                <Group>
                  <Form.Item
                    name="receiveCommunicationFromFilters"
                    valuePropName="checked"
                    noStyle
                  >
                    <Switch
                      checkedChildren={<CdCheckIcon />}
                      unCheckedChildren={<CdClose />}
                    />
                  </Form.Item>
                  <div>
                    <Header>
                      {gettextCatalog.getString(
                        'Practical email based information from "{{organizationName}}"',
                        { organizationName }
                      )}
                    </Header>
                    <Subtitle>
                      {gettextCatalog.getString(
                        'Typically practical information about activities that you are engaged in or have signed up for.'
                      )}
                    </Subtitle>
                  </div>
                </Group>

                {currentContact && currentContact?.consents?.length > 0 && (
                  <>
                    <Divider />
                    <Header>{gettextCatalog.getString('Consent')}</Header>
                    <SectionPart
                      currentContact={currentContact}
                      categories="consents"
                      valuePropName="consented"
                      label={gettextCatalog.getString('I agree to')}
                      subLabel={gettextCatalog.getString(
                        'if you revoke this consent you will no longer receive newsletter communication from "{{organizationName}}"',
                        { organizationName }
                      )}
                    />
                  </>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    padding: '16px 0',
                  }}
                >
                  <Button
                    type="primary"
                    onClick={async () => {
                      await onFinish();
                      close();
                    }}
                  >
                    {gettextCatalog.getString('Confirm')}
                  </Button>
                </div>
              </Container>
            </Form>
          </>
        ) : (
          <Alert
            message={gettextCatalog.getString(
              'The link you clicked has expired. Please try again.'
            )}
            type="warning"
            showIcon
          />
        )}
      </Spin>
    </Modal>
  );
};

const SectionPart = ({
  currentContact,
  categories,
  valuePropName,
  label,
  subLabel,
}) => (
  <>
    {currentContact[categories]?.map((category, index) => (
      <Group key={category.id}>
        <Form.Item hidden name={[categories, index, 'id']} noStyle>
          <Input />
        </Form.Item>
        <Form.Item hidden name={[categories, index, 'name']} noStyle>
          <Input />
        </Form.Item>
        <Form.Item hidden name={[categories, index, 'url']} noStyle>
          <Input />
        </Form.Item>
        <Form.Item hidden name={[categories, index, 'defaultConsent']} noStyle>
          <Input />
        </Form.Item>
        <Form.Item
          name={[categories, index, valuePropName]}
          valuePropName="checked"
          noStyle
        >
          <Switch
            checkedChildren={<CdCheckIcon />}
            unCheckedChildren={<CdClose />}
          />
        </Form.Item>
        <div>
          <Header>
            {label}{' '}
            {category.url ? (
              <Link href={category.url} target="blank">
                {category.name}
              </Link>
            ) : (
              category.name
            )}
          </Header>
          {category.defaultConsent && category.defaultConsent === true && (
            <Subtitle>{subLabel}</Subtitle>
          )}
        </div>
      </Group>
    ))}
  </>
);
