import moment from 'moment';
import { Space, Typography } from 'antd';
import React from 'react';

import { ColumnType } from '@/react/shared/components/cd-table';
import gettextCatalog from '@/react/services/I18nService';
import { OrderType } from '@/react/shared/models/table';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { SearchCalendarPrintTableData } from '@/react/calendar/reports/types/report.types';
import { renderColor } from '@/react/calendar/reports/common/Helper';
import { getCommaFormattedNumber } from '@/react/shared/utils';

export const SearchCalendarPrintTableColumn = (
  type: string,
  isMultiParish: boolean
) => {
  const goToEvent = (data) => {
    if (data.type === 'absence') {
      return getStateUrl(`app.private.calendar.absence`, {
        id: data.id,
      });
    } else if (data.type === 'event') {
      return getStateUrl(`app.private.calendar.event`, {
        id: data.id,
      });
    }
  };
  const getSuffix = (minutes) => {
    if (minutes === 0) return;

    if (minutes >= 60) {
      // const numHours = getCommaFormattedNumber(minutes / 60, 1);
      const numHours = minutes / 60; // Use number for calculation
      const formattedNumHours = getCommaFormattedNumber(numHours, 1);
      return gettextCatalog.getPlural(
        numHours,
        '1 hour',
        '{{numHours}} hours',
        {
          numHours: formattedNumHours,
        }
      );
    } else {
      // Show minutes
      return gettextCatalog.getString('{{amount}} minutes', {
        amount: minutes,
      });
    }
  };
  const searchColumns: ColumnType<SearchCalendarPrintTableData>[] = [
    {
      key: 'startDate',
      title: gettextCatalog.getString('Start date'),
      dataIndex: 'startDate',
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
      defaultSortOrder: 'ascend',
      orderType: OrderType.DATE,
      render: (data) => moment(data).format('ddd LL HH:mm'),
    },
    {
      key: 'endDate',
      title: gettextCatalog.getString('End date'),
      dataIndex: 'endDate',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
      orderType: OrderType.DATE,
      render: (data) => moment(data).format('ddd LL HH:mm'),
    },
    {
      key: 'preparationStartDate',
      dataIndex: 'preparationStartDate',
      title: gettextCatalog.getString('Preparation time'),
      render: (data, record) => {
        const minutes = moment(record.startDate).diff(data, 'minutes');
        return getSuffix(minutes);
      },
    },
    {
      key: 'cleanupEndDate',
      dataIndex: 'cleanupEndDate',
      title: gettextCatalog.getString('Clean up time'),
      render: (data, record) => {
        const minutes = moment(data).diff(record?.endDate, 'minutes');
        return getSuffix(minutes);
      },
    },
    {
      key: 'title',
      title: gettextCatalog.getString('Title'),
      defaultSortOrder: 'ascend',
      dataIndex: 'title',
      orderType: OrderType.STRING,
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (data, record) => <a href={goToEvent(record)}>{data}</a>,
    },
    {
      key: 'description',
      title: gettextCatalog.getString('Description'),
      hidden: true,
      dataIndex: 'description',
    },
    {
      key: 'group',
      title: gettextCatalog.getString('Shared with'),
      dataIndex: 'group',
      render: (data) => {
        const groupNames = data.map((group) => group.name).join(', ');
        return <span>{groupNames}</span>;
      },
    },
    {
      key: 'locationName',
      title: gettextCatalog.getString('Location name'),
      dataIndex: 'locationName',
    },
    {
      key: 'location',
      title: gettextCatalog.getString('Address', null, 'Print calendar'),
      hidden: true,
      dataIndex: 'location',
    },
    {
      key: 'contributor',
      title: gettextCatalog.getString('Contributor'),
      dataIndex: 'contributor',
    },
    {
      key: 'price',
      title: gettextCatalog.getString('Price'),
      hidden: true,
      dataIndex: 'price',
    },
    {
      key: 'internalNote',
      title: gettextCatalog.getString('Internal note'),
      hidden: true,
      dataIndex: 'internalNote',
    },
    {
      key: 'churches',
      title: gettextCatalog.getString('Parishes'),
      dataIndex: 'churches',
      hidden: !isMultiParish,
      render: (data) =>
        data &&
        data
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((church) => (
            <Space direction="vertical">
              <Space direction="horizontal">
                {renderColor({ color: church.color })}
                <span>{church.name}</span>
              </Space>
            </Space>
          )),
    },
    {
      key: 'resources',
      title: gettextCatalog.getString('Resources'),
      dataIndex: 'resources',
      render: (resources) => {
        if (!resources || Object.keys(resources).length === 0) {
          return null;
        }
        const sortedKeys = Object.keys(resources).sort((a, b) => {
          const nameA = resources[a].name;
          const nameB = resources[b].name;
          return nameA.localeCompare(nameB);
        });

        return (
          <Space direction="vertical">
            {sortedKeys.map((key) => {
              const resource = resources[key];
              return (
                <Space key={key} direction="horizontal">
                  {renderColor({ color: resource.color })}
                  {resource.name}
                </Space>
              );
            })}
          </Space>
        );
      },
    },
    {
      key: 'users',
      title: gettextCatalog.getString('Users'),
      dataIndex: 'users',
      render: (resources) => {
        if (!resources || Object.keys(resources).length === 0) {
          return null;
        }
        const sortedKeys = Object.keys(resources).sort((a, b) => {
          const resourceA = resources[a];
          const resourceB = resources[b];
          const nameA = resourceA.name || resourceA.email;
          const nameB = resourceB.name || resourceB.email;
          return nameA.localeCompare(nameB);
        });

        return (
          <Space direction="vertical">
            {sortedKeys.map((key) => {
              const resource = resources[key];
              return resource.name || resource.email;
            })}
          </Space>
        );
      },
    },
    {
      key: 'taxonomies',
      title: gettextCatalog.getString('Categories'),
      dataIndex: 'taxonomies',
      render: (resources) => {
        if (!resources || Object.keys(resources).length === 0) {
          return null;
        }
        const sortedKeys = Object.keys(resources).sort((a, b) => {
          const nameA = resources[a].name;
          const nameB = resources[b].name;
          return nameA.localeCompare(nameB);
        });
        return (
          <Space direction="vertical">
            {sortedKeys.map((key) => {
              const resource = resources[key];
              return (
                <Space key={key} direction="horizontal">
                  {renderColor({ color: resource.color })}
                  {resource.name}
                </Space>
              );
            })}
          </Space>
        );
      },
    },
    {
      key: 'priests',
      title: gettextCatalog.getString('Priest (Assigned from Rota)'),
      dataIndex: 'priests',
      render: (data) =>
        data &&
        data.map((priest) => (
          <Space direction="vertical">
            <Space direction="horizontal">
              <Typography.Text>{priest.firstName}</Typography.Text>
              <Typography.Text>{priest.lastName}</Typography.Text>
            </Space>
          </Space>
        )),
    },
    {
      key: 'priestsInitials',
      title: gettextCatalog.getString('Priest Initials (Assigned from Rota)'),
      dataIndex: 'priests',
      render: (data) =>
        data &&
        data.map((priest) => (
          <Space direction="vertical">
            <Space direction="horizontal">
              <Typography.Text>{priest.initials}</Typography.Text>
            </Space>
          </Space>
        )),
    },
    {
      key: 'intentions',
      title: gettextCatalog.getString('Intentions'),
      dataIndex: 'intentions',
      render: (data) =>
        data &&
        data.map((intention) => (
          <Space direction="vertical">
            <Space direction="horizontal">
              {renderColor({ size: 4 })}
              <Typography.Text>{intention.intentionText}</Typography.Text>
            </Space>
          </Space>
        )),
    },
  ];
  const activityListColumns: ColumnType<SearchCalendarPrintTableData>[] = [
    {
      key: 'startDate',
      title: gettextCatalog.getString('Start date'),
      dataIndex: 'startDate',
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
      defaultSortOrder: 'ascend',
      orderType: OrderType.DATE,
      render: (data) => moment(data).format('ddd LL HH:mm'),
    },
    {
      key: 'endDate',
      title: gettextCatalog.getString('End date'),
      dataIndex: 'endDate',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
      orderType: OrderType.DATE,
      render: (data) => moment(data).format('ddd LL HH:mm'),
    },
    {
      key: 'title',
      title: gettextCatalog.getString('Title'),
      defaultSortOrder: 'ascend',
      dataIndex: 'title',
      orderType: OrderType.STRING,
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (data, record) => <a href={goToEvent(record)}>{data}</a>,
    },
    {
      key: 'locationName',
      title: gettextCatalog.getString('Location name'),
      dataIndex: 'locationName',
    },
    {
      key: 'contributor',
      title: gettextCatalog.getString('Contributor'),
      dataIndex: 'contributor',
    },
    {
      key: 'price',
      title: gettextCatalog.getString('Price'),
      dataIndex: 'price',
    },
    {
      key: 'churches',
      title: gettextCatalog.getString('Parishes'),
      dataIndex: 'churches',
      render: (data) =>
        data &&
        data
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((church) => (
            <Space direction="vertical">
              <Space direction="horizontal">
                {renderColor({ color: church.color })}
                <span>{church.name}</span>
              </Space>
            </Space>
          )),
    },
    {
      key: 'resources',
      title: gettextCatalog.getString('Resources'),
      dataIndex: 'resources',
      render: (resources) => {
        if (!resources || Object.keys(resources).length === 0) {
          return null;
        }
        const sortedKeys = Object.keys(resources).sort((a, b) => {
          const nameA = resources[a].name;
          const nameB = resources[b].name;
          return nameA.localeCompare(nameB);
        });

        return (
          <Space direction="vertical">
            {sortedKeys.map((key) => {
              const resource = resources[key];
              return (
                <Space key={key} direction="horizontal">
                  {renderColor({ color: resource.color })}
                  {resource.name}
                </Space>
              );
            })}
          </Space>
        );
      },
    },
    {
      key: 'users',
      title: gettextCatalog.getString('Users'),
      dataIndex: 'users',
      render: (resources) => {
        if (!resources || Object.keys(resources).length === 0) {
          return null;
        }
        const sortedKeys = Object.keys(resources).sort((a, b) => {
          const resourceA = resources[a];
          const resourceB = resources[b];
          const nameA = resourceA.name || resourceA.email;
          const nameB = resourceB.name || resourceB.email;
          return nameA.localeCompare(nameB);
        });

        return (
          <Space direction="vertical">
            {sortedKeys.map((key) => {
              const resource = resources[key];
              return resource.name || resource.email;
            })}
          </Space>
        );
      },
    },
    {
      key: 'taxonomies',
      title: gettextCatalog.getString('Categories'),
      dataIndex: 'taxonomies',
      render: (resources) => {
        if (!resources || Object.keys(resources).length === 0) {
          return null;
        }
        const sortedKeys = Object.keys(resources).sort((a, b) => {
          const nameA = resources[a].name;
          const nameB = resources[b].name;
          return nameA.localeCompare(nameB);
        });
        return (
          <Space direction="vertical">
            {sortedKeys.map((key) => {
              const resource = resources[key];
              return (
                <Space key={key} direction="horizontal">
                  {renderColor({ color: resource.color })}
                  {resource.name}
                </Space>
              );
            })}
          </Space>
        );
      },
    },
    {
      key: 'priests',
      title: gettextCatalog.getString('Priest (Assigned from Rota)'),
      dataIndex: 'priests',
      render: (data) =>
        data &&
        data.map((priest) => (
          <Space direction="vertical">
            <Space direction="horizontal">
              <Typography.Text>{priest.firstName}</Typography.Text>
              <Typography.Text>{priest.lastName}</Typography.Text>
            </Space>
          </Space>
        )),
    },
    {
      key: 'priestsInitials',
      title: gettextCatalog.getString('Priest Initials (Assigned from Rota)'),
      dataIndex: 'priests',
      render: (data) =>
        data &&
        data.map((priest) => (
          <Space direction="vertical">
            <Space direction="horizontal">
              <Typography.Text>{priest.initials}</Typography.Text>
            </Space>
          </Space>
        )),
    },
    {
      key: 'intentions',
      title: gettextCatalog.getString('Intentions'),
      dataIndex: 'intentions',
      render: (data) =>
        data &&
        data.map((intention) => (
          <Space direction="vertical">
            <Space direction="horizontal">
              {renderColor({ size: 4 })}
              <Typography.Text>{intention.intentionText}</Typography.Text>
            </Space>
          </Space>
        )),
    },
  ];
  const absenceColumns: ColumnType<SearchCalendarPrintTableData>[] = [
    {
      key: 'startDate',
      title: gettextCatalog.getString('Start date'),
      dataIndex: 'startDate',
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
      defaultSortOrder: 'ascend',
      orderType: OrderType.DATE,
      render: (data) => moment(data).format('ddd LL HH:mm'),
    },
    {
      key: 'endDate',
      title: gettextCatalog.getString('End date'),
      dataIndex: 'endDate',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
      orderType: OrderType.DATE,
      render: (data) => moment(data).format('ddd LL HH:mm'),
    },
    {
      key: 'title',
      title: gettextCatalog.getString('Title'),
      defaultSortOrder: 'ascend',
      dataIndex: 'title',
      orderType: OrderType.STRING,
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (data, record) => <a href={goToEvent(record)}>{data}</a>,
    },
    {
      key: 'group',
      title: gettextCatalog.getString('Shared with'),
      dataIndex: 'group',
      render: (data) => {
        const groupNames = data.map((group) => group.name).join(', ');
        return <span>{groupNames}</span>;
      },
    },
    {
      key: 'users',
      title: gettextCatalog.getString('Users'),
      dataIndex: 'users',
      render: (resources) => {
        if (!resources || Object.keys(resources).length === 0) {
          return null;
        }
        const sortedKeys = Object.keys(resources).sort((a, b) => {
          const resourceA = resources[a];
          const resourceB = resources[b];
          const nameA = resourceA.name || resourceA.email;
          const nameB = resourceB.name || resourceB.email;
          return nameA.localeCompare(nameB);
        });

        return (
          <Space direction="vertical">
            {sortedKeys.map((key) => {
              const resource = resources[key];
              return resource.name || resource.email;
            })}
          </Space>
        );
      },
    },
  ];
  switch (type) {
    case 'search':
      return searchColumns;
    case 'activityList':
      return activityListColumns;
    case 'absence':
      return absenceColumns;
    default:
      return searchColumns;
  }
};
