/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Form, Select, DatePicker, Space, Alert } from 'antd';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbtack,
  faHistory,
  faPrint,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

import gettextCatalog from '../../../services/I18nService';
import { handleSuccessMessage } from '../../../shared/utils';
import { StoleStatusTypes } from '../../models/stole';
import { navigate } from '../../../services/StateServiceFactory';
import { getStoleBillingEvents } from '../../redux/stoles/Selectors';
import { getChurches } from '../../../shared/store/resources';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import { isLoading as isLoadingSelector } from '../../../shared/loading/redux/Selectors';
import {
  getStyledInlineForm,
  StyledTable,
} from '../../../shared/antd/StyledAntdComponents';
import {
  fetchStoleBillingEvents,
  clearStoleBillingEvents,
  generateStoleBillingReport,
} from '../../redux/stoles/Actions';
import StoleService from '../../services/StoleService';
import { PaymentMethodTypes } from '../../models/intention';
import { FEvent } from '../../../calendar/models/calendar';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

// Antd
const DatePickerMoment =
  DatePicker.generatePicker<Moment>(momentGenerateConfig);
const { Option } = Select;
const StyledInlineForm = getStyledInlineForm(150, 320);

const StolesBilling: FunctionComponent = () => {
  const dispatch = useDispatch();

  const churches = useSelector(getChurches);
  const _billingEvents = useSelector(getStoleBillingEvents);
  const billingEvents: FEvent[] =
    _billingEvents && _billingEvents.asMutable({ deep: true });
  const isLoading: boolean = useSelector(isLoadingSelector);
  const [selectedChurch, setSelectedChurch] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<Moment>();

  const [form] = Form.useForm();
  const title: string = gettextCatalog.getString('Stoles Billing');

  // Data for backend requests
  const month = moment(selectedDate).month();
  const year = moment(selectedDate).year();
  const churchId: number = selectedChurch;

  // Update billing status if there are intentions

  let billingStatus = null;
  if (billingEvents) {
    const completedStole = _.find(billingEvents, [
      'stole.status',
      StoleStatusTypes.COMPLETED,
    ]);
    const openStole = _.find(billingEvents, [
      'stole.status',
      StoleStatusTypes.OPEN,
    ]);

    // All stoles are assigned and not completed
    if (openStole && !completedStole) {
      billingStatus = StoleStatusTypes.OPEN;
    } else if (!openStole && completedStole) {
      billingStatus = StoleStatusTypes.COMPLETED;
    }
  }

  // Initial load
  useEffect(() => {
    updateBillingEvents();
  }, []);

  useEffect(() => {
    updateBillingEvents();
  }, [selectedChurch]);

  // Initial load
  useEffect(() => {
    updateBillingEvents();
  }, [selectedDate]);

  const handleChurchUpdate = (church: number) => {
    setSelectedChurch(church);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const back = () => {
    navigate('app.private.intention.stoles.overview');
  };

  // Move stoles from assigned to completed
  const processBilling = () => {
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Process Billing'),
      message: gettextCatalog.getString(
        'Do you want to process the stole billing?'
      ),
      onOk() {
        StoleService.processBilling({ month, year, churchIds: [churchId] })
          .then(() => {
            handleSuccessMessage(
              gettextCatalog.getString('Successfully processed billing.')
            );
            generateBillingReport();
            updateBillingEvents();
          })
          .catch(ErrorHandlingService.handleError);
      },
    });
  };

  // Move stoles from completed to assigned
  const revertBilling = () => {
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Revert Billing'),
      message: gettextCatalog.getString('Do you want to revert billing?'),
      onOk() {
        StoleService.revertBilling({ month, year, churchIds: [churchId] })
          .then(() => {
            handleSuccessMessage(
              gettextCatalog.getString('Successfully restored billing.')
            );
            updateBillingEvents();
          })
          .catch(ErrorHandlingService.handleError);
      },
    });
  };

  const generateBillingReport = () => {
    dispatch(
      generateStoleBillingReport({ month, year, churchIds: [churchId] })
    );
  };

  const updateBillingEvents = () => {
    // If a month is selected
    if (selectedDate && selectedChurch) {
      dispatch(fetchStoleBillingEvents({ month, year, churchIds: [churchId] }));
    } else {
      dispatch(clearStoleBillingEvents());
    }
  };

  const invalidateMouseEvent = (event: React.MouseEvent): void => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();
  };

  const navigateToEvent = (calendarId: number, event: React.MouseEvent) => {
    invalidateMouseEvent(event);
    navigate('app.private.calendar.event', { id: calendarId });
  };

  // Set disabled months in month selector
  const disabledDate = (current) =>
    current && current > moment().startOf('month');

  const areStolesUnpaid = () => _.some(billingEvents, ['stole.paid', false]);

  const shouldDisableProcessButton = () =>
    !selectedDate ||
    !selectedChurch ||
    _.isEmpty(billingEvents) ||
    billingStatus !== StoleStatusTypes.OPEN ||
    areStolesUnpaid();

  const shouldDisableRevertButton = () =>
    !selectedDate ||
    !selectedChurch ||
    _.isEmpty(billingEvents) ||
    billingStatus !== StoleStatusTypes.COMPLETED ||
    areStolesUnpaid();

  const shouldDisableGenerateReportButton = () =>
    !selectedDate ||
    !selectedChurch ||
    _.isEmpty(billingEvents) ||
    billingStatus !== StoleStatusTypes.COMPLETED ||
    areStolesUnpaid();

  // Get table config based on state
  const columns: any = [
    {
      title: 'Nummer',
      dataIndex: ['stole', 'formattedReferenceNumber'],
      key: 'formattedReferenceNumber',
    },
    {
      title: 'Stoltext',
      dataIndex: ['stole', 'stoleText'],
      key: 'stoleText',
    },
    {
      title: 'Zahlungsstatus',
      dataIndex: ['stole', 'paid'],
      key: 'paid',
      align: 'center',
      render: function columnRender(paid: boolean) {
        return (
          <div>
            {paid ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} />
            )}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Status'),
      dataIndex: ['stole', 'status'],
      key: 'status',
      render: function columnRender(status: StoleStatusTypes) {
        return (
          <div>
            <label>
              {status === StoleStatusTypes.OPEN
                ? gettextCatalog.getString('Open')
                : gettextCatalog.getString('Completed')}
            </label>
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Fee'),
      dataIndex: ['stole', 'fee', 'name'],
      key: 'fee',
    },
    {
      title: gettextCatalog.getString('Amount'),
      dataIndex: ['stole', 'fee', 'amount'],
      key: 'amount',
      render: function columnRender(amount: number) {
        return (
          <Space>
            {_.get(window, 'cdApp.organization.currency')}
            {amount}
          </Space>
        );
      },
    },
    {
      title: 'Pfarrei',
      dataIndex: ['stole', 'church', 'name'],
      key: 'church',
    },
    {
      title: gettextCatalog.getString('Resource'),
      dataIndex: ['stole', 'resource', 'name'],
      key: 'resource',
    },
    {
      title: gettextCatalog.getString('Comment'),
      dataIndex: ['stole', 'comment'],
      key: 'comment',
    },
    {
      title: 'Zahlungsmethode',
      dataIndex: ['stole', 'paymentMethod'],
      key: 'paymentMethod',
      render: function columnRender(paymentMethod: PaymentMethodTypes) {
        return (
          <div>
            {paymentMethod === PaymentMethodTypes.PAIDBYCASH
              ? 'Bar'
              : 'Überweisung'}
          </div>
        );
      },
    },
    {
      title: 'Kalendereintrag',
      dataIndex: null,
      key: 'event',
      render: function columnRender(calendarEvent: FEvent) {
        return (
          <div>
            <a
              onClick={(event: React.MouseEvent) =>
                navigateToEvent(calendarEvent.id, event)
              }
            >
              <Space direction="vertical">
                <label>
                  {gettextCatalog.formatDate(
                    calendarEvent.startDate as Date,
                    'LLL'
                  )}
                </label>
                <span>{calendarEvent.title}</span>
              </Space>
            </a>
          </div>
        );
      },
    },
  ];

  // Return the stoles home screen
  return (
    <CdPage
      pageHeaderProps={{
        title,
        extra: (
          <Button onClick={back}>{gettextCatalog.getString('Back')}</Button>
        ),
      }}
    >
      <StyledInlineForm form={form} layout="vertical">
        {/* Church - Required */}
        <Form.Item
          name="churches"
          label={gettextCatalog.getString('Select a church:')}
          required={true}
        >
          <Select
            placeholder="Pfarrei"
            style={{ width: '50%' }}
            onChange={handleChurchUpdate}
            allowClear
            defaultValue={selectedChurch}
          >
            {churches.map((church) => (
              <Option key={church.id} value={church.id}>
                {_.get(church, 'name')}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* Month Selector */}
        <Form.Item
          name="month"
          label={gettextCatalog.getString('Select a month to bill:')}
          required={true}
        >
          <DatePickerMoment
            style={{ width: '50%' }}
            picker="month"
            disabledDate={disabledDate}
            onChange={handleDateChange}
            defaultValue={selectedDate ? moment(selectedDate) : null}
          />
        </Form.Item>
        {/* Stoles list */}
        <Form.Item>
          <Space style={{ paddingBottom: 8, paddingTop: 8 }}>
            <Button
              onClick={processBilling}
              disabled={shouldDisableProcessButton()}
            >
              <FontAwesomeIcon icon={faThumbtack} style={{ marginRight: 8 }} />
              {gettextCatalog.getString('Process Billing')}
            </Button>
            <Button
              onClick={revertBilling}
              disabled={shouldDisableRevertButton()}
            >
              <FontAwesomeIcon icon={faHistory} style={{ marginRight: 8 }} />
              {gettextCatalog.getString('Revert Billing')}
            </Button>
            <Button
              onClick={generateBillingReport}
              disabled={shouldDisableGenerateReportButton()}
            >
              <FontAwesomeIcon icon={faPrint} style={{ marginRight: 8 }} />
              {gettextCatalog.getString('Generate Billing Report')}
            </Button>
          </Space>

          {areStolesUnpaid() ? (
            <Alert
              message={gettextCatalog.getString(
                'In order to process or print a month, all stoles must be paid'
              )}
              type="warning"
              style={{ marginTop: 8, marginBottom: 8 }}
              showIcon
            />
          ) : null}
          <StyledTable
            id="stolesBillingTable"
            scroll={{ x: true }}
            bordered={true}
            rowKey="id"
            dataSource={billingEvents}
            columns={columns}
            loading={isLoading}
            rowClassName={(record) =>
              _.get(record, 'stole.paid') ? null : 'warningRow'
            }
          />
        </Form.Item>
      </StyledInlineForm>
    </CdPage>
  );
};

export default StolesBilling;
