import React from 'react';
import PropTypes from 'prop-types';
import { $filter } from 'ngimport';
import { Typography } from 'antd';
import { isUndefined } from 'lodash';

import gettextCatalog from '@/react/services/I18nService';

const TaskCellContent = ({ task, hideNotes, isPrinting }) => (
  <div
    className={
      isPrinting
        ? 'rotas-table__task-cell-content-print'
        : 'rotas-table__task-cell-content'
    }
  >
    <ul className="rotas-table__task-users">
      {task.users && task.users.length
        ? task.users.map((user, index) => {
            // @ts-ignore
            const name = $filter('getName')(user);
            return (
              <li
                key={user.id + index + task.id + 'userListTasKlCel'}
                style={{ wordWrap: 'break-word' }}
                className={
                  (user.status === 'blocked' && 'text-danger') || undefined
                }
              >
                {name}
              </li>
            );
          })
        : null}
      {task.unassigned ? (
        <li className="text-danger">
          {gettextCatalog.getString('{{count}} x Missing', {
            count: task.unassigned,
          })}
        </li>
      ) : null}
    </ul>
    {!hideNotes && task.note ? (
      <div>
        <hr className="u-my-10" />
        <Typography.Paragraph className="pre-line" style={{ fontSize: '12px' }}>
          {task.note}
        </Typography.Paragraph>
      </div>
    ) : null}
  </div>
);

TaskCellContent.propTypes = {
  task: PropTypes.object,
  hideNotes: PropTypes.bool,
  isPrinting: PropTypes.bool,
};

export const TaskCell = ({
  task,
  event,
  isPublic,
  hideNotes,
  onAssignUser,
  isPrinting,
  scope,
}: {
  task: any;
  event: any;
  isPublic?: boolean;
  hideNotes: boolean;
  onAssignUser?: any;
  isPrinting?: boolean;
  scope: any;
}) => {
  const cellIsDisabled = isUndefined(task.unassigned);
  const onClick = (e) => {
    e.preventDefault();
    if (cellIsDisabled) return;
    onAssignUser.apply(scope, [e, event.eventId, task.id]);
  };
  const OnKeyPress = (e) => {
    if (e.keyCode === 13) {
      onClick(e);
    }
  };

  if (isPublic || isPrinting) {
    return (
      <TaskCellContent
        task={task}
        hideNotes={hideNotes}
        isPrinting={isPrinting}
      />
    );
  } else {
    return (
      <a onClick={onClick} onKeyPress={OnKeyPress}>
        {/* Show CTA on hover */}
        <div className="rotas-table__hover">
          {/* When users are assigned */}
          {task.users && task.users.length ? (
            <div className="u-flex u-align-items-baseline">
              <i className="fa fa-pencil-alt text-info u-pr-5" />
              <div className="text-info">
                {gettextCatalog.getString('Edit')}
              </div>
            </div>
          ) : null}

          {!cellIsDisabled && !task.users.length ? (
            <div className="u-flex u-align-items-baseline">
              <i className="fa fa-plus text-success u-pr-5" />
              <div className="text-success">
                {gettextCatalog.getString('Assign')}
              </div>
            </div>
          ) : null}
        </div>
        <TaskCellContent task={task} hideNotes={hideNotes} />
      </a>
    );
  }
};
