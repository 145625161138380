import React from 'react';

import { ColumnType } from '../../../shared/components/cd-table';
import {
  EventWithStudioItems,
  IEventInvitationForFrontendLightweight,
} from '../../types/studio';
import gettextCatalog from '../../../services/I18nService';
import {
  DisplayDateSimple,
  DisplayDateSpanSimple,
} from '../../../shared/components/FormattedDate';
import { navigate } from '../../../services/StateServiceFactory';
import { MessageType } from '../../../people/types/message';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const PostersAndInvitesTableColumns = (canCommunicate: boolean) => {
  const navigateToPoster = (posterId, calendarId) =>
    navigate('app.private.calendar.poster', { posterId, calendarId });

  const navigateToInvitation = (messageId) =>
    navigate(
      'app.private.people.messages.editorV2.view',
      { messageId, type: MessageType.EVENT_INVITATION },
      { reload: false }
    );
  const columns: ColumnType<EventWithStudioItems>[] = [
    {
      dataIndex: 'studioItemsLastUpdatedAt',
      title: gettextCatalog.getString('Created'),
      active: true,
      defaultSortOrder: 'descend',
      render: function SimpleDate(date) {
        return (
          <DisplayDateSimple
            date={date}
            allDay={false}
            showRelativeTime={true}
          />
        );
      },
    },
    {
      key: 'eventDate',
      dataIndex: null,
      title: gettextCatalog.getString('Event date'),
      active: true,
      render: function SimpleDate(event: EventWithStudioItems) {
        return (
          <DisplayDateSpanSimple
            start={event.startDate}
            end={event.endDate}
            allDay={event.allDay}
            showEndTime
          />
        );
      },
    },
    {
      dataIndex: 'title',
      title: gettextCatalog.getString('Event title'),
      active: true,
    },
    {
      key: 'posters',
      dataIndex: null,
      title: gettextCatalog.getString('Posters'),
      active: true,
      render: function columnRender(event: EventWithStudioItems) {
        return (
          <div>
            {event.posters &&
              event.posters.map((poster, index) => (
                <div key={index}>
                  <a
                    key={index}
                    onClick={() => navigateToPoster(poster.id, event.id)}
                  >
                    {poster.title}
                  </a>
                </div>
              ))}
          </div>
        );
      },
    },
    {
      dataIndex: 'invitations',
      title: gettextCatalog.getString('Invitations'),
      active: true,
      render: function columnRender(
        invitations: IEventInvitationForFrontendLightweight[]
      ) {
        return (
          <div>
            {invitations &&
              invitations.map((invitation, index) => (
                <CdTooltip
                  title={
                    !canCommunicate
                      ? gettextCatalog.getString(
                          'You don’t have permission to view invites.'
                        )
                      : undefined
                  }
                  key={`invites-${index}`}
                >
                  <div key={index}>
                    <a
                      key={index}
                      onClick={() =>
                        canCommunicate && navigateToInvitation(invitation.id)
                      }
                    >
                      {invitation.title}
                    </a>
                  </div>
                </CdTooltip>
              ))}
          </div>
        );
      },
    },
  ];
  return columns;
};
