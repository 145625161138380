import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Input,
  Row,
  Col,
  Select,
  Layout,
  Result,
  Button,
  TreeSelect,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import mountingContainer from '../mountContainer';
import {
  loadPartnerFiles,
  onFileSelected,
  importPartnerFile,
  onPageChange,
  onSetPageSize,
  partnerFilesGetFilters,
  closeModal,
} from '../../store/actions';
import FileList from '../../components/file-list';
import { PartnerFile } from '../../store/actions/partner';

import {
  makeSelectFiles,
  makeSelectTotalCount,
  makeSelectPartnerId,
  makeSelectSelectedFile,
  makeSelectCurrentPage,
  makeSelectPageSize,
  makeSelectPageSizeOptions,
  makeSelectCallback,
  makeSelectPartnerFilesLoading,
  makeSelectPartnerFilesPublicationFilters,
  makeSelectIsGebCustomer,
  makeSelectIsUsedFromEditor,
} from './selectors';
import SideBar from './side-bar';
import { typeFilters, tagFilters, tagMapping } from './filters';

import cdApp from '@/react/config';
import gettextCatalog from '@/react/services/I18nService';

const { Search } = Input;
const { Content } = Layout;

const mapDispatchToProps = (
  dispatch: React.Dispatch<{ type: string; payload?: any }>
) => ({
  loadPartnerFiles: (payload) => dispatch(loadPartnerFiles(payload)),
  onFileSelected: (fileId) => dispatch(onFileSelected(fileId)),
  importPartnerFile: (fileId, partnerId, callback, imageFormat) =>
    dispatch(importPartnerFile(fileId, partnerId, callback, imageFormat)),
  onPageChange: (page) => dispatch(onPageChange(page)),
  onSetPageSize: (pageSize) => dispatch(onSetPageSize(pageSize)),
  partnerFilesGetFilters: () => dispatch(partnerFilesGetFilters()),
  closeModal: () => dispatch(closeModal()),
});

const mapStateToProps = createStructuredSelector({
  items: makeSelectFiles(),
  count: makeSelectTotalCount(),
  partnerId: makeSelectPartnerId(),
  pageSize: makeSelectPageSize(),
  currentPage: makeSelectCurrentPage(),
  selectedFile: makeSelectSelectedFile(),
  pageSizeOptions: makeSelectPageSizeOptions(),
  callback: makeSelectCallback(),
  partnerFilesLoading: makeSelectPartnerFilesLoading(),
  publicationFilters: makeSelectPartnerFilesPublicationFilters(),
  isGebCustomer: makeSelectIsGebCustomer(),
  isUsedFromEditor: makeSelectIsUsedFromEditor(),
});

type OwnProps = {
  partnerId: string;
  isGebCustomer: boolean;
  isUsedFromEditor: boolean;
  callback: any;
};

interface Props {
  partnerId: string;
  items: PartnerFile[];
  selectedFile: PartnerFile | undefined;
  count: number;
  pageSize: number;
  currentPage: number;
  pageSizeOptions: number[];
  partnerFilesLoading: boolean;
  publicationFilters: string[];
  isGebCustomer: boolean;
  isUsedFromEditor: boolean;
  loadPartnerFiles: any;
  onFileSelected: any;
  importPartnerFile: any;
  onPageChange: any;
  onSetPageSize: any;
  callback: any;
  partnerFilesGetFilters: any;
  closeModal: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Partner: React.FC<Props> = ({
  partnerId,
  items,
  count,
  pageSize,
  currentPage,
  selectedFile,
  partnerFilesLoading,
  publicationFilters,
  isGebCustomer,
  isUsedFromEditor,
  onFileSelected,
  importPartnerFile,
  loadPartnerFiles,
  onPageChange,
  onSetPageSize,
  callback,
  partnerFilesGetFilters,
  closeModal,
}) => {
  const [search, setSearchState] = useState();
  const [publication, setPublicationState] = useState();
  const [type, setTypeState] = useState();
  const [tags, setTagsState] = useState();

  useEffect(() => {
    loadPartnerFiles({
      partnerId,
      currentPage,
      pageSize,
      search,
      publication,
      type,
      tags,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search, publication, type, tags]);
  useEffect(() => {
    partnerFilesGetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (value: string) => {
    /* @ts-ignore */
    setSearchState(value);
    onPageChange(1);
  };

  const onPublicationFilterChange = (value: string[]) => {
    /* @ts-ignore */
    setPublicationState(value);
    onPageChange(1);
  };

  const onTypeFilterChange = (value: string[]) => {
    /* @ts-ignore */
    setTypeState(value);
    onPageChange(1);
  };

  const onTagFilterChange = (keys: string[]) => {
    const selectedTags = keys.reduce(
      (tags, key) => tags.concat(tagMapping[key]),
      []
    );
    /* @ts-ignore */
    setTagsState(selectedTags);
    onPageChange(1);
  };

  const settingsLink = `http://app.churchdesk.com/o/${cdApp.organization.id}/settings/other#gep`;

  return (
    <React.Fragment>
      {isGebCustomer ? (
        <Layout style={{ background: 'none' }}>
          <Content style={{ padding: '0 0 12px' }}>
            <Row justify="start" style={{ padding: '8px 12px 12px' }}>
              <Col flex="auto">
                <Select
                  style={{ width: 170, marginRight: 10, marginBottom: 6 }}
                  mode="multiple"
                  getPopupContainer={mountingContainer}
                  onChange={onPublicationFilterChange}
                  placeholder={gettextCatalog.getString('Publication date')}
                  maxTagPlaceholder={(selectedItems) =>
                    `${gettextCatalog.getString('{{count}} publication date', {
                      count: selectedItems.length,
                    })}`
                  }
                  maxTagCount={0}
                  allowClear
                  showArrow
                >
                  {(publicationFilters || []).map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  style={{ width: 170, marginRight: 10, marginBottom: 6 }}
                  mode="multiple"
                  getPopupContainer={mountingContainer}
                  onChange={onTypeFilterChange}
                  placeholder={gettextCatalog.getString('Types')}
                  maxTagPlaceholder={(selectedItems) =>
                    `${gettextCatalog.getString('{{count}} type', {
                      count: selectedItems.length,
                    })}`
                  }
                  maxTagCount={0}
                  allowClear
                  showArrow
                >
                  {Object.keys(typeFilters).map((item) => (
                    <Select.Option key={item} value={item}>
                      {typeFilters[item]}
                    </Select.Option>
                  ))}
                </Select>
                <TreeSelect
                  style={{ width: 170, marginBottom: 6 }}
                  treeData={tagFilters}
                  treeCheckable={true}
                  showCheckedStrategy="SHOW_PARENT"
                  placeholder={gettextCatalog.getString('Tags')}
                  onChange={onTagFilterChange}
                  getPopupContainer={mountingContainer}
                  maxTagPlaceholder={(selectedItems) =>
                    `${gettextCatalog.getString('{{count}} tag', {
                      count: selectedItems.length,
                    })}`
                  }
                  maxTagCount={0}
                  allowClear
                  showArrow
                />
              </Col>
              <Col flex="280px">
                <Search
                  enterButton
                  placeholder={gettextCatalog.getString('Search files')}
                  onSearch={onSearch}
                  allowClear
                />
              </Col>
            </Row>
            <FileList
              items={items}
              count={count}
              showSizeChanger={true}
              currentPage={currentPage}
              pageSize={pageSize}
              loading={partnerFilesLoading}
              selectedFile={selectedFile}
              onFileSelected={onFileSelected}
              onPageSizeChange={onSetPageSize}
              onPageChange={onPageChange}
            />
          </Content>
          <SideBar
            item={selectedFile}
            onImportFile={(fileId, partner, imageFormat) =>
              importPartnerFile(fileId, partner, callback, imageFormat)
            }
            isUsedFromEditor={isUsedFromEditor}
          />
        </Layout>
      ) : (
        <Result
          icon={
            <img
              src="../../img/gemeindebrief.png"
              alt="logo"
              width="auto"
              height="48"
            />
          }
          title={gettextCatalog.getString(
            'Get access to more than 18.000 images, graphics and written content!'
          )}
          subTitle={gettextCatalog.getString(
            'With a subscription to the Gemeindebrief database, you can easily create simple, beautiful and professional newsletters along with blogs and events for your website. Gemeindebrief offers a large range of appealing pictures and exciting content for any occasion.'
          )}
          extra={[
            <Button
              onClick={closeModal}
              type="primary"
              href={settingsLink}
              target="_blank"
            >
              {gettextCatalog.getString('I am already subscribing')}
            </Button>,
            <Button
              type="default"
              href="https://gemeindebrief-magazin.evangelisch.de/dazubuchen"
              target="_blank"
            >
              {gettextCatalog.getString('Become a subscriber')}{' '}
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                style={{ marginLeft: 6 }}
              />
            </Button>,
          ]}
        />
      )}
    </React.Fragment>
  );
};

const connectedPartner: React.ComponentType<OwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(Partner);
export default connectedPartner;
