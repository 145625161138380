import { Result } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import React, { useEffect } from 'react';

import getTextCatalog from '../../services/I18nService';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { CdReportSendMessageWithCircle } from '@/react/shared/components/Icons';
import gettextCatalog from '@/react/services/I18nService';

export const showTimeRegistrationReportConfirmModal = createCdModal(
  ({ setModalProps }) => {
    useEffect(() => {
      setModalProps({
        modalType: ModalType.MODAL,
        hideOk: true,
        width: 500,
        cancelText: getTextCatalog.getString('Close'),
        onCancel: () => {
          NiceModal.hide('TimeRegistrationReportConfirmModal');
        },
      });
    }, [setModalProps]);

    return (
      <Result
        status="success"
        icon={<CdReportSendMessageWithCircle />}
        title={gettextCatalog.getString(
          'The report is being generated. You will receive an email shortly.'
        )}
      />
    );
  }
);
